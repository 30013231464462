import { connect } from "react-redux";
import Orders from "./component";
import {  toast } from 'react-toastify';
import { fetchOrderDetailsRequest } from "./action";
import { useEffect, useState } from "react";
import OrderDeatails from "./component";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { mpClick } from "../../Mixpanel";
const OrderDeatailsContainer = (props) => {
const [orderdetail,setOrderdetail]=useState('')

const [loader,setLoader]=useState(false)
const {orderId} =useParams()
const navigate =useNavigate()
useEffect(()=>{
  fetchOrderDetails()
},[])
const UserJWTAuth = localStorage.getItem('UserJWTAuth');
useEffect(()=>{
 
 console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
 if(!UserJWTAuth){
   navigate('/')
 }
},[])

useEffect(()=>{
  mpClick({EventName:"order_details_page_opened"})
},[])
const totalAmount = orderdetail&&orderdetail.lineItems&&orderdetail.lineItems.reduce((accumulator, item) => accumulator + item.offerPrice*item.unitCount, 0);
console.log("totalAmount",totalAmount)

  

  const fetchOrderDetails= () => {
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  
    props.fetchOrderDetailsRequest({
      url: `/consumerbe/api/v2/order/${orderId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      },
      "request": {
        request:{

        }
        
  
  
        },
      handleErrorOrderDetails,
      handleResponseOrderDetails,
    });
  };
  
  
  const handleErrorOrderDetails=(error)=>{
    console.log("res")
    setLoader(false)
    if(error.response.status==401|| error.response.status==403){

      console.log("khan handleErrorVmInventory", error.response.data.status.code);
      //Logout
      //localStorage.removeItem("UserJWTAuth")
        navigate("/login");
  }
  else{ toast.error(`${error.response.data.status.code}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
  }
  }
  
  const handleResponseOrderDetails=(res)=>{
    console.log("handleResponseOrderDetails",res)
    setLoader(false)
    setOrderdetail(res.data.data)
  }
  





return(
    <OrderDeatails
    orderdetail={orderdetail}
    totalAmount={totalAmount}
    />
)


}



function mapStateToProps({ props }) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    fetchOrderDetailsRequest
   
  })(OrderDeatailsContainer);