import React, { useEffect ,useState} from "react";
import FaqHelpSupport from "./component";
import { fetchFaqTopicRequest,fetchOrdersHistoryRequest } from "./action";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const FaqTopicsConatiner=(props)=>{
  const navigate=useNavigate()
const [orderhistory,setOrderhistory]=useState('')
const [loader,setLoader]=useState(true);
const [topicList,setTopicList]=useState([])


useEffect(()=>{
    fetchfaqTopics()
    fetchOrderHistory()
},[])



const fetchOrderHistory = () => {
  setLoader(true)
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  
    props.fetchOrdersHistoryRequest({
      url: `/order/order/orderhistory`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      },
      "request": {
        request:{}
        
  
  
        },
      handleErrorOrderHistory ,
      handleResponseOrderHistory ,
    });
  };
  
  
  const handleErrorOrderHistory=(error)=>{
    
setLoader(false)
    console.log("res")
    toast.error(`${error.response.data.status}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    
  }
  
  const handleResponseOrderHistory=(res)=>{
    console.log("handleResponseOrderHistory",res.data.response)
   setLoader(false)
   setOrderhistory(res.data.response)
  }


    const fetchfaqTopics = () => {
        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      
        props.fetchFaqTopicRequest({
          url: `/support/api/v2/faq/topics`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
            //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid")
          },
          "request": {
            request:{}
            
      
      
            },
          handleErrorFaqTopic ,
          handleResponseFaqTopic ,
        });
      };
      
      
      const handleErrorFaqTopic=(error)=>{
        console.log("res")
        setLoader(false)
        if(error.response.status==401 || error.response.status==403){

          console.log("khan handleErrorVmInventory", error.response.data.status.code);
          //Logout
          //localStorage.removeItem("UserJWTAuth")
            navigate("/login");
      }
      else{ toast.error(`${error.response.data.status.code}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      }
        
      }
      
      const handleResponseFaqTopic=(res)=>{
        console.log("handleResponseFaqTopic-------",res.data.data
        )
        setLoader(false)
        setTopicList(res.data.data)
      }
      const FaqDetailsApi=(e,topicId)=>{

        console.log("topicId",topicId)
        navigate(`/faqDetails/${topicId}`)

        

      }

    return(
      
        <FaqHelpSupport
        topicList={topicList}
        FaqDetailsApi={FaqDetailsApi}
        orderhistory={orderhistory}
        loader={loader}
        
        />
        
    )
}
function mapStateToProps( props ) {
    return {
      props,
    };
  }

export default connect(mapStateToProps, {
    fetchFaqTopicRequest,
    fetchOrdersHistoryRequest
   
  })(FaqTopicsConatiner);
