import React, { useState } from "react";
import { Link } from "react-router-dom";
import backArrow from "../../assets/backArrow.png";
import Addbtn from "../../assets/addbtn.png";
import nextArrow from "../../assets/nextArrow.png";
import faqArrow  from '../../assets/faqArrow.png'
import WaitingLoder from "../WaitingLoader/component";
const FaqHelpSupport = (props) => {
  const [veiwallOrders,setViewAllOrders]=useState(false)
  const viewMoreButtonStyles = {
    display: "flex",
    width: "90px",
    padding: "0px 12px 0px 16px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "10px",
    height:"50px",
    border: "1px solid rgba(148, 163, 184, 0.54)",
    background: "rgba(148, 163, 184, 0.06)",
  };


  function TruncatedHeading({ text, limit }) {
    if (text&&text.length <= limit) {
      return <>{text}</>;
    }
    return <>{`${text.substring(0, limit)}...`}</>;
  }
  const TopNav = () => {
    return (
      <>
        <div className="_2456r">
          <div className="_3nxa7" data-testid="listing-header-location">
            <button
              className="_1NdRR"
              aria-label="Selected address is Delhi, India; Double tap to change address."
              id="change-address-btn-id"
            >
              <div style={{ display: "flex", "align-items": "center" }}>
                <Link to={"/profile"}>
                  <img style={{ height: "23px" }} src={backArrow} />
                </Link>
                <h3 style={{ "margin-left": "10px" }}>Help & Support</h3>
                <div style={{ marginLeft: "10px" }}></div>
              </div>
            </button>
          </div>
        </div>
      </>
    );
  };
  const viewAll=()=>{
    if(veiwallOrders){
      setViewAllOrders(false)
    }
    else{
      setViewAllOrders(true)
    }

  }
  return (
    <>
{props.loader&&<WaitingLoder/>}
      <TopNav />

      <div
        style={{
          background:
            "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
          //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.PreCardItems[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.PreCardItems[0]&&"8.5rem",
        }}
        className={"h-screen overflow-y-auto pd-36"}
      >
        <div style={{ padding: "5%", marginTop: "77px" }}>
          {false?<h1
            style={{
              fontSize: "14px",
              fontWeight: "600",
              padding: "2%",
              letterSpacing: "2%",
              marginBottom: "5%",
              padding: "2%",
              color: "#333F4E",
            }}
          >
            Need help with orders?
          </h1>:<h1
            style={{
              fontSize: "14px",
              fontWeight: "600",
              padding: "2%",
              letterSpacing: "2%",
              marginBottom: "5%",
              padding: "2%",
              color: "#333F4E",
            }}
          >
           
          Need help with recent orders?</h1>}

          {!props&&props.orderhistory&&props.orderhistory[0]&&<div
            style={{
              width: "100%",
              background: "white",
              height: "195px",
              top: "146px",
              left: "11px",
              "border-radius": "18px",
            }}
          >
            <div style={{ padding: "5%" }}>
              <h1 style={{ fontSize: "16px", color: "#333F4E" }}>
                You don't have any recent order
              </h1>
              <p
                style={{
                  fontSize: "14px",
                  color: " #384F6FC4",
                  marginTop: "9px",
                }}
              >
                Order your favourite food items from the vending machine.
              </p>
            </div>
            <div style={{ padding: "5%" }}>
              <button
                style={{
                  width: "100%",
                  height: "43px",
                  padding: "11px, 48px, 11px, 48px",
                  "border-radius": "8px",
                  gap: "10px",
                  background: "#00B177",
                  color: "white",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Order now
              </button>
            </div>


            
          </div>}
{veiwallOrders?
<div>
          {props&&props.orderhistory&&props.orderhistory.map(el => (
          <>

<div style={{padding:"2%"}}>
    <div className=" divBox" style={{ padding:"5%",}}>
      <div  >
      <div style={{padding:"2%"}}>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-lg font-bold text-blue-700 font-roboto font-semibold" style={{color:"#718198",fontSize:"15px"}}>
            Order ID: <span className="orderIdValue " style={{fontSize:"15px"}}>{el.orderId}</span>
          </p>
        </div>
        <div>
          <p className="text-lg font-bold text-blue-700 font-roboto font-semibold price">Rs.{el.amount}</p>
        </div>
      </div>
      <div>
        <p className="text-sm text-gray-600 font-roboto address">{"Lodhipur Shahjahanpur"}</p>
      </div>
      <div>

      <div className="flex mt-0">
  <p className="text-gray-700 font-roboto text-sm font-medium itemsCount">{2} items</p>
  <h3 className={`text-${'cancelled' === 'cancelled' ? 'red' : 'green-500'} font-roboto text-sm font-medium ml-1`}> • {"complete"}</h3>
</div>
      </div>
    
    </div>

 
    <div className="w-336 h-100  mt-4 flex items-center justify-between">
      
        <div className="relative flex ">
        {el.orderLineItems.slice(0, 3).map((e, index) => (
  <div key={index}>
          <img
            style={{ width: "50px", height:"50px", padding: "8px",  "margin-left":"5px",border: "1px solid #ccc",    "border-radius": "9px" }}
            src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/product/fingerchips.jpeg`}
            alt="stageuct"
          />
 
    

           </div>
))}

           {el.orderLineItems.length>3&&<div
            style={{ width: "50px",height:"50px", padding: "8px", "margin-left":"5px",border: "1px solid #ccc","border-radius":"9px",
          
            display: "flex",
            "text-align": "center",
            "align-items": "center",
            "justify-content":"space-around",
          

          }}
          className="countPlus"
            src="https://m.media-amazon.com/images/I/71kOsITKSkL._SX522_.jpg"
            alt="stageuct"
          >+{el.orderLineItems.length-3}</div>}


        </div>

        <div className="flex flex-col items-center text-blue-700">
          <div style={viewMoreButtonStyles}>
          <a style={{"color":"#718198",textDecoration:"none"}} href={`https://api.whatsapp.com/send/?phone=917289005034&text=Hello%2C I+need+assistance+with+my+order.+My+order+ID +is + ${el.orderId}&type=phone_number&app_absent=0`} className="viewDetails" ><span>HELP</span></a>
            <div  className="viewDetailsIcon">
            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"/></svg>
            </div>
          </div>
        </div>
      </div>
      </div>
      
</div>
</div>
       </>
        ))}

       {true&& <p style={{textAlign:"center","text-decoration":"underline",color:"#718198","margin-top":"5%"}} onClick={e=>{viewAll()}} >Hide Orders</p>}
   
   </div>:<div>
          {props&&props.orderhistory&&props.orderhistory.slice(0,2).map(el => (
          <>

<div style={{padding:"2%"}}>
    <div className=" divBox" style={{ padding:"5%",}}>
      <div  >
      <div style={{padding:"2%"}}>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-lg font-bold text-blue-700 font-roboto font-semibold" style={{color:"#718198",fontSize:"15px"}}>
            Order ID: <span className="orderIdValue " style={{fontSize:"15px"}}>{el.orderId}</span>
          </p>
        </div>
        <div>
          <p className="text-lg font-bold text-blue-700 font-roboto font-semibold price">Rs.{el.amount}</p>
        </div>
      </div>
      <div>
        <p className="text-sm text-gray-600 font-roboto address">{"Lodhipur Shahjahanpur"}</p>
      </div>
      <div>

      <div className="flex mt-0">
  <p className="text-gray-700 font-roboto text-sm font-medium itemsCount">{2} items</p>
  <h3 className={`text-${'cancelled' === 'cancelled' ? 'red' : 'green-500'} font-roboto text-sm font-medium ml-1`}> • {"complete"}</h3>
</div>
      </div>
    
    </div>

 
    <div className="w-336 h-100  mt-4 flex items-center justify-between">
      
        <div className="relative flex ">
        {el.orderLineItems.slice(0, 3).map((e, index) => (
  <div key={index}>
          <img
            style={{ width: "50px", height:"50px", padding: "8px",  "margin-left":"5px",border: "1px solid #ccc",    "border-radius": "9px" }}
            src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/product/fingerchips.jpeg`}
            alt="stageuct"
          />
 
    

           </div>
))}

           {el.orderLineItems.length>3&&<div
            style={{ width: "50px",height:"50px", padding: "8px", "margin-left":"5px",border: "1px solid #ccc","border-radius":"9px",
          
            display: "flex",
            "text-align": "center",
            "align-items": "center",
            "justify-content":"space-around",
          

          }}
          className="countPlus"
            src="https://m.media-amazon.com/images/I/71kOsITKSkL._SX522_.jpg"
            alt="stageuct"
          >+{el.orderLineItems.length-3}</div>}


        </div>

        <div className="flex flex-col items-center text-blue-700">
          <div style={viewMoreButtonStyles}>
          <a style={{"color":"#718198",textDecoration:"none"}} href={`https://api.whatsapp.com/send/?phone=917289005034&text=Hello%2C I+need+assistance+with+my+order.+My+order+ID +is + ${el.orderId}&type=phone_number&app_absent=0`} className="viewDetails" ><span>HELP</span></a>
            <div  className="viewDetailsIcon">
            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"/></svg>
            </div>
          </div>
        </div>
      </div>
      </div>
      
</div>
</div>
       </>
        ))}

       {true&& <p style={{textAlign:"center","text-decoration":"underline",color:"#718198","margin-top":"5%"}} onClick={e=>{viewAll()}} >View all orders</p>}

       
   
   </div>}
   {<div
            style={{
              width: "100%",
              background: "white",
              height: "195px",
              top: "146px",
              left: "11px",marginTop:"19px",
              "border-radius": "18px",
            }}
          >
            <div style={{ padding: "5%" }}>
              <h1 style={{ fontSize: "16px", color: "#333F4E",fontWeight:"600" }}>
              Kiosk order issues?
              </h1>
              <p
                style={{
                  fontSize: "14px",
                  color: " #384F6FC4",
                  marginTop: "9px",
                }}
              >
                Submit your payment screenshot to know about
refund status.
              </p>
            </div>
            <div style={{ padding: "5%" }}>
              <Link to='/help&support'>
              <button
                style={{
                  width: "100%",
                  height: "43px",
                  padding: "11px, 48px, 11px, 48px",
                  "border-radius": "8px",
                  gap: "10px",
                  background: "#00B177",
                  color: "white",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
              Check Refund Status
              </button>
              </Link>
            </div>


            
          </div>}
          <h1
              style={{
                fontSize: "14px",
                fontWeight: "600",
                letterSpacing: "2%",
                color: "#333F4E",
                marginTop: "10%",
                padding: "2%",marginBottom:"5%"
              }}
            >
              Choose a service you need help with
            </h1>


            <div
            style={{
              width: "100%",
              background: "white",
             // height: "195px",
              top: "146px",
              left: "11px",
              "border-radius": "18px",
            }}
          >
      {props&&props.topicList&&props.topicList[0]&&props.topicList.map(el => (
          <>
          <div onClick={(e)=>{props.FaqDetailsApi(e,el.topicId)}}style={{display:"flex",  "align-items":"center"}}><div style={{width:"90%"}}><h1 style={{ padding: "5% 5% 5% 10%" ,fontSize:"12px",fontWeight:"500",color:"#384F6FC4"}}><TruncatedHeading text={el.topicName} limit={40}/></h1></div><img style={{width:"10px",height:"10px"}}src={faqArrow}/></div>
          <div style={{paddingLeft:"5%",paddingRight:"5%"}}><hr style={{ border: "1px solid #384F6F17"}}></hr></div></>
        ))}
        


            
          </div>
        </div>
   
      </div>
    </>
  );
};
export default FaqHelpSupport;
