
import Webcam from "react-webcam";
import { useState } from 'react';
import vmicon from '../../assets/vmicon.png'
import { Link } from 'react-router-dom';
import ContactLessHeader from '../Header/ContactLessHeader';
import QrReader from "react-web-qr-reader";
const ContactLessVending=(props)=>{


  const videoConstraints = {
    facingMode: 'environment',  // This sets the camera to use the back camera
  };




 

  /**/


    return(<>
    
    <ContactLessHeader  title={"PickUp Order"} route={"/"} />
    {!props.vendingSuccess&&<div >
           {/*  <HeaderSecondary onClick={handleQrCloseClick} className='block' /> */}
           <div>
              <div className="mt-[60px] h-full w-full backdrop-blur-md absolute top-0 left-0"></div>

               <Webcam  videoConstraints={videoConstraints} className="object-cover h-screen w-screen" />  
              <div className="mt-[80px] w-[200px] h-[200px] border-4 border-[var(--color-primary)] rounded-md flex justify-center items-center absolute top-1/4 left-1/4  ">
                
                      <QrReader
                      delay={300}
                      onError={props.handleError}
                      onScan={props.handleScan}
                      facingMode={'environment'}
                      style={{width:"100%"}}
                      //className="object-cover rounded-md w-full h-full"
                               />
                
              </div> 
            </div>
          </div>}
          {props.vendingSuccess &&<div  className="modal"  style={{ backgroundColor: "#eefaf9" }}>
      <div style={{ padding:"5%"}}>
        <div style={{display:"flex","justify-content":"space-around"}}>
      <img  src={vmicon}/> 
      </div>
      <div style={{display:"flex","justify-content":"space-around","text-align": "center"}}>
      <h3 >Order pickup request sent to Vending machine</h3>
      </div>
      <div style={{display:"flex","justify-content":"space-around","text-align": "center",    "margin-top":"5px"}}>
      <p >Collect your order from the delivery pocket at the bottom of the vending machine.</p>
      </div>
      <Link to="/">
      <div style={{display:"flex","justify-content":"space-evenly","align-items":"center", "margin-top":"30%"}}>
        <button className="homebtn">Home</button>
      </div>
      </Link>
 {/*  const handleQrCloseClick =()=>{
    setFetchLocation(!isFetchLocation)
  } */}
      
      </div>
      </div>
    }
    
    
    </>)

}

export default ContactLessVending