import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Procced from "../Procced/component";
import coupon2 from "../../assets/coupon1.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import loginBackground from "../../assets/loginBackground.png";
import keypad from "../../assets/keypad.png";
import otpkeyicon from "../../assets/otpkeyicon.png";
import proArrow from "../../assets/proArrow.png";
import changeArrow from "../../assets/changeArrow.png";
import { mpAddItemintoCart, mpClick } from "../../Mixpanel";
import bpPg from '../../assets/bpPg.png'
import dpPg from '../../assets/dpPg.png'
import pluxee from '../../assets/plx-logo.svg'
import "../Mycart/mycart.css";
import MyCartHeader from "../../components/Header/MyCartHeader";
import WaitingLoder from "../WaitingLoader/component";
import { Box, Typography } from "@material-ui/core";
import paytm from '../../assets/Paytm.png'
import r_card from '../../assets/r_card.png'
import card from '../../assets/card.png'

const image_base_url = "https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/";

function Mycart(props) {
  console.log("couponReward")
  const finalDiscount = props && props.couponAppled && props.couponAppled.reward.count
  useEffect(() => { }, [props.couponAppled])
  const finalAmountGrand = finalDiscount ? props && props.totalAmount && props.totalAmount - finalDiscount : props && props.totalAmount

  const finalAmountGrandDiscount = finalAmountGrand && finalAmountGrand - (props && props.couponReward && props && props.couponReward.count);

  console.log("props&&props.appliedCouponData&&props.appliedCouponData.data.reward.count", props && props.appliedCouponData)
  const x = 45
  let v = x.toFixed(2);
  console.log(" float", v);
  console.log("props.clientId", props.clientId);
  const navigate = useNavigate();
  const [myCart, setMyCart] = useState(localStorage.getItem("mycart"));
  const defaultPgName = localStorage.getItem("defaultPgName");
  const machineId = localStorage.getItem("machineId");

  //console.log("couponTotalReward",couponTotalReward&&couponTotalReward.data.data.reward.count)
  //const mycart=


  const ComboData = props && props.comboList && props.comboList[0] && props.comboList.map((p) => {

    console.log("ComboData", (p.id) == (props && props.comboCart && props.comboCart.id))

    if ((p.id) == (props && props.comboCart && props.comboCart.id)) {
      console.log("ComboData if ", props && props.comboCart)

      //setTotalAmount(totalamount+p.offer_price)


      return { ...p, mycount: props && props.comboCart && props.comboCart.count };
    }
    return p;
  });

  console.log("combodata--->", ComboData)


  const ComboComponent = ({ data }) => {


    return (
      <>
        <Box>

          <Box
            className="styles_slider__podEu"
            data-testid="grid-slider"
            style={{ marginTop: "10px", padding: "3%" }}
          >




            <Box

              marginBottom={"5%"}
              boxShadow={"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"}

              borderRadius={"16px"}
              width={"100%"}

              style={{ background: "white" }}

            >


              <Box margin={"10px"}>
                <Box

                >
                  <Box
                    className="styles_ImageContainer__2rk9a"

                    style={{
                      background: "rgb(255, 255, 255)",
                      height: "129px",
                      width: "114px",
                      "margin-bottom": "11px"
                    }}
                  >
                    <img
                      className="styles_Image__1fplJ"
                      loading="lazy"

                      src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${props && props.comboCart && props.comboCart.imageUrl}`}
                    />
                  </Box>

                </Box>
                <Box

                  className="styles_containerRestaurant__3vhx3"
                  style={{ padding: "12px!important" }}
                >
                  <div
                    className="styles_containerImageBadge__14fk3"
                    style={{ fontFamily: "Roboto" }}
                  >
                    <div
                      data-testid="resturant-card-name"
                      className="styles_restaurantName__29jAP"
                      style={{ fontSize: "14px", marginTop: "10px" }}
                    >
                      <TruncatedVmname
                        text={props && props.comboCart && props.comboCart.name}

                        limit={25}

                      />
                    </div>
                    {/*          <div
                              style={{ color: "#94A3B8", fontSize: "12px",marginRight:"8px" }}
                            >
                              Tray <TruncatedTray text={el&&el.slot_id&&el.slot_id.toString()} limit={1}/>
                            </div> */}
                  </div>

                  <div

                    style={{}}
                  >
                    <span
                      data-testid="restaurant-card-cuisines"
                      style={{
                        fontSize: "12px",
                        color: "rgba(148, 163, 184, 1)",
                        fontFamily: "Roboto",



                        "font-style": "normal",
                        "font-weight": "400",
                        "line-height": "20px",
                      }}
                    >
                      <span style={{ visibility: "" }}><TruncatedDescription text={props && props.comboCart && props.comboCart.description} limit={25} /></span>
                      {/*                             <Typography fontSize={"12px"} style={{color:"rgba(113, 129, 152, 1)"}}>{el.activeCount>0&&<span>{el.activeCount}{" "}Left</span>}</Typography>
 */}                            </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      "margin-top": "42px",
                    }}
                  >
                    <h2 className="Real-Rs"
                      style={{
                        
                        
                      }}
                    >
                                            Rs.{""} {((props && props.comboCart && props.comboCart.totalPrice && props.comboCart.totalPrice) * (props && props.comboCart && props.comboCart && props.comboCart.count)).toFixed(2)}

                    </h2>
                    <h2
                      style={{
                        color: "#718198",
                        "text-align": "right",
                        /* SM14 */
                        "font-family": "Roboto",
                        "font-size": "10px",
                        "font-style": "normal",
                        "font-weight": "600",
                        "line-height": "150%",/* 21px */
                        "letter-spacing": "0.2px",
                        "marginLeft":"-10px",
                        "text-decoration": "line-through"
                      }}
                    >
                      ₹{""} {((props && props.comboCart && props.comboCart.actualPrice && props.comboCart.actualPrice) * (props && props.comboCart && props.comboCart && props.comboCart.count)).toFixed(2)}

                    </h2>

                    {



                      <div className="addToCartBtn"
                        style={{
                        

                        }}
                      >
                        <h3
                          onClick={(e) => {
                            props.ComboCartRemove(
                              e,

                              props && props.comboCart && props.comboCart.count,
                              props && props.comboCart && props.comboCart.id
                            );
                          }}
                        >
                          -
                        </h3>

                        {props && props.comboCart && props.comboCart.count}

                        <h3
                          onClick={(e) => {
                            props.ComboCartADD(
                              e,

                              props && props.comboCart && props.comboCart.count,
                              props && props.comboCart && props.comboCart.id
                            );
                          }}
                        >
                          +
                        </h3>
                      </div>



                    }
                  </div>
                </Box>
              </Box>

              <div />

            </Box>




          </Box>
        </Box>
        <>



        </>

      </>



    );
  };

  useEffect(() => {

    console.log("Paste OTp", props.otp)
  }, [props.otp])

  useEffect(() => {

    if (props.itemsList && props.itemsList[0] && props && props.flagMp) {
      mpAddItemintoCart({ EventName: "Add_more_items", data: { Number_of_Items: props && props.itemsList && props.itemsList.length, CartValue: finalAmountGrand } })
      props.setFlagMp(false)
    }
  }, [props.itemsList && props.itemsList])
  useEffect(() => {

    console.log("window.innerHeight", window.innerHeight, myCart);
    mpClick({ EventName: "Cart_page_opened" })

  }, []);





  const completeOrder = (e, finalAmount) => {
    console.log("Balance Condition", props.distotalAmount, props.totalAmount, props.paymentOptionsList[3].balance, finalAmount, props &&
      props.paymentOptionsList[3].balance >=
      (props.distotalAmount ? props.distotalAmount : props.totalAmount));
    console.log("props&&props.discountedAmount", props && props.distotalAmount)
    const defaultPgId = localStorage.getItem("defaultPgId");

    if (!(props && props.comboCart && props.comboCart == null)) {
      if (finalAmountGrand) {
        if (props && props.couponReward && props.couponReward.type == "freegift") {
          localStorage.setItem("finalAmount", finalAmountGrand)
        }
        if (props && props.couponReward && props.couponReward.type == "cashback") {
          localStorage.setItem("finalAmount", finalAmountGrand)
        }
        if (props && props.couponReward && props.couponReward.type == "discount") {
          localStorage.setItem("finalAmount", finalAmount)
        }
        if (!(props && props.couponReward && props.couponReward.type)) {
          localStorage.setItem("finalAmount", finalAmountGrand)
        }
      }
      else (
        localStorage.setItem("finalAmount", (props && props.comboCart && props.comboCart.totalPrice) * (props && props.comboCart && props.comboCart.count))

      )
    }



    if (props && props.paymentOptionsList[2].pgId == defaultPgId) {
      console.log("Balance Condition", props.distotalAmount, props.totalAmount, props &&
        props.paymentOptionsList[2].balance >=
        (props.distotalAmount ? props.distotalAmount : props.totalAmount));
      if (
        props &&
        props.paymentOptionsList[2].balance >=
        (props.distotalAmount ? finalAmount : props.totalAmount)
      ) {

        if (!(props && props.paymentOptionsList[2].reason)) {
          props.CreateOrderapi();
          console.log("completeOrder", props.distotalAmount, props.totalAmount);
        }
        else {
          toast.error(`Business Payments Not Allow`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      } else {
        console.log("Business payments Low Balance");
        toast.error(`Business payments Low Balance`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }

    if (props && props.paymentOptionsList[3].pgId == defaultPgId) {
      console.log("completeOrder", props.distotalAmount, props.totalAmount);
      if (
        props &&
        props.paymentOptionsList[3].balance >=
        (props.distotalAmount ? props.distotalAmount : props.totalAmount)
      ) {
        console.log("completeOrder", props.distotalAmount, props.totalAmount);
        props.CreateOrderapi();
      } else {
        console.log("DaalChini  Low Balance");
        toast.error(`DaalChini Points Low `, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }

    if (
      props.paymentOptionsList[1].pgId == defaultPgId ||
      props.paymentOptionsList[1].pgId == defaultPgId ||
      props.paymentOptionsList[5].pgId == defaultPgId
    ) {
      props.CreateOrderapi();
    }

    if (props && props.paymentOptionsList[0].pgId == defaultPgId) {
      console.log("completeOrder", props.distotalAmount, props.totalAmount);
      if (
        props &&
        props.paymentOptionsList[0].balance >=
        (props.distotalAmount ? props.distotalAmount : props.totalAmount)
      ) {
        console.log("completeOrder", props.distotalAmount, props.totalAmount);
        props.CreateOrderapi();
      } else {
        console.log("Low Paytm Balance");
        toast.error(`Low Paytm Balance`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
  };


  const [cartFlag, setCartFlag] = useState(true);
  useEffect(() => {
    setCartFlag(localStorage.getItem("mycart"));

    if (!localStorage.getItem("mycart")) {
      console.log("props Mycart props Mycart");
      // navigate("/");
    }

    calculateTotalAmount();
  }, []);

  const calculateTotalAmount = () => {
    const amount =
      props.itemsList &&
      props.itemsList.reduce(
        (total, stageuct) =>
          // console.log("totalamounttotalamount",total,stageuct.count,stageuct.mrp)
          total + stageuct.amount * stageuct.count * 6
      );
    console.log("props amount", amount);
  };
  console.log("props Mycart", cartFlag);
  let list = false;
  if (props.itemsList) {
    console.log("props ItemCard props ItemCard", props.itemsList);
    list = props.itemsList;
    if (!list[0]) {
      navigate(`/items?machineId=${machineId}`, { replace: true });
    }
    if (!list[0] && cartFlag) {
      navigate(`/items?machineId=${machineId}`, { replace: true });
    }
  }
  function TruncatedVmname({ text, limit }) {
    if (text && text.length <= limit) {
      return <span style={{ fontSize: "14px", color: "#384F6F" }}>{text}</span>;
    }
    return (
      <span style={{ fontSize: "14px", color: "#384F6F" }}>{`${text && text.substring(
        0,
        limit
      )}`}</span>
    );
  }
  function TruncatedDescription({ text, limit }) {
    if (text && text.length <= limit) {
      return (
        <

          >
          {text}
        </>
      );
    }
    return (
      <>{`${text && text.substring(0, limit)}...`}</>
    );
  }
  function TruncatedTray({ text, limit }) {
    if (text && text.length <= limit) {
      return (
        <

          >
          {text}
        </>
      );
    }
    return (
      <>{`${text.substring(0, limit)}`}</>
    );
  }
  return (
    <>


      <Box
        style={{
          height: "100vh",
          boxShadow: "none",
          background:
            "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
        }}


      >

        {" "}
        {props.GuestLogin ? (
          <>
            {
              cartFlag == "true" && (
                <>
                  {props.loader && <WaitingLoder />}

                  <MyCartHeader title={"My Cart"} route={`/items?machineId=${props && props.precardVm}`} />

                  <Box className="_2yoTv ns7Wo" style={{ boxShadow: "none" }}>
                    <div>


                      <div>
                        <div className="_2NVhy _3hjyL">
                          <div>
                            <div style={{ padding: 0 }}>
                              <div>
                                <div />
                              </div>
                            </div>
                            <div style={{ padding: 0 }}>
                              <div>
                                <div />
                                <div
                                  data-testid="grid-header"
                                  className="styles_headerContainer__2UgeD"
                                />
                              </div>
                            </div>
                            <div style={{ padding: "8px 0px 0px" }}>
                              <div>
                                <div />
                              </div>
                            </div>
                            <div>
                              <div>
                                <div />
                              </div>
                            </div>

                            <div

                              style={{ padding: 0, "overflow-y": "scroll" }}
                            >
                              <div>
                                <div />
                                <div
                                  data-testid="grid-header"
                                  className="styles_headerContainer__2UgeD"
                                >
                                  <div>
                                    <span
                                      className="styles_headerContainerSubtitle__1WRg5"
                                      aria-hidden="true"
                                    >
                                      <h3
                                        style={{

                                          marginLeft: "5%",
                                          fontSize: "17px",
                                          color: "#718198",

                                          /* M17 */
                                          "font-family": "Roboto",

                                          "font-style": "normal",
                                          "font-weight": "500",
                                        }}
                                      >
                                        Items In Your Cart {" "}
                                      </h3>
                                    </span>
                                  </div>
                                </div>
                                {props && props.comboCart && <ComboComponent />}

                                <Box

                                  style={{ boxShadow: "none" }}
                                  margin={"3%"}


                                >
                                  {list &&
                                    list.map((el, index) => (

                                      <Box

                                        marginBottom={"5%"}
                                        boxShadow={"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"}

                                        borderRadius={"16px"}
                                        width={"100%"}

                                        style={{ background: "white" }}

                                      >

                                        {console.log("el---->", el)}
                                        <Box height={"108px"}>
                                          <Box
                                            padding={"4%"}
                                          >
                                            <Box>
                                              <Box
                                                className="styles_ImageContainer__2rk9a"

                                                style={{
                                                  background: "rgb(255, 255, 255)",
                                                  height: "78px",
                                                  width: "77px"
                                                }}
                                              >
                                                <img
                                                  className="styles_Image__1fplJ"
                                                  loading="lazy"

                                                  src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.variantImages[0]}`}
                                                />
                                              </Box>

                                            </Box>
                                            <Box

                                              className="styles_containerRestaurant__3vhx3"
                                              style={{ padding: "12px!important" }}
                                            >
                                              <div
                                                className="styles_containerImageBadge__14fk3"
                                                style={{ fontFamily: "Roboto" }}
                                              >
                                                <div
                                                  data-testid="resturant-card-name"
                                                  className="styles_restaurantName__29jAP"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  <TruncatedVmname
                                                    text={el.mvName}
                                                    limit={25}
                                                  />
                                                </div>

                                              </div>

                                              <div


                                              >
                                                <span
                                                  data-testid="restaurant-card-cuisines"
                                                  style={{
                                                    fontSize: "12px",
                                                    color: "rgba(148, 163, 184, 1)",
                                                    fontFamily: "Roboto",



                                                    "font-style": "normal",
                                                    "font-weight": "400",
                                                    "line-height": "20px",
                                                  }}
                                                >
                                                  <span>Tray:{" "}{el.slotId &&
                                                    el.slotId.toString()[0]}</span>

                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "flex-end"
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex", "align-items": "center", width: "65%"
                                                  }}
                                                >
                                                  {el.discountedAmount ? (
                                                    <h2
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                        color: "#8d97a1",
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      Rs.{""}{" "}
                                                      {el.amount
                                                        .toFixed(2)}
                                                    </h2>
                                                  ) : (
                                                    <h2
                                                      style={{
                                                        color: "#718198",
                                                        "text-align": "right",
                                                        /* SM14 */
                                                        "font-family": "Roboto",
                                                        "font-size": "14px",
                                                        "font-style": "normal",
                                                        "font-weight": "600",
                                                        "line-height": "150% ",/* 21px */
                                                        "letter-spacing": "0.2px"
                                                      }}
                                                    >
                                                      Rs.{""}{" "}
                                                      {(el.amount
                                                      ).toFixed(2)}
                                                    </h2>
                                                  )}

                                                  {el.discountedAmount && (
                                                    <h2
                                                      style={{
                                                        color: "#718198",
                                                        "text-align": "right",
                                                        /* SM14 */
                                                        "font-family": "Roboto",
                                                        "font-size": "14px",
                                                        "font-style": "normal",
                                                        "font-weight": "600",
                                                        "line-height": "150% ",/* 21px */
                                                        "letter-spacing": "0.2px"
                                                      }}
                                                    >
                                                      {" "}
                                                      Rs.{""}{" "}
                                                      {
                                                        el.discountedAmount.toFixed(2)
                                                      }
                                                    </h2>
                                                  )}
                                                </div>

                                                {el.count > 0 ? (
                                                  <div
                                                    style={{
                                                      border:
                                                        "2px solid rgb(0, 177, 119)",
                                                      display: "flex",
                                                      width: "100px",
                                                      height: "31px",
                                                      backgroundColor:
                                                        "rgb(0, 177, 119)",
                                                      color: "white",
                                                      justifyContent:
                                                        "space-around",
                                                      alignItems:
                                                        "center",
                                                      padding: "10px",
                                                      borderRadius:
                                                        "15px",

                                                      "border-radius":
                                                        "8px",
                                                      border:
                                                        "1px solid #00B177",
                                                      background:
                                                        "rgba(0, 177, 119, 0.04)",
                                                    }}
                                                  >
                                                    <h3
                                                      onClick={(e) => {
                                                        props.decreaseQuantity(
                                                          e,
                                                          index,
                                                          el.slotId
                                                        );
                                                      }}
                                                      style={{
                                                        color: "#00B177",
                                                      }}
                                                    >
                                                      -
                                                    </h3>
                                                    <h3
                                                      style={{
                                                        color: "#583C43",
                                                        "font-family":
                                                          "Roboto",
                                                        "font-size":
                                                          "14px",
                                                        "font-style":
                                                          "normal",
                                                        "font-weight":
                                                          "400",
                                                      }}
                                                    >
                                                      {el.count}{" "}
                                                      {el.slot_id}
                                                    </h3>
                                                    <h3
                                                      onClick={(e) => {
                                                        props.increaseQuantity(
                                                          e,
                                                          index,
                                                          el.slotId
                                                        );
                                                      }}
                                                      style={{
                                                        color: "#00B177",
                                                      }}
                                                    >
                                                      +
                                                    </h3>
                                                  </div>
                                                ) : (
                                                  <>
                                                    <div
                                                      style={{
                                                        border:
                                                          "2px solid rgb(0, 177, 119)",
                                                        display: "flex",
                                                        width: "100px",
                                                        "background-color":
                                                          "rgba(0, 177, 119, 0.04)",
                                                        color: "#00B177",
                                                        justifyContent:
                                                          "space-around",
                                                        alignItems:
                                                          "center",
                                                        padding: "10px",
                                                        borderRadius:
                                                          "15px",
                                                      }}
                                                      onClick={(e) => {
                                                        props.increaseQuantity(
                                                          e,
                                                          index,
                                                          el.slotId
                                                        );
                                                      }}
                                                    >
                                                      ADD
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </Box>
                                          </Box>
                                        </Box>

                                        <div />

                                      </Box>

                                    ))}
                                  {( props.comboCart == null) && <>{!(localStorage.getItem("Guest_clientId")) && <>
                                    {!(props && props.couponReward) ? (
                                      <Link to='/coupon'>
                                        <div
                                          className="styles_row__1ivP1 shadow-box"
                                          data-testid="grid-row"
                                          style={{
                                            marginBottom: "-5px",
                                            background: "linear-gradient(0deg, rgba(250, 225, 75, 0.16) 0%, rgba(250, 225, 75, 0.16) 100%), rgb(255, 255, 255)",

                                            "border-radius": "10px",

                                            height: "63px",
                                            marginBottom: 5,
                                            //background:"linear-gradient(0deg, rgba(250, 225, 75, 0.16) 0%, rgba(250, 225, 75, 0.16) 100%), #FFF",
                                          }}
                                        >
                                          <div
                                            className="styles_slide__2c207"
                                            style={{ marginRight: 0 }}
                                          >
                                            <div>
                                              <div
                                                className="styles_cardWithPadding__JE1QE "
                                                data-testid="home-restaurant-card-wrapper"
                                                style={{
                                                  width:
                                                    "calc((100vw - 0px) - 12px)",
                                                }}
                                              >
                                                <div className="styles_container__fLC0R" style={{ padding: "5%" }}>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      "align-items": "center",
                                                    }}
                                                  >
                                                    <img
                                                      style={{
                                                        width: "20px",
                                                        height: "20px",
                                                      }}
                                                      src={coupon2}
                                                    />
                                                    <h2
                                                      style={{

                                                        "font-size": "15px",
                                                        color: " #384F6F",
                                                        "font-family": "Roboto",
                                                        "font-size": "15px",
                                                        marginLeft: "7px",
                                                      }}
                                                    >
                                                      Apply Coupon:
                                                      <span
                                                        style={{
                                                          color: "#00B177",

                                                          "font-family":
                                                            "Roboto",
                                                          "font-size": "15px",
                                                          "font-style":
                                                            "normal",
                                                          "font-weight": "600",
                                                          "line-height":
                                                            "normal",
                                                        }}
                                                      >
                                                        {" "}

                                                      </span>{" "}
                                                    </h2>
                                                  </div>
                                                  {/*       <p
                                                    style={{
                                                      fontSize: "13px",
                                                      "margin-top": "1px",
                                                      color: "#64748B",
                                                    
                                                      "font-family": "Roboto",
                                                      "font-size": "13px",
                                                      "font-style": "normal",
                                                      "font-weight": "400",
                                                      "line-height":
                                                        "150%" ,
                                                      "letter-spacing": "0.2px",
                                                      "margin-left": "26px",
                                                    }}
                                                  >
                                                    You saved{" "}
                                                    <span
                                                      style={{
                                                        fontWeight: "bold",
                                                        color: "#384F6F",
                                                        "font-family": "Roboto",
                                                        "font-size": "13px",
                                                        "font-style": "normal",
                                                        "font-weight": "700",
                                                        "line-height": "150%",
                                                        "letter-spacing":
                                                          "0.2px",
                                                      }}
                                                    >
                                                      ₹{""}{props&&props.couponReward&&props.couponReward.count}
                                                      {props &&
                                                        props.couponAppled &&
                                                        props.couponAppled
                                                          .reward &&
                                                        props.couponAppled
                                                          .reward.count.toFixed(2)}
                                                    </span>{" "}
                                                    on your final bill
                                                  </p> */}
                                                </div>
                                              </div>
                                              <div />
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    ) :

                                      <div
                                        className="styles_row__1ivP1 shadow-box"
                                        data-testid="grid-row"
                                        style={{
                                          marginBottom: "-5px",
                                          background: "linear-gradient(0deg, rgba(250, 225, 75, 0.16) 0%, rgba(250, 225, 75, 0.16) 100%), rgb(255, 255, 255)",
                                          marginTop: "4%",
                                          "border-radius": "10px",

                                          height: "63px",
                                          marginBottom: 5,
                                          //background:"linear-gradient(0deg, rgba(250, 225, 75, 0.16) 0%, rgba(250, 225, 75, 0.16) 100%), #FFF",
                                        }}
                                      >
                                        <div
                                          className="styles_slide__2c207"
                                          style={{ marginRight: 0 }}
                                        >
                                          <div>
                                            <div
                                              className="styles_cardWithPadding__JE1QE "
                                              data-testid="home-restaurant-card-wrapper"
                                              style={{
                                                width:
                                                  "calc((100vw - 0px) - 12px)",
                                              }}
                                            >
                                              <div className="styles_container__fLC0R">
                                                <div style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "space-between"
                                                }}>
                                                  <div> <div
                                                    style={{
                                                      display: "flex",
                                                      "align-items": "flex-end",
                                                    }}
                                                  >
                                                    <img
                                                      style={{
                                                        width: "20px",
                                                        height: "20px",
                                                      }}
                                                      src={coupon2}
                                                    />
                                                    <h2
                                                      style={{
                                                        marginTop: "15px",
                                                        "font-size": "15px",
                                                        color: " #384F6F",
                                                        "font-family": "Roboto",
                                                        "font-size": "15px",
                                                        marginLeft: "7px",
                                                      }}
                                                    >
                                                      Applied Coupon:
                                                      <span
                                                        style={{
                                                          color: "#00B177",

                                                          "font-family":
                                                            "Roboto",
                                                          "font-size": "15px",
                                                          "font-style":
                                                            "normal",
                                                          "font-weight": "600",
                                                          "line-height":
                                                            "normal",
                                                          textTransform: "uppercase"
                                                        }}
                                                      >
                                                        {" "}  {localStorage.getItem("appliedCouponName")}

                                                      </span>{" "}
                                                    </h2>
                                                  </div></div>

                                                  <h1 style={{ marginTop: "11px", marginRight: "26px", fontSize: "14px" }} onClick={(e) => { props.RemoveCoupon() }}>Remove</h1>
                                                </div>
                                                <p
                                                  style={{
                                                    fontSize: "13px",
                                                    "margin-top": "1px",
                                                    color: "#64748B",
                                                    /* R13 */
                                                    "font-family": "Roboto",
                                                    "font-size": "13px",
                                                    "font-style": "normal",
                                                    "font-weight": "400",
                                                    "line-height":
                                                      "150%" /* 19.5px */,
                                                    "letter-spacing": "0.2px",
                                                    "margin-left": "26px",
                                                  }}
                                                >
                                                  You saved{" "}
                                                  {!(localStorage.getItem("Guest_clientId")) && <span
                                                    style={{
                                                      fontWeight: "bold",
                                                      color: "#384F6F",
                                                      "font-family": "Roboto",
                                                      "font-size": "13px",
                                                      "font-style": "normal",
                                                      "font-weight": "700",
                                                      "line-height": "150%",
                                                      "letter-spacing":
                                                        "0.2px",
                                                    }}
                                                  >
                                                    ₹{""}{props.couponReward.type == "freegift" ? props && props.couponReward && props.couponReward.amount : props && props.couponReward && props.couponReward.count}
                                                    {/*   {props &&
                                                    props.couponAppled &&
                                                    props.couponAppled
                                                      .reward &&
                                                    props.couponAppled
                                                      .reward.count.toFixed(2)} */}
                                                  </span>}{" "}
                                                  {props.couponReward.type == "freegift" ? <>rupees on this order</> : <>{props.couponReward.type == "cashback" ? <>rupees on this order</> : <>on your final bill</>}</>}
                                                </p>
                                              </div>
                                            </div>
                                            <div />
                                          </div>
                                        </div>
                                      </div>}

                                  </>
                                  }</>}
                                  {props && props.couponReward && props.couponReward.type == "freegift" && <div
                                    className="styles_row__1ivP1 shadow-box"
                                    data-testid="grid-row"
                                    style={{
                                      marginBottom: "-5px",
                                      marginTop: "4%",
                                      "border-radius": "10px",

                                      "background": "white",
                                      height: props && props.couponReward && props.couponReward.type == "freegift" ? "123px" : "37px",
                                      marginBottom: "50%",
                                    }}
                                  >
                                    <div
                                      className="styles_slide__2c207"
                                      style={{ marginRight: 0 }}
                                    >
                                      <div>
                                        <div
                                          className="styles_cardWithPadding__JE1QE"
                                          data-testid="home-restaurant-card-wrapper"
                                          style={{
                                            width:
                                              "calc((100vw - 0px) - 12px)",
                                          }}
                                        >
                                          <a
                                            data-testid="resturant-card-anchor-container"
                                            className="styles_container__fLC0R"
                                            aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                            tabIndex={0}
                                            role="button"
                                          >

                                            <div
                                              style={{
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "align-items": "center",
                                                "margin-right": "28px",
                                                marginTop: "10px",
                                              }}
                                            >
                                              <h2
                                                style={{
                                                  "font-family": "Roboto",
                                                  "font-size": "14px",
                                                  "font-style": "normal",
                                                  "font-weight": "600",
                                                  "line-height":
                                                    "150%" /* 21px */,
                                                  "letter-spacing": "0.2px",
                                                  color: "#384F6F"

                                                }}
                                              >
                                                Grand Total
                                              </h2>
                                              <h2
                                                style={{
                                                  "font-family": "Roboto",
                                                  "font-size": "14px",
                                                  "font-style": "normal",
                                                  "font-weight": "600",
                                                  "line-height":
                                                    "150%" /* 21px */,
                                                  "letter-spacing": "0.2px",
                                                  color: "#384F6F"
                                                }}
                                              >
                                                ₹{" "}
                                                {finalAmountGrand && finalAmountGrand.toFixed(2)}
                                              </h2>


                                            </div>
                                          </a>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            "justify-content":
                                              "space-between",
                                            "align-items": "center",
                                            "margin-right": "44px",
                                            marginTop: "5px",
                                            paddingLeft: "16px"
                                          }}
                                        >
                                          <h2
                                            style={{
                                              "font-family": "Roboto",
                                              "font-size": "14px",
                                              "font-style": "normal",
                                              "font-weight": "600",
                                              "line-height":
                                                "150%" /* 21px */,
                                              "letter-spacing": "0.2px",
                                              color: "#384F6F"

                                            }}
                                          >
                                            Free Gift added
                                          </h2>



                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            "justify-content":
                                              "space-between",
                                            "align-items": "center",
                                            "margin-right": "44px",
                                            marginTop: "10px",
                                            paddingLeft: "16px"
                                          }}
                                        >
                                          <h2
                                            style={{
                                              "font-family": "Roboto",
                                              "font-size": "12px",
                                              "font-style": "normal",

                                              "line-height":
                                                "150%" /* 21px */,
                                              "letter-spacing": "0.2px",
                                              color: "rgb(100, 116, 139)"

                                            }}
                                          >
                                            {props.couponReward && props.couponReward.mvName}
                                          </h2>
                                          <h2 style={{ color: "rgb(100, 116, 139)", "fontSize": "12px" }}>

                                            ₹{" "}{props.couponReward && props.couponReward.amount.toFixed(2)}
                                          </h2>


                                        </div>
                                      </div>
                                    </div>
                                  </div>}
                                  {props && props.couponReward && props.couponReward.type == "cashback" && <div
                                    className="styles_row__1ivP1 shadow-box"
                                    data-testid="grid-row"
                                    style={{
                                      marginBottom: "-5px",
                                      marginTop: "4%",
                                      "border-radius": "10px",

                                      "background": "white",
                                      height: props && props.couponReward && props.couponReward.type == "cashback" ? "123px" : "37px",
                                      marginBottom: "50%",
                                    }}
                                  >
                                    <div
                                      className="styles_slide__2c207"
                                      style={{ marginRight: 0 }}
                                    >
                                      <div>
                                        <div
                                          className="styles_cardWithPadding__JE1QE"
                                          data-testid="home-restaurant-card-wrapper"
                                          style={{
                                            width:
                                              "calc((100vw - 0px) - 12px)",
                                          }}
                                        >
                                          <a
                                            data-testid="resturant-card-anchor-container"
                                            className="styles_container__fLC0R"
                                            aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                            tabIndex={0}
                                            role="button"
                                          >

                                            <div
                                              style={{
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "align-items": "center",
                                                "margin-right": "28px",
                                                marginTop: "10px",
                                              }}
                                            >
                                              <h2
                                                style={{
                                                  "font-family": "Roboto",
                                                  "font-size": "14px",
                                                  "font-style": "normal",
                                                  "font-weight": "600",
                                                  "line-height":
                                                    "150%" /* 21px */,
                                                  "letter-spacing": "0.2px",
                                                  color: "#384F6F"

                                                }}
                                              >
                                                Grand Total
                                              </h2>
                                              <h2
                                                style={{
                                                  "font-family": "Roboto",
                                                  "font-size": "14px",
                                                  "font-style": "normal",
                                                  "font-weight": "600",
                                                  "line-height":
                                                    "150%" /* 21px */,
                                                  "letter-spacing": "0.2px",
                                                  color: "#384F6F"
                                                }}
                                              >
                                                ₹{" "}
                                                {finalAmountGrand && finalAmountGrand.toFixed(2)}
                                              </h2>


                                            </div>
                                          </a>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            "justify-content":
                                              "space-between",
                                            "align-items": "center",
                                            "margin-right": "44px",
                                            marginTop: "5px",
                                            paddingLeft: "16px"
                                          }}
                                        >
                                          <h2
                                            style={{
                                              "font-family": "Roboto",
                                              "font-size": "14px",
                                              "font-style": "normal",
                                              "font-weight": "600",
                                              "line-height":
                                                "150%" /* 21px */,
                                              "letter-spacing": "0.2px",
                                              color: "#384F6F"

                                            }}
                                          >
                                            Cashback Earned
                                          </h2>



                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            "justify-content":
                                              "space-between",
                                            "align-items": "center",
                                            "margin-right": "44px",
                                            marginTop: "10px",
                                            paddingLeft: "16px"
                                          }}
                                        >
                                          <h2
                                            style={{
                                              "font-family": "Roboto",
                                              "font-size": "12px",
                                              "font-style": "normal",

                                              "line-height":
                                                "150%" /* 21px */,
                                              "letter-spacing": "0.2px",
                                              color: "rgb(100, 116, 139)"

                                            }}
                                          >
                                            Cashback will be added to your Daalchini <br /> Points Wallet after Pickup
                                          </h2>
                                          <h2 style={{ color: "#ffb300", "fontSize": "12px" }}>

                                            +{" "}{props.couponReward.count.toFixed(2)}
                                          </h2>


                                        </div>
                                      </div>
                                    </div>
                                  </div>}

                                  {(props && props.couponReward && props.couponReward.type == "discount") && <div
                                    className="styles_row__1ivP1 shadow-box"
                                    data-testid="grid-row"
                                    style={{
                                      marginBottom: "-5px",
                                      marginTop: "4%",
                                      "border-radius": "10px",

                                      "background": "white",
                                      height: "37px",
                                      marginBottom: "50%",
                                    }}
                                  >
                                    <div
                                      className="styles_slide__2c207"
                                      style={{ marginRight: 0 }}
                                    >
                                      <div>
                                        <div
                                          className="styles_cardWithPadding__JE1QE"
                                          data-testid="home-restaurant-card-wrapper"
                                          style={{
                                            width:
                                              "calc((100vw - 0px) - 12px)",
                                          }}
                                        >
                                          <a
                                            data-testid="resturant-card-anchor-container"
                                            className="styles_container__fLC0R"
                                            aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                            tabIndex={0}
                                            role="button"
                                          >

                                            <div
                                              style={{
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "align-items": "center",
                                                "margin-right": "28px",
                                                marginTop: "10px",
                                              }}
                                            >
                                              <h2
                                                style={{
                                                  "font-family": "Roboto",
                                                  "font-size": "14px",
                                                  "font-style": "normal",
                                                  "font-weight": "600",
                                                  "line-height":
                                                    "150%" /* 21px */,
                                                  "letter-spacing": "0.2px",
                                                  color: "#384F6F"

                                                }}
                                              >
                                                Grand Total
                                              </h2>
                                              <h2
                                                style={{
                                                  "font-family": "Roboto",
                                                  "font-size": "14px",
                                                  "font-style": "normal",
                                                  "font-weight": "600",
                                                  "line-height":
                                                    "150%" /* 21px */,
                                                  "letter-spacing": "0.2px",
                                                  color: "#384F6F"
                                                }}
                                              >
                                                ₹{" "}
                                                {finalAmountGrandDiscount && (finalAmountGrandDiscount).toFixed(2)}
                                              </h2>


                                            </div>
                                          </a>
                                        </div>


                                      </div>
                                    </div>
                                  </div>}
                                  {!(props && props.couponReward) && <div
                                    className="styles_row__1ivP1 shadow-box"
                                    data-testid="grid-row"
                                    style={{
                                      marginBottom: "-5px",
                                      marginTop: "4%",
                                      "border-radius": "10px",

                                      "background": "white",
                                      height: props && props.couponReward && props.couponReward.type == "cashback" ? "123px" : "37px",
                                      marginBottom: "50%",
                                    }}
                                  >
                                    <div
                                      className="styles_slide__2c207"
                                      style={{ marginRight: 0 }}
                                    >
                                      <div>
                                        <div
                                          className="styles_cardWithPadding__JE1QE"
                                          data-testid="home-restaurant-card-wrapper"
                                          style={{
                                            width:
                                              "calc((100vw - 0px) - 12px)",
                                          }}
                                        >
                                          <a
                                            data-testid="resturant-card-anchor-container"
                                            className="styles_container__fLC0R"
                                            aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                            tabIndex={0}
                                            role="button"
                                          >

                                            <div
                                              style={{
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "align-items": "center",
                                                "margin-right": "28px",
                                                marginTop: "10px",
                                              }}
                                            >
                                              <h2
                                                style={{
                                                  "font-family": "Roboto",
                                                  "font-size": "14px",
                                                  "font-style": "normal",
                                                  "font-weight": "600",
                                                  "line-height":
                                                    "150%" /* 21px */,
                                                  "letter-spacing": "0.2px",
                                                  color: "#384F6F"

                                                }}
                                              >
                                                Grand Total
                                              </h2>
                                              <h2
                                                style={{
                                                  "font-family": "Roboto",
                                                  "font-size": "14px",
                                                  "font-style": "normal",
                                                  "font-weight": "600",
                                                  "line-height":
                                                    "150%" /* 21px */,
                                                  "letter-spacing": "0.2px",
                                                  color: "#384F6F"
                                                }}
                                              >
                                                ₹{" "}
                                                {props && props.comboCart && ((props && props.comboCart && props.comboCart.totalPrice && props.comboCart.totalPrice) * (props && props.comboCart && props.comboCart && props.comboCart.count)).toFixed(2)}
                                                {finalAmountGrandDiscount && (finalAmountGrandDiscount).toFixed(2)}
                                              </h2>


                                            </div>
                                          </a>
                                        </div>


                                      </div>
                                    </div>
                                  </div>}
                                  {props.clientId ? (
                                    <div
                                      style={{
                                        position: "fixed",
                                        bottom: `-15px`,
                                        "margin-left": "-8px",
                                        width: "100%"
                                      }}
                                    >
                                      <div
                                        className="styles_row__1ivP1 shadow-box"
                                        data-testid="grid-row"
                                        style={{

                                          "margin-bottom": "15px",
                                          height: "129px",
                                          "border-top-right-radius": "11px",
                                          "border-top-left-radius": "11px",


                                          "border-radius": "20px 20px 0px 0px",
                                          background: "#FFF",
                                          "box-shadow": "0px 0px 24px 0px rgba(0, 0, 0, 0.08)"
                                        }}
                                      >


                                        <div
                                          className="styles_cardWithPadding__JE1QE"

                                          style={{
                                            width: "100%"
                                          }}
                                        >


                                          <div style={{ width: "94%", padding: "5%" }}>
                                            <div
                                              style={{
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",

                                              }}
                                            >
                                              <div style={{ display: "flex", }}>
                                                {localStorage.getItem("defaultPgName") == "WALLET" && <div style={{ display: "flex", alignItems: "center", marginRight: "13px" }}> <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", border: "1px solid rgba(148, 163, 184, 0.5)", borderRadius: "5px" }}><img src={bpPg} style={{ height: "27x", height: "27px" }} /></div></div>}
                                                {localStorage.getItem("defaultPgName") == "WALLET_LOYALTY" && <div style={{ display: "flex", alignItems: "center", marginRight: "13px" }}> <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", border: "1px solid rgba(148, 163, 184, 0.5)", borderRadius: "5px" }}><img src={dpPg} style={{ height: "27x", height: "27px" }} /></div></div>}
                                                {localStorage.getItem("defaultPgName") == "SODEXO" && <div style={{ display: "flex", alignItems: "center", marginRight: "13px" }}> <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", border: "1px solid rgba(148, 163, 184, 0.5)", borderRadius: "5px" }}><img src={pluxee} style={{ height: "27x", height: "27px" }} /></div></div>}

                                                {(localStorage.getItem("defaultPgName") == "PAYTM") && <div style={{ display: "flex", alignItems: "center", marginRight: "13px" }}> <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", border: "1px solid rgba(148, 163, 184, 0.5)", borderRadius: "5px" }}><img src={paytm} style={{ height: "27x", height: "27px" }} /></div></div>}
                                                {(localStorage.getItem("defaultPgName") == "R_WALLET") && <div style={{ display: "flex", alignItems: "center", marginRight: "13px" }}> <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", border: "1px solid rgba(148, 163, 184, 0.5)", borderRadius: "5px" }}><img src={r_card} style={{ height: "27x", height: "27px" }} /></div></div>}
                                                {(localStorage.getItem("defaultPgName") == "R_CARD") && <div style={{ display: "flex", alignItems: "center", marginRight: "13px" }}> <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", border: "1px solid rgba(148, 163, 184, 0.5)", borderRadius: "5px" }}><img src={card} style={{ height: "27x", height: "27px" }} /></div></div>}



                                                <div>
                                                  <h2
                                                    style={{
                                                      color: "#384F6F",

                                                      /* SM14 */
                                                      "font-family": "Roboto",
                                                      "font-size": "14px",
                                                      "font-style": "normal",
                                                      "font-weight": "600",
                                                      "line-height": "150%", /* 21px */
                                                      "letter-spacing ": "0.2px"
                                                    }}
                                                  >
                                                    Payment Method
                                                  </h2>
                                                  <p
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {defaultPgName ==
                                                      "PAYTM" &&
                                                      "Paytm Wallet"}
                                                    {defaultPgName ==
                                                      "WALLET_LOYALTY" &&
                                                      "Daalchini Points"}
                                                    {defaultPgName ==
                                                      "WALLET" &&
                                                      "Business Payments"}
                                                    {defaultPgName ==
                                                      "SODEXO" &&
                                                      "Pluxee"}
                                                    {defaultPgName ==
                                                      "RAZORPAY" &&
                                                      "Razorpay"}
                                                    {defaultPgName ==
                                                      "R_CARD" &&
                                                      "Debit/Credit Card "}{" "}
                                                    {defaultPgName ==
                                                      "R_UPI" && "UPI"}
                                                    {defaultPgName ==
                                                      "R_WALLET" &&
                                                      "Wallet"}
                                                  </p>
                                                </div>
                                              </div>
                                              {
                                                <div
                                                  style={{
                                                    "text-decoration":
                                                      "none",
                                                    marginRight: "-20px"

                                                  }}
                                                  onClick={props.goToPaymentOption}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      "align-items":
                                                        "center",
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        fontSize:
                                                          "13px",
                                                        color:
                                                          "#718198",
                                                      }}
                                                    >
                                                      Change
                                                    </h3>
                                                    <img
                                                      style={{
                                                        "margin-left":
                                                          "5px",
                                                      }}
                                                      src={changeArrow}
                                                    />
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",

                                                "align-items": "center",
                                              }}
                                            >
                                              <div>
                                                <h3
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  Total Amount
                                                </h3>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                  }}
                                                >

                                                  {console.log(
                                                    "props.distotalAmount",
                                                    props.distotalAmount
                                                  )}
                                                  {!props.distotalAmount ? (
                                                    <h2
                                                      style={{
                                                        fontSize:
                                                          "18px",
                                                      }}
                                                    >




                                                      {!(props && props.comboCart == null) && <>
                                                      <span style={{  }}>₹ &nbsp;{props && props.comboCart && ((props && props.comboCart && props.comboCart.totalPrice && props.comboCart.totalPrice) * (props && props.comboCart && props.comboCart && props.comboCart.count)).toFixed(2)}</span></>}
                                                      {(props && props.comboCart == null) && props && props.couponReward && props.couponReward.type === "discount" ? (
                                                        <span>₹ &nbsp;{finalAmountGrandDiscount && finalAmountGrandDiscount.toFixed(2)}</span>
                                                      ) : (
                                                        <>
                                                          {(props && props.comboCart == null) && <>₹ &nbsp;{finalAmountGrand && finalAmountGrand.toFixed(2)}</>}
                                                        </>
                                                      )}

                                                    </h2>
                                                  ) : (
                                                    <h2
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                        color:
                                                          "#8d97a1",


                                                        "font-family": "Roboto",
                                                        "font-size": "18px",
                                                        "font-style": "normal",
                                                        "font-weight": "700",
                                                        "line-height": "normal"
                                                      }}
                                                    >
                                                      ₹{""}{" "}
                                                      {
                                                        props.totalAmount.toFixed(2)
                                                      }
                                                    </h2>
                                                  )}

                                                  {!props.distotalAmount ? (
                                                    <h2>

                                                    </h2>
                                                  ) : (
                                                    <h2 style={{
                                                      color: "#384F6F",

                                                      "font-family": "Roboto",
                                                      "font-size": "18px",
                                                      "font-style": "normal",
                                                      "font-weight": "700",
                                                      "line-height": "normal"
                                                    }}>
                                                      {" "}
                                                      ₹{""}{" "}
                                                      {
                                                        props && props.couponReward && props.couponReward.type == "discount" ? finalAmountGrandDiscount && finalAmountGrandDiscount.toFixed(2) : finalAmountGrand && finalAmountGrand

                                                      }
                                                    </h2>
                                                  )}
                                                </div>
                                              </div>
                                              <div>
                                                <div
                                                  style={{
                                                    "align-items":
                                                      "center",
                                                    display: "flex",
                                                    marginTop: "10px",
                                                  }}
                                                >
                                                  <button
                                                    onClick={(e) => {
                                                      completeOrder(e, finalAmountGrandDiscount)
                                                    }
                                                    }
                                                    style={{
                                                      backgroundColor:
                                                        "rgb(0, 177, 119)",
                                                      color: "white",
                                                      padding: "13px",
                                                      fontSize: "14px",
                                                      "border-radius":
                                                        "12px",
                                                      marginRight:
                                                        "-27px",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        "align-items":
                                                          "center",


                                                      }}
                                                    >
                                                      <div>
                                                        Place Order
                                                      </div>
                                                      <img
                                                        style={{
                                                          "margin-left":
                                                            "5px",
                                                        }}
                                                        src={proArrow}
                                                      />
                                                    </div>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                        <div />

                                      </div>
                                    </div>
                                  ) :
                                    (
                                      <div
                                        style={{
                                          position: "fixed",
                                          bottom: `-15px`,
                                          "margin-left": "-14px",
                                        }}
                                      >
                                        <div
                                          className="styles_row__1ivP1 shadow-box"
                                          data-testid="grid-row"
                                          style={{
                                            marginTop: "4%",
                                            "margin-right": "12px",
                                            "margin-left": "10px",
                                            "margin-bottom": "13px",
                                            height: "129px",
                                            background: "white",
                                            "border-top-right-radius": "11px",
                                            "border-top-left-radius": "11px",
                                          }}
                                        >
                                          <div
                                            className="styles_slide__2c207"
                                            style={{ marginRight: 0 }}
                                          >
                                            <div>
                                              <div
                                                className="styles_cardWithPadding__JE1QE"
                                                data-testid="home-restaurant-card-wrapper"
                                                style={{
                                                  width:
                                                    "calc((100vw - 0px) - 12px)",
                                                }}
                                              >
                                                <a
                                                  data-testid="resturant-card-anchor-container"
                                                  className="styles_container__fLC0R"
                                                  aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                                  tabIndex={0}
                                                  role="button"
                                                >
                                                  <div
                                                    className="styles_imgContainer__1uHo5"
                                                    aria-hidden="true"
                                                  ></div>
                                                  <div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",

                                                      }}
                                                    >

                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",

                                                        "align-items": "center",
                                                        marginTop: "10%"
                                                      }}
                                                    >
                                                      <div>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <h3
                                                            style={{
                                                              color: "#384F6F",
                                                            }}
                                                          >
                                                            Almost There
                                                          </h3>
                                                        </div>
                                                        <p
                                                          style={{
                                                            fontSize: "11px",
                                                            color: "#718198",
                                                          }}
                                                        >
                                                          Login or Signup to
                                                          place your order
                                                        </p>
                                                      </div>
                                                      <button
                                                        style={{
                                                          backgroundColor:
                                                            "rgb(0, 177, 119)",
                                                          color: "white",
                                                          padding: "15px",
                                                          fontSize: "14px",
                                                          "border-radius":
                                                            "17px",
                                                          marginRight: "-5px",
                                                          width: "40%",
                                                          textDecoration:
                                                            "none",
                                                        }}
                                                        onClick={(e) => {
                                                          props.LoginFLagFun(e);
                                                        }}
                                                      >
                                                        <h3
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          LOGIN
                                                        </h3>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                              <div />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </Box>

                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </Box>

                </>
              )
              /*  :<><NotFoundPage/></> */
            }
          </>
        ) : (
          <>
            {props.loader && <WaitingLoder />}
            {props.login && (


              <>
                {localStorage.getItem('Guest_clientId') && <h1 style={{
                  position: "fixed",
                  "top": "25px",
                  left: "30px",

                  "border-radius": "27px",
                  background: "rgba(0, 0, 0, 0.62)",


                  color: "#FFF",
                  "text-align": "center",
                  "font-family": "Roboto",
                  "font-size": "12px",
                  "font-style": "normal",
                  "font-weight": "500",
                  "line-height": "28px",

                  "padding": "5px",

                  width: "20%", "text-align": "center",
                  display: "flex", width: "66px",
                  height: "25px", "padding": "10px",
                  "flex-direction": "column",
                  "justify-content": "center",
                  "align-items": "center",
                  gap: "10px", "flex-shrink": "0"


                }} onClick={(e) => { props.GuestBack() }}>Back</h1>}
                <div style={{}}>
                  <img src={loginBackground} />
                </div>
                <div style={{ padding: "33px" }}>
                  <div className="a-accordion-row-a11y">
                    <h3
                      style={{
                        margin: "15px",
                        "text-align": "center",
                        color: "#384F6F",
                      }}
                    >
                      India's First 24*7 Instant Automated Store
                    </h3>
                    <p className="hr" style={{ "margin-top": "46px" }}>
                      <span className="hrspan">Login</span>
                    </p>
                  </div>
                  <div className="a-accordion-inner">
                    <div
                      className="auth-validate-form fwcim-form auth-clearable-form"
                      style={{ marginTop: "15%" }}
                    >
                      <div className="a-input-text-group a-spacing-medium a-spacing-top-micro">
                        <div className="a-row a-spacing-base">
                          <span className="a-declarative">
                            <div className="a-input-text-wrapper auth-required-field auth-fill-claim ">
                              <div
                                className="a-section country-picker aok-hidden"
                                style={{ display: "none" }}
                              >
                                <span className="a-declarative">
                                  <span className="country-display-text">
                                    IN +91
                                  </span>
                                </span>
                              </div>

                              <div className="inputWithIcon">
                                <input
                                  className="hello"
                                  type="text"
                                  placeholder="Your Mobile Number"
                                  onChange={(e) => {
                                    props.onchangeMobile(e);
                                  }}
                                />
                                <i
                                  style={{
                                    "margin-top": "11px",
                                    "margin-left": "4%",
                                  }}
                                >
                                  <img style={{ width: "40%" }} src={keypad} />
                                </i>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                      {props.mobile.length == 10 ? (
                        <div className="a-section">
                          <div className="a-button-stack">
                            <span
                              id="continue"
                              className={"continue a-button-span12"}
                            >
                              <span
                                className="a-button-inner"
                                style={{
                                  backgroundColor: "rgb(0, 177, 119)",
                                  color: "white",
                                }}
                              >
                                <button
                                  className="a-button-input"
                                  onClick={props.LoginApi}
                                  style={{
                                    backgroundColor: "rgb(0, 177, 119)",
                                    color: "white",
                                  }}
                                />
                                <span
                                  className="a-button-text"
                                  style={{
                                    fontSize: "16px",
                                    backgroundColor: "#00B177",
                                    color: "white",
                                  }}
                                >
                                  Submit
                                </span>
                              </span>
                            </span>
                            <div className="a-section a-spacing-medium">
                              <a
                                style={{ textDecoration: "none" }}
                                href="https://www.daalchini.co.in/terms.html"
                              >
                                {" "}
                                <div
                                  id="legalTextRow"
                                  className="a-row a-spacing-top-medium a-size-small"
                                  style={{
                                    "margin-top": "22%",
                                    "text-align": "center",
                                    fontSize: "12px",
                                  }}
                                >
                                  <span style={{ color: "#718198" }}>
                                    By continuing, you agree to our{" "}
                                  </span>
                                  <span style={{ color: "#583C43" }}>
                                    Terms of Service
                                    <span style={{ color: "#718198" }}>and</span>{" "}
                                    Privacy Policy.
                                  </span>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="a-section">
                          <div className="a-button-stack">
                            <span
                              id="continue"
                              className={
                                "a-button a-button-span12 a-button-primary"
                              }
                            >
                              <span
                                className="a-button-inner"
                                style={{
                                  backgroundColor: "rgb(0, 177, 119)",
                                  color: "white",
                                }}
                              >
                                <button
                                  className="a-button-input"
                                  style={{
                                    backgroundColor: "rgb(0, 177, 119)",
                                    color: "white",
                                  }}
                                />
                                <span
                                  className="a-button-text"
                                  style={{
                                    fontSize: "16px",
                                    "background-color": "#BCBDBD",
                                    color: "white",
                                  }}
                                >
                                  Submit
                                </span>
                              </span>
                            </span>

                            <div className="a-section a-spacing-medium">
                              <a
                                style={{ textDecoration: "none" }}
                                href="https://www.daalchini.co.in/terms.html"
                              >
                                {" "}
                                <div
                                  id="legalTextRow"
                                  className="a-row a-spacing-top-medium a-size-small"
                                  style={{
                                    "margin-top": "22%",
                                    "text-align": "center",
                                    fontSize: "12px",
                                  }}
                                >
                                  <span style={{ color: "#718198" }}>
                                    By continuing, you agree to our{" "}
                                  </span>
                                  <span style={{ color: "#583C43" }}>
                                    Terms of Service{" "}
                                    <span style={{ color: "#718198" }}>and</span>{" "}
                                    Privacy Policy.
                                  </span>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>


            )}
            {props.loginOtp && (
              <>



                <img style={{}} src={loginBackground} />

                <div style={{ padding: "33px" }}>
                  <div className="a-accordion-inner">
                    <div className="auth-validate-form fwcim-form auth-clearable-form">
                      <div className="a-input-text-group a-spacing-medium a-spacing-top-micro">
                        <div className="a-row a-spacing-base">
                          <span className="a-declarative">
                            <div className="a-input-text-wrapper auth-required-field auth-fill-claim ">
                              <div
                                className="a-section country-picker aok-hidden"
                                style={{ display: "none" }}
                              >
                                <span className="a-declarative"></span>
                              </div>
                              <h3
                                style={{
                                  margin: "15px",
                                  "text-align": "center",
                                  color: "#384F6F",
                                }}
                              >
                                India's First 24*7 Instant Automated Store
                              </h3>

                              <div style={{ marginTop: "20%" }}>
                                <p
                                  className="hr"
                                  style={{ "margin-bottom": "50px" }}
                                >
                                  <span className="hrspan">OTP</span>
                                </p>

                                <div className="inputWithIcon">


                                  <input className="hello" maxLength={4} type="text" placeholder="ENTER OTP" onChange={(e) => { props.onchangeOtp(e) }} onInput={(e) => { props.setOtp(e.target.value) }} onPaste={(e) => { props.setOtp(e.clipboardData.getData("text")) }} />

                                  <i
                                    style={{
                                      "margin-top": "15px",
                                      "margin-left": "4%",
                                    }}
                                  >
                                    <img
                                      style={{ width: "50%" }}
                                      src={otpkeyicon}
                                    />
                                  </i>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                      {console.log("PASTEWALa", props.otp)}
                      {props.otpFlag && props.otp.length == 4 ? (
                        <div className="a-section">
                          <div className="a-button-stack">
                            <span
                              id="continue"
                              className={"continue a-button-span12"}
                            >
                              <span className="a-button-inner">
                                <button
                                  id="continue"
                                  className="a-button-input"
                                  onClick={props.SubmitOTP}
                                />
                                <span
                                  style={{ color: "white", fontSize: "16px" }}
                                  className="a-button-text"
                                >
                                  Verify
                                </span>
                              </span>
                            </span>
                            <div className="a-section a-spacing-medium"></div>
                          </div>
                        </div>
                      ) : (
                        <div className="a-section">
                          <div className="a-button-stack">
                            <span
                              id="continue"
                              className="a-button a-button-span12 a-button-primary"
                            >
                              <span className="a-button-inner">
                                <input id="continue" className="a-button-input" />
                                <span
                                  className="a-button-text"
                                  style={{
                                    fontSize: "16px",
                                    "background-color": "rgb(188, 189, 189)",
                                    color: "white",
                                  }}
                                >
                                  Verify
                                </span>
                              </span>
                            </span>
                            <div className="a-section a-spacing-medium"></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>

            )}
          </>
        )}
      </Box>
    </>
  );
}

export default Mycart;
