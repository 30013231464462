export const POST_GIFT_CARD_REQUEST="POST_GIFT_CARD_REQUEST"



export const sendGiftRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:POST_GIFT_CARD_REQUEST,
        data:myurldata
    }

    
   



}





export const GET_GIFT_CARD_HISTORY_REQUEST="GET_GIFT_CARD_HISTORY_REQUEST"



export const getGiftHistoryRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:GET_GIFT_CARD_HISTORY_REQUEST,
        data:myurldata
    }

    
   
}


export const GET_GIFT_CARD_CLAIM_REQUEST="GET_GIFT_CARD_CLAIM_REQUEST"



export const getGiftClaimRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:GET_GIFT_CARD_CLAIM_REQUEST,
        data:myurldata
    }

    
   
}












