export const FETCH_COUPONS_LIST_REQUEST="FETCH_COUPONS_LIST_REQUEST"
export const FETCH_COUPONS_LIST_SUCCESS="FETCH_COUPONS_LIST_SUCCESS"
export const FETCH_COUPONS_LIST_FAILURE="FETCH_COUPONS_LIST_FAILURE"


export const fetchCouponsRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_COUPONS_LIST_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchCouponsSuccess=(Coupons)=>{
return{
    type:FETCH_COUPONS_LIST_SUCCESS,
    Coupons:Coupons
}
}

export const fetchCouponsFailure=(error)=>{
    return{
    type:FETCH_COUPONS_LIST_FAILURE,
    error:error
    }
}



export const FETCH_APPLY_COUPON_REQUEST="FETCH_APPLY_COUPON_REQUEST"
export const FETCH_APPLY_COUPON_SUCCESS="FETCH_APPLY_COUPON_SUCCESS"
export const FETCH_APPLY_COUPON_FAILURE="FETCH_APPLY_COUPON_FAILURE"


export const fetchApplyCouponRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_APPLY_COUPON_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchApplyCouponSuccess=(ApplyCoupon)=>{
return{
    type:FETCH_APPLY_COUPON_SUCCESS,
    ApplyCoupon:ApplyCoupon
}
}

export const fetchApplyCouponFailure=(error)=>{
    return{
    type:FETCH_APPLY_COUPON_FAILURE,
    error:error
    }
}
