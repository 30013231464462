import { call, put, takeLatest } from "redux-saga/effects";

import axios from "axios";
import { FETCH_ACTIVE_ORDERS_VM_INVENTORY_REQUEST, FETCH_BUY_AGAIN_REQUEST, FETCH_CLEAR_PRE_CARD_INVENTORY_REQUEST, FETCH_COMBO_CART_ADD_REQUEST, FETCH_COMBO_CART_REMOVE_REQUEST, FETCH_COMBO_REQUEST, FETCH_MOVE_CART_REQUEST, FETCH_PRE_CART_VM_INVENTORY_REQUEST, FETCH_VM_INVENTORY_REQUEST, FETCH_VM_INVENTORY_SPECIAL_REQUEST, SEND_ADD_INTO_CART_REQUEST, SEND_REMOVE_FROM_CART_REQUEST, SEND_TODAY_ADD_INTO_CART_REQUEST, SEND_TODAY_REMOVE_FROM_CART_REQUEST, fetchActiveOrdersVmInventoryFailure, fetchActiveOrdersVmInventorySuccess, fetchBuyAgainFailure, fetchBuyAgainSuccess, fetchClearPreCardFailure, fetchClearPreCardSuccess, fetchComboFailure, fetchComboSuccess, fetchMoveCartFailure, fetchMoveCartSuccess, fetchPreCartVmInventoryFailure, fetchPreCartVmInventorySuccess, fetchVmInventoryFailure, fetchVmInventoryRequest, fetchVmInventorySpecialFailure, fetchVmInventorySpecialRequest, fetchVmInventorySpecialSuccess, fetchVmInventorySuccess, sendAddInotoCartFailure, sendAddIntoCartSuccess, sendComboCartADDFailure, sendComboCartADDSuccess, sendComboCartREMOVEFailure, sendComboCartREMOVESuccess, sendRemoveFromCartFailure, sendRemoveFromCartSuccess, sendTodayAddInotoCartFailure, sendTodayAddIntoCartRequest, sendTodayAddIntoCartSuccess, sendTodayRemoveFromCartFailure, sendTodayRemoveFromCartSuccess } from "./action";
import { FETCH_BANNER_REQUEST, fetchBannerSuccess } from "../ImagesAds/action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////

const callApi = async (mydata) => {
   
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      
      if(mydata)  
      {
        const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request

        
      });
      return response;
    }
  
     
    } catch (error) {
      throw error;
    }
  };
//////////////////////////////////////////////////////////////////////////////////////////////////
function* fetchCombo(action) {
  
  try {
    const response = yield call(callApi, action);

    yield put(fetchComboSuccess(response));

    action.data.handleResponseCombo(response);
  } catch (error) {
  
    yield put(fetchComboFailure(error.message));
    action.data.handleErrorCombo(error)
  }
}


//////////////////////////////////////////////////////////////////////////////////////////////////
function* fetchComboAddToCart(action) {
 
  try {
    const response = yield call(callApi, action);

    yield put(sendComboCartADDSuccess(response));

    action.data.handleResponseComboCartADD(response);
  } catch (error) {
  
    yield put(sendComboCartADDFailure(error.message));
    action.data.handleErrorComboCartADD(error)
  }
}



//////////////////////////////////////////////////////////////////////////////////////////////////
function* fetchComboRemoveToCart(action) {
 
  try {
    const response = yield call(callApi, action);

    yield put(sendComboCartREMOVESuccess(response));

    action.data.handleResponseComboCartRemove(response);
  } catch (error) {
   
    yield put(sendComboCartREMOVEFailure(error.message));
    action.data.handleErrorComboCartRemove(error)
  }
}


////////////////////////////////////////// VM INVENTORY LIST ///////////////////////////////////////////////////////
function* fetchMoveCart(action) {
 
  try {
    const response = yield call(callApi, action);

    yield put(fetchMoveCartSuccess(response));

    action.data.handleResponseMoveCart(response);
  } catch (error) {
   
    yield put(fetchMoveCartFailure(error.message));
    action.data.handleErrorMoveCart(error)
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////// VM INVENTORY LIST ///////////////////////////////////////////////////////
function* fetchVmInventory(action) {
  
  try {
    const response = yield call(callApi, action);

    yield put(fetchVmInventorySuccess(response));

    action.data.handleResponseVmInventory(response);
  } catch (error) {
   
    yield put(fetchVmInventoryFailure(error.message));
    //action.data.handleErrorVmInventory(error)
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////// VM INVENTORY LIST ///////////////////////////////////////////////////////
function* fetchVmInventorySpecial(action) {
  
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchVmInventorySpecialSuccess(response));
  
      action.data.handleResponseVmInventorySpecial(response);
    } catch (error) {
     
      yield put(fetchVmInventorySpecialFailure(error.message));
      action.data.handleErrorVmInventorySpecial(error)
    }
  }
  
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  


////////////////////////////////////////// PREVIOUS CART IN VM INVENTORY LIST ///////////////////////////////////////////////////////
function* fetchPreCartVmInventory(action) {
   
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchPreCartVmInventorySuccess(response));
  
      action.data.handleResponsePreCardVmInventory(response);
    } catch (error) {
      
      yield put(fetchPreCartVmInventoryFailure(error&&error.message));
       action.data.handleErrorPreCardVmInventory(error) 
    }
  }
  
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  
////////////////////////////////////////// ADD INTO CART IN VM INVENTORY ///////////////////////////////////////////////////////
function* sendAddIntoCart(action) {
 
  try {
    const response = yield call(callApi, action);

    yield put(sendAddIntoCartSuccess(response));

    action.data.handleResponseAddIntoCart(response);
  } catch (error) {
  
    action.data.handleErrorAddIntoCart(error)
    yield put(sendAddInotoCartFailure(error.message));
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////




////////////////////////////////////////// REMOVE FROM CART IN VM INVENTORY ///////////////////////////////////////////////////////
function* sendRemoveFromCart(action) {
  
  try {
    const response = yield call(callApi, action);

    yield put(sendRemoveFromCartSuccess(response));

    action.data.handleResponseRemovefromCart(response);
  } catch (error) {
  
    action.data.handleErrorRemovefromCart(error&& error.response&&error.response.data.status.message)
    yield put(sendRemoveFromCartFailure(error.message));
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////// ADD INTO CART IN VM INVENTORY ///////////////////////////////////////////////////////
function* sendTodayAddIntoCart(action) {
  
  try {
    const response = yield call(callApi, action);

    yield put(sendTodayAddIntoCartSuccess(response));

    action.data.handleResponseTodayAddIntoCart(response);
  } catch (error) {
   
    action.data.handleErrorTodayAddIntoCart(error)
    yield put(sendTodayAddInotoCartFailure(error.message));
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////




////////////////////////////////////////// REMOVE FROM CART IN VM INVENTORY ///////////////////////////////////////////////////////
function* sendTodayRemoveFromCart(action) {

  try {
    const response = yield call(callApi, action);

    yield put(sendTodayRemoveFromCartSuccess(response));

    action.data.handleResponseTodayRemovefromCart(response);
  } catch (error) {
   
    action.data.handleErrorTodayRemovefromCart(error&& error.response&&error.response.data.status.message)
    yield put(sendTodayRemoveFromCartFailure(error.message));
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////



/////////////////////////////////// ACTIVE ORDER for VM INVENTORY DATA////////////////////////////////////////////////////

function* fetchActiveOrdersVmInventory(action) {
  
  try {
    const response = yield call(callApi, action);

    yield put(fetchActiveOrdersVmInventorySuccess(response));

    action.data.handleResponseActiveOrdersVmInventory(response);
  } catch (error) {
   
    yield put(fetchActiveOrdersVmInventoryFailure(error.message));
    action.data.handleErrorActiveOrders(error)
  }
}



/////////////////////////////////// CLEAR PREVIOUS CARDfor VM INVENTORY DATA////////////////////////////////////////////////////

function* fetchClearPreCardVmInventory(action) {
 
  try {
    const response = yield call(callApi, action);

    yield put(fetchClearPreCardSuccess(response));

    action.data.handleResponseClearPreCard(response);
  } catch (error) {
    
    action.data.handleErrorClearPreCard(error)
    yield put(fetchClearPreCardFailure(error.message));
  }
}
function* fetchBuyAgain(action) {
  
  try {
    const response = yield call(callApi, action);

    yield put(fetchBuyAgainSuccess(response));

    action.data.handleResponseBuyAgain(response);
  } catch (error) {
   
    action.data.handleErrorBuyAgain(error)
    yield put(fetchBuyAgainFailure(error.message));
    action.data.handleErrorBuyAgain(error)
  }
}

function* VmInventoryPageSaga() {
  yield takeLatest(FETCH_VM_INVENTORY_REQUEST, fetchVmInventory);
  yield takeLatest(FETCH_VM_INVENTORY_SPECIAL_REQUEST,fetchVmInventorySpecial)
  yield takeLatest(FETCH_PRE_CART_VM_INVENTORY_REQUEST,fetchPreCartVmInventory)
  yield takeLatest(SEND_ADD_INTO_CART_REQUEST,sendAddIntoCart)
  yield takeLatest(SEND_REMOVE_FROM_CART_REQUEST,sendRemoveFromCart)
  yield takeLatest(SEND_TODAY_ADD_INTO_CART_REQUEST,sendTodayAddIntoCart)
  yield takeLatest(SEND_TODAY_REMOVE_FROM_CART_REQUEST,sendTodayRemoveFromCart)
  yield takeLatest(FETCH_ACTIVE_ORDERS_VM_INVENTORY_REQUEST,fetchActiveOrdersVmInventory)
  yield takeLatest(FETCH_CLEAR_PRE_CARD_INVENTORY_REQUEST,fetchClearPreCardVmInventory)
  yield takeLatest(FETCH_BUY_AGAIN_REQUEST,fetchBuyAgain)
  yield takeLatest(FETCH_MOVE_CART_REQUEST,fetchMoveCart)
  yield takeLatest(FETCH_COMBO_REQUEST,fetchCombo)
  yield takeLatest(FETCH_COMBO_CART_ADD_REQUEST,fetchComboAddToCart)
  yield takeLatest(FETCH_COMBO_CART_REMOVE_REQUEST,fetchComboRemoveToCart)

}

export default VmInventoryPageSaga;
