
import { ADD_TO_CART } from "./constant";
import { REMOVE_TO_CART,INITIATED_PAYMENT_API } from "./constant";
export function fetchInventory(
    method,
    url,
    headers,
    body,
    handleSuccess,
    handleError,
    //showToast,

) {
    console.log('method---',method,url,body)
    
    return {
      type:ADD_TO_CART,
      method,
      url,
      headers,
      body,  
      handleSuccess,
      handleError,
      //showToast,
    };
  }
  export function initiatedPaymentApi(
    method,
    url,
    headers,
    body,
    handleSuccess,
    handleError,
    //showToast,

) {
    console.log('initiatedPaymentApi---',)
    
    return {
      type:INITIATED_PAYMENT_API,
      method,
      url,
      headers,
      body,  
      handleSuccess,
      handleError,
      //showToast,
    };
  }


  export function addtocart(
    method,
    url,
    body,
    handleSuccess,
    //handleError,
    //showToast,

) {
    console.log('method---',method,url,body)
    
    return {
      type:ADD_TO_CART,
      method,
      url,
      body,  
      handleSuccess,
      //handleError,
      //showToast,
    };
  }

  export function removetocart(
    method,
    url,
    body,
    handleSuccess,
    //handleError,
    //showToast,

) {
    console.log('method---',method,url,body)
    
    return {
      type:REMOVE_TO_CART,
      method,
      url,
      body,  
      handleSuccess,
      //handleError,
      //showToast,
    };
  }




  //fetchPaymentOptionMycartRequest