export const FETCH_BP_PAYMENT_OPTION_REQUEST="FETCH_BP_PAYMENT_OPTION_REQUEST"
export const FETCH_BP_PAYMENT_OPTION_SUCCESS="FETCH_BP_PAYMENT_OPTION_SUCCESS"
export const FETCH_BP_PAYMENT_OPTION_FAILURE="FETCH_BP_PAYMENT_OPTION_FAILURE"


export const fetchBpPaymentOptionRequest=(myurldata)=>{
    console.log("fetchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_BP_PAYMENT_OPTION_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchBpPaymentOptionSuccess=(OrderHistory)=>{
return{
    type:FETCH_BP_PAYMENT_OPTION_SUCCESS,
    OrderHistory:OrderHistory
}
}

export const fetchBpPaymentOptionFailure=(error)=>{
    return{
    type:FETCH_BP_PAYMENT_OPTION_FAILURE,
    error:error
    }
}



/////////////////////////////////////////////////////////////////////////
export const FETCH_BP_UNLINK_REQUEST="FETCH_BP_UNLINK_REQUEST"
export const FETCH_BP_UNLINK_SUCCESS="FETCH_BP_UNLINK_SUCCESS"
export const FETCH_BP_UNLINK_FAILURE="FETCH_BP_UNLINK_FAILURE"


export const fetchBpUnLinkRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_BP_UNLINK_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchBpUnLinkSuccess=(OrderHistory)=>{
return{
    type:FETCH_BP_UNLINK_SUCCESS,
    OrderHistory:OrderHistory
}
}

export const fetchBpUnLinkFailure=(error)=>{
    return{
    type:FETCH_BP_UNLINK_FAILURE,
    error:error
    }
}




/////////////////////////////////////////////////////////////////////////
export const FETCH_DP_HISTORY_REQUEST="FETCH_DP_HISTORY_REQUEST"
export const FETCH_DP_HISTORY_SUCCESS="FETCH_DP_HISTORY_SUCCESS"
export const FETCH_DP_HISTORY_FAILURE="FETCH_DP_HISTORY_FAILURE"


export const fetchDpHistoryRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_DP_HISTORY_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchDpHistorySuccess=(OrderHistory)=>{
return{
    type:FETCH_DP_HISTORY_SUCCESS,
    OrderHistory:OrderHistory
}
}

export const fetchDpHistoryFailure=(error)=>{
    return{
    type:FETCH_DP_HISTORY_FAILURE,
    error:error
    }
}


////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_CREATE_DP_RECHARGE_ORDER_REQUEST="FETCH_CREATE_DP_RECHARGE_ORDER_REQUEST"
export const FETCH_CREATE_DP_RECHARGE_ORDER_SUCCESS="FETCH_CREATE_DP_RECHARGE__ORDER_SUCCESS"
export const FETCH_CREATE_DP_RECHARGE_ORDER_FAILURE="FETCH_CREATE_DP_RECHARGE_ORDER_FAILURE"


export const fetchCreateOrderRequest=(myurldata)=>{
    console.log("fetchCreateOrderRequest myurldata---->",myurldata)
    return{
        type:FETCH_CREATE_DP_RECHARGE_ORDER_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchCreateOrderSuccess=(CreateOrder)=>{
return{
    type:FETCH_CREATE_DP_RECHARGE_ORDER_SUCCESS,
    CreateOrder:CreateOrder
}
}

export const fetchCreateOrderFailure=(error)=>{
    return{
    type:FETCH_CREATE_DP_RECHARGE_ORDER_FAILURE,
    error:error
    }
}