import VMInventory from "./component"
import { connect } from "react-redux";
import { fetchMoveCartRequest,fetchVmInventoryRequest ,fetchClearPreCardRequest,sendComboCartREMOVERequest,sendComboCartADDRequest,fetchVmInventorySpecialRequest,fetchPreCartVmInventoryRequest,sendAddIntoCartRequest,sendRemoveFromCartRequest,sendTodayAddIntoCartRequest,sendTodayRemoveFromCartRequest,fetchActiveOrdersVmInventoryRequest,fetchBuyAgainRequest,fetchComboRequest} from "./action";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveOrdersVmInventory, mpActiveOrdersVmInventory, mpCart, mpCartClicked, mpClick, mpGlobalAddToCart, mpGlobalAddToCartError } from "../../Mixpanel";


const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;

  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;

  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const VMInventory_Container=(props)=>{

const navigate=useNavigate()
const [inventoryList,setInventoryList]=useState()
const [todayinventoryList,setTodayInventoryList]=useState()
const [searchTerms, setSearchTerms] = useState("")
const [cartItemsList,setCartItemsList]=useState([])
const [comboCart,setComboCart]=useState([])
const [cartItemsList1,setCartItemsList1]=useState([])
const [activeOrderList,setActiveOrderList]=useState(null)
const [currentMachineID,setCurrentMachineID]=useState()
const [preMachineID,setPreMachineID]=useState()
const [comboList,setComboList]=useState(null)
const [flagMp,setFlagMp]=useState(false)
const [comboCount,setComboCount]=useState(0)
const [comboId,setComboId]=useState(null)





//https://api-prod.daalchini.co.in/consumerbe/api/v2/outlet/{machineId}/previously-purchased



const [final_VmInventoryList,SetFinal_VmInventoryList]=useState([])
const [final_VmInventorySpecialList,SetFinal_VmInventorySpecialList]=useState([])

const [vmInventoryMycountList,setVmInventoryMycountList]=useState([])
const [todayvmInventoryMycountList,setTodayVmInventoryMycountList]=useState([])


const [cartCount,setCartCount]=useState(null);
const [carttotalAmount,setCarttotalAmount]=useState(null)




const [loader,setLoader]=useState(false)

const [comboCartList,setComboCartList]=useState(null)


const VmInventorySpecialList=props&&props.VmInventory&&props.VmInventory.VmInventorySpecialList
const VmInventoryList=props&&props.VmInventory&&props.VmInventory.VmInventoryList.data&&props.VmInventory.VmInventoryList.data.data
const PreCartVmInventoryList=props&&props.VmInventory&&props.VmInventory.PreCartVmInventoryList
const ActiveOrders=props&&props.VmInventory&&props.VmInventory.ActiveOrdeVmInventory
const MachineName=props&&props.VmInventory&&props.VmInventory.MachineName
const MachineAddress=props&&props.VmInventory&&props.VmInventory.MachineAddress
//console.log("MachineName vm ",MachineName)

const PreCartMachineIdVmInventory=props&&props.VmInventory&&props.VmInventory.PreCartMachineIdVmInventory
const CurrentMachineID=props&&props.VmInventory&&props.VmInventory.VmInventoryMachineId
//console.log("CurrentMachineID PreCartMachineIdVmInventory",PreCartMachineIdVmInventory,CurrentMachineID)
const mId = getParameterByName('machineId');
let machineId =mId&& mId.split('?')[0]

const value = getParameterByName('value');
let value_machineId =value&&value.split('?')[0]

const movecart = getParameterByName('move_cart');
let movecart_machine =movecart&&movecart.split('?')[0]

//console.log("value_machineId",value_machineId,movecart_machine)
localStorage.setItem("machineId",machineId);
localStorage.setItem("PreVmId",PreCartMachineIdVmInventory)
useEffect(()=>{

  fetchCombo()
 
},[])

const [addThisSlot,setAddThisSlot]=useState(null)

const [addThisCombo,setAddThisCombo]=useState(null)
const [comboReplaceflag,setComboReplaceflag]=useState(false)



const [replaceCard,setReplceCard]=useState(false)
const [buyAgainList,setBuyAgainList]=useState(null)


useEffect(()=>{

  if(localStorage.getItem("move_cart_vmid"))
  {
  fetchmoveCart()
  }
},[])


const fetchCombo= () => {
      
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  //console.log("fetchCombo")

  props.fetchComboRequest({
    url: `/consumerbe/api/v2/outlet/${machineId}/combo`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },
    handleErrorCombo,
    handleResponseCombo,
  });
};
const handleResponseCombo=(res)=>{
//console.log("handleResponseCombo",res.data.data)
let d=res.data.data.map(obj => {
  //console.log("combo array",obj)
   obj.mycount = 0;

   return obj;
})
//console.log("combo array----",d)
setComboList(d)

}
const handleErrorCombo=(error)=>{
 /*  toast.error(`${error&&error.response&&error.response.data.status}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  }); */

}



const fetchmoveCart= () => {
      
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchMoveCartRequest({
    url: `/consumerbe/api/v2/cart/${value_machineId?value_machineId:localStorage.getItem("move_cart_vmid")}/transfer`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },
    handleErrorMoveCart,
    handleResponseMoveCart,
  });
};
const handleResponseMoveCart=(res)=>{
//console.log("handleResponseMoveCart",res)
if(res&&res.data&&res.data.data){
  navigate("/mycart")
  localStorage.setItem("mycart",true)
}
}
const handleErrorMoveCart=(error)=>{
  //console.log("handleErrorMoveCart",error.response.data.status.code)
  if(error.response.data.status.code=="UNAUTHORIZED")
  {
    localStorage.setItem("move_cart_vmid",value_machineId)
  }

}
    useEffect(()=>{

      localStorage.removeItem("mycart");
      localStorage.removeItem("SodexoPaymentRequestId");
      localStorage.removeItem("OrderId");
      localStorage.removeItem("sodexoPaymentStatus")
      if(movecart_machine=="true"){
        fetchmoveCart()
        }
        else{
        fetchVmInventory()
        fetchVmInventoryTodaySpecial()
        fetchPreCard()
        fetchActiveOrder()
        fetchBuyAgain()
        }
       
     


    },[])

    useEffect(()=>{

      if(!(localStorage.getItem("UserJWTAuth"))){
        navigate('/')

      }
     
    },[localStorage.getItem("UserJWTAuth")])



    let totalitemcount=0,totalAmount=0
    useEffect(()=>{
      
      cartItemsList&&cartItemsList.forEach(item => {
        const count = item.count;
        const amount = item.amount;
        const itemTotal = count * amount;
        totalitemcount+=item.count
        totalAmount += itemTotal;
      });
      setCartCount(totalitemcount)
      setCarttotalAmount(totalAmount)
   
  
    },[cartItemsList])


   





    useEffect(()=>{

      const TodayVmInventorySpecialList= VmInventorySpecialList&&VmInventorySpecialList.map((obj,index)=> ({ ...obj, mycount: 0,index:index}));
 
 
 
   
     
      const TodaymatchingData =
        PreCartVmInventoryList&&PreCartVmInventoryList[0]
          ? PreCartVmInventoryList
          : cartItemsList1;
   /*    console.log(
        "TodaymatchingData",
        TodaymatchingData,
        cartItemsList1
      ); */
     
  
      const TodaymergedData = TodayVmInventorySpecialList && TodayVmInventorySpecialList.map((item) => {
        const TodaymatchingItem = TodaymatchingData && TodaymatchingData.find((matchItem) => {
          //console.log("Checking matchItem:",CurrentMachineID==PreCartMachineIdVmInventory);
          //console.log("CurrentMachineID:", CurrentMachineID);
          //console.log("PreCartMachineIdVmInventory:", PreCartMachineIdVmInventory);
          
          return (matchItem.slotId ===item.slot_id&&CurrentMachineID==PreCartMachineIdVmInventory)
  
        });
    
      
        return {
          ...item,
          mycount: TodaymatchingItem && TodaymatchingItem.count
            ? TodaymatchingItem.count
            : item.mycount,
        };
      });
      
    //console.log("TodaymergedData", TodaymergedData);
    SetFinal_VmInventorySpecialList(TodaymergedData)



    },[VmInventorySpecialList,CurrentMachineID,PreCartMachineIdVmInventory])

    useEffect(()=>{

      const comboListInventory= comboList&&comboList.map((obj,index)=> ({ ...obj, mycount: 0,index:index}));
 
 
 
   
     
      const TodaymatchingData =
        comboCart&&comboCart[0]
          ? comboCart
          : cartItemsList1;
  
  
      const TodaymergedData = comboListInventory && comboListInventory.map((item) => {
        const TodaymatchingItem = TodaymatchingData && TodaymatchingData.find((matchItem) => {
     
          
          return (matchItem.slotId ===item.slot_id&&CurrentMachineID==PreCartMachineIdVmInventory)
  
        });
    
      
        return {
          ...item,
          mycount: TodaymatchingItem && TodaymatchingItem.count
            ? TodaymatchingItem.count
            : item.mycount,
        };
      });
      



    },[VmInventorySpecialList,CurrentMachineID,PreCartMachineIdVmInventory])



    const fetchBuyAgain= () => {
      
      const UserJWTAuth = localStorage.getItem("UserJWTAuth");
    
      props.fetchBuyAgainRequest({
        url: `/consumerbe/api/v2/outlet/${machineId}/previously-purchased`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
          "x-app-platform": "web",
          "x-app-version": "v1.0.1",
          //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid")
        },
        handleErrorBuyAgain,
        handleResponseBuyAgain,
      });
    };
    
    const handleResponseBuyAgain=(res)=>{
     
   
    setBuyAgainList(res.data.data)
  
  
    }
    
    const handleErrorBuyAgain=(error)=>{

      if(error.response.status==401|| error.response.status==403){

    
        //Logout
        localStorage.removeItem("UserJWTAuth")
          navigate("/login");
    }
      
     else{ setLoader(false)
     /*  toast.error(`${error.response.data.status.code}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      }); */
    }
    
    }
    



    const fetchVmInventory= () => {
      setLoader(true)
        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      
        props.fetchVmInventoryRequest({
          url: `/consumerbe/api/v2/outlet/${machineId}/items`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
            //HEADER ERROR
            "x-device-id":localStorage.getItem("uuid")
          },
          handleErrorVmInventory ,
          handleResponseVmInventory,
        });
      };
      
      const handleResponseVmInventory=(res)=>{
       setLoader(false)
   
      setCurrentMachineID(res.headers.machineid)
      const updatedArray = res.data.data.map((obj,index)=> ({ ...obj, mycount: 0,index:index}));

      setInventoryList(updatedArray)
    
      }
      
      const handleErrorVmInventory=(error)=>{
        if(error.response.status==401 || error.response.status==403){

          //Logout
          localStorage.removeItem("UserJWTAuth")
            navigate("/login");
      }
       else{
        setLoader(false)
    /*     toast.error(`${error.response.data.status.code}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        }); */
      }
      
      }
      
      const handleResponseVmInventorySpecial=(res)=>{
        const updatedArray = res.data.data.map((obj,index)=> ({ ...obj, mycount: 0,index:index}));
        setTodayInventoryList(updatedArray)
        
        }
        
        const handleErrorVmInventorySpecial=(error)=>{
          if(error&&error.response.status==401 ||error&& error.response.status==403){

           
            //Logout
            localStorage.removeItem("UserJWTAuth")
              navigate("/login");
        }
         else{ 
      /*     toast.error(`${error.response.data.status.code}`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }); */
        }
        
        }
  
      const fetchVmInventoryTodaySpecial= () => {
        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      
        props.fetchVmInventorySpecialRequest({
          url: `/consumerbe/api/v2/outlet/${machineId}/special`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
          },
          handleErrorVmInventorySpecial ,
          handleResponseVmInventorySpecial ,
        });
      };
      
      const fetchPreCard = () => {
        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      
        props.fetchPreCartVmInventoryRequest({
          url: `/consumerbe/api/v2/cart`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
          },
          handleErrorPreCardVmInventory ,
          handleResponsePreCardVmInventory ,
        });
      };
      
      const handleResponsePreCardVmInventory=(res)=>{
      
        setComboCartList(res.data.data.comboDetails)
      setPreMachineID(res.headers.machineid)
      setCartItemsList(res.data.data.items);
      setComboCart(res.data.data.comboDetails)
      if(res&&res.data&&res.data.data&&res.data.data&&res.data.data.item&&res.data.data.items[0]){mpCart({EventName:"Cart_Icon_displayed",data:{ItemsInCart:res.data.data.items,SourcePage:"VmInventory Page"}})}
     
      }
      
      const handleErrorPreCardVmInventory=(error)=>{
      
        if(error.response&&error.response.status==401|| error.response&&error.response.status==403){

          //Logout
          localStorage.removeItem("UserJWTAuth")
            navigate("/login");
      }
       else{
      toast.error(`${error.response&&error.response.data.status.code}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });  
      }
      
      }



const handleErrorAddIntoCart=(res,slotId)=>{

  
  setLoader(false)
 
   toast.error(`${res.response&&res.response.data.status.message}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
if(res.response&&res.response.data.status.message=="Cart contains items from another machine"/* ||res.response&&res.response.data.status.message=="Combo present in cart" */){

  
  //window.alert(slotId)
  setReplceCard(true)
  mpClick({EventName:"Clear_Cart_Modal_Opened"})
}



}
const handleResponseAddIntoCart=(res,sId)=>{

  setLoader(false)

  setCartItemsList1(res.data&&res.data.data.items);
  if(replaceCard){
    mpClick({EventName:`Another_vm_item_added vmid=${machineId}`})
    mpClick({EventName:`Cart_replaced`})
  }


  let count=0,amount=0
  const r=res.data.data.items.map((p) => {
 
 
    if(p.mvId>0){
    count+=p.count;
    amount+=p.count*p.amount;
    }


    return p;
  });


  
  fetchPreCard()
  const updatedproducts = inventoryList.map((p) => {

  
   
   if (p.slot_id === sId) {
   
     
     //setTotalAmount(totalamount+p.offer_price)
     setCartCount(cartCount+1)
     
     return { ...p, mycount: p.mycount + 1 };
   }
   return p;
 });




  setInventoryList(updatedproducts);



 
 
 




}




  const AddIntoCart = (e,i,sId,myCartcount,mv_name,mv_id,mrp) => {

mpGlobalAddToCart({EventName:"Add_to_cart",data:{ProductID:mv_id, ProductName:mv_name, ProductCategory:"", ProductPrice:mrp, VMID:machineId ,SourcePage:"vm_inventory_page"}})

  if(PreCartVmInventoryList&&PreCartVmInventoryList[0]|| comboCartList&&comboCartList){

setAddThisSlot({sId:sId,index:i})



  }
    setLoader(true)
        let payloadcount=0;
       
        if(myCartcount>0){
               payloadcount=myCartcount+1

              }
           else{
           payloadcount=inventoryList[i].mycount+1
                }
        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
        
        props.sendAddIntoCartRequest({
          url: `/consumerbe/api/v2/cart?machineId=${machineId}&replace-cart=${replaceCard}`,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
            //HEADER ERROR
           "x-device-id":localStorage.getItem("uuid"),
           
          },
          request: [
            {
              slotId: parseInt(sId), // Parsing sId to an integer
              count: payloadcount,      // Incrementing the count by 1
            },
          ],
          handleErrorAddIntoCart:(res)=>handleErrorAddIntoCart(res,sId),
          handleResponseAddIntoCart: (res) => handleResponseAddIntoCart(res,sId),
        });




   
    
      };
  

const handleErrorRemovefromCart=(res)=>{
  setLoader(false)

 toast.error(`${res}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  }); 




}

  const handleResponseRemovefromCart=(res,sId,myCartcount)=>{
    setCartItemsList1(res.data.data.items);
  
   if(sId==localStorage.getItem("Global_Slot_id")){
    if(myCartcount==1){
      localStorage.removeItem("Global_Slot_id")
    }
    
   }
    
    setLoader(false)
    
    let count=0,amount=0
      const r=res.data.data.items.map((p) => {
        if(p.mvId>0){
     
        count+=p.count;
        amount+=p.count*p.amount;
        }


        return p;
      });

      fetchPreCard()
    
    const updatedproducts = inventoryList.map((p) => {
   
    
      if (p.slot_id === sId) {
       
        //setCartCount(cartCount-1)
        //setTotalAmount(totalamount-p.offer_price)
        return { ...p, mycount: p.mycount - 1 };
      }
      return p;
    });
   


    setInventoryList(updatedproducts);

  



  }

      const RemovefromCart = (e,i,sId,myCartcount,mv_name,mv_id,mrp) => {
        mpGlobalAddToCart({EventName:"Remove_from_cart",data:{ProductID:mv_id, ProductName:mv_name, ProductCategory:"", ProductPrice:mrp, VMID:machineId ,SourcePage:"vm_inventory_page"}})

        setLoader(true)
        let payloadcount=0;
    
        

        payloadcount=myCartcount-1

        
        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      
        props.sendRemoveFromCartRequest({
          url: `/consumerbe/api/v2/cart?machineId=${machineId}`,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
            //HEADER ERROR
            "x-device-id":localStorage.getItem("uuid")
          },
          request: [
            {
              slotId: parseInt(sId), // Parsing sId to an integer
              count: payloadcount,      // Incrementing the count by 1
            },
          ],
          handleErrorRemovefromCart ,
          handleResponseRemovefromCart: (res) => handleResponseRemovefromCart(res,sId,myCartcount),
        });




   
    
      };








      const TodayAddIntoCart = (e,i,sId,myCartcount,mv_name,mv_id,mrp) => {

        mpGlobalAddToCart({EventName:"Add_to_cart",data:{ProductID:mv_id, ProductName:mv_name, ProductCategory:"", ProductPrice:mrp, VMID:machineId ,SourcePage:"vm_inventory_page"}})


        if(todayinventoryList&&todayinventoryList[0]){

          setAddThisSlot({sId:sId,index:i})
          
          
          
            }



        setLoader(true)
      
        let payloadcount=0;
    
        if(myCartcount>0){
               payloadcount=myCartcount+1

              }
           else{
           payloadcount=todayinventoryList[i].mycount+1
                }
        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      
        props.sendTodayAddIntoCartRequest({
          url: `/consumerbe/api/v2/cart?machineId=${machineId}`,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
            //HEADER ERROR
           "x-device-id":localStorage.getItem("uuid")
          },
          request: [
            {
              slotId: parseInt(sId), // Parsing sId to an integer
              count: payloadcount,      // Incrementing the count by 1
            },
          ],
          handleErrorTodayAddIntoCart ,
          handleResponseTodayAddIntoCart: (res) => handleResponseTodayAddIntoCart(res,sId),
        });




   
    
      };

      const handleErrorTodayAddIntoCart=(res)=>{
        setLoader(false)
     
     toast.error(`${res.response.data.status.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        }); 
     
        if(res.response.data.status.message=="Cart contains items from another machine"){


          //window.alert(slotId)
          setReplceCard(true)
        }
      
      }

      const handleResponseTodayAddIntoCart=(res,sId)=>{
        setCartItemsList1(res.data.data.items);

        setLoader(false)
        let count=0,amount=0
        const r=res.data.data.items.map((p) => {
       
      
          if(p.mvId>0){
          count+=p.count;
          amount+=p.count*p.amount;
          }
  
  
          return p;
        });
        setTimeout(() => {
          fetchPreCard()
        }, 1000);
  
        
       
    
         const updatedproducts = todayinventoryList&&todayinventoryList.map((p) => {
     
          if (p.slot_id === sId) {
         
           // setTotalAmount(totalamount-p.offer_price)
            //setCartCount(cartCount-1)
            
            return { ...p, mycount: p.mycount +1 };
          }
          return p;
        });
        setTodayVmInventoryMycountList(updatedproducts);
      
fetchPreCard()
fetchVmInventoryTodaySpecial()


      }
      






      const TodayRemovefromCart = (e,i,sId,myCartcount) => {
        setLoader(true)
        let payloadcount=0;
      

        payloadcount=myCartcount-1

        
        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      
        props.sendTodayRemoveFromCartRequest({
          url: `/consumerbe/api/v2/cart?machineId=${machineId}`,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
            //HEADER ERROR
           "x-device-id":localStorage.getItem("uuid")
          },
          request: [
            {
              slotId: parseInt(sId), // Parsing sId to an integer
              count: payloadcount,      // Incrementing the count by 1
            },
          ],
          handleErrorTodayRemovefromCart ,
          handleResponseTodayRemovefromCart: (res) => handleResponseTodayRemovefromCart(res,sId),
        });




   
    
      };

      const handleResponseTodayRemovefromCart=(res,sId)=>{
        setCartItemsList1(res.data.data.items);

        setLoader(false)
    
        let count=0,amount=0
        const r=res.data.data.items.map((p) => {
       
          
          if(p.mvId>0){
          count+=p.count;
          amount+=p.count*p.amount;
          }
  
  
          return p;
        });
  
    
         const updatedproducts = VmInventorySpecialList&&VmInventorySpecialList.map((p) => {
     
       
          if (p.slot_id === sId) {
        
           // setTotalAmount(totalamount-p.offer_price)
            //setCartCount(cartCount-1)
            
            return { ...p, mycount: p.mycount - 1 };
          }
          return p;
        });
        setTodayVmInventoryMycountList(updatedproducts);
      
fetchPreCard()
fetchVmInventoryTodaySpecial()
      }

      const handleErrorTodayRemovefromCart=(res)=>{
        setLoader(false)
  
       toast.error(`${res}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      
      }


  const handleResponseActiveOrdersVmInventory=(res)=>{


    mpActiveOrdersVmInventory({EventName:"Active_order_icon_displayed",data:{order_id:res.data&&res.data.data[0].orderId,items_count:props.itemlength,SourcePage:"Vm_Inventory_Page"}})
    setActiveOrderList(res.data.data)
    
     
    setLoader(false)

   


  }
  const handleErrorActiveOrders = (error) => {


    if(error&&error.response&&error.response.status==401|| error&&error.response&&error.response.status==403){

     
      //Logout
      localStorage.removeItem("UserJWTAuth")
        navigate("/login");
  }

  else{ 
    if(error&&error.response)
    {toast.error(`${error&&error.response&&error.response.data.status.code}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
}
} 
  };

      const fetchActiveOrder = () => {
        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      
        props.fetchActiveOrdersVmInventoryRequest({
          url: `/consumerbe/api/v2/order/active`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
            //HEADER ERROR
            "x-device-id":localStorage.getItem("uuid")
          },
          
          handleResponseActiveOrdersVmInventory ,
          handleErrorActiveOrders 
        });
      };







  

      const filteredproducts = inventoryList&&inventoryList.filter((product) =>
      product.mv_name.toLowerCase().includes(searchTerms.toLowerCase())
      );
  const ComboCartADD=(e,i,count,cid,replaceFlag)=>{
    let payloadcount=0;

    console.log("CART ID",i,count,cid,replaceFlag)
    
    setAddThisCombo({count:1,cid:cid,index:i})

    //setLoader(true)
    if(replaceFlag){
       payloadcount=count;
    }

      
      else{

         if(count>0){
      payloadcount=count+1

     }
 else{
   
  payloadcount=comboList[i].mycount+1
       } 

      }
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  
    props.sendComboCartADDRequest({
      url: `/consumerbe/api/v2/cart/combo?machineId=${machineId}&replace-cart=${comboReplaceflag}`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
       "x-device-id":localStorage.getItem("uuid")
      },
      request: 
        {

          "comboId":parseInt(cid),
	        "count":payloadcount
        
        },
      
      handleErrorComboCartADD:(error)=>handleErrorComboCartADD(error,cid),
      handleResponseComboCartADD: (res) => handleResponseComboCartADD(res,cid),
    });

}

const replaceMyCard=()=>{



  fetchPreCard()
  setReplceCard(false)
  
 
  AddIntoCart("tahzeeb",addThisSlot.index,addThisSlot.sId,0)
  
  mpClick({EventName:"Clear_Cart"})

  
  }


  const replaceMyCombo=()=>{

   
  
    fetchPreCard()
    setComboReplaceflag(false)
    console.log("Cart ID addThisCombo.cid",addThisCombo)
    ComboCartADD("tahzeeb",addThisCombo.i,addThisCombo.count,addThisCombo.cid,true)
    
    mpClick({EventName:"Combo Clear"})
  
    
    }

const handleErrorComboCartADD=(error)=>{
setComboReplaceflag(true)

  if(error&&error.response)
    {
      toast.error(`${error&&error.response&&error.response.data.status.message}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
   

}
}

const handleResponseComboCartADD=(res,cid)=>{

  fetchPreCard()

  setComboCart(res.data.data.comboDetails);

  const updatedproducts = comboList.map((p) => {

  
    
   if (p.id===cid ) {
      
    
      return { ...p, mycount: p.mycount + 1 };
    } 
     return p; 
  });



setComboList(updatedproducts)
   
//setComboId(cid)



}
  const ComboCartRemove=(e,i,count,cid)=>{


  //setLoader(true)
  let payloadcount=0;
  if(count>0){
    payloadcount=count-1

   }
else{
payloadcount=comboList[i].mycount-1
     } 
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.sendComboCartREMOVERequest({
    url: `/consumerbe/api/v2/cart/combo?machineId=${machineId}`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
     "x-device-id":localStorage.getItem("uuid")
    },
    request: 
      {

        "comboId":parseInt(cid),
        "count":payloadcount
      
      },
    
    handleErrorComboCartRemove:(res)=>handleErrorComboCartRemove(res,cid),
    handleResponseComboCartRemove: (res) => handleResponseComboCartRemove(res,cid),


    }   

  )
}


const handleErrorComboCartRemove=(error,cid)=>{
  if(error&&error.response)
    {
      toast.error(`${error&&error.response&&error.response.data.status.message}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
   

}
}

const handleResponseComboCartRemove=(res,cid)=>{
  setComboCart(res.data.data.comboDetails);
  const updatedproducts = comboList.map((p) => {

  
    
   if (p.id===cid ) {
      
      
      //setTotalAmount(totalamount+p.offer_price)
      setComboCount(p.myCount)
      
      return { ...p, mycount: p.mycount-1 };
    } 
     return p; 
  });


  setComboList(updatedproducts)
   
    //setComboId(cid)
}

  
      


    return(
    
    <>
    
    <VMInventory
    VmInventorySpecialList={VmInventorySpecialList}
    VmInventoryList={VmInventoryList}
    PreCartVmInventoryList={PreCartVmInventoryList}
    Final_VmInventoryList={final_VmInventoryList}
    final_VmInventorySpecialList={final_VmInventorySpecialList}
    AddIntoCart={AddIntoCart}
    RemovefromCart={RemovefromCart}
    TodayRemovefromCart={TodayRemovefromCart}
    TodayAddIntoCart={TodayAddIntoCart}
    carttotalAmount={carttotalAmount}
    totalitemcount={cartCount}
    ActiveOrders={ActiveOrders}
    loader={loader}
    MachineAddress={MachineAddress}
    MachineName={MachineName}
    setReplceCard={setReplceCard}
    replaceCard={replaceCard}
  


    setSearchTerms={setSearchTerms}
    searchTerms={searchTerms}
    filteredproducts={filteredproducts}
    activeOrderList={activeOrderList}
    currentMachineID={CurrentMachineID}
    machineId={machineId}
    preMachineID={preMachineID}

    cartItemsList={cartItemsList}
    cartItemsList1={cartItemsList1}
    buyAgainList={buyAgainList}
    flagMp={flagMp}
    comboList={comboList}
    ComboCartADD={ComboCartADD}
    setComboCount={setComboCount}
    comboCount={comboCount}
    ComboCartRemove={ComboCartRemove}
    comboCart={comboCart}
    comboReplaceflag={comboReplaceflag}
    setComboReplaceflag={setComboReplaceflag}
    replaceMyCard={replaceMyCard}
    replaceMyCombo={replaceMyCombo}
    
    />
    
    </>)
}

const mapStateToProps = (props) => {
    return props;
  };
  
  const mapDispatchToProps = {
    fetchVmInventoryRequest,
    fetchVmInventorySpecialRequest,
    fetchPreCartVmInventoryRequest,
    sendAddIntoCartRequest,
    sendTodayAddIntoCartRequest,
    sendTodayRemoveFromCartRequest,
    sendRemoveFromCartRequest,
    fetchActiveOrdersVmInventoryRequest,
    fetchClearPreCardRequest,
    fetchBuyAgainRequest,
    fetchMoveCartRequest,
    fetchComboRequest,
    sendComboCartADDRequest,
    sendComboCartREMOVERequest

  
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(VMInventory_Container);
  