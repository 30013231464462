import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_GLOBAL_SEARCH_REQUEST,
  fetchGlobalSearchSuccess,
  fetchGlobalSearchFailure,
  fetchVmInventroyFailure,
  FETCH_VM_WISE_INVENTROY_REQUEST,
  fetchVmInventroySuccess,
  FETCH_ADD_TO_CART_REQUEST,
  fetchAddToCartSuccess,
  fetchAddToCartFailure,
  FETCH_PRE_CART__GLOBAL_REQUEST,
  
  fetchPreCartGlobalFailure,
  fetchPreCartGlobalSuccess,
  FETCH_ACTIVE_ORDERS_GLOBAL_REQUEST,
  fetchActiveOrdersGlobalSuccess

} from "./action";
import axios from "axios";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
  console.log("callApi", mydata.data);

  const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
  try {
    const response = await axios({
      method: mydata.data.method,
      url: url,
      headers: mydata.data.headers,
      data: mydata.data.request
      
    });

    return response;
  } catch (error) {
    throw error;
  }
};


//////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////  GLOBAL SEARCH DATA ///////////////////////////////////////////////////////
function* fetchGlobalSearch(action) {
  console.log("fetchUserSaga myurl", action);
  try {
    const response = yield call(callApi, action);

    yield put(fetchGlobalSearchSuccess(response));

    action.data.handleResponseGlobalSearch(response);
  } catch (error) {
    console.log("fetchUserSaga error", error.message);
    yield put(fetchGlobalSearchFailure(error.message));
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////




//////////////////////////////////////////  VM WISE INVENTROYU DATA ///////////////////////////////////////////////////////
function* fetchVmWiseInventory(action) {
    console.log("fetchUserSaga myurl", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchVmInventroySuccess(response));
  
      action.data.handleResponseVmWiseInventory(response);
    } catch (error) {
      console.log("fetchUserSaga error", error.message);
      yield put(fetchVmInventroyFailure(error.message));
    }
  }
  
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  





//////////////////////////////////////////  ADD TO CART DATA ///////////////////////////////////////////////////////
function* fetchAddtoCart(action) {
  console.log("fetchUserSaga myurl", action);
  try {
    const response = yield call(callApi, action);

    yield put(fetchAddToCartSuccess(response));

    action.data.handleResponseAddToCart(response);
  } catch (error) {
    //console.log("fetchUserSaga error", error.response.data.status.message);
    
    action.data.handleErrorAddToCart(error&& error.response);
    yield put(fetchAddToCartFailure(error.message));
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////






//////////////////////////////////////////  PREVIOUS  CART DATA ///////////////////////////////////////////////////////
function* fetchPreCart(action) {
  console.log("fetchUserSaga myurl", action);
  try {
    const response = yield call(callApi, action);

    yield put(fetchPreCartGlobalSuccess(response));

    action.data.handleResponsePreCardGlobal(response);
  } catch (error) {
    console.log("fetchUserSaga error", error.message);
    action.data.handleErrorPreCardGlobal(error)
    yield put(fetchPreCartGlobalFailure(error.message));
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////  ACTIVE  CART DATA ///////////////////////////////////////////////////////
function* fetchActiveOrders(action) {
  console.log("fetchUserSaga myurl", action);
  try {
    const response = yield call(callApi, action);

    yield put(fetchActiveOrdersGlobalSuccess(response));

    action.data.handleResponseActiveOrdersGlobal(response);
  } catch (error) {
    console.log("fetchUserSaga error", error.message);
    yield put(fetchPreCartGlobalFailure(error.message));
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////








function* gobalSearchSaga() {
  yield takeLatest(FETCH_GLOBAL_SEARCH_REQUEST, fetchGlobalSearch);
  yield takeLatest(FETCH_VM_WISE_INVENTROY_REQUEST, fetchVmWiseInventory);
  yield takeLatest(FETCH_ADD_TO_CART_REQUEST,fetchAddtoCart)
  yield takeLatest(FETCH_PRE_CART__GLOBAL_REQUEST,fetchPreCart)
  yield takeLatest(FETCH_ACTIVE_ORDERS_GLOBAL_REQUEST,fetchActiveOrders)
 
}

export default gobalSearchSaga;
