export const FETCH_BANNER_REQUEST=" FETCH_BANNER_REQUEST"
export const FETCH_BANNER_SUCCESS="FETCH_BANNER_SUCCESS"
export const FETCH_BANNER_FAILURE=" FETCH_BANNER_FAILURE"


export const fetchBannersRequest=(data)=>{
    console.log("fetchVmList myurldata---->",data)
    return{
        type:FETCH_BANNER_REQUEST,
        data:data
    }

}

export const fetchBannerSuccess=(BannerList)=>{
return{
    type:FETCH_BANNER_SUCCESS,
    BannerList:BannerList
}
}

export const fetchBannerFailure=(error)=>{
    return{
    type:FETCH_BANNER_FAILURE,
    error:error
    }
}