
import { connect } from "react-redux";
import { fetchBannersRequest } from "./action";
import { useEffect, useState } from "react";
import Banner from './component';
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
const Banner_Container=(props)=>{
    console.log("Banner_Container",navigator.onLine)
   const navigate=useNavigate()
   const [BannerData,setBannerData]=useState([])
    const Banner_VmId=props.vmid
   
    const handleResponse=(res)=>{
        console.log("handleResponse",res&&res.data.response)
        setBannerData(res&&res.data.response)
       }
       
       const handleError=(error)=>{
        console.log("khan handleErrorVmInventory", error&&error.response);
        if(error&&error.response&&error.response.status==401|| error&&error.response&&error.response.status==403){

            console.log("khan handleErrorVmInventory", error&&error.response.data.status.code);
            //Logout
            //localStorage.removeItem("UserJWTAuth")
              navigate("/login");
        }
        else{ toast.error(`${error.response.data.status.code}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        }
        
          }
       
       useEffect(()=>{
       
  
       
       const UserJWTAuth = localStorage.getItem('UserJWTAuth');
       console.log("fetchBanners online ",navigator.onLine)
       if(navigator.onLine){
       props.fetchBannersRequest(
         
               {
              url:"/order/fetch/banners",
              method:"POST",
              "headers": {
               'Authorization':`Bearer ${UserJWTAuth}`,
               
               "x-app-platform": "web",
               "x-app-version": "v1.0.1",
               //HEADER ERROR
               "x-device-id":localStorage.getItem("uuid")
               
             },
             request:{"request": {
     
                "vendingMachineId": Banner_VmId
                }
            },
              handleError,
              handleResponse
       
           })
          }
       },[])


return(

<>
{navigator.onLine&&<Banner
BannerData={BannerData}
/>}


</>)

}

const mapStateToProps=(props)=>{

    return( props)
 
 }
 
 
 const mapDispatchToProps={
 
    fetchBannersRequest
 
 }
 
 export default connect(mapStateToProps,mapDispatchToProps)(Banner_Container);