import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_BANNER_REQUEST,
  fetchBannerFailure,
  fetchBannerSuccess,
} from "./action";
import axios from "axios";

/* const callApi = async (mydata) => {
  console.log("callApi", mydata.data.request);

  const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
  try {
    const response = await axios({
      method: mydata.data.method,
      url: url,
      headers: mydata.data.headers,
      request:{
     
        "vendingMachineId": 8
         
    }  
    });

    return response;
  } catch (error) {
    throw error;
  }
}; */

function callApi(mydata) {

    const method=mydata.data.method
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    const header=mydata.data.headers 
    const data= mydata.data.request
    const UserAuthJWT = localStorage.getItem('UserAuthJWT');
    console.log("UserAuthJWT",UserAuthJWT)
  
   
  
  
    return axios({
     
      data,
      method,
      url: `${url}`,
      headers:header, 
      //headers: response.headers,
     
    
    }).then(response=>{
      console.log("Axois---->",response)
      return response
     
    
  
    });
  } 




function* fetchBanner(action) {
  console.log("fetchUserSaga myurl", action);
  try {
    const response = yield call(callApi, action);

    yield put(fetchBannerSuccess(response));

    action.data.handleResponse(response);
  } catch (error) {
    console.log("fetchUserSaga error", error.message);
    action.data.handleError(error)
    yield put(fetchBannerFailure(error.message));
  }
}

function* vmListSaga() {
  yield takeLatest(FETCH_BANNER_REQUEST, fetchBanner);
}

export default vmListSaga;
