import { Link } from "react-router-dom";
import TagpayIcon from "../../assets/tagpay.png";
import scanbutton from "../../assets/ScanButton.png";
import TopNavBar from "../Header/AddTagHeader";

import OtpInput from "../../react-otp-input";
import WaitingLoder from "../WaitingLoader/component";
import Webcam from "react-webcam";
import {  toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import QrReader from "react-web-qr-reader";
import { AiOutlineClose } from 'react-icons/ai';
import Tagkey from '../../assets/tagkey.png'
const TagAdd = (props) => {

  const navigate=useNavigate()
  const navigator=useNavigate()
  const [cameraError, setCameraError] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [cameraFacingMode, setCameraFacingMode] = useState('environment'); // 'user' for front camera, 'environment' for rear camera
  const videoConstraints = {
    facingMode: 'environment',  // This sets the camera to use the back camera
  };
  
const CameraHeader = ({onClick}) => {


   const  handleBack=()=>{
  
    setShowScanner(false)
    }
  
    return (
      <div className='h-[60px] flex items-center daalchini-header-color '>
          <AiOutlineClose size=" 25"className='ml-5' onClick={handleBack}/>
          <p className='font-body font-medium mt-1 ml-2 text-gray-800'>Scan Tag</p>
      </div>
    )
  }


  function handleScan(result) {
    console.log("contactless",result)
      if (result) {
        // Do something with the result
        console.log("PickupOrderContainer---------->",result.data);
  
        const machineIdIndex = result&&result.data.indexOf("machineId=");
  
        if(result.data){
        props.setTokenNumber(result.data)
        setShowScanner(false)
      
      }
        
  
        
        
      }
    }


  const handleError = (error) => {
    console.log("Camera Error", error);
    toast.error(`${error}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    setCameraError(true);
    props.setShow_Qr_Modal(true);
  };
  return (
    <>
    {  showScanner&& 
    
    <div>
      <CameraHeader />
              <div className="mt-[60px] h-full w-full backdrop-blur-md absolute top-0 left-0"></div>

               <Webcam  videoConstraints={videoConstraints}  className="object-cover h-screen w-screen" />  
              <div className="mt-[80px] w-[200px] h-[200px] border-4 border-[var(--color-primary)] rounded-md flex justify-center items-center absolute top-1/4 left-1/4  ">
                {showScanner && (
                      <QrReader
                      delay={50}
                      onError={handleError}
                      onScan={handleScan}
                      facingMode={cameraFacingMode}
                      style={{width:"100%",border:"none!important","box-shadow":"none"}}
                  
                               />
                )}
              </div> 
            </div>}
    {props.loader&&<WaitingLoder/>}
   
      {!showScanner&&props.pageCount=="1"&& (
        
        <div
        style={{
          background:
            "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
            //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.PreCardItems[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.PreCardItems[0]&&"8.5rem",
        }}
       className={props.login|| props.loginOtp?"h-screen overflow-y-auto ":" pb-36 overflow-y-auto h-screen"}
    
    
      >
           <TopNavBar title={"ADD TAG"} route={"/profile"}/>
       
          <div
            style={{
              padding: "5%",
              display: "flex",
              "justify-content": "space-around",
            }}
          >
            <div>
              <img style={{ width: "100%", padding: "23%" }} src={TagpayIcon} />
              <h3>Enter Tag Number</h3>
            </div>
          </div>

          <div style={{ display: "flex", "justify-content": "space-evenly" }}>
            {" "}
            <input 
            placeholder="Enter Tag Number"
            value={props.tokenNumber}
              style={{
                "text-align": "center",
                padding: "3%",
                width: "81%",
                "margin-bottom": "11%",
                borderRadius: "11px",
                "font-size": "21px",
              }}
              onChange={(e)=>{props.handleChangeRfIDToken(e)}}
            />
          </div>
          <div style={{ display: "flex", "justify-content": "space-evenly" }}>
            <button
              className="btnEnable"
              style={{ width: "81%" }}
              onClick={props.SearchToken}
            >
              <p style={{ "font-size": "21px" }}>SUBMIT</p>

            </button>
          </div>

          <div style={{ position: "absolute", left: "22%", top: "69%" }}>
            <p style={{ "text-align": "center", "margin-top": "11px" }}>Or</p>
            <p style={{ "text-align": "center", "margin-top": "11px" }}>
            scan Qr Code to Add New Tag 
            </p>
          </div>
          <div style={{ position: "absolute", bottom: "7px", left: "35%" }}>
            <img src={scanbutton} onClick={(e)=>{setShowScanner(true)}}/>
          </div>
        </div>
      )} 
   


   
   {props.askLink&&<div  className="modalDeactive"  /* style={{width:"90%",top:"20%", height:"392px",left:"5%"}} */ >
   <div className="modal-content-tagHistory">
     <img src={""} style={{"margin-left":"37%"}} />
     <h3 style={{"text-align":"center"}}>De-activate Tag?</h3>
     <p style={{ "text-align":"center","margin-top": "11px"}}>You won’t be able to use the tag after deactivation. For activating again, you will need the tag’s secret code.</p>
    <p style={{ "text-align":"center","margin-top": "11px","font-weight":"bold"}}>Your remaining balance ₹995 will be transferred to Daalchini Points.</p>
     
     <div style={{display:"flex","justify-content":"space-around","margin-top": "8px"}}>
     <button onClick={(e)=>{props.setDeactiveModal(false)}} className="canclebtn">Cancel</button>
       <button onClick={props.Deactive} className="deactivebtn">De-activate</button>
     </div>
     </div>
   </div>}

   {props.pageCount=="2"&&<div>
    <TopNavBar title={"Link TagPay"} route={"/profile"}/>
        <div style={{"padding-top": "81px",height:"100vh",background: "rgb(245,233,233)",
background: "radial-gradient(circle, rgba(245,233,233,0.4066001400560224) 0%, rgba(185,245,247,0.3393732492997199) 100%)"}} >
       
      
        <img
        
          className="RegCloseImg"
        />
        <div className="REG">
          <div style={{    display:"flex","justify-content":"space-around"}}>  
          <div className="RedImgdiv" style={{width:"111px",height:"111px", "border-radius":"72px" ,  display:"flex","align-items":"center"}}>
            <img  style={{ width:"100%",height:"100%"}}src={Tagkey} className="RegImg" />
          </div>
           </div> 
          <diV style={{    display:"flex","justify-content": "center","margin-top":"8%","text-align":"center"}}>
          <div className="">
            <h1 style={{ color:"#384F6F","font-family":"Roboto","font-size":"20px","font-style":"normal","font-weight":"700","line-height":"normal" }}>Enter Secret Code</h1>
            <p style={{ color: "#64748B",

/* R14 */
"font-family":"Roboto",
"font-size":"14px","font-style":"normal","font-weight":"400",
"line-height":" 150%", /* 21px */
"letter-spacing":"0.2px" }}>
           Your tag secret is printed on the tag hoder
            </p>
           
          </div>
          </diV>
          <div style={{display:"flex","justify-content": "center",marginTop:"15%"}}>
          <OtpInput
        value={props.secretkey}
     
        onChange={props.handleChangeOtp}
        numInputs={6}
        separator={<span style={{ width: "8px" }}></span>}
        isInputNum={true}
        shouldAutoFocus={true}
        inputStyle={{
         
          borderRadius: "8px",
          width: "44px",
          height: "44px",
          fontSize: "12px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue",
          border: "1px solid #6c5858"
        }}
        focusStyle={{
          border: "1px solid #CFD3DB",
          outline: "none"
        }}
      />
      </div>
   
     
     <div style={{display:"flex","justify-content":"center", "margin-top":"44px"}}>
     
        
  
            </div> 

          
         
            <div style={{position:"absolute",bottom:"51px",width:"100%","right":"2%"}}>
          <p href="whatsapp://" className="whatsappLink" style={{display: "flex","justify-content":"center",marginTop:"21px",position:"absolute","color":"#b3a1a1",
    "margin-left": "50px",
    "margin-top": "13px"}}>
          By linking your TagPay, you agree to Terms & Conditions.          </p>
         {props&&props.secretkey&&props.secretkey.length==6? <button onClick={props.LinkTagFun} className="verybtn" style={{display:"flex","justify-content": "center" ,  "margin":"37px","border-radius": "13px",}}>
         Submit
          </button>: <button className="verybtn" style={{display:"flex","justify-content": "center" ,  "margin":"37px","border-radius": "13px",backgroundColor:"#BCBDBD"}}>
          Submit
          </button>}
          </div>
        </div>
      </div>
      </div>}
      




    </>
  );
};

export default TagAdd;
