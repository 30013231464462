export default function swDev() {
    // Construct the service worker URL using PUBLIC_URL
    const swUrl = `${process.env.PUBLIC_URL}/sw.js`;

    // Register the service worker
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(swUrl)
            .then((registration) => {
                console.log("Service Worker registered with scope:", registration.scope);
            })
            .catch((error) => {
                console.error("Service Worker registration failed:", error);
            });
    } else {
        console.log("Service Worker not supported");
    }
}
