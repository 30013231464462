import { useEffect, useState } from 'react';
import  Coins from '../../assets/coins.png'
import Gift from '../../assets/Gift.png'
import moreIcon from '../../assets/moreIcon.png'
import DiscounTag from '../../assets/DiscountTag.png'
import moregray from '../../assets/moregray.png'
import GiftGray from '../../assets/Giftgray.png'
import coinsGray from '../../assets/coinsGray.png'
import close from '../../assets/close.png'
import { Link } from 'react-router-dom';
import Cong from '../../assets/cong.png'
import Cong1 from '../../assets/cong1.png'
import DiscounTagGray from '../../assets/DiscountTagGray.png'
import { useNavigate } from 'react-router-dom';
import WaitingLoder from '../WaitingLoader/component';
import { mpCoupon } from '../../Mixpanel';
const Coupons=(props)=>{
  const navigate=useNavigate()

useEffect(()=>{
if(localStorage.getItem("Guest_clientId")){
  navigate('/myCart')
}
mpCoupon({data:"Coupon Page Open"})
},[])
const appliedCouponName = localStorage.getItem("appliedCouponName");

// Calculate the length of the string
const lengthOfCouponName = appliedCouponName ? appliedCouponName.length : 0;
console.log("props&&props.showCouponName",props&&props.showCouponName)
const applicableCoupons = props&&props.CouponsList.filter(coupon => coupon.isApplicable === true);
const notApplicableCoupons = props&&props.CouponsList.filter(coupon => coupon.isApplicable === false);
  function CouponHeader( {text, limit} ) {
  
        function TruncatedHeading() {
          if (text&&text.length <= limit) {
            return <h3 style={{color:"#583C43",
             
              "font-family": "Roboto",
              "font-size":"18px",
              "font-style":" normal",
              "font-weight":"500",
              "line-height": "normal",
              "letter-spacing": "0.36px"}}>{text}</h3>;
          }
          return <h3  style={{color:"#583C43",
         "width":"150%",
          "font-family": "Roboto",
          "font-size":"18px",
          "font-style":" normal",
          "font-weight":"500",
          "line-height": "normal",
          "letter-spacing": "0.36px"}}>{`${text.substring(0, limit)}...`}</h3>;
        }
        function TruncatedSubheading({ text, limit }) {
          if (text.length <= limit) {
            return <p style={{width:"200%",fontSize:"13px",color:"#583C43"}}>{text}</p>;
          }
          return <p style={{width:"200%",fontSize:"13px",color:"#583C43"}}>{`${text.substring(0, limit)}...`}</p>;
        }
        
        return (
          <div className='h-[60px] flex items-center '>
          <div className="_2456r" style={{height:props.height}}>
      <div className="_3nxa7" data-testid="listing-header-location">
        <button
          className="_1NdRR"
          aria-label="Selected address is Delhi, India; Double tap to change address."
          id="change-address-btn-id"
        >
          <div style={{display:"flex","align-items":"center",marginTop:props.marginTop}}>
         <Link to={'/myCart'}><img  style={{height:"18px","margin-right":"12px"}}src={close}/></Link>
         <div style={{marginLeft:"10px"}}>
          <TruncatedHeading  text={`${props.title}`} limit={20}></TruncatedHeading>
          {props&&props.subtitle&&<TruncatedSubheading  text={`${props.subtitle}`} limit={40}></TruncatedSubheading>}
          </div>
          </div>
        </button>
      
      </div>
      </div>
      </div>
        );
      }




const goToMycart=()=>{
  navigate('/myCart')
}


    const [expandedDiv, setExpandedDiv] = useState([]);

    const handleToggleTransform = (index) => {
        setExpandedDiv((prevExpandedDiv) => {
          const updatedDiv = [...prevExpandedDiv];
          updatedDiv[index] = !updatedDiv[index];
          return updatedDiv;
        });
      };
  
    return(<>
    {props.loader && <WaitingLoder />}
     {props&&props.showCouponFlag&& (
            <div className="modal">
              <div className="modal-content">
                <img
                  src={Cong1}
                  style={{ 
                    position:"absolute",
                    top: "-43px",

                    left: "33%",
                    width: "38%"
                 }}
                />
                <div style={{ "text-align": "center", "margin-top": "15%" }}>
                  <h3 style={{color: "#384F6F",
"text-align": "center",
"font-family": "Roboto",
"font-size":"16px",
"font-style": "normal",
"font-weight": "700",
"line-height": "28px", /* 175% */
"letter-spacing":"1px"}}>Congratulations!</h3>
                  <p style={{ marginTop: "3%" ,color: "rgba(56, 79, 111, 0.69)",
"text-align":"center",
"font-family": "Roboto",
"font-size":"12px",
"font-style": "normal",
"font-weight": "400",
"line-height":"20px"}}>
                   Your coupon Code  <span style={{textTransform:"uppercase"}}>{localStorage.getItem("appliedCouponName")}</span> applied successfully!
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    "justify-content": "space-around",
                    marginTop: lengthOfCouponName>10?"5%":"9%",
                  }}
                >
                  <button
                    onClick={(e) => {
                      goToMycart();
                    }}
                    className="nobtn"
                    style={{display: "flex",
                      "width":"118px",
                      "height": "41px",
                      "padding":"10px",
                      "justify-content": "center",
                      "align-items":"center",
                      "gap": "10px",
                      "flex-shrink":"0"}}
                  >
                    Ok
                  </button>
              
                </div>
              </div>
            </div>
          )}
    <CouponHeader text="Coupon" limit={10}/>
<div style={{padding:"5%",marginTop:"5%"}}><div style={{padding:"2%",borderRadius:"16px",  border:"1px solid rgba(148, 163, 184, 0.52)",display:"flex",alignItems: "center"}}><input   value={props&&props.couponName} onChange={(e)=>{props.couponNameInput(e.target.value)}} style={{width:"100%",padding:"1%",color:"background: rgba(148, 163, 184, 0.52);"}} placeholder='Enter Coupon Code'/>{props&&props.couponName.length>2?<span style={{color:"black",fontSize:"15px"}} onClick={(e)=>{props&&props.ApplyCoupon(e,true)}}>Apply</span>:<span style={{color:"rgba(148, 163, 184, 0.52)",fontSize:"15px"}}>Apply</span>}</div></div>

    {applicableCoupons&&applicableCoupons[0]&&<h1 style={{    paddingLeft:"6%",
    paddingTop:"5%"}}>Applicable Coupons </h1>
    }
 {applicableCoupons[0]&&applicableCoupons.map((el,index)=>{
    console.log("coupon map",index,el)
    return(<div>
    
    {el.isApplicable&&<div key={index} style={{display:"grid",justifyContent:"center",padding:"5%"}}>
       <div style={{height:"136px",width:"100%",backgroundColor:"red",borderRadius:"10px"}}>

        <div style={{width:"30px",height:"30px",backgroundColor:"white",borderRadius:"30px",marginTop:"15%",marginLeft:"-16px"}}></div>
       
        <div style={{width:"30px",height:"30px",backgroundColor:"white",borderRadius:"30px",marginTop:"-8%",marginLeft:"95%"}}></div>
        
       <div style={{marginLeft:"60px",marginTop:"-82px",width:"5px"}}><div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>

       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>
       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>
       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>
       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>
       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>
       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>

       </div>
       <div style={{marginTop:"-123px",marginLeft:"26%"}}><h1 style={{color:"white",fontWeight:"bold"}}>{el.couponName}</h1><h1 style={{color:"white",fontSize:"11px",fontWeight:"bold"}}>{el.couponDescription}</h1>
       <div style={{marginTop:"37px",display:"flex",justifyContent:"space-around",alignItems:"center"}}><div style={{display:"flex",marginLeft:"-35px"}}><h1 style={{color:"white",fontSize:"11px"}}>More</h1><img onClick={(e)=>{handleToggleTransform(index)}} src={moreIcon} style={{width:"10px",height:"10px",    marginTop:"3px", marginLeft:"5px"}}/></div><div style={{width:"100px",height:"32px",background:"rgba(0, 0, 0, 1)",borderRadius:"15px",color:"white",display:"flex",justifyContent:"center",alignItems:"center",boxShadow:"0px -5px 9px 0px rgba(255, 255, 255, 0.24)"}} onClick={(e)=>{props.ApplyCoupon(e,el.couponName)}}>Apply</div></div></div>
       
       </div>  
      { el.couponType==2&&<img src={Gift} style={{    width: "29%",
    
    marginTop:"-151px",marginLeft:"211px"}}/>}
     { el.couponType==5&&<img src={DiscounTag} style={{    width: "29%",
    
    marginTop:"-151px",marginLeft:"211px"}}/>}
     { el.couponType==1&&<img src={Coins} style={{    width: "29%",
    
    marginTop:"-151px",marginLeft:"211px"}}/>}
<div        
      style={{
        width:"100%",
        transition: "height 1s ease",
        height: expandedDiv[index] ? "200px" : "0px",
        marginTop: "-23px",
        zIndex: "-1",
        border: "1px solid rgba(207, 207, 207, 1)",
        borderTop: "none",
        borderRadius: "12px",
        overflow: "hidden", 
      }}>

<div style={{padding:"5%",marginTop:"20px"}}>
       {/*  <h1 style={{color:"rgba(114, 114, 114, 1)",fontWeight:"500",
fontSize:"12px"}}>Free Gift Terms:</h1> */}
    <h1 style={{color:"rgba(114, 114, 114, 1)",fontWeight:"500",
fontSize:"12px"}}>{el.tnc}</h1>
      </div>
      </div>
    </div>}
    
    </div>)
 })}

{notApplicableCoupons&&notApplicableCoupons[0]&&<h1 style={{    paddingLeft:"6%",
    paddingTop:"5%"}}>Available Coupons</h1>}
  {notApplicableCoupons&&notApplicableCoupons.map((el,index)=>{
    console.log("coupon map",index,el)
    return(<div>
    
    {el.isApplicable==false&&<div key={index} style={{display:"grid",justifyContent:"center",padding:"5%"}}>
       <div style={{height:"136px",width:"100%",backgroundColor:"rgba(238, 238, 238, 1)",borderRadius:"10px"}}>

        <div style={{width:"30px",height:"30px",backgroundColor:"white",borderRadius:"30px",marginTop:"15%",marginLeft:"-16px"}}></div>
       
        <div style={{width:"30px",height:"30px",backgroundColor:"white",borderRadius:"30px",marginTop:"-8%",marginLeft:"95%"}}></div>
        
       <div style={{marginLeft:"60px",marginTop:"-82px",width:"5px"}}><div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>

       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>
       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>
       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>
       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>
       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>
       <div style={{width:"5px",height:"15px",backgroundColor:"white",marginBottom:"5px", borderRadius:"41px"}}></div>

       </div>
       <div style={{marginTop:"-123px",marginLeft:"26%"}}><h1 style={{color:"rgba(72, 72, 72, 0.67)",fontWeight:"bold",}}>{el.couponName}</h1><h1 style={{color:"rgba(0, 0, 0, 0.91)",fontSize:"11px",fontWeight:"bold"}}>{el.couponDescription}</h1>
       <div style={{marginTop:"37px",display:"flex"}}><div style={{display:"flex"}}><h1 style={{color:"rgba(56, 79, 111, 1)",fontSize:"11px",}}>More</h1><img onClick={(e)=>{handleToggleTransform(index)}} src={moregray} style={{width:"10px",height:"10px",    marginTop:"3px", marginLeft:"5px"}}/></div><div style={{ marginLeft:"22px"}}><p style={{fontSize:"10px",color:"rgba(255, 0, 0, 0.84)"}}>{el.reason}</p></div></div></div>
       
       </div>  
      {  el.couponType==1&&<img src={coinsGray} style={{    width: "36%",
    
    marginTop:"-178px",marginLeft:"211px"}}/>}
          {  el.couponType==5&&<img src={DiscounTagGray} style={{    width: "46%",
    
    marginTop:"-195px",marginLeft:"185px"}}/>}
          {  el.couponType==2&&<img src={GiftGray} style={{    width: "46%",
    
    marginTop:"-209px",marginLeft:"185px"}}/>}
<div        
      style={{
        width:"100%",
        transition: "height 1s ease",
        height: expandedDiv[index] ? "200px" : "0px",
        marginTop: "-23px",
        zIndex: "-1",
        border: "1px solid rgba(207, 207, 207, 1)",
        borderTop: "none",
        borderRadius: "12px",
        overflow: "hidden", 
      }}>

<div style={{padding:"5%",marginTop:"20px"}}>
       {/*  <h1 style={{color:"rgba(114, 114, 114, 1)",fontWeight:"500",
fontSize:"12px"}}>Free Gift Terms:</h1> */}
    <h1 style={{color:"rgba(114, 114, 114, 1)",fontWeight:"500",
fontSize:"12px"}}>{el.tnc}</h1>
      </div>
      </div>
    </div>}
    
    </div>)
 })}
    
    </>)
}

export default Coupons;