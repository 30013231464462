import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchPaymentOptionRequest,fetchSodexoUnlinkRequest ,fetchCheckSodexoRequest,fetchSodexoLinkRequest,fetchPreCardRequest} from "./action1";
import { fetchInventory } from "./action";
import PaymentOptions from "./component";
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import { mpClick } from "../../Mixpanel";
const PaymentOptionsContainer = (props) => {

const navigate = useNavigate();
const[paymentOptionsList,setPaymentOptionsList]=useState(null)
const[defaultPgName,setDefaultPgName]=useState("")
const [loader,setLoader]=useState(false)
const [sodexoUnlinkModal,setSodexoUnlinkModal]=useState(false)
const [preCardVmID,setPreCardVmID]=useState(null)
const UserJWTAuth = localStorage.getItem('UserJWTAuth');
useEffect(()=>{
 
 console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
 if(!UserJWTAuth){
   navigate('/')
 }
 mpClick({EventName:"Pg_option_page_opened"})
},[])

 


useEffect(()=>{
 
  PreCard()
  fetchPaymentOption()
  checkSodexoLink()
  
},[preCardVmID])


const handleErrorPaymentOption=(error)=>{
  setLoader(false)
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    //localStorage.removeItem("UserJWTAuth")
      navigate("/login");
}
else{ toast.error(`${error.response.data.status.code}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
}
const handleResponsePaymentOption=(res)=>{
  console.log("Get response fetchPaymentOption",res.data.data);
      
  localStorage.setItem("BpBalance",res.data.data[2].balance)
  setPaymentOptionsList(res.data.data);
  setLoader(false)
 
}



const fetchPaymentOption = () => {
  setLoader(true)
  const machineId = localStorage.getItem('machineId');
  const UserJWTAuth = localStorage.getItem('UserJWTAuth');
 console.log("('/myCart'==localStorage.getItem('paytmOptionFrom')",('/myCart'==localStorage.getItem('paytmOptionFrom')))
 
  props.fetchPaymentOptionRequest(
    
    {
    url:('/myCart'==localStorage.getItem('paytmOptionFrom')&&preCardVmID)?`/consumerbe/api/v2/payment/option?machineId=${preCardVmID}&balancePgArr=PAYTM%7CWALLET_LOYALTY%7CWALLET`:`/consumerbe/api/v2/payment/option?&balancePgArr=PAYTM%7CWALLET_LOYALTY%7CWALLET`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },
    request:{

      machineId:9
    },

    handleErrorPaymentOption ,
    handleResponsePaymentOption ,
  });

};

const handleErrorPreCard=()=>{

}

const handleResponsePreCard=(res)=>{
  console.log("handleResponsePreCard-------",res.headers.machineid)
  
  setPreCardVmID(res.headers.machineid)
}


const PreCard = () => {
  setLoader(false);
  const card_id = localStorage.getItem("card_id");
  props.fetchPreCardRequest({
    url: `/consumerbe/api/v2/cart`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,

      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },
    request: {
     
    },

    handleErrorPreCard,
    handleResponsePreCard: (res) =>
      handleResponsePreCard(res),
  });
};




const handleErrorCheckSodexo=()=>{

}

const handleResponseCheckSodexo=()=>{
  localStorage.removeItem("SodexoRequestId")
}

const checkSodexoLink = () => {


  const SodexoRequestId = localStorage.getItem('SodexoRequestId');
  const UserJWTAuth = localStorage.getItem('UserJWTAuth');
  if(SodexoRequestId){
  
  props.fetchCheckSodexoRequest({
    url: `/payment/api/v2/sodexo/check/link/${SodexoRequestId}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      
      //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },

    handleErrorCheckSodexo ,
    handleResponseCheckSodexo ,
  });
}
};


const handleErrorSodexoLink=()=>{

}

const handleResponseLinkSodexo=(res)=>{
  console.log("Get response SodexoLink",res.data.data.redirectUrl.requestId);
        
  localStorage.setItem("SodexoRequestId",res.data.data.requestId)
  
  window.open(res.data.data.redirectUrl,"_self")
}

const LinkSodexo= () => {


  const SodexoRequestId = localStorage.getItem('SodexoRequestId');
  const UserJWTAuth = localStorage.getItem('UserJWTAuth');

  
  props.fetchSodexoLinkRequest({
    url:'/payment/api/v2/sodexo/link',
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },

    handleErrorSodexoLink ,
    handleResponseLinkSodexo ,
  });
}




const handleErrorUnLinkLinkSodexo=()=>{

}

const handleResponseUnLinkLinkSodexo=(res)=>{
  setSodexoUnlinkModal(false)
        
  
}

const UnLinkLinkSodexo= () => {


  const SodexoRequestId = localStorage.getItem('SodexoRequestId');
  const UserJWTAuth = localStorage.getItem('UserJWTAuth');

  
  props.fetchSodexoUnlinkRequest({
    url:'/payment/api/v2/sodexo/unlink',
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },

    handleErrorUnLinkLinkSodexo ,
    handleResponseUnLinkLinkSodexo ,
  });
}







useEffect(()=>{
  fetchPaymentOption()
},[defaultPgName,sodexoUnlinkModal])

const SetpaymentGatewayId=(e,pgId,pgname,RazorPay,Paytm)=>{
console.log("SetpaymentGatewayId",pgId)
  console.log("SetpaymentGatewayId  name",pgId,pgname)
 localStorage.setItem("defaultPgId",pgId);
 if(pgname=="WALLET"){
  localStorage.setItem("defaultPgName",pgname);
  setDefaultPgName(pgname)
 }
 if(pgname=="WALLET_LOYALTY"){
   localStorage.setItem("defaultPgName",pgname);
   setDefaultPgName(pgname)
  }
  if(pgname=="PAYTM"){
    localStorage.setItem("defaultPgName",pgname);
    setDefaultPgName(pgname)
   }
 
  if(pgname=="SODEXO"){
    localStorage.setItem("defaultPgName",pgname);
    setDefaultPgName(pgname)
   }
  
   if(RazorPay=="R_WALLET"){
    localStorage.setItem("defaultPgName","R_WALLET");
    setDefaultPgName("R_WALLET")
   }
   if(RazorPay=="R_CARD"){
    localStorage.setItem("defaultPgName","R_CARD");
    setDefaultPgName("R_CARD")
   }
   if(RazorPay=="R_UPI"){
    localStorage.setItem("defaultPgName","R_UPI");
    setDefaultPgName("R_UPI")
   }

   
 //localStorage.setItem("defaultPgName",pgname);
 const profile=localStorage.getItem("profile")
 if(!profile){
 navigate(`${localStorage.getItem('paytmOptionFrom')}`,{ replace: true })
 }
}

return(
    <PaymentOptions
    paymentOptionsList={paymentOptionsList}
    SetpaymentGatewayId={SetpaymentGatewayId}
    SodexoLink={LinkSodexo}
    loader={loader}
    sodexoUnlinkModal={sodexoUnlinkModal}
    setSodexoUnlinkModal={setSodexoUnlinkModal}
    UnLinkLinkSodexo={UnLinkLinkSodexo}
    
    /> 
   
)

}




function mapStateToProps({ props }) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    
    fetchPaymentOptionRequest,
    fetchCheckSodexoRequest,
    fetchSodexoLinkRequest,
    fetchPreCardRequest,
    fetchSodexoUnlinkRequest
  })(PaymentOptionsContainer);