import React from "react";
import { Link, useNavigate } from "react-router-dom";
import nextArrow from "../../assets/nextArrow.png";

import WaitingLoder from "../WaitingLoader/component";
import TopNavBar from "../../components/Header/GlobalSearchHeader";
import BottomNarBar from "../BottomNav/Bottom";
import viewArrow from "../../assets/viewArrow.png";
import '../Orders/orders.css'
import { mpClick } from "../../Mixpanel";

function Orders(props) {

  const navigate=useNavigate()
  const gotoDetails=(e,order_id)=>{

    
    navigate(`/orders/Details/${order_id}`)
    

  }

  const viewMoreButtonStyles = {
    display: "flex",
    width: "90px",
    padding: "0px 12px 0px 16px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "10px",
    height:"50px",
    border: "1px solid rgba(148, 163, 184, 0.54)",
    background: "rgba(148, 163, 184, 0.06)",
  };

  console.log("props Orders", props.orderhistory);
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  const navigator = useNavigate();
  const TopNav = () => {
    return (
      <>
        <div className="_2456r">
          <div className="_3nxa7" data-testid="listing-header-location">
            <button
              className="_1NdRR"
              aria-label="Selected address is Delhi, India; Double tap to change address."
              id="change-address-btn-id"
            >
              <div style={{ display: "flex", "align-items": "center" }}>
                <h2
                  style={{
                    marginLeft: "25px",
                    fontSize: "18px",
                    color: "#583C43",
                  }}
                >
                  Order History
                </h2>

                <div style={{ marginLeft: "10px" }}></div>
              </div>
            </button>

            <span className="icon-Offers-outline _2P0Ff" />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <BottomNarBar />
      {props.loader && <WaitingLoder />}
      <TopNav />

      <>
      <BottomNarBar/>
      {props.loader && <WaitingLoder />}
    <TopNav/>



<div
          style={{
            background:
              "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
              //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.ActiveOrderItemsListGlobal[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.ActiveOrderItemsListGlobal[0]&&"8.5rem",
          }}
          className=" pb-16 pt-20 overflow-y-auto h-screen"

        >
   

    
    {props.orderhistory && props.orderhistory.map((el, index) => (
  <React.Fragment key={index}>
   

    {el.vendingMachineId>1&&<div style={{padding:"2%"}}>
    <div className=" divBox" style={{ padding:"5%",}}>
      <div  >
      <div style={{padding:"2%"}}>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-lg font-bold text-blue-700 font-roboto font-semibold" style={{color:"#718198",fontSize:"15px"}}>
            Order ID: <span className="orderIdValue " style={{fontSize:"15px"}}>{el.orderId}</span>
          </p>
        </div>
        <div>
          <p className="text-lg font-bold text-blue-700 font-roboto font-semibold price">Rs.{el.amount.toFixed(2)}</p>
        </div>
      </div>
      <div>
        <p className="text-sm text-gray-600 font-roboto address">{el.vendingMachineAddress}</p>
      </div>
      <div>

      <div className="flex mt-0">
  <p className="text-gray-700 font-roboto text-sm font-medium itemsCount">{el.orderLineItems.length} items</p>
  <h3 className={`text-${el.status === 'cancelled' ? 'red' : 'green-500'} font-roboto text-sm font-medium ml-1`}> • {el.status}</h3>
</div>
      </div>
    
    </div>

 
    <div className="w-336 h-100  mt-4 flex items-center justify-between">
      
        <div className="relative flex ">
        {el.orderLineItems.slice(0, 3).map((e, index) => (
  <div key={index}>
          <img
            style={{ width: "50px", height:"50px", padding: "8px",  "margin-left":"5px",border: "1px solid #ccc",    "border-radius": "9px" }}
            src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${e.imageUrl}`}
            alt="product"
          />
 
    
           </div>
))}
           {el.orderLineItems.length>3&&<div
            style={{ width: "50px",height:"50px", padding: "8px", "margin-left":"5px",border: "1px solid #ccc","border-radius":"9px",
          
            display: "flex",
            "text-align": "center",
            "align-items": "center",
            "justify-content":"space-around",
          

          }}
          className="countPlus"
            src="https://m.media-amazon.com/images/I/71kOsITKSkL._SX522_.jpg"
            alt="product"
          >+{el.orderLineItems.length-3}</div>}
        </div>

        <div className="flex flex-col items-center text-blue-700">
          <div style={viewMoreButtonStyles}>
            <div onClick={(e)=>{gotoDetails(e,el.orderId)}} className="viewDetails"><span>View</span><br/><span>Details</span></div>
            <div  className="viewDetailsIcon">
            <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z"/></svg>
            </div>
          </div>
        </div>
      </div>
      </div>
      
</div>
</div>}
</React.Fragment>
))}
</div>
    </>
    </>
  );
}

export default Orders;
