import React, { useState } from "react";
import PropTypes from "prop-types";
import { Skeleton, styled, useTheme } from "@mui/material";

import { Typography ,Paper,MobileStepper,Button} from "@mui/material";

import { KeyboardArrowLeft } from "@mui/icons-material";
import { KeyboardArrowRight } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath:
      "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60"
  },
  {
    label: "Bird",
    imgPath:
      "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60"
  },
  {
    label: "Bali, Indonesia",
    imgPath:
      "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80"
  },
  {
    label: "NeONBRAND Digital Marketing, Las Vegas, United States",
    imgPath:
      "https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60"
  },
  {
    label: "Goč, Serbia",
    imgPath:
      "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60"
  },
  {
    label:"tahzeeb",
    imgPath:"https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/banner/Delivery+Banner%402x+(1).jpg"
  }
];

const useStyles = styled((theme) => ({
  root: {
    maxWidth: "100vh",
    flexGrow: 1
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  img: {
    height: 255,
    display: "block",
    maxWidth: "100vh",
    overflow: "hidden",
    width: "100%"
  },
  mobileStepper: {
    backgroundColor: theme.palette.background.default
  }
}));

const SwipeableTextMobileStepper = (props) => {

  console.log("SwipeableTextMobileStepper",props&&props.BannerData&&props.BannerData.banners&&props.BannerData.banners[0])
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
    {props&&props.BannerData&&props.BannerData.banners&&props.BannerData.banners[0]&&<div className={classes.root} >
  
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {props&&props.BannerData&&props.BannerData.banners&&props.BannerData.banners[0]&&props.BannerData.banners.map((step, index) => (
          <div key={step.label} style={{overflow:"hidden",width:"100%"}}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} style={{width:"100%",borderRadius:"13px"}} src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${step.bannerUrl}`} alt={step.label} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      {/* Rendering dots */}
      <MobileStepper
        sx={{ background: "transparent"}}
        steps={Math.min(props&&props.BannerData.banners.length, 5)} // Display maximum of 3 dots or number of items in data array, whichever is smaller
        position="static"
        activeStep={activeStep}
        className={classes.mobileStepper}
        nextButton={
          <Button  sx={{visibility:"hidden"}} size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button  sx={{visibility:"hidden"}} size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </div>
      }
    </>
  );
};

SwipeableTextMobileStepper.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SwipeableTextMobileStepper;
