//////////////////////////////////VM LIST ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_VMLIST_REQUEST=" FETCH_VMLIST_REQUEST"
export const FETCH_VMLIST_SUCCESS="FETCH_VMLIST_SUCCESS"
export const FETCH_VMLIST_FAILURE=" FETCH_VMLIST_FAILURE"


 
export const fetchVmListRequest=(myurldata)=>{
    console.log("fetchUserRequest myurldata---->",myurldata)
    return{
        type:FETCH_VMLIST_REQUEST,
        data:myurldata
    }
//let see we are getting this into saga or not 
    
   
}

export const fetchVmListSuccess=(VmList)=>{
return{
    type:FETCH_VMLIST_SUCCESS,
    VmList:VmList
}
}

export const fetchVmListFailure=(error)=>{
    return{
    type:FETCH_VMLIST_FAILURE,
    error:error
    }
}





//////////////////////////////////PREVIOUS CARD  ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_PRE_CARD_REQUEST_VMLIST="FETCH_PRE_CARD_REQUEST_VMLIST"
export const FETCH_PRE_CARD_SUCCESS_VMLIST="FETCH_PRE_CARD_SUCCESS_VMLIST"
export const FETCH_PRE_CARD_FAILURE_VMLIST="FETCH_PRE_CARD_FAILURE_VMLIST"


 
export const fetchPreCardRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_PRE_CARD_REQUEST_VMLIST,
        data:myurldata
    }

    
   
}

export const fetchPreCardSuccess=(PreCardData)=>{
return{
    type:FETCH_PRE_CARD_SUCCESS_VMLIST,
    PreCardData:PreCardData
}
}

export const fetchPreCardFailure=(error)=>{
    return{
    type:FETCH_PRE_CARD_FAILURE_VMLIST,
    error:error
    }
}











///////////////////////////////////ACTIVE ORDERS  ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_ACTIVE_ORDERS_REQUEST_VMLIST="FETCH_ACTIVE_ORDERS_REQUEST_VMLIST"
export const FETCH_ACTIVE_ORDERS_SUCCESS_VMLIST="FETCH_ACTIVE_ORDERS_SUCCESS_VMLIST"
export const FETCH_ACTIVE_ORDERS_FAILURE_VMLIST="FETCH_ACTIVE_ORDERS_FAILURE_VMLIST"


 
export const fetchActiveOrdersRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_ACTIVE_ORDERS_REQUEST_VMLIST,
        data:myurldata
    }

    
   
}

export const fetchActiveOrdersSuccess=(ActiveOrders)=>{
return{
    type:FETCH_ACTIVE_ORDERS_SUCCESS_VMLIST,
    ActiveOrders:ActiveOrders
}
}

export const fetchActiveOrdersFailure=(error)=>{
    return{
    type:FETCH_ACTIVE_ORDERS_FAILURE_VMLIST,
    error:error
    }
}