import { connect } from "react-redux";
import Orders from "./component";
import {fetchOrdersHistoryRequest} from './action'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import { mpClick } from "../../Mixpanel";
const OrdersContainer = (props) => {

const OrderHistory=props&&props.props&&props.props.OrderHistory&&props.props.OrderHistory.OrederHistoryList



console.log("OrderHistory",OrderHistory)
const [orderhistory,setOrderhistory]=useState('')
const [loader,setLoader]=useState(true);
const navigate=useNavigate()
useEffect(()=>{
  if(navigator.onLine){
  fetchOrderHistory()
}
else{
  setLoader(false)
}
},[])

    
useEffect(()=>{
  mpClick({EventName:"past_orders_page_opened"})
},[])
const UserJWTAuth = localStorage.getItem('UserJWTAuth');
useEffect(()=>{
 
 console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
 if(!UserJWTAuth){
   navigate('/')
 }
},[])
useEffect(()=>{
 
  console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
  if(localStorage.getItem('Guest_clientId')){
    navigate('/login')
  }
 },[])
 

const fetchOrderHistory = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchOrdersHistoryRequest({
    url: `/order/order/orderhistory`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
    "x-device-id":localStorage.getItem("uuid")
    },
    "request": {
      request:{}
      


      },
    handleErrorOrderHistory ,
    handleResponseOrderHistory ,
  });
};


const handleErrorOrderHistory=(error)=>{
  console.log("res")
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    //localStorage.removeItem("UserJWTAuth")
      navigate("/login");
}
else{ toast.error(`${error.response.data.status.code}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
  setLoader(false)
}

const handleResponseOrderHistory=(res)=>{
  console.log("handleResponseOrderHistory",res)
  setLoader(false)
}









return(
    <Orders
    orderhistory={OrderHistory}
    loader={loader}/>
)


}



function mapStateToProps( props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    fetchOrdersHistoryRequest
   
  })(OrdersContainer);