import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { fetchCouponsFailure, fetchCouponsSuccess,FETCH_COUPONS_LIST_REQUEST,FETCH_APPLY_COUPON_REQUEST, fetchApplyCouponSuccess, fetchApplyCouponFailure } from "./action";

///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetcheCoupons(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchCouponsSuccess(response));
  
      action.data.handleResponseCoupons(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorCoupons(error)
      yield put(fetchCouponsFailure(error.message));
    }
  }

    /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetcheApplyCoupon(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchApplyCouponSuccess(response));
  
      action.data.handleResponseApplyCoupons(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorApplyCoupon(error)
      yield put(fetchApplyCouponFailure(error.message));
    }
  }


  function* CouponsSaga() {
    yield takeLatest(FETCH_COUPONS_LIST_REQUEST, fetcheCoupons);
    yield takeLatest(FETCH_APPLY_COUPON_REQUEST,fetcheApplyCoupon)
   
    
  }
  
  export default CouponsSaga;