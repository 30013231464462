import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_ACTIVE_ORDERS_REQUEST_VMLIST,
  FETCH_PRE_CARD_REQUEST_VMLIST,
  FETCH_VMLIST_REQUEST,
  fetchActiveOrdersFailure,
  fetchActiveOrdersSuccess,
  fetchPreCardFailure,
  fetchPreCardSuccess,
  fetchVmListFailure,
  fetchVmListSuccess,
} from "./action";
import axios from "axios";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
  console.log("callApi", mydata.data);

  const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;

  try {
    const response = await axios({
      method: mydata.data.method,
      url: url,
      headers: mydata.data.headers,
      data: mydata.data.request
    });

    console.log("axios response", response);
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was canceled (due to no internet or other reasons)
      console.log('Request canceled:', error.message);
    } else {
      // Handle other errors
      console.error('Error:', error);

      // Check if it's a network error (no internet)
      if (error.message === 'Network Error') {
        if(navigator.onLine){
        // Retry the API call after a delay (e.g., 5 seconds)
        console.log('Retrying after 5 seconds...',);
        await new Promise((resolve) => setTimeout(resolve, 5000));
        return callApi(mydata);
        }
      } else {
        // Throw the error for other types of errors
        throw error;
      }
    }
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////// VM LIST ///////////////////////////////////////////////////////
function* fetchVmlist(action) {
  console.log("fetchUserSaga myurl", action);
  try {
    const response = yield call(callApi, action);

    yield put(fetchVmListSuccess(response));

    action.data.handleResponseVmList(response);
  } catch (error) {
    console.log("fetchUserSaga error", error.message);
    yield put(fetchVmListFailure(error.message));
    action.data.handleErrorVmList(error)
  
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////







/////////////////////////////////// PREVIOUS CARD DATA////////////////////////////////////////////////////

function* fetchPreCard(action) {
  console.log("fetchPreCard saga", action);
  try {
    const response = yield call(callApi, action);

    yield put(fetchPreCardSuccess(response));

    action.data.handleResponsePreCard(response);
  } catch (error) {
    console.log("fetchPreCard error", error.message);
    yield put(fetchPreCardFailure(error.message));
    action.data.handleErrorPreCard(error)
  }
}



/////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetchActiveOrders(action) {
  console.log("fetchPreCard saga", action);
  try {
    const response = yield call(callApi, action);

    yield put(fetchActiveOrdersSuccess(response));

    action.data.handleResponseActiveOrders(response);
  } catch (error) {
    console.log("fetchPreCard error", error.message);
    yield put(fetchActiveOrdersFailure(error.message));
    action.data.handleErrorActiveOrders(error)
  }
}














function* vmListSaga() {
  yield takeLatest(FETCH_VMLIST_REQUEST, fetchVmlist);
  yield takeLatest(FETCH_PRE_CARD_REQUEST_VMLIST, fetchPreCard);
  yield takeLatest(FETCH_ACTIVE_ORDERS_REQUEST_VMLIST,fetchActiveOrders)
}

export default vmListSaga;
