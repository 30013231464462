export const FETCH_PAYMENT_OPTIONS_LIST_REQUEST="FETCH_PAYMENT_OPTIONS_LIST_REQUEST"
export const FETCH_PAYMENT_OPTIONS_LIST_SUCCESS="FETCH_PAYMENT_OPTIONS_LIST_SUCCESS"
export const FETCH_PAYMENT_OPTIONS_LIST_FAILURE="FETCH_PAYMENT_OPTIONS_LIST_FAILURE"


export const fetchPaymentOptionRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_PAYMENT_OPTIONS_LIST_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchPaymentOptionSuccess=(PaymentOption)=>{
return{
    type:FETCH_PAYMENT_OPTIONS_LIST_SUCCESS,
    PaymentOption:PaymentOption
}
}

export const fetchPaymentOptionFailure=(error)=>{
    return{
    type:FETCH_PAYMENT_OPTIONS_LIST_FAILURE,
    error:error
    }
}


export const FETCH_LINK_SODEXO_REQUEST="FETCH_LINK_SODEXO_REQUEST"
export const FETCH_LINK_SODEXO_SUCCESS="FETCH_LINK_SODEXO_SUCCESS"
export const FETCH_LINK_SODEXO_FAILURE="FETCH_LINK_SODEXO_FAILURE"


export const fetchSodexoLinkRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_LINK_SODEXO_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchSodexoLinkSuccess=(SodexoLink)=>{
return{
    type:FETCH_LINK_SODEXO_SUCCESS,
    SodexoLink:SodexoLink
}
}

export const fetchSodexoLinkFailure=(error)=>{
    return{
    type:FETCH_LINK_SODEXO_FAILURE,
    error:error
    }
}



export const FETCH_CHECK_SODEXO_REQUEST="FETCH_CHECK_SODEXO_REQUEST"
export const FETCH_CHECK_SODEXO_SUCCESS="FETCH_CHECK_SODEXO_SUCCESS"
export const FETCH_CHECK_SODEXO_FAILURE="FETCH_CHECK_SODEXO_FAILURE"


export const fetchCheckSodexoRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_CHECK_SODEXO_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchCheckSodexoSuccess=(Check_Sodexo)=>{
return{
    type:FETCH_CHECK_SODEXO_SUCCESS,
    Check_Sodexo:Check_Sodexo
}
}

export const fetchCheckSodexoFailure=(error)=>{
    return{
    type:FETCH_CHECK_SODEXO_FAILURE,
    error:error
    }
}






///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_PRE_CARD_REQUEST="FETCH_PRE_CARD_REQUEST"
export const FETCH_PRE_CARD_SUCCESS="FETCH_PRE_CARD_SUCCESS"
export const FETCH_PRE_CARD_FAILURE="FETCH_PRE_CARD_FAILURE"


export const fetchPreCardRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_PRE_CARD_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchPreCardSuccess=(PreCard)=>{
return{
    type:FETCH_PRE_CARD_SUCCESS,
    PreCard:PreCard
}
}

export const fetchPreCardFailure=(error)=>{
    return{
    type:FETCH_PRE_CARD_FAILURE,
    error:error
    }
}
///////////////////////////////////////////////////////////////////////////////////////
export const FETCH_UNLINK_SODEXO_REQUEST="FETCH_UNLINK_SODEXO_REQUEST"
export const FETCH_UNLINK_SODEXO_SUCCESS="FETCH_UNLINK_SODEXO_SUCCESS"
export const FETCH_UNLINK_SODEXO_FAILURE="FETCH_UNLINK_SODEXO_FAILURE"


export const fetchSodexoUnlinkRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_UNLINK_SODEXO_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchSodexoUnlinkSuccess=(SodexoUnlink)=>{
return{
    type:FETCH_UNLINK_SODEXO_SUCCESS,
    SodexoUnlink:SodexoUnlink
}
}

export const fetchSodexoUnlinkFailure=(error)=>{
    return{
    type:FETCH_UNLINK_SODEXO_FAILURE,
    error:error
    }
}


