import Tagpay from "./component"
import {fetchTagListRequest} from "./action"
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
const TagListContainer=(props)=>{
  const navigate=useNavigate()
    const [tagList,setTagList]=useState([])
    const [loader,setLoader]=useState(false)
 
    useEffect(()=>{
        getTagList()
    },[])

    useEffect(() => {
      const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      console.log("UserJWTAuth VmlistContainer", UserJWTAuth);
      if (!UserJWTAuth) {
        navigate("/login");
      }
    }, []);
  

const handleResponseTagList=(res)=>{

  setTagList(res.data.response);
  setLoader(false)

}

const handleErrorTagList=(error)=>{
  setLoader(false)
  if(error.response.status==401 || error.response.status==403){

    console.log("khanhandleErrorTagList", error.response);
    //Logout
    //localStorage.removeItem("UserJWTAuth")
      //navigate("/login");
}
else{ toast.error(`${error.response.data.status.code}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}

}


const getTagList = () => {
  
  const UserJWTAuth = localStorage.getItem('UserJWTAuth');
  console.log("UserJWTAuth",UserJWTAuth)
  setLoader(true)
  console.log("inventory...container")

  props.fetchTagListRequest({
    url: `/auth/tag/linked`,
    method:  "GET",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")

    },
    


    handleErrorTagList,
    handleResponseTagList,
  });
};



    return(
        <Tagpay
        tagList={tagList}
        loader={loader}
        />


    )
}
function mapStateToProps(props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    
    fetchTagListRequest
  })(TagListContainer);

