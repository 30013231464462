
import { connect } from "react-redux";
import Orders from "./component";
import { fetchPickupOrderRequest } from "./action";
import { useEffect, useState } from "react";
import PickupOrder from "./component";
import {  toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { mpClick } from "../../Mixpanel";
const PickupOrderContainer = (props) => {
const navigate = useNavigate();
const OrderId = localStorage.getItem('orderId');

const [orderhistory,setOrderhistory]=useState('')
const [qrflag,setQrfalg]=useState(true)
const [vendingSuccess,setVendingSuccess]=useState(false);
const [isFetchLocation, setFetchLocation] = useState(true);
const [qrData, setQRData] = useState(null);
const [showScanner, setShowScanner] = useState(true);
const [mId,setMId]=useState()


let r=true
function handleScan(result) {
  console.log("contactless",result)
    if (result) {
      // Do something with the result
      console.log("PickupOrderContainer---------->",result);

      const machineIdIndex = result&&result.data.indexOf("machineId=");

      if (machineIdIndex !== -1) {
        const machineId = result.data.substring(machineIdIndex + 10);
        console.log("======================================",machineId);
        setMId(machineId) 
        pickupApi(machineId)// output: 8
        //setQrfalg(false);
        } else {
        if(r){
          toast.error(`${"Machine ID Not Found"}`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
        r=false
      }
      }

      
      
    }
  }
  
  useEffect(()=>{
   
  },[])
  function handleError(error) {
    console.error("",error);
  }


const handleErrorPickupOrder=(res)=>{
  const contactLessOrderID = localStorage.getItem('contactLessOrderID');
    console.log("Get response p----",res&&res.response.data.status=="FAILURE");

if(res&&res.response.data.status=="FAILURE"){
console.log("helllo",res&&res.response.data.status=="FAILURE",contactLessOrderID===mId)
  if(!(contactLessOrderID==mId))

{    toast.error(`${"Invalid code or Machine"}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
  });
}

if(res.response.status==401 || res.response.status==403){

   
  //Logout
  //localStorage.removeItem("UserJWTAuth")
    navigate("/login");
}
else{ toast.error(`${res.response.data.status.code}`, {
position: toast.POSITION.TOP_RIGHT,
autoClose: 1000,
});
}

}
    
//setVendingSuccess(true)
mpClick({EventName:"Contactless_pickup_failed"})

}



const handleQrCloseClick =()=>{
  setFetchLocation(!isFetchLocation)
}
const handleResponsePickupOrder=(res)=>{
    console.log("Get response pickupApi",res.data.status=="SUCCESS");
   
    setOrderhistory(res.data.response)
   localStorage.removeItem("contactLessOrderID")
   if(res.data.status=="SUCCESS"){
   setVendingSuccess(true)
   mpClick({EventName:"Contactless_pickup_successful"})
   }
   else{


   }
   
}

  const pickupApi = (machineId) => {
   
    const UserJWTAuth = localStorage.getItem('UserJWTAuth');
    const contactLessOrderID = localStorage.getItem('contactLessOrderID');
  
    props.fetchPickupOrderRequest({
      url:  `/consumer/api/v1/orders/initiate-pickup`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
        "x-device-id":localStorage.getItem("uuid")
      },
      "request": {
        
            "vendingMachineId": machineId,
            "orderId":contactLessOrderID
            
  
  
        },
      handleErrorPickupOrder ,
      handleResponsePickupOrder ,
    });
    mpClick({EventName:"Contactless_pickup_initiated"})
  };
  
  








return(
    <PickupOrder
    handleScan={handleScan}
    handleError={handleError}
    qrflag={qrflag}
    vendingSuccess={vendingSuccess}
    />
)


}



function mapStateToProps(props) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    fetchPickupOrderRequest
  })(PickupOrderContainer);