import React from "react";
import QrIcon from "../../assets/QrIcon.png";
import whiteforwordarrow from "../../assets/whiteforwordArrow.png";
import "./activeOrderFav.css";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
//import { mpActiveOrdersVmInventory } from "../../Mixpanel";
function ActiveOrdersFab(props) {


const navigate=useNavigate()
function TruncatedText({ text, limit,color,size }) {
  if (text.length <= limit) {
    return <span style={{ fontSize: size?size:"", color:color?color:"" }}>{text}</span>;
  }
  return (
    <span style={{ fontSize:size?size:"", color:color?color:"" }}>{`${text&&text.substring(
      0,
      limit
    )}`}{" "}...</span>
  );
}
  

const goToPickUpOrder=(e)=>{

  localStorage.setItem("contactLessOrderID",props.orderID)
  localStorage.setItem("contactLessMachineID",props.orderMachineId)
  navigate("/pickupOrder")
}



const goToActiveOrder=(e)=>{
  //mpActiveOrdersVmInventory({EventName:"",data:{}})
  
  //mpActiveOrdersVmInventory({EventName:"Active_order_icon_clicked",data:{order_id:props.order_id,items_count:props.orderLength,SourcePage:props.SourcePage}})

  navigate("/activeOrder")
}


  console.log("props.orderLength props", props.orderLength>1);
  function TruncatedHeading({ text, limit }) {
    if (text && text.length <= limit) {
      return (
        <p
          style={{
            color: "rgba(255, 255, 255, 0.69)",
            "font-family": "Roboto",
            "font-size": "10px",
            "font-style": "normal",
            "font-weight": "400",
          }}
        >
          {text}
        </p>
      );
    }
    return (
      <p
        style={{
          fontSize: "10px",
          color: "rgba(255, 255, 255, 0.69)",
          "font-family": "Roboto",
          "font-size": "10px",
          "font-style": "normal",
          "font-weight": "400",
        }}
      >{`${text && text.substring(0, limit)}...`}</p>
    );
  }
  return (
  <Box   style={{
    display: "flex",
    position: "fixed",
    "z-index": "99999999",
    bottom: props.Top ? props.Top : "113px",
    "justify-content": "center",
    width: "99%",
    "justify-content": "space-between",
  }}>
    <Box     onClick={(e)=>{goToActiveOrder(e)}}
        style={{
          textDecoration: "none",
          color: "white",
          bottom: "68px",
          backgroundColor: "blue",
          "background-color": "#2D2B45",
          color: "white",
          height: "62px",
          width: "100%",
          marginLeft: "13px",
         
          "border-radius": "10px",
          "z-index": "99999",
        }}>
          <Box display={"flex"} justifyContent={"space-between"} paddingLeft={"21px"} paddingRight={"14px"} paddingTop={"4px"} alignItems={"center"} style={{marginTop:props&&props.orderLength>1?"":"10px"}}>
            <Box>
          <Typography style={{fontWeight:"500",fontSize:"13px"}}>
          PIckup your order
          </Typography>
          {<Typography style={{fontWeight:"400",fontSize:"10px"}}><TruncatedText text={props.itemname}limit={25}/>{props&&props.itemlength>1&&<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ More Items</span>}</Typography>}
         </Box>
         <Box>
         <img style={{ width: "9px",}} src={whiteforwordarrow} />

         </Box>
     
          </Box>
          {props.orderLength>1&& (
          <>
            <hr className="horizontal-line" />
<div style={{display:"flex",    paddingLeft:"21px",
    marginTop:"-4px"}}>
            <p
              style={{
              /*   "margin-left": "13%", */
                fontSize: "10px",
               /*  color: "rgba(255, 255, 255, 0.69)", */
               color:"white",
                "font-family": "Roboto",
                "font-size": "10px",
                "font-style": "normal",
                "font-weight": "400",
                marginTop: "5px",

               /*  "line-height": "20px", */
              }}
            >
              + {props.orderLength - 1} more Order{" "}
            </p>
            <p style={{visibility:"hidden"}}>Tahzeeb khan</p>
            </div>
            </>)
            }
    </Box>

    <Box onClick={(e)=>{goToPickUpOrder(e)}}>
      <Box        style={{
            width: "77px",
            "margin-left": "7%",
            "background-color": "#2D2B45",
            "margin-right": "16px",
            "border-radius": "8px",
            height: "61px",
            display: "flex",
            "justify-content": " space-around",
            "align-items": "center",
          }}>
              <img style={{ width: "37px" }} src={QrIcon} />

      </Box>
    </Box>

  </Box>
  );
}

export default ActiveOrdersFab;
