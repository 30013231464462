export const FETCH_ORDER_SEARCH_REQUEST="FETCH_ORDER_SEARCH_REQUEST"
export const FETCH_ORDER_SEARCH_SUCCESS="FETCH_ORDER_SEARCH_SUCCESS"
export const FETCH_ORDER_SEARCH_FAILURE="FETCH_ORDER_SEARCH_FAILURE"


export const  fetchOrderSearchRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_ORDER_SEARCH_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchOrderSearchSuccess=(OrderHistory)=>{
return{
    type:FETCH_ORDER_SEARCH_SUCCESS,
    OrderHistory:OrderHistory
}
}

export const fetchOrderSearchFailure=(error)=>{
    return{
    type:FETCH_ORDER_SEARCH_FAILURE,
    error:error
    }
}