export const FETCH_FAQ_TOPIC_REQUEST="FETCH_FAQ_TOPIC_REQUEST"
export const FETCH_FAQ_TOPIC_SUCCESS="FETCH_FAQ_TOPIC_SUCCESS"
export const FETCH_FAQ_TOPIC_FAILURE="FETCH_FAQ_TOPIC_FAILURE"


export const fetchFaqTopicRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_FAQ_TOPIC_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchFaqTopicSuccess=(FaqTopic)=>{
return{
    type:FETCH_FAQ_TOPIC_SUCCESS,
    FaqTopic:FaqTopic
}
}

export const fetchFaqTopicFailure=(error)=>{
    return{
    type:FETCH_FAQ_TOPIC_FAILURE,
    error:error
    }
}




//////////////////////////////// //////////////////////////////////////////////// /////////////////////////
export const FETCH_ORDER_HISTORY_LIST_FAQ_REQUEST="FETCH_ORDER_HISTORY_LIST_FAQ_REQUEST"
export const FETCH_ORDER_HISTORY_LIST_FAQ_SUCCESS="FETCH_ORDER_HISTORY_LIST_FAQ_SUCCESS"
export const FETCH_ORDER_HISTORY_LIST_FAQ_FAILURE="FETCH_ORDER_HISTORY_LIST_FAQ_FAILURE"


export const fetchOrdersHistoryRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_ORDER_HISTORY_LIST_FAQ_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchOrdersHistorySuccess=(OrderHistory)=>{
return{
    type:FETCH_ORDER_HISTORY_LIST_FAQ_SUCCESS,
    OrderHistory:OrderHistory
}
}

export const fetchOrdersHistoryFailure=(error)=>{
    return{
    type:FETCH_ORDER_HISTORY_LIST_FAQ_FAILURE,
    error:error
    }
}