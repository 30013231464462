import { call, put, take, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { sendUserLoginMycartFailure, sendUserLoginMycartSuccess ,sendSubmitOtpMycartSuccess,sendSubmitOtpMycartFailure, SEND_USER_LOGIN_MYCART_REQUEST, FETCH_PAYMENT_OPTION_MYCART_REQUEST, fetchPaymentOptionMycartSuccess, fetchPaymentOptionMycartFailure, FETCH_PRE_CARD_MYCART_REQUEST, fetchPreaCardMycartSuccess, fetchPreCardMycartSuccess, fetchPreCardMycartFailure, FETCH_COPUON_REQUEST, fetchCouponSuccess, fetchCouponFailure, FETCH_APPLY_COPUON_REQUEST, fetchApplyCouponSuccess, fetchApplyCouponFailure, FETCH_ADD_TO_CART_MY_CART_REQUEST, fetchAddToCartMycartSuccess, fetchAddToCartMycartFailure, FETCH_REMOVE_FROM_CART_MY_CART_REQUEST, fetchRemoveFormCartMycartSuccess, fetchRemoveFormCartMycartFailure, FETCH_CREATE_ORDER_REQUEST, fetchCreateOrderSuccess, fetchCreateOrderFailure, FETCH_INITIATED_PAYMENT_REQUEST, fetchInitiatedPaymentSuccess, fetchInitiatedPaymentFailure, FETCH_CHECK_SODEXO_ORDER_STATUS_REQUEST, fetchCheckSodexoOrderStatusSuccess, fetchCheckSodexoOrderStatusFailure, FETCH_SODEXO_LINK_REQUEST, fetchSodexoLinkSuccess, fetchSodexoLinkFailure, FETCH_CHECK_SODEXO_LINK_REQUEST, fetchCheckSodexoLinkSuccess, fetchCheckSodexoLinkFailure, FETCH_REMOVE_COUPON_REQUEST, fetchRemoveCouponFailure, fetchRemoveCouponSuccess,  } from "./action1";
import { SEND_SUBMIT_OTP_MYCART_REQUEST } from "./action1";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
//////////////////////////////////////////////////////////////////////////////////////////////////
  




/////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* sendUserLoginMycartDetails(action) {
    console.log("sendUserLoginMycartDetails", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(sendUserLoginMycartSuccess(response));
  
      action.data.handleResponseUserLoginForMyCart(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorUserLoginForMyCart(error.message)
      yield put(sendUserLoginMycartFailure(error.message));
    }
  }





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* sendSubmitOtoMycartDetails(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(sendSubmitOtpMycartSuccess(response));
  
      action.data.handleResponseSubmitOtpMyCart(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      yield put(sendSubmitOtpMycartFailure(error.message));
    }
  }


  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchPaymentOptionMycartDetails(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchPaymentOptionMycartSuccess(response));
  
      action.data.handleResponsePaymentOption(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorPaymentOption(error.message);
      yield put(fetchPaymentOptionMycartFailure(error.message));
    }
  }



  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchPreCardMycartDetails(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  console.log("fetchPreCardMycartDetails response",response)
      yield put(fetchPreCardMycartSuccess(response));
  
      action.data.handleResponsePreCardMyCart(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorPreCardMyCart(error);
      yield put(fetchPreCardMycartFailure(error.message));
      
    }
  }


  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchCoupon(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchCouponSuccess(response));
  
      action.data.handleResponseFetchCouponMyCart(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      //action.data.handleErrorFetchCouponMyCart(error);
      yield put(fetchCouponFailure(error.message));
    }
  }



  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchApplyCoupon(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchApplyCouponSuccess(response));
  
      action.data.handleResponseApplyCoupon(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorApplyCoupon(error.message);
      yield put(fetchApplyCouponFailure(error.message));
    }
  }




  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchAddToCartMycart(action) {
    console.log("fetchAddToCartMycart saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchAddToCartMycartSuccess(response));
  
      action.data.handleResponseAddToCartMyCart(response);
    } catch (error) {
      console.log("fetchAddToCartMycart error", error);
      action.data.handleErrorAddToCartMyCart(error);
      yield put(fetchAddToCartMycartFailure(error.message));
    }
  }



  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchReomoveFromCartMycart(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
      console.log("fetchReomoveFromCartMycart error", response);
      yield put(fetchRemoveFormCartMycartSuccess(response));
  
      action.data.handleResponseRemoveFromCartMyCart(response);
    } catch (error) {
      console.log("fetchReomoveFromCartMycart error", error);
      action.data.handleErrorRemoveFromCartMyCart(error);
      yield put(fetchRemoveFormCartMycartFailure(error.message));
    }
  }


  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchCreateOrder(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
      console.log("fetchReomoveFromCartMycart error", response);
      yield put(fetchCreateOrderSuccess(response));
  
      action.data.handleResponseCreateOrder(response);
    } catch (error) {
      console.log("fetchReomoveFromCartMycart error", error);
      action.data.handleErrorCreateOrder(error);
      yield put(fetchCreateOrderFailure(error.message));
    }
  }


  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchInitiatedPayment(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
      console.log("fetchReomoveFromCartMycart error", response);
      yield put(fetchInitiatedPaymentSuccess(response));
  
      action.data.handleResponseInitiatedPayment(response);
    } catch (error) {
      console.log("fetchReomoveFromCartMycart error", error);
      action.data.handleErrorInitiatedPayment(error);
      yield put(fetchInitiatedPaymentFailure(error.message));
    }
  }


  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchCheckSodexoOrderStatus(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
      console.log("fetchReomoveFromCartMycart error", response);
      yield put(fetchCheckSodexoOrderStatusSuccess(response));
  
      action.data.handleResponseCheckSodexoOrder(response);
    } catch (error) {
      console.log("fetchReomoveFromCartMycart error", error);
      action.data.handleErrorCheckSodexoOrder(error);
      yield put(fetchCheckSodexoOrderStatusFailure(error.message));
    }
  }


  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchSodexoLink(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
      console.log("fetchReomoveFromCartMycart error", response);
      yield put(fetchSodexoLinkSuccess(response));
  
      action.data.handleResponseSodexoLink(response);
    } catch (error) {
      console.log("fetchReomoveFromCartMycart error", error);
      action.data.handleErrorSodexoLink(error);
      yield put(fetchSodexoLinkFailure(error.message));
    }
  }


    /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

    function* fetchCheckSodexoLink(action) {
      console.log("fetchPreCard saga", action);
      try {
        const response = yield call(callApi, action);
        console.log("fetchReomoveFromCartMycart error", response);
        yield put(fetchCheckSodexoLinkSuccess(response));
    
        action.data.handleResponseCheckSodexoLink(response);
      } catch (error) {
        console.log("fetchReomoveFromCartMycart error", error);
        action.data.handleErrorCheckSodexoLink(error);
        yield put(fetchCheckSodexoLinkFailure(error.message));
      }
    }

    function* RemoveCoupon(action){

    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
      console.log("fetchReomoveFromCartMycart error", response);
      yield put(fetchRemoveCouponSuccess(response));
  
      action.data.handleResponseRemoveCoupon(response);
    } catch (error) {
      console.log("fetchReomoveFromCartMycart error", error);
      action.data.handleErrorRemoveCoupon(error);
      yield put(fetchRemoveCouponFailure(error.message));
    }
    
}


  function* MyCartSaga() {
    yield takeLatest(SEND_USER_LOGIN_MYCART_REQUEST, sendUserLoginMycartDetails);
    yield takeLatest(SEND_SUBMIT_OTP_MYCART_REQUEST,sendSubmitOtoMycartDetails)
    yield takeLatest(FETCH_PAYMENT_OPTION_MYCART_REQUEST,fetchPaymentOptionMycartDetails)
    yield takeLatest(FETCH_PRE_CARD_MYCART_REQUEST,fetchPreCardMycartDetails)
    yield takeLatest(FETCH_COPUON_REQUEST,fetchCoupon)
    yield takeLatest(FETCH_APPLY_COPUON_REQUEST,fetchApplyCoupon)
    yield takeLatest(FETCH_REMOVE_COUPON_REQUEST,RemoveCoupon)
    yield takeLatest(FETCH_ADD_TO_CART_MY_CART_REQUEST,fetchAddToCartMycart)
    yield takeLatest (FETCH_REMOVE_FROM_CART_MY_CART_REQUEST,fetchReomoveFromCartMycart)
    yield takeLatest(FETCH_CREATE_ORDER_REQUEST,fetchCreateOrder)
    yield takeLatest(FETCH_INITIATED_PAYMENT_REQUEST,fetchInitiatedPayment)
    yield takeLatest(FETCH_CHECK_SODEXO_ORDER_STATUS_REQUEST,fetchCheckSodexoOrderStatus)
    yield takeLatest(FETCH_SODEXO_LINK_REQUEST,fetchSodexoLink)
    yield takeLatest(FETCH_CHECK_SODEXO_LINK_REQUEST,fetchCheckSodexoLink)
   
  }
  
  export default MyCartSaga;