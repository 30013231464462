//https://api-prod.daalchini.co.in/auth/user/business-profile/deregister
// https://api-prod.daalchini.co.in

import Dphistory from './component'
import {fetchDpHistoryRequest,fetchBpPaymentOptionRequest,fetchBpUnLinkRequest,fetchCreateOrderRequest} from "./action"
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import {fetchClearPreCardRequest,sendAddIntoCartRequest} from '../VmInventory/action'
import {fetchInitiatedPaymentRequest } from '../Mycart/action1'
import { mpClick } from '../../Mixpanel';
const DpHistoryContainer=(props)=>{
  const navigate=useNavigate()
  const machineId=localStorage.getItem('machineId')
  const navigator=useNavigate()
  const [loader,setLoader]=useState(false)
  console.log("useParams",useParams())
  const {uId} =useParams()
    const [unlinkModal,setUnlinkModal]=useState(false)
    const [bpHistory,setBpHistory]=useState([])
    const [requestsumbitted,setRequestsumbitted]=useState(false)
    const [paymentOptionsList,setPaymentOptionsList]=useState()
    const [finalAmount,setFinalAmount]=useState(20)
    const [rechargeModal,setRechargeModal]=useState(false)
    const [amount,setAmount]=useState()
    const [WebSocketFlag,setWebSocketFlag]=useState(false)
    const [rechargeSucessModal,setRechargeSucessModal]=useState(false)
    const razorpayMethod=localStorage.getItem("default_Recharge_PgName")=="RECHARGE_R_CARD"?"card":localStorage.getItem("default_Recharge_PgName")=="RECHARGE_R_WALLET"?"wallet":"UPI"

    const now = new Date();
    let rechargeOrderId
const options = { timeZone: 'Asia/Kolkata' };
const indiaDateTimeStr = now.toLocaleString('en-IN', options);

console.log(indiaDateTimeStr); 
 
    useEffect(()=>{
        DpHistory()
        fetchPaymentOption()
    },[])
    useEffect(() => {
      console.log("CONSOLE NUMBER ->> 3");
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    }, []);

    useEffect(()=>{
      mpClick({EventName:"Dp_page_opened"})
    },[])
    const UnLinkModalfun=()=>{
      setUnlinkModal(true)
    }
  


    const handlePaymentSuccess = (response) => {
      console.log("handlePaymentSuccess response",response)
      setLoader(false)
      Webwalasocket(rechargeOrderId)
      fetchPaymentOption()
      DpHistory()
      setRechargeModal(false)

    };

    const fetchPaymentOption = () => {
      const UserJWTAuth = localStorage.getItem('UserJWTAuth');
      const machineId = localStorage.getItem('machineId');
    
      props.fetchBpPaymentOptionRequest({
        url:  `/consumerbe/api/v2/payment/option?machineId=${0}&balancePgArr=PAYTM%7CWALLET_LOYALTY%7CWALLET`,
        method:"GET",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
          "x-app-platform": "web",
          "x-app-version": "v1.0.1",
          //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid")
        },
        "request": {
          request:{}
          
    
    
          },
        handleErrorBpPaymentOption ,
        handleResponseBpPaymentOption ,
      });
    };
    

const handleErrorBpPaymentOption=(error)=>{
  setLoader(false)

  if(error.response.status==401 || error.response.status==403){

   
    //Logout
    //localStorage.removeItem("UserJWTAuth")
      navigate("/login");
}
else{ toast.error(`${error.response.data.status.code}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}

}

const  handleResponseBpPaymentOption=(response)=>{
  setLoader(false)
  localStorage.setItem("BpBalance",response&&response.data&&response.data.data[2].balance)
  setPaymentOptionsList(response.data.data);

}








const handleResponseBpUnlink=(response)=>{
  console.log("Get response fetchTagList",response.data.response);
  navigator("/paymentOption")
}

const handleErrorBpUnlink=(response)=>{

}






const DpHistory = () => {
  const currentDate = new Date();
  let utcD,utcM,utcMM,utcSS,utcHH
  const utcYear = currentDate.getUTCFullYear();
  // Get UTC date
  const utcDate2 = currentDate.toISOString().split('T')[0];
  
  // Get UTC time
  const utcTime2 = currentDate.toISOString().split('T')[1].split('.')[0];
  
  console.log("tk UTC Date:", utcDate2);
  console.log("tk UTC Time:", utcTime2);
 

  const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  const utcHours = currentDate.getUTCHours();
  const utcMinutes = currentDate.getUTCMinutes();
  const utcSeconds = currentDate.getUTCSeconds();
  console.log("utcHours")
  if(String(utcMinutes).length==1){
    utcMM="0"+utcMinutes
    console.log("utcMM",utcMM)
    }
    else{
    utcMM=utcMinutes
    }
    
    
    if(String(utcSeconds).length==1){
    utcSS="0"+utcSeconds
    console.log("utcMM",utcMM)
    }
    else{
    utcSS=utcSeconds
    }
    
    
    if(String(utcHours).length==1){
    utcHH="0"+utcHours
    console.log("utcMM",utcMM)
    }
    else{
    utcHH=utcHours
    }
      console.log("currentDatecurrentDate",`${utcYear}-${utcM}-${utcD} ${utcHH}:${utcMM}:${utcSS}`)
    
    const machineId = localStorage.getItem('machineId');
   
   
    const utcDate = currentDate.getUTCDate();
    const utcMonth = currentDate.getUTCMonth() + 1; // Months are zero-based, so we add 1
    const type = typeof utcMonth;
    console.log("type",type);
    console.log("utcDate ",String(utcDate).length)
    if(String(utcDate).length==1){
      utcD="0"+utcDate
      console.log("utcDate",utcD)
    }
    else{
      utcD=utcDate
    }
   
    if(String(utcMonth).length==1){
    utcM="0"+utcMonth
      console.log("utcM--->",utcM)
    }
    else{
      utcM=utcMonth
    }
   
    console.log("tk my date",`${utcMonth}-${utcD}-${utcYear}`); // Output: '5/12/2023' (format: MM/DD/YYYY)
  
 
  console.log("utcHours")
  if(String(utcMinutes).length==1){
  utcMM="0"+utcMinutes
  console.log("utcMM",utcMM)
  }
  else{
  utcMM=utcMinutes
  }
  
  
  if(String(utcSeconds).length==1){
  utcSS="0"+utcSeconds
  console.log("utcMM",utcMM)
  }
  else{
  utcSS=utcSeconds
  }
  
  
  if(String(utcHours).length==1){
  utcHH="0"+utcHours
  console.log("utcMM",utcMM)
  }
  else{
  utcHH=utcHours
  }
    console.log("currentDatecurrentDate",`${utcYear}-${utcM}-${utcD} ${utcHH}:${utcMM}:${utcSS}`)
  
  
    let diffUtcM,diffUtcY
  if((utcM-1>0)){
  
    diffUtcM=utcM-1
    if(String(diffUtcM).length==1){
      diffUtcM="0"+diffUtcM
        console.log("diffUtcM--->",diffUtcM)
      }
      else{
        diffUtcM=diffUtcM
      }
    diffUtcY=utcYear
  }
  else{
  
    diffUtcM=12
    diffUtcY=utcYear-1
  
  }
  
    console.log("UserJWTAuth",UserJWTAuth)

  props.fetchDpHistoryRequest({
    url: '/consumer/api/v1/user/order/history',
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },
    "request": {
      "from":`${diffUtcY}-${diffUtcM}-${utcD} ${utcHH}:${utcMM}:${utcSS}`,"to":`${utcYear}-${utcM}-${utcD} ${utcHH}:${utcMM}:${utcSS}`,"pgId":9
      


      

  },
    handleErrorDpHistory ,
    handleResponseDpHistory ,
  });
};


const handleResponseDpHistory=(response)=>{
  setBpHistory(response.data);
  response.data&&response.data&&mpClick({EventName:"Dp_history_displayed"})
}

const  handleErrorDpHistory=(error)=>{
  console.log("khan handleErrorVmInventory", error.response);
  if(error.response.status==401 || error.response.status==403){

   
    //Logout
    //localStorage.removeItem("UserJWTAuth")
      navigate("/login");
}
else{ toast.error(`${error.response.data.status.code}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}

}
 
const handleErrorAddIntoCart=(res)=>{

  
  setLoader(false)
 
  console.log("handleErrorAddIntoCart",res)
  toast.error(`${res.response.data.status.message}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });




}
const handleResponseAddIntoCart=(res)=>{
console.log("handleResponseAddIntoCart",res)
  setLoader(false)
 
  CreateOrderRecharge()
 
 




}




  const AddIntoCart = (e) => {
    setLoader(true)

    setWebSocketFlag(true)
    if(amount<1){
      setLoader(false)
      toast.error(`Add Amount`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
    });
      return

    }


    setLoader(true)

        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      
        props.sendAddIntoCartRequest({
          url: `/consumerbe/api/v2/cart?machineId=-1`,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
            //HEADER ERROR
           "x-device-id":localStorage.getItem("uuid")
          },
          request: [
            {
              slotId: 100, // Parsing sId to an integer
              count: amount,      // Incrementing the count by 1
            },
          ],
          handleErrorAddIntoCart:(res)=>handleErrorAddIntoCart(res),
          handleResponseAddIntoCart: (res) => handleResponseAddIntoCart(res),
        });




   
    
      };

      const handleErrorCreateOrder=(res)=>{
        console.log(" tahzeeb handleErrorCreateOrder",res)
        //razorpay()
  
      }
      
      const handleResponseCreateOrder=(response)=>{
        console.log("tahzeeb handleResponseCreateOrder",response.data.data)
        rechargeOrderId=response&&response.data.data.orderId
        InitiatedPaymentapi(rechargeOrderId)
        razorpay()
       
        
      }
      
          const CreateOrderRecharge= (e) => {
           
      
       
            const UserJWTAuth = localStorage.getItem('UserJWTAuth');
            console.log("UserJWTAuth",UserJWTAuth)
            setLoader(true)
            console.log("inventory...container")
  
          
            props.fetchCreateOrderRequest({
              url:  `/consumerbe/api/v2/outlet/-1/order`,
              method:  "POST",
              headers: {
                Authorization: `Bearer ${UserJWTAuth}`,
                  "x-app-platform": "web",
                "x-app-version": "v1.0.1",
                 //HEADER ERROR
                "x-device-id":localStorage.getItem("uuid"),
               
          
              },
              request:{
               
                
           
                  
                    "type": "recharge",
            
                
                  
                
                },
                
              
          
          
              handleErrorCreateOrder,
              handleResponseCreateOrder,
            });
          };





          const handleErrorInitiatedPayment = (res) => {
            toast.error(`${res.data.status.message}`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setLoader(false);
            
          };
      
          const handleResponseInitiatedPayment = (res) => {
            mpClick({EventName:"Recharge_initiated"})
            Webwalasocket(rechargeOrderId);

          }
      
      
          const InitiatedPaymentapi = () => {
            const UserJWTAuth = localStorage.getItem("UserJWTAuth");
            const defaultPgId = localStorage.getItem("defaultPgId");
            const defaultPgName = localStorage.getItem("defaultPgName");
            const OrderId = localStorage.getItem("orderId");
        
            props.fetchInitiatedPaymentRequest({
              url: `/consumerbe/api/v2/order/${rechargeOrderId}/payment`,
              method: "POST",
              headers: {
                Authorization: `Bearer ${UserJWTAuth}`,
        
                "x-app-platform": "web",
                "x-app-version": "v1.0.1",
                //HEADER ERROR
                "x-device-id":localStorage.getItem("uuid")
              },
              request: {
                pgId: 2,
              },
        
              handleErrorInitiatedPayment,
              handleResponseInitiatedPayment: (res) =>
                handleResponseInitiatedPayment(res),
            });
          };


          const handlePaymentError=()=>{
            setLoader(false)
            setRechargeModal(false)
            setWebSocketFlag(false)
            mpClick({EventName:"Razorpay_Close"})
            
          }


            const razorpay=()=>{
    console.log("razorpayMethod",razorpayMethod)
      const payAmount=amount*100
      console.log("razorpay response no")
 /*      setTimeout(() => {
       // navigate('/order/status')
      }, 100000); */
      const UserJWTAuth = localStorage.getItem('UserJWTAuth');
      const defaultPgName = localStorage.getItem(' defaultPgName ');
     
     
      const options = {
        key: "rzp_live_ZVpG4pXQKTLFCS",
        amount: `${payAmount}`,
        description:`order=${rechargeOrderId}`,
        //image: logo,
        handler: function (response) {
          console.log("razorpay response",response,response)
          let userKey;
          userKey = UserJWTAuth
          handlePaymentSuccess(response);
          
          const payload = {
            header: {
              oauthToken: UserJWTAuth,
              userkey: userKey,
            },
            data: {
              request: {
                orderId: rechargeOrderId,
                //paymentGateway: orderData.paymentGateway,
                paymentId: 9,
                paymentStatus: 9
                  ? "SUCESS"
                  : "FAILED",
              },
              onClose: function() {
               
                //alert('Payment modal closed!');
                
                setLoader(false)
                document.getElementById('razorpay-container').remove();
              },
              
          
             
            },
          };
          
          
    
          
        },
       
        prefill: {
          email: "info@daalchini.co.in",
            contact:
              JSON.parse(localStorage.getItem("userDetails")) &&
              JSON.parse(localStorage.getItem("userDetails")).mobileNo,
          method:razorpayMethod
            
            
        },
        
        theme: {
          color: "#432B2B",
          hide_topbar: false,
        },
        notes: {
          order_id:rechargeOrderId,
        },
        onpaymenterror: function (error) {
          handlePaymentError(error);
        },
        modal: {
          ondismiss: function (error) {
            console.log("error",error)
            handlePaymentError(error);
          }
        }
      
      };
      
      var rzp1 = new window.Razorpay(options);
      setLoader(false)
      rzp1.open();
    
    }

    const Webwalasocket = (orderid) => {
      setLoader(true);
      const OrderId = localStorage.getItem("orderId");
      console.log("Webwalasocket data", OrderId);
      const ws = new WebSocket(
        `wss://api-prod.daalchini.co.in/consumerbe/status/payment?order_id=${orderid}`
      );
  
      console.log("next Linew  WWWWWWWWWWWWWWWWWWWWWWWww", ws);
      ws.onmessage = (event) => {
        console.log("Webwalasocket data", event);
        const data = JSON.parse(event.data);
        console.log("Webwalasocket data", data);
        if(data.success){
        setRechargeSucessModal(true)
        setWebSocketFlag(false)
        setLoader(false)
        mpClick({EventName:"Recharge_successful"})
        }
    
     
   
  
    
  
      };
  
      console.log("next Linew");
    };


    const handleErrorClearPreCard=()=>{

    }
    
    
    const handleResponseClearPreCard=(res)=>{
    
 
   
    
    
    }
    
    
          const fetcClearPreCard = () => {
            const UserJWTAuth = localStorage.getItem("UserJWTAuth");
          
            props.fetchClearPreCardRequest({
              url: `/consumerbe/api/v2/cart`,
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${UserJWTAuth}`,
                "x-app-platform": "web",
                "x-app-version": "v1.0.1",
                //HEADER ERROR
                "x-device-id":localStorage.getItem("uuid")
              },
              handleErrorClearPreCard ,
              handleResponseClearPreCard ,
            });
          };
    
const RechargeFun=()=>{
  fetcClearPreCard()
  setRechargeModal(true)
  mpClick({EventName:"Recharge_clicked"})
}
    return(
        <Dphistory
        bpHistory={bpHistory}
        unlinkModal={unlinkModal}
        setUnlinkModal={setUnlinkModal}
       
        UnLinkModalfun={UnLinkModalfun}
        requestsumbitted={requestsumbitted}
        setRequestsumbitted={ setRequestsumbitted}
        paymentOptionsList={paymentOptionsList}
        rechargeModal={rechargeModal}
        setRechargeModal={setRechargeModal}
        setAmount={setAmount}
        amount={amount}
        AddIntoCart={AddIntoCart}
        RechargeFun={RechargeFun}
        rechargeSucessModal={rechargeSucessModal}
        setRechargeSucessModal={setRechargeSucessModal}
        loader={loader}
        />


    )
}
function mapStateToProps(props) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    
    fetchDpHistoryRequest,fetchBpUnLinkRequest,
    fetchBpPaymentOptionRequest,
    sendAddIntoCartRequest,
    fetchClearPreCardRequest,
    fetchCreateOrderRequest,
    fetchInitiatedPaymentRequest
  })(DpHistoryContainer);

