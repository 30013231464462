export const SEND_DELETE_ACCOUNT_REQUEST="SEND_DELETE_ACCOUNT_REQUEST"
export const SEND_DELETE_ACCOUNT_SUCCESS="SEND_DELETE_ACCOUNT_SUCCESS"
export const SEND_DELETE_ACCOUNT_FAILURE="SEND_DELETE_ACCOUNT_FAILURE"


export const sendDeleteAccountRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:SEND_DELETE_ACCOUNT_REQUEST,
        data:myurldata
    }

    
   
}

export const sendDeleteAccountSuccess=(UserDetails)=>{
return{
    type:SEND_DELETE_ACCOUNT_SUCCESS,
    UserDetails:UserDetails
}
}

export const sendDeleteAccountFailure=(error)=>{
    return{
    type:SEND_DELETE_ACCOUNT_FAILURE,
    error:error
    }
}

