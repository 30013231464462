 import React from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import {cilArrowLeft} from 'react-icons/ci'
import { useNavigate } from 'react-router-dom';
import Arrow from '../../assets/backArrow.png'


const HeaderSecondary = (props) => {
const navigator=useNavigate()
 const  handleBack=()=>{

  navigator(props&&props.route)
  }

  return (
    <div className='h-[60px] flex items-center daalchini-header-color '>
        <img src={Arrow}  style={{    width: "24px"}}className='ml-5' onClick={handleBack}/>
        <p className='font-body font-medium mt-1 ml-2 text-gray-800'>{props&&props.title}</p>
    </div>
  )
}




export default HeaderSecondary 

