import FaqDetails from "./component"
import { connect } from "react-redux";
import React, { useEffect ,useState} from "react";
import { fetchFaqDetailsRequest } from "./action";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const FaqDetailsContainer=(props)=>{
  const navigate=useNavigate()
  const [orderhistory,setOrderhistory]=useState('')
const [loader,setLoader]=useState(true);
const [topicDetailsList,seTopicDetailsList]=useState([])
const {topicId} =useParams()


    useEffect(()=>{
        FaqDetailsApi()
       
    },[])
    const FaqDetailsApi=()=>{

        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
          
        props.fetchFaqDetailsRequest({
          url: `/support/api/v2/faq/topic/${topicId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
            //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid")
          },
          "request": {
            request:{}
            
      
      
            },
          handleErrorFaqDetails ,
          handleResponseFaqDetails ,
        });
    
      }
    
      const handleErrorFaqDetails=(error)=>{
        console.log("res")
        setLoader(false)
        if(error.response.status==401|| error.response.status==403){

          console.log("khan handleErrorVmInventory", error.response.data.status.code);
          //Logout
          //localStorage.removeItem("UserJWTAuth")
          navigate("/login");
      }
      else{ toast.error(`${error.response.data.status.code}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      }


        
      }
      
      const  handleResponseFaqDetails=(res)=>{
        console.log("handleResponseFaqDetails-------",res.data.data
        )
        seTopicDetailsList(res.data.data)
        setLoader(false)
        
      }








    return(<>
    
    <FaqDetails 
    topicDetailsList={topicDetailsList}/>
    
    </>)
}


function mapStateToProps( props ) {
    return {
      props,
    };
  }

export default connect(mapStateToProps, {
  fetchFaqDetailsRequest,
    
   
  })(FaqDetailsContainer);
