import React from "react";
import { Link, useNavigate } from "react-router-dom";
import nextArrow from "../../assets/nextArrow.png";
import ActiveOrdersHeader from "../Header/ActiveOrdersHeader";
import { useState } from "react";

import "../ActiveOrder/activeorder.css";
import { useEffect } from "react";
import Qr from "../../assets/qrmark.svg";

import { Cancel } from "axios";
import CancleIcon from "../../assets/Rejected.png";
import CancleDone from "../../assets/cancle.png";
import SadFace from "../../assets/SadFace.png";
import { mpClick, mpHelp } from "../../Mixpanel";
let minutes;
let seconds;
let v=3;

function ActiveOrder(props) {
const navigate=useNavigate()
console.log("props",props.vendingMachineAddress)


const goToPickUpOrder=(e,orderID,machineId)=>{
  
  localStorage.setItem("contactLessOrderID",orderID)
  localStorage.setItem("contactLessMachineID",machineId)
  navigate("/pickupOrder")
}



 const [diffInMs,setDiffInMs]=useState()


 function Truncatedproname({ text, limit }) {
  if (text&&text.length <= limit) {
    return <span style={{ fontSize: "14px", color: "#384F6F" }}>{text}</span>;
  }
  return (
    <span style={{ fontSize: "14px", color: "#384F6F" }}>{`${text&&text.substring(
      0,
      limit
    )}`}</span>
  );
}

function TruncatedVmadd({ text, limit }) {
  if (text.length <= limit) {
    return <span>{text}</span>;
  }
  return (
    <span>{`${text&&text.substring(
      0,
      limit
    )}`}</span>
  );
}
  //console.log("ActiveOrderepochtime",props.epochtime)
  const updatetime=()=>{
    //console.log("ActiveOrderepochtime update",props&&props.epochtime&&props.epochtime)
    let alltimer=[]
    props&&props.epochtime&&props.epochtime[0]&&props.epochtime.map((el,index)=>{
      //console.log("ActiveOrderepochtime update hello update")
      const time=(props.epochtime[index] - Date.now())
      const currentMinutes = Math.floor(time / 60000);
      //console.log("timemap",index,currentMinutes)
      alltimer.push(currentMinutes)
      setDiffInMs(alltimer)

    })
    for (let i = 0; i <props&& props.epochtime.length; i++) {
      //console.log("ActiveOrderepochtime update hello update")
      //console.log(`epochtime for Object ${i + 1}: ${props.epochtime[i]}`);
    
     // current epoch time in seconds- currentDate.getTime();
    // current time in minutes
    
    //console.log(" currentMinutes  diffInMs",currentMinutes*60,currentMinutes)
 
    }
  
  }


  const helpFun=(e,order_id)=>{
    mpHelp({EventName:"Help_clicked",data:{order_id:order_id}})

    window.location.replace(`https://api.whatsapp.com/send/?phone=917289005034&text=Hello%2C I+need+assistance+with+my+order.+My+order+ID +is + ${order_id}&type=phone_number&app_absent=0`)
  }
   useEffect(()=>{
 updatetime()
},[props.epochtime,diffInMs]) 
  //console.log("props diffInMs----> ",diffInMs)


  let percentage
  let convertPer
  //let remainingMinutes
  //let remainingSeconds
  let mm = 400;
  const [m, setM] = useState(0);
  const [s, setS] = useState(0);
  const [remainingSeconds,setRemainingSeconds]=useState(0)
  const [remainingMinutes,setRemainingMinutes]=useState(0)
  const [minutes,setMinutes]=useState(0);
  const [count, setCount] = useState(0);


 useEffect(() => {
  // Function to recalculate the value
  const recalculateValue = () => {
    // Perform the calculation here
   
   
    updatetime()
    //setValue(newValue);
  };
  

 

  // Schedule the recalculation every 1 minute (60000 milliseconds)
  const intervalId = setInterval(recalculateValue, 60000);

  // Clean up the interval on component unmount
  return () => {
    clearInterval(intervalId);
  };
}, [/* props.epochtime,diffInMs */]);


const CreatedTime=(props)=>{

  console.log("TIME",props.time)
  var date = new Date(props.time);

  // Convert to Indian time string
  var indianTime = date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
  
  console.log("indianTime",indianTime);
  

return(


  <>
  <>{indianTime}</>
  </>
)

 }




/*  const intervalId = setInterval(() => {
    
  console.log("props.currentMinutes",props.currentMinutes)
  },1000);    */

useEffect(()=>{


  


  },[props.currentMinutes])
















  const machineId = localStorage.getItem("machineId");

  //console.log("props Orders", props);
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  const navigator = useNavigate();

  return (
    <>
   <ActiveOrdersHeader title={"Active Order"} route={"/"} />
   <div
          style={{
            background:
              "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
          }}
          className=" pb-1 pt-3 p-2 overflow-y-auto h-screen"

        >
      {UserJWTAuth ? (
        <>
 
          {props && props.cancleflag && (
            <div className="modal">
              <div className="modal-content">
                <img
                  src={CancleIcon}
                  style={{ position: "absolute", top: "-41px", left: "39%" }}
                />
                <div style={{ "text-align": "center", "margin-top": "15%" }}>
                  <h3 style={{color: "#384F6F",
"text-align": "center",
"font-family": "Roboto",
"font-size":"16px",
"font-style": "normal",
"font-weight": "700",
"line-height": "28px", /* 175% */
"letter-spacing":"1px"}}>Cancel Order ?</h3>
                  <p style={{ marginTop: "3%" ,color: "rgba(56, 79, 111, 0.69)",
"text-align":"center",
"font-family": "Roboto",
"font-size":"12px",
"font-style": "normal",
"font-weight": "400",
"line-height":"20px"}}>
                    Do You Want To Cancel The Order
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    "justify-content": "space-around",
                    marginTop: "9%",
                  }}
                >
                  <button
                    onClick={(e) => {
                      props.setCancleflag(false);
                    }}
                    className="nobtn"
                    style={{display: "flex",
                      "width":"118px",
                      "height": "41px",
                      "padding":"10px",
                      "justify-content": "center",
                      "align-items":"center",
                      "gap": "10px",
                      "flex-shrink":"0"}}
                  >
                    NO
                  </button>
                  <button onClick={props.CancleOrder} className="yesbtn" style={{display: "flex",
width:"118px",
"height": "41px",
"padding":"10px",
"justify-content": "center",
"align-items": "center",
gap: "10px","flex-shrink":"0"}}>
                    YES
                  </button>
                </div>
              </div>
            </div>
          )}
          {props && props.cancleErrorflag && (
            <div className="modal">
              <div className="modal-content">
                <img
                  src={SadFace}
                  style={{ position: "absolute", top: "-50px", left: "35%" }}
                />
                <div style={{ "text-align": "center", "margin-top": "15%" }}>
                  <h3 style={{color:"#384F6F",
"text-align": "center",
"font-family": "Roboto",
"font-size": "16px",
"font-style":"normal",
"font-weight": "700",
"line-height": "28px", /* 175% */
"letter-spacing":"0.5px"}}>Order can’t be cancelled</h3>
                  <p style={{ marginTop: "3%" ,color: "rgba(56, 79, 111, 0.69)",
"text-align": "center",
"font-family": "Roboto",
"font-size": "12px",
"font-style": "normal",
"font-weight": "400",
"line-height":"15px"}}>{props.cancleErrorMsg}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    "justify-content": "space-around",
                    marginTop: "9%",
                  }}
                >
                  <button
                  
                    onClick={(e) => {
                      props.setCancleErrorflag(false);
                    }}
                    className="nobtn"
                    style={{display: "flex",
                     width: "72%",
                      "padding": "10px",
                      "justify-content": "center",
                      "align-items":"center",
                      "gap": "10px",
                      "flex-shrink":"0"}}

                  >
                   <span style={{color: "#FFF","text-align":"center",
"font-family": "Inter",
"font-size":"15px",
"font-style": "normal",
"font-weight":"800","line-height": "13.5px", /* 90% */
"letter-spacing":"1px"}}></span> OK
                  </button>
                </div>
              </div>
            </div>
          )}

          {props && props.cancleSuccessflag && (
            <div className="modal">
              <div className="modal-content">
                <img
                  src={CancleDone}
                  style={{ position: "absolute", top: "-65px", left: "30%" }}
                />
                <div style={{ "text-align": "center", "margin-top": "15%" }}>
                  <h3 style={{color: "#384F6F",
"text-align":"center",
"font-family": "Roboto",
"font-size":"16px",
"font-style": "normal",
"font-weight":"700",
"line-height": "28px",
"letter-spacing":"1px"}}>{props.cancleSuccessMsg}</h3>
                  <p style={{ marginTop: "3%",color: "rgba(56, 79, 111, 0.69)",
"text-align":"center",
"font-family":"Roboto",
"font-size": "12px",
"font-style": "normal",
"font-weight": "400",
"line-height": "20px"}}>{"Your order has been cancelled"}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    "justify-content": "space-around",
                    marginTop: "1%",
                  }}
                >
                  <Link
                    to={`/items?machineId=${machineId}`}
                    className="nobtn"
                    style={{ width: "72%", textDecoration: "none" }}
                  >
                    <button
                      onClicke={{ width: "72%" }}
                      onClickstyle={{ width: "72%" }}
                      onClick={(e) => {
                        props.setCancleSuccessflag(false);
                      }}
                    >
                      OK
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        

        

          {props&&props.activeOrderList1&&props.activeOrderList1[0]&&props.activeOrderList1.map((data,index)=>{
  return(
  <>
  {console.log("index------",props.activeOrderList1.length>1&&index==0)}
 <div style={{ "padding-top":props.activeOrderList1.length>1&&index==0?"0%":"3%",
  }}>
<div style={{"border-radius":"10px",border:"1px solid rgba(170, 170, 170, 0.12)"}}>

          <p style={{
            
            color: "#718198",

"font-family": "Roboto",
"font-size":"14px",
"font-style": "normal",
"font-weight":"500",
"line-height": "normal",
"letter-spacing":"0.28px",
            
             "padding-top":"6%",


"marginLeft":"5%",


"marginBottom":"30px",
"line-height":"normal"}}><TruncatedVmadd text={data.vendingMachineAddress} limit={40}/></p>
  <div
            className="styles_row__1ivP1 shadow-box"
            data-testid="grid-row"
            style={{
              marginBottom: 24,
              marginTop: "4%",
              "border-radius": "10px",
              "margin-right": "12px",
              "margin-left": "12px",
              "margin-top": "1px",
              "border-radius": "16px",
              background: "linear-gradient(0deg, #D2EEE5 0%, #D2EEE5 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF"
            }}
          >
            <div className="styles_slide__2c207 " style={{ marginRight: 0,width:"100%" }}>
              <div>
                <div
                  className="styles_cardWithPadding__JE1QE"
                  data-testid="home-restaurant-card-wrapper"
                  style={{
                   /*  width: "calc((100vw - 0px) - 12px)" */
                    "margin-top": "21px",
                    "padding-bottom": "11px",
                  }}
                >
                  <a
                    data-testid="resturant-card-anchor-container"
                    className="styles_container__fLC0R"
                    aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                    tabIndex={0}
                    role="button"
                  >
                    
                    <div style={{ display: "flex" , "justify-content":"space-between" }}>
                      <div>
                        <h3 style={{color: "#384F6F",
"font-family": "Roboto",
"font-size": "17px",
"font-style": "normal",
"font-weight": "600",
"line-height": "normal",
"text-transform":"capitalize"}}>
                          Contactless pickup
                        </h3>
                        <p style={{ color:" #64748B",
"font-family": "Roboto",
"font-size": "13px",
"font-style":"normal",
"font-weight": "400",
"line-height": "20px", /* 153.846% */
"letter-spacing":"0.2px"}}>
                          Scan the QR code on the vending <br />
                          machine to pick your order.
                        </p>
                      </div>
                     { diffInMs&&diffInMs[index]>0 ?<div style={{"text-align":"center"}}>
                        <h1 style={{fontSize:"18px"}}>Expire in </h1>
                        <h1 style={{color:"red"}}>{diffInMs&& diffInMs[index]}<span style={{"font-size":"20px"}}>min</span> </h1>
                      </div>:
                      <h1 style={{color:"red",fontSize:"18px",}}>Expired </h1>
                      }
                      
            
                    </div>
                    <div  style={{ textDecoration: "none" }}>
                      {console.log("element of map",data)}
                      <button
                        className="qrbtn"
                        style={{ display: "flex", "align-items": "center" }}
                        onClick={(e)=>{goToPickUpOrder(e,data.orderId,data.vendingMachineId)}}
                      >
                        Scan QR Code <img src={Qr} />{" "}
                      </button>
                    </div>
                  </a>
                </div>
                <div />
              </div>
            </div>
          </div>
<p style={{color: "#94A3B8",
"font-family": "Roboto",
"font-size": "14px",
"font-style": "italic",
"font-weight":"400",
"text-align": "center",
"line-height": "150%", /* 21px */
"letter-spacing":"0.2px"}}>Or</p>


          <div style={{ marginTop: "15px", padding: "31px" }}>
            <div style={{    display:"flex","justify-content":"space-between","align-items":"center"}}>
            <div style={{ display: "flex","align-items":"center" }}>
              <h3 style={{color: "#384F6F",
"font-family": "Roboto",
"font-size": "14px",
"font-style":"normal",
"font-weight": "600",
"line-height": "normal",
"text-transform":"uppercase"}}>PICKUP CODE:</h3>
              <h3 style={{ color: "#FE8B27",
"font-family":"Roboto",
"font-size": "14px",
"font-style": "normal",
"font-weight": "600",
"line-height":"normal",
"text-transform": "uppercase" }}>
              &nbsp;  {data &&data.dcCode}
              </h3>
            </div>
            <p style={{"font-size":"14px"}}><CreatedTime time= {data && data.createdAt}/></p>
            </div>
            <p style={{ color: "#64748B",
"font-family": "Roboto",
"font-size":"13px",
"font-style":"normal",
"font-weight": "400",
"line-height": "20px",/* 153.846% */

"letter-spacing":"0.2px"}}>
              Enter the pickup code on the vending machine to pick your order.
            </p>
          </div>


          <div ><h4 style={{marginLeft:"33px",color:" #718198",

/* SM14 */
"font-family": "Roboto",
"font-size": "14px",
"font-style": "normal",
"font-weight": "600",
"line-height": "150%" ,/* 21px */
marginBottom:"68px",
"letter-spacing": "0.2px"}}>Order ID: <span style={{color: "#384F6F",

  /* SM14 */
  "font-family":"Roboto","font-size": "14px",
  "font-style":" normal",
  "font-weight":"600","line-height":"150%","letter-spacing":"0.2px"}}>#{data && data.orderId}</span></h4></div>
          <div style={{ marginTop: "-81px", }}>
            <div className="_2NVhy _3hjyL">
              <div>
                <div style={{ padding: 0 }}>
                  <div>
                    <div />
                  </div>
                </div>
                <div style={{ padding: 0 }}>
                  <div>
                    <div />
                    <div
                      data-testid="grid-header"
                      className="styles_headerContainer__2UgeD"
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <div />
                    <div style={{ "margin-top": "60px" }}>
                      {/*  <ImageAd /> */}
                    </div>
                  </div>
                </div>
                <div style={{ padding: "8px 0px 0px" }}>
                  <div>
                    <div />
                    <div
                      data-testid="grid-header"
                      className="styles_headerContainer__2UgeD"
                    ></div>
                  </div>
                </div>

                <div style={{ padding: 0 }}>
                  <div>
                    <div />

                    {props &&
                      data &&
                      data.lineItems.map((el, index) => {
                        return (
                          <div
                            className="shadow-box"
                            data-testid="grid-row"
                            style={{
                              marginBottom: 24,
                              marginTop: "4%",
                              
                              "margin-right": "12px",
                              "margin-left": "12px",
                              "border-radius": "16px",
"background": "#FFF",
"box-shadow":"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"
                            }}
                          >
                            <div
                              className="styles_slide__2c207"
                              style={{ marginRight: 0 }}
                            >
                              <div>
                                <div
                                  className="styles_cardWithPadding__JE1QE"
                                  data-testid="home-restaurant-card-wrapper"
                                  style={{
                                   // width: "calc((100vw - 0px) - 12px)",
                                    width:"100%"
                                  }}
                                >
                                  <div
                                    style={{ marginTop: "21px" }}
                                    data-testid="resturant-card-anchor-container"
                                    className="styles_container__fLC0R"
                                    aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                    tabIndex={0}
                                    role="button"
                                  >
                                    <div
                                      className="styles_imgContainer__1uHo5"
                                      aria-hidden="true"
                                    >
                                      <div
                                        className="styles_ImageContainer__2rk9a"
                                        data-testid="resturant-card-image-container"
                                        style={{
                                          background: "rgb(255, 255, 255)",
                                        }}
                                      >
                                        <img
                                          style={{ height: "100%" }}
                                          className="styles_Image__1fplJ"
                                          loading="lazy"
                                          data-testid="resturant-card-image"
                                          src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.imageUrl}`}
                                        />
                                      </div>
                                    </div>
                                    <div >
                                    <div
                                      aria-hidden="true"
                                      className="styles_containerRestaurant__3vhx3"
                                    >
                                      <div className="styles_containerImageBadge__14fk3">
                                        <div
                                          data-testid="resturant-card-name"
                                          className="styles_restaurantName__29jAP"
                                          style={{fontSize:"16px"}}
                                        >
                                        <Truncatedproname text={el.productName} limit={25}/>

                                        </div>
                                      </div>

                                      <div style={{display:"flex","justify-content":"space-between","align-self":"stretch",marginTop:"9px"}}>
                                        <h3 style={{color:"#718198",

/* SM14 */
"font-family":"Roboto","font-size":"14px","font-style": "normal","font-weight": "600","line-height": "150%", /* 21px */
"letter-spacing": "0.2px"}}>
                                          {el.unitCount} Items
                                        </h3>
                                    
                         
                                        <h2
                                          style={{
                                            color: "#00B177"
                                            ,"text-align": "right",
                                            
                                            /* SM14 */
                                            "font-family":"Roboto",
                                            "font-size": "14px",
                                            "font-style": "normal",
                                            "font-weight": "600",
                                            "line-height": "150%", /* 21px */
                                            "letter-spacing":" 0.2px",
                                            marginRight:"25px"
                                          }}
                                        >
                                          Rs.{""}
                                          {el.offerPrice.toFixed(2)}
                                        </h2>
                                  
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div />
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    <div
                      className=" shadow-box"
                      data-testid="grid-row"
                      style={{
                        marginBottom: "-5px",
                        marginTop: "4%",
                        "border-radius": "10px",
                        "margin-right": "12px",
                        "margin-left": "12px",
                        height: "51px",
                        background:"white",
                        marginBottom: 5,
                      }}
                    >
                      <div
                        className="styles_slide__2c207"
                        style={{ marginRight: 0 }}
                      >
                        <div>
                          <div
                            className="styles_cardWithPadding__JE1QE"
                            data-testid="home-restaurant-card-wrapper"
                            style={{
                              //width: "calc((100vw - 0px) - 12px)",
                              width:"100%"
                            }}
                          >
                            <a
                            style={{"margin-top":"16px"}}
                              data-testid="resturant-card-anchor-container"
                              className="styles_container__fLC0R"
                              aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                              tabIndex={0}
                              role="button"
                            >
                              <div
                                className="styles_imgContainer__1uHo5"
                                aria-hidden="true"
                              ></div>
                              <div
                                style={{
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "align-items": "center",
                                  "margin-right": "8%",
                                  marginTop: "10px",
                                }}
                              >
                                <h2 style={{color: "#384F6F",
"font-family":"Roboto","font-size":"14px",

"font-style":"normal","font-weight":"600","line-height":"150%", /* 21px */
"letter-spacing":"0.2px"}}>Grand Total</h2>
                              {/*   <h2 style={{color: "#384F6F",
"font-family":"Roboto","font-size":"14px",

"font-style":"normal","font-weight":"600","line-height":"150%",
"letter-spacing":"0.2px"}}>₹ {props && props.amounts[index]&&props.amounts[index].toFixed(2) }</h2> */}
 <h2 style={{color: "#384F6F",
"font-family":"Roboto","font-size":"14px",

"font-style":"normal","font-weight":"600","line-height":"150%",
"letter-spacing":"0.2px"}}>₹ {props && data.amount&&data.amount.toFixed(2) }</h2>


                              </div>
                            </a>
                          </div>
                          <div />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "18px",
                        display: "flex",
                        "justify-content": "space-around",
                        width:"100%"
                      }}
                    >
                      <button
                        style={{
                          width:"50%",
                          color: "#718198",
                         
                        
                          "display": "flex",

height: "38px",
padding: "0px 16px",
"justify-content": "center",
"align-items": "center",
"flex-shrink": "0",
                         
                          "border-radius": "8px",


border:"1px solid rgba(113, 129, 152, 0.38)"
                        }}
                        onClick={(e) => props.CancleOrderset(e,data.orderId)}
                      >
                        Cancel Order
                      </button>
                        <div  onClick={(e)=>{helpFun(e,data.orderId)}} >
                      <button
                        style={{
                          color: "#718198",
                          width:"112%",
                          /* "border-radius": "7px",
                         
                          padding: "16px 13px",
                          "text-align": "center",
                          "text-decoration": "none",
                          display: "inline-block", */
                         
                         
                         
                        
                            
  
  height: "38px",
  padding: "0px 16px",
  "justify-content": "center",
  "align-items": "center",
  "flex-shrink": "0",
                           
                            "border-radius": "8px",
  
  
  border:"1px solid rgba(113, 129, 152, 0.38)"
                        }}
                      >
                       <span style={{
"text-align": "center",
"font-family":"Roboto",

"font-size":"14px","font-style":"normal",
"font-weight": "500",
"line-height":"normal"}}></span> Help & Support
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
     
            </div>
          </div>
</div>
</div>


  
  </>
  )
}).reverse()
}



         
        
        </>
      ) : (
        <div style={{ "margin-top": "50%", "text-align": "center" }}>
          <h1>SomeThing Went Wrong</h1>
        </div>
      )}
      </div>
    </>
  );
}

export default ActiveOrder;
