import { connect } from "react-redux";
import Orders from "./component";

import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Orderstatus from "./component";
import { useNavigate } from "react-router-dom";

const OrderstatusContainer = (props) => {
const navigate = useNavigate();
const [orderdetail,setOrderdetail]=useState('')
const machineId = localStorage.getItem('machineId');
const {orderId} =useParams()
const UserJWTAuth = localStorage.getItem('UserJWTAuth');
useEffect(()=>{
 
 console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
 if(!UserJWTAuth){
   navigate('/')
 }
},[])
useEffect(()=>{
  const websocketRes = JSON.parse(localStorage.getItem('websocketRes'));
   setTimeout(() => {
        if(websocketRes&&websocketRes.success){
        console.log("Orderstatus navigate")
        navigate('/activeOrder',{ replace: true })
        }
        else{
          navigate(`/items?machineId=${machineId}`,{ replace: true })
        }
      }, 3000); 
  
},[])









return(
    <Orderstatus/>
   
)


}



function mapStateToProps({ props }) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {

   
  })(OrderstatusContainer);