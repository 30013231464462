import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {  FETCH_BP_PAYMENT_OPTION_REQUEST, FETCH_BP_UNLINK_REQUEST, FETCH_DP_HISTORY_REQUEST, FETCH_ORDER_HISTORY_LIST_REQUEST, fetchDpHistoryFailure, fetchDpHistorySuccess, fetchBpPaymentOptionFailure, fetchBpPaymentOptionSuccess, fetchBpUnLinkFailure, fetchBpUnLinkSuccess, fetchOrdersHistoryFailure, fetchOrdersHistorySuccess, FETCH_CREATE_DP_RECHARGE_ORDER_REQUEST, fetchCreateOrderSuccess, fetchCreateOrderFailure } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetcheBpPaymentOption(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchBpPaymentOptionSuccess(response));
  
      action.data.handleResponseBpPaymentOption(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorBpPaymentOption(error)
      yield put(fetchBpPaymentOptionFailure(error.message));
    }
  }



  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetcheBpUnlink(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchBpUnLinkSuccess(response));
  
      action.data.handleResponseBpUnlink(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorBpUnlink(error)
      yield put(fetchBpUnLinkFailure(error.message));
    }
  }






  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetcheDpHistory(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchDpHistorySuccess(response));
  
      action.data.handleResponseDpHistory(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorDpHistory(error)
      yield put(fetchDpHistoryFailure(error.message));
    }
  }


  function*  fetchCreateOrderDpRecharge(action){

    console.log("fetchCreateOrderRecharge saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchCreateOrderSuccess(response));
  
      action.data.handleResponseCreateOrder(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorCreateOrder(error)

      yield put(fetchCreateOrderFailure(error.message));
    }

  }




  function* DpHistorySaga() {
    yield takeLatest(FETCH_BP_PAYMENT_OPTION_REQUEST, fetcheBpPaymentOption);
    yield takeLatest(FETCH_CREATE_DP_RECHARGE_ORDER_REQUEST, fetchCreateOrderDpRecharge);
    yield takeLatest(FETCH_DP_HISTORY_REQUEST, fetcheDpHistory);
    
  }
  
  export default DpHistorySaga;