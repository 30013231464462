export const FETCH_FAQ_DETAILS_REQUEST="FETCH_FAQ_DETAILS_REQUEST"
export const FETCH_FAQ_DETAILS_SUCCESS="FETCH_FAQ_DETAILS_SUCCESS"
export const FETCH_FAQ_DETAILS_FAILURE="FETCH_FAQ_DETAILS_FAILURE"


export const fetchFaqDetailsRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_FAQ_DETAILS_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchFaqDetailsSuccess=(FaqDETAILS)=>{
return{
    type:FETCH_FAQ_DETAILS_SUCCESS,
    FaqDETAILS:FaqDETAILS
}
}

export const fetchFaqDetailsFailure=(error)=>{
    return{
    type:FETCH_FAQ_DETAILS_FAILURE,
    error:error
    }
}

