import React, { useEffect, useState } from "react";
import Paytm from "./component";
import { connect } from "react-redux";
import { fetchVerifyPaytmOtpRequest ,fetchPatmAuthRequest } from "./action";
import {  toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
const PaytmContainer=(props)=>{
const navigate = useNavigate();
const[mobile,setMobile]=useState("")
const[Otp,setOtp]=useState("")
const [mobileFlag,setMoblieFlag]=useState(true)
const [isExistingPaytmUser,setIsExistingPaytmUser]=useState("")
const [paytmState,setPaytmState]=useState("")
const [loader,setLoader]=useState(false)
const [remainingTime, setRemainingTime] = useState(10);
const [isDisabled, setIsDisabled] = useState(true);

const [showTimer, setShowTimer] = useState(false);
  const [timer, setTimer] = useState(30);

useEffect(()=>{
  
  SubmitMobile()
  
},[])

useEffect(() => {
  let interval;
  
  if (showTimer && timer > 0) {
    interval = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);
  }

  return () => {
    clearInterval(interval);
  };
}, [showTimer, timer]);




const UserJWTAuth = localStorage.getItem('UserJWTAuth');
useEffect(()=>{
 
 console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
 if(!UserJWTAuth){
   navigate('/')
 }
},[])

useEffect(() => {
   
    
  t()
  console.log("isDisable",isDisabled)
  
 
  
    }, [isDisabled]);
const timmer=()=>{

  setRemainingTime(prevTime => prevTime - 1);
 
  console.log("remainingTime>=0",remainingTime)
  
}


  
  
  
  const t=()=>{
    let interval;
    if (isDisabled ) {
      interval = setInterval(() => {
       
      timmer()
      }, 1000); // Update the remaining time every second
    }
  
    return () => {
      clearInterval(interval);
    };
  }  
  
let onchangeMobile=(e)=>{
  
    setMobile(e.target.value)
console.log("mobile",e.target.value,mobile)

}


let onchangeOtp=(e)=>{

    setOtp(e.target.value)
console.log("mobile",e.target.value)

}


const handleSendOTP = () => {

    

  SubmitMobile()

 
  t()

  setTimeout(() => {
    setIsDisabled(true);

  }, 60000);

};



const SubmitMobile = () => {
  setTimeout(() => {
    setIsDisabled(true);

  }, 60000);


    const UserJWTAuth = localStorage.getItem('UserJWTAuth');
    const activeOrderId = localStorage.getItem('activeOrderId');
    console.log("UserJWTAuth",UserJWTAuth)
    setLoader(true)
    console.log("inventory...container")

  props.fetchPatmAuthRequest({
    url: `/payment/link/paytm/wallet`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },
    "request": {
      
        "mobileNo": null
        


      },
    handleErrorPaytmAuth ,
    handleResponsePaytmAuth,
  });
};

const handleErrorPaytmAuth=(err)=>{

  setLoader(false)
       
  console.log("Get response pickupApi",err&&err.response&&err.response.data.statusMessage);
  toast.error(`${err&&err.response&&err.response.data.statusMessage}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
});
}

const handleResponsePaytmAuth=(response)=>{
        console.log("Get response paytm Link",response&&response.data.statusCode);
        //setIsDisabled(false)
        setIsDisabled(true);
        setRemainingTime(60);
        if(response.data.status=="SUCCESS"){
            setIsExistingPaytmUser(response&&response.data.response&&response.data.response.isExistingPaytmUser)
            setPaytmState(response&&response.data.response&&response.data.response.state)
            

        }
        if(response&&response.data&&response.data.statusCode){
         // navigate('/paymentOption')
         //setMoblieFlag(false)

        }
        
        setLoader(false)
        if(response.data.status=="FAILURE"){
          //setIsDisabled(false)
        toast.error(`${response&&response.data.statusMessage}`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
    }
      
}



const Verfy = () => {
 
  const UserJWTAuth = localStorage.getItem('UserJWTAuth');
  console.log("UserJWTAuth",UserJWTAuth)
  setLoader(true)
  console.log("inventory...container")

  props.fetchVerifyPaytmOtpRequest({
    url: `/payment/validate/paytm/otp`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")

    },
    request: {
      
      "request": {
        "state":paytmState,
        "mobileNo": null,
        "otp": Otp
        
        }


      },
    handleErrorVerifyPaytmOtp,
    handleResponseVerifyPaytmOtp,
  });
};


const handleErrorVerifyPaytmOtp=(err)=>{
  setLoader(false)
       
  console.log("Get response pickupApi",err&&err.response&&err.response.data.statusMessage);
  toast.error(`${err&&err.response&&err.response.data.statusMessage}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
});
}
const handleResponseVerifyPaytmOtp=(response)=>{
  console.log("Get response paytm Link",response);
  //setIsDisabled(true)
 
  if(response.data.status=="SUCCESS"){
      setIsExistingPaytmUser(response&&response.data.response&&response.data.response.isExistingPaytmUser)
      setPaytmState(response&&response.data.response&&response.data.response.state)
      navigate('/paymentOption')
      //setMoblieFlag(false)

  }
  
  setLoader(false)
  
  if(response.data.status=="FAILURE"){
    
  toast.error(`${response&&response.data.statusMessage}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
      
  });
}
}



    return(<>
    
    <Paytm 
    onchangeMobile={onchangeMobile}
    mobileFlag={mobileFlag}
    mobile={mobile}
    SubmitMobile={SubmitMobile}
    onchangeOtp={onchangeOtp}
    Otp={Otp}
    Verfy={Verfy}
    remainingTime={remainingTime}
    isDisabled={isDisabled}
    handleSendOTP={handleSendOTP}
    
   
    />
    </>)
}
function mapStateToProps({ props }) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    
    fetchVerifyPaytmOtpRequest,
    fetchPatmAuthRequest
  })(PaytmContainer);