import React, { useEffect, useState } from "react";


import WaitingLoder from "../WaitingLoader/component";
import { Link } from "react-router-dom";

import QrMark from "../../assets/qrmark.png";

import backArrow from "../../assets/backArrow.png";


import locat from "../../assets/locat.png";

import abandoned from "../../assets/abandoned.png";
import apple from "../../assets/apple.png";
import playstore from "../../assets/playstore.png";
import Failed from "../../assets/failed.png";
import whatsapp from "../../assets/whatsapp.png";

import '../HelpandSupport/helpandsupport.css';
import faqhelp from "../../assets/faqhelp.png";
import food from "../../assets/food.png";
import headphone from "../../assets/headphone.png";
import ordersuccess from "../../assets/ordersuccess.png";
import ordernotfound from "../../assets/ordernotfound.png";
import orderPlaced from "../../assets/orderPlaced.png";
import orderCancle from "../../assets/orderCancle.png";
import orderabandoned from "../../assets/orderabandoned.png";
import bird from "../../assets/bird.png";
import pytm from "../../assets/pytmnot.png";

const HelpAndSupport = (props) => {
  const TopNav = () => {
    return (
      <>
        {props.loader && <WaitingLoder />}
        {
          <div className="_2456r">
            <div className="_3nxa7" data-testid="listing-header-location">
              <button
                className="_1NdRR"
                aria-label="Selected address is Delhi, India; Double tap to change address."
                id="change-address-btn-id"
              >
                <div style={{ display: "flex", "align-items": "center" }}>
                  <Link to="/faq">
                    <img
                      style={{ height: "18px", "margin-right": "12px" }}
                      src={backArrow}
                    />
                  </Link>
                  <div style={{ marginLeft: "10px" }}>
                    <p> Order Status</p>
                  </div>
                </div>
              </button>

              <span className="icon-Offers-outline _2P0Ff" />
              <div
                style={{
                  display: "flex",
                  "margin-right": "13px",
                  "align-items": "center",
                }}
              >
                {props.locationAllow && (
                  <>
                    <a
                      style={{ "margin-top": "5px" }}
                      //href="https://api.whatsapp.com/send/?phone=917289005034&text=Need+help&type=phone_number&app_absent=0"
                      target="_blank"
                    >
                      {" "}
                      <img
                        src={locat}
                        style={{
                          width: "26px",
                          height: "33px",
                          "margin-right": "21px",
                          padding: "5%",
                        }}
                        //onClick={(e)=>{LocatFunClose(e)}}
                      />{" "}
                    </a>

                    <img
                      onClick={(e) => {
                        props.handleShowQr(e);
                      }}
                      style={{ width: "33px", height: "33px" }}
                      src={QrMark}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        }
      </>
    );
  };

  return (
    <>
      {props.orderCancleflag && (
        <div className="modal" style={{ backgroundColor: "#ffff" }}>
          <TopNav />
          <div
            style={{
              /* padding: "5%", */
              display: "flex",
              "justify-content": "space-around",
            }}
          >
            <img style={{ width: "50%" }} src={orderCancle} />
          </div>

          <h1
            style={{
              fontSize: "24px",
              fontWeight: "500",
              "margin-left": "19%",
            }}
          >
            Your Order Has Cancel
          </h1>

          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {props.orderCancleREflag && (
              <button
                style={{
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#6EFF8724",
                  "align-items": "center",
                  color: "#00A41C",
                }}
                className="btnEnable"
              >
                Refund successful
              </button>
            )}
          </div>

          <div style={{ position: "absolute", bottom: "72px", width: "100%" }}>
            <p
              style={{
                "text-align": "center",
                fontSize: "10px",
                "margin-top": "11px",
                color: "rgba(56, 79, 111, 0.56)",
                position: "absolute",
                top: "18px",
                left: "76px",
              }}
            >
              for more information about your order, use our mobile app.{" "}
            </p>

            <div style={{ display: "flex", padding: "9%", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "50", height: "41px", padding: "5%" }}
                  src={playstore}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>GET IT ON</p>
                  <p style={{ "font-size": "13px" }}>Google play</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "42px", height: "41px", padding: "5%" }}
                  src={apple}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>DOWNLOAD ON THE </p>
                  <p style={{ "font-size": "13px" }}>Apple Store</p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "absolute", bottom: "36px", left: "36%" }}>
            <p
              style={{
                "font-size": "10px",
                color: "#384F6F",
                "font-weight": "500",
                "margin-left": "9px",
              }}
            >
              For more Help{" "}
            </p>
            <div style={{ display: "flex", "align-items": "center" }}>
              <a
                style={{ textDecoration: "none" }}
                href="https://api.whatsapp.com/send/?phone=917289005034&text=Need+help&type=phone_number&app_absent=0"
              >
                <p style={{ color: "#0075FF", textDecoration: "underline" }}>
                  Contact Us!
                </p>
              </a>
              <img style={{ width: "10%" }} src={whatsapp} />
            </div>
          </div>
        </div>
      )}

      {props.orderAbandonedflag && (
        <div className="modal" style={{ backgroundColor: "#ffff" }}>
          <TopNav />
          <div
            style={{
              padding: "5%",
              display: "flex",
              "justify-content": "space-around",
            }}
          >
            <img style={{ width: "50%" }} src={orderabandoned} />
          </div>

          <h1
            style={{
              fontSize: "24px",
              fontWeight: "500",
              "margin-left": "14%",
            }}
          >
            Your Order Has Abandoned
          </h1>

          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {props.orderAbandonedREflag && (
              <button
                style={{
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#6EFF8724",
                  "align-items": "center",
                  color: "#00A41C",
                }}
                className="btnEnable"
              >
                Refund successful
              </button>
            )}
          </div>

          <div style={{ position: "absolute", bottom: "72px", width: "100%" }}>
            <p
              style={{
                "text-align": "center",
                fontSize: "10px",
                "margin-top": "11px",
                color: "rgba(56, 79, 111, 0.56)",
                position: "absolute",
                top: "18px",
                left: "76px",
              }}
            >
              for more information about your order, use our mobile app.{" "}
            </p>

            <div style={{ display: "flex", padding: "9%", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "50", height: "41px", padding: "5%" }}
                  src={playstore}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>GET IT ON</p>
                  <p style={{ "font-size": "13px" }}>Google play</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "42px", height: "41px", padding: "5%" }}
                  src={apple}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>DOWNLOAD ON THE </p>
                  <p style={{ "font-size": "13px" }}>Apple Store</p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "absolute", bottom: "36px", left: "36%" }}>
            <p
              style={{
                "font-size": "10px",
                color: "#384F6F",
                "font-weight": "500",
                "margin-left": "9px",
              }}
            >
              For more Help{" "}
            </p>
            <div style={{ display: "flex", "align-items": "center" }}>
              <a
                style={{ textDecoration: "none" }}
                href="https://api.whatsapp.com/send/?phone=917289005034&text=Need+help&type=phone_number&app_absent=0"
              >
                <p style={{ color: "#0075FF", textDecoration: "underline" }}>
                  Contact Us!
                </p>
              </a>

              <img style={{ width: "10%" }} src={whatsapp} />
            </div>
          </div>
        </div>
      )}

      {props.orderCreated && (
        <div className="modal" style={{ backgroundColor: "#ffff" }}>
          <TopNav />
          <div
            style={{
              padding: "5%",
              display: "flex",
              "justify-content": "space-around",
            }}
          >
            <img style={{ width: "50%" }} src={pytm} />
          </div>

          <h1
            style={{
              fontSize: "24px",
              fontWeight: "500",
              "margin-left": "14%",
            }}
          >
            Payment is not complete
          </h1>

          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {props.orderAbandonedREflag && (
              <button
                style={{
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#6EFF8724",
                  "align-items": "center",
                  color: "#00A41C",
                }}
                className="btnEnable"
              >
                Refund successful
              </button>
            )}
          </div>

          <div style={{ position: "absolute", bottom: "72px", width: "100%" }}>
            <p
              style={{
                "text-align": "center",
                fontSize: "10px",
                "margin-top": "11px",
                color: "rgba(56, 79, 111, 0.56)",
                position: "absolute",
                top: "18px",
                left: "76px",
              }}
            >
              for more information about your order, use our mobile app.{" "}
            </p>

            <div style={{ display: "flex", padding: "9%", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "50", height: "41px", padding: "5%" }}
                  src={playstore}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>GET IT ON</p>
                  <p style={{ "font-size": "13px" }}>Google play</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "42px", height: "41px", padding: "5%" }}
                  src={apple}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>DOWNLOAD ON THE </p>
                  <p style={{ "font-size": "13px" }}>Apple Store</p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "absolute", bottom: "36px", left: "36%" }}>
            <p
              style={{
                "font-size": "10px",
                color: "#384F6F",
                "font-weight": "500",
                "margin-left": "9px",
              }}
            >
              For more Help{" "}
            </p>
            <div style={{ display: "flex", "align-items": "center" }}>
              <a
                style={{ textDecoration: "none" }}
                href="https://api.whatsapp.com/send/?phone=917289005034&text=Need+help&type=phone_number&app_absent=0"
              >
                <p style={{ color: "#0075FF", textDecoration: "underline" }}>
                  Contact Us!
                </p>
              </a>

              <img style={{ width: "10%" }} src={whatsapp} />
            </div>
          </div>
        </div>
      )}

      {props.orderPartiallyflag && (
        <div className="modal" style={{ backgroundColor: "#ffff" }}>
          <TopNav />
          <div
            style={{
              padding: "5%",
              display: "flex",
              "justify-content": "space-around",
            }}
          >
            <img style={{ width: "50%" }} src={bird} />
          </div>

          <h1
            style={{
              fontSize: "24px",
              fontWeight: "500",
              "margin-left": "10%",
            }}
          >
            your order is Partially successful{" "}
          </h1>

          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {props.orderNotFoundREflag && (
              <button
                style={{
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#6EFF8724",
                  "align-items": "center",
                  color: "#00A41C",
                }}
                className="btnEnable"
              >
                Refund successful
              </button>
            )}
          </div>
          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {props.orderAbandonedREflag && (
              <button
                style={{
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#6EFF8724",
                  "align-items": "center",
                  color: "#00A41C",
                }}
                className="btnEnable"
              >
                Refund successful
              </button>
            )}
          </div>

          <div style={{ position: "absolute", bottom: "72px", width: "100%" }}>
            <p
              style={{
                "text-align": "center",
                fontSize: "10px",
                "margin-top": "11px",
                color: "rgba(56, 79, 111, 0.56)",
                position: "absolute",
                top: "18px",
                left: "76px",
              }}
            >
              for more information about your order, use our mobile app.{" "}
            </p>

            <div style={{ display: "flex", padding: "9%", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "50", height: "41px", padding: "5%" }}
                  src={playstore}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>GET IT ON</p>
                  <p style={{ "font-size": "13px" }}>Google play</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "42px", height: "41px", padding: "5%" }}
                  src={apple}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>DOWNLOAD ON THE </p>
                  <p style={{ "font-size": "13px" }}>Apple Store</p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "absolute", bottom: "36px", left: "36%" }}>
            <p
              style={{
                "font-size": "10px",
                color: "#384F6F",
                "font-weight": "500",
                "margin-left": "9px",
              }}
            >
              For more Help{" "}
            </p>
            <div style={{ display: "flex", "align-items": "center" }}>
              <a
                style={{ textDecoration: "none" }}
                href="https://api.whatsapp.com/send/?phone=917289005034&text=Need+help&type=phone_number&app_absent=0"
              >
                <p style={{ color: "#0075FF", textDecoration: "underline" }}>
                  Contact Us!
                </p>
              </a>

              <img style={{ width: "10%" }} src={whatsapp} />
            </div>
          </div>
        </div>
      )}

      {props.orderSuccessflag && (
        <div className="modal" style={{ backgroundColor: "#ffff" }}>
          <TopNav />
          <div
            style={{
              padding: "5%",
              display: "flex",
              "justify-content": "space-around",
            }}
          >
            <img style={{ width: "80%" }} src={ordersuccess} />
          </div>

          <h1
            style={{
              fontSize: "24px",
              fontWeight: "500",
              "margin-left": "17%",
            }}
          >
            Your Order is Successful
          </h1>

          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {props.orderNotFoundREflag && (
              <button
                style={{
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#6EFF8724",
                  "align-items": "center",
                  color: "#00A41C",
                }}
                className="btnEnable"
              >
                Refund successful
              </button>
            )}
          </div>
          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {/*     <button style={{display:"flex","justify-content":"center","background-color":"#6EFF8724" ,"align-items":"center",color:"#00A41C"    }} className="btnEnable" >
  Refund successful
      </button> */}
          </div>

          <div style={{ position: "absolute", bottom: "72px", width: "100%" }}>
            <p
              style={{
                "text-align": "center",
                fontSize: "10px",
                "margin-top": "11px",
                color: "rgba(56, 79, 111, 0.56)",
                position: "absolute",
                top: "18px",
                left: "76px",
              }}
            >
              for more information about your order, use our mobile app.{" "}
            </p>

            <div style={{ display: "flex", padding: "9%", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "50", height: "41px", padding: "5%" }}
                  src={playstore}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>GET IT ON</p>
                  <p style={{ "font-size": "13px" }}>Google play</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "42px", height: "41px", padding: "5%" }}
                  src={apple}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>DOWNLOAD ON THE </p>
                  <p style={{ "font-size": "13px" }}>Apple Store</p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "absolute", bottom: "36px", left: "36%" }}>
            <p
              style={{
                "font-size": "10px",
                color: "#384F6F",
                "font-weight": "500",
                "margin-left": "9px",
              }}
            >
              For more Help{" "}
            </p>
            <div style={{ display: "flex", "align-items": "center" }}>
              <a
                style={{ textDecoration: "none" }}
                href="https://api.whatsapp.com/send/?phone=917289005034&text=Need+help&type=phone_number&app_absent=0"
              >
                <p style={{ color: "#0075FF", textDecoration: "underline" }}>
                  Contact Us!
                </p>
              </a>

              <img style={{ width: "10%" }} src={whatsapp} />
            </div>
          </div>
        </div>
      )}

      {props.orderNotFoundflag && (
        <div className="modal" style={{ backgroundColor: "#ffff" }}>
          <TopNav />
          <div
            style={{
              padding: "5%",
              display: "flex",
              "justify-content": "space-around",
            }}
          >
            <img style={{ width: "50%" }} src={ordernotfound} />
          </div>

          <h1
            style={{
              fontSize: "24px",
              fontWeight: "500",
              "margin-left": "17%",
            }}
          >
            Unable to Find Your Order
          </h1>

          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {props.orderNotFoundREflag && (
              <button
                style={{
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#6EFF8724",
                  "align-items": "center",
                  color: "#00A41C",
                }}
                className="btnEnable"
              >
                Refund successful
              </button>
            )}
          </div>

          <div style={{ position: "absolute", bottom: "72px", width: "100%" }}>
            <p
              style={{
                "text-align": "center",
                fontSize: "10px",
                "margin-top": "11px",
                color: "rgba(56, 79, 111, 0.56)",
                position: "absolute",
                top: "18px",
                left: "76px",
              }}
            >
              for more information about your order, use our mobile app.{" "}
            </p>

            <div style={{ display: "flex", padding: "9%", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "50", height: "41px", padding: "5%" }}
                  src={playstore}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>GET IT ON</p>
                  <p style={{ "font-size": "13px" }}>Google play</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "42px", height: "41px", padding: "5%" }}
                  src={apple}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>DOWNLOAD ON THE </p>
                  <p style={{ "font-size": "13px" }}>Apple Store</p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "absolute", bottom: "36px", left: "36%" }}>
            <p
              style={{
                "font-size": "10px",
                color: "#384F6F",
                "font-weight": "500",
                "margin-left": "9px",
              }}
            >
              For more Help{" "}
            </p>
            <div style={{ display: "flex", "align-items": "center" }}>
              <a
                style={{ textDecoration: "none" }}
                href="https://api.whatsapp.com/send/?phone=917289005034&text=Need+help&type=phone_number&app_absent=0"
              >
                <p style={{ color: "#0075FF", textDecoration: "underline" }}>
                  Contact Us!
                </p>
              </a>

              <img style={{ width: "10%" }} src={whatsapp} />
            </div>
          </div>
        </div>
      )}

      {props.orderPlacedflag && (
        <div className="modal" style={{ backgroundColor: "#ffff" }}>
          <TopNav />
          <div
            style={{
              padding: "5%",
              display: "flex",
              "justify-content": "space-around",
            }}
          >
            <img style={{ width: "80%" }} src={orderPlaced} />
          </div>

          <h1
            style={{
              fontSize: "24px",
              fontWeight: "500",
              "margin-left": "25%",
            }}
          >
            Your Order is Placed
          </h1>

          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {props.orderNotFoundREflag && (
              <button
                style={{
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#6EFF8724",
                  "align-items": "center",
                  color: "#00A41C",
                }}
                className="btnEnable"
              >
                Refund successful
              </button>
            )}
          </div>
          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {props.orderNotFoundREflag && (
              <button
                style={{
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#6EFF8724",
                  "align-items": "center",
                  color: "#00A41C",
                }}
                className="btnEnable"
              >
                Refund successful
              </button>
            )}
          </div>
          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            <p>Kindly pickup you order</p>
          </div>

          <div style={{ position: "absolute", bottom: "72px", width: "100%" }}>
            <p
              style={{
                "text-align": "center",
                fontSize: "10px",
                "margin-top": "11px",
                color: "rgba(56, 79, 111, 0.56)",
                position: "absolute",
                top: "18px",
                left: "76px",
              }}
            >
              for more information about your order, use our mobile app.{" "}
            </p>

            <div style={{ display: "flex", padding: "9%", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "50", height: "41px", padding: "5%" }}
                  src={playstore}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>GET IT ON</p>
                  <p style={{ "font-size": "13px" }}>Google play</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "42px", height: "41px", padding: "5%" }}
                  src={apple}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>DOWNLOAD ON THE </p>
                  <p style={{ "font-size": "13px" }}>Apple Store</p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "absolute", bottom: "36px", left: "36%" }}>
            <p
              style={{
                "font-size": "10px",
                color: "#384F6F",
                "font-weight": "500",
                "margin-left": "9px",
              }}
            >
              For more Help{" "}
            </p>
            <div style={{ display: "flex", "align-items": "center" }}>
              <a
                style={{ textDecoration: "none" }}
                href="https://api.whatsapp.com/send/?phone=917289005034&text=Need+help&type=phone_number&app_absent=0"
              >
                <p style={{ color: "#0075FF", textDecoration: "underline" }}>
                  Contact Us!
                </p>
              </a>

              <img style={{ width: "10%" }} src={whatsapp} />
            </div>
          </div>
        </div>
      )}

      {props.orderFailedflag && (
        <div className="modal" style={{ backgroundColor: "#ffff" }}>
          <TopNav />
          <div
            style={{
              padding: "5%",
              display: "flex",
              "justify-content": "space-around",
            }}
          >
            <img style={{ width: "50%" }} src={Failed} />
          </div>
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "500",
              "margin-left": "24%",
            }}
          >
            your order has Failed
          </h1>

          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {props.orderFailedREflag && (
              <button
                style={{
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#6EFF8724",
                  "align-items": "center",
                  color: "#00A41C",
                }}
                className="btnEnable"
              >
                Refund successful
              </button>
            )}
          </div>

          <div style={{ position: "absolute", bottom: "72px", width: "100%" }}>
            <p
              style={{
                "text-align": "center",
                fontSize: "10px",
                "margin-top": "11px",
                color: "rgba(56, 79, 111, 0.56)",
                position: "absolute",
                top: "18px",
                left: "76px",
              }}
            >
              for more information about your order, use our mobile app.{" "}
            </p>

            <div style={{ display: "flex", padding: "9%", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "50", height: "41px", padding: "5%" }}
                  src={playstore}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>GET IT ON</p>
                  <p style={{ "font-size": "13px" }}>Google play</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "42px", height: "41px", padding: "5%" }}
                  src={apple}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>DOWNLOAD ON THE </p>
                  <p style={{ "font-size": "13px" }}>Apple Store</p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "absolute", bottom: "36px", left: "36%" }}>
            <p
              style={{
                "font-size": "10px",
                color: "#384F6F",
                "font-weight": "500",
                "margin-left": "9px",
              }}
            >
              For more Help{" "}
            </p>
            <div style={{ display: "flex" }}>
              <p style={{ color: "#0075FF", textDecoration: "underline" }}>
                CoSuccessfulntact Us!
              </p>
              <img style={{ width: "10%" }} src={whatsapp} />
            </div>
          </div>
        </div>
      )}
      {false && (
        <div className="modal" style={{ backgroundColor: "#ffff" }}>
          <TopNav />
          <div
            style={{
              padding: "5%",
              display: "flex",
              "justify-content": "space-around",
            }}
          >
            <img style={{ width: "50%" }} src={abandoned} />
          </div>
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "500",
              "margin-left": "26%",
            }}
          >
            Your Order Has Abandoned
          </h1>

          <div
            style={{
              display: "flex",
              "justify-content": "space-evenly",
              "margin-top": "5%",
            }}
          >
            {
              <button
                style={{
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#6EFF8724",
                  "align-items": "center",
                  color: "#00A41C",
                }}
                className="btnEnable"
              >
                Refund successful
              </button>
            }
          </div>

          <div style={{ position: "absolute", bottom: "72px", width: "100%" }}>
            <p
              style={{
                "text-align": "center",
                fontSize: "10px",
                "margin-top": "11px",
                color: "rgba(56, 79, 111, 0.56)",
                position: "absolute",
                top: "18px",
                left: "76px",
              }}
            >
              for more information about your order, use our mobile app.{" "}
            </p>

            <div style={{ display: "flex", padding: "9%", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "50", height: "41px", padding: "5%" }}
                  src={playstore}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>GET IT ON</p>
                  <p style={{ "font-size": "13px" }}>Google play</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  "box-shadow": " 2px 5px 23px 0px #0000000D",
                  width: "100%",
                  margin: "5%",
                }}
                onClick={(e) => {
                  props.handleShowQr(e);
                }}
              >
                <img
                  style={{ width: "42px", height: "41px", padding: "5%" }}
                  src={apple}
                />
                <div style={{ "margin-top": "8%" }}>
                  <p style={{ "font-size": "7px" }}>DOWNLOAD ON THE </p>
                  <p style={{ "font-size": "13px" }}>Apple Store</p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ position: "absolute", bottom: "36px", left: "36%" }}>
            <p
              style={{
                "font-size": "10px",
                color: "#384F6F",
                "font-weight": "500",
                "margin-left": "9px",
              }}
            >
              For more Help{" "}
            </p>
            <div style={{ display: "flex", "align-items": "center" }}>
              <a
                style={{ textDecoration: "none" }}
                href="https://api.whatsapp.com/send/?phone=917289005034&text=Need+help&type=phone_number&app_absent=0"
              >
                <p style={{ color: "#0075FF", textDecoration: "underline" }}>
                  Contact Us!
                </p>
              </a>

              <img style={{ width: "10%" }} src={whatsapp} />
            </div>
          </div>
        </div>
      )}

      {false && (
        <div className="modal" style={{ backgroundColor: "#ffff" }}>
          <TopNav />
          <h1
            style={{
              color: "#384F6F",
              fontSize: "16px",
              fontWeight: "500",
              "padding-left": "5%",
            }}
          >
            How can we help you
          </h1>

          <div className="card card-1">
            <div style={{ display: "flex", "justify-content": "space-around" }}>
              <img style={{ width: "36%", padding: "7%" }} src={faqhelp} />
            </div>
            <div style={{ display: "flex", "justify-content": "space-around" }}>
              <p style={{ color: "#384F6F", fontSize: "14px" }}>FAQ</p>
            </div>

            <div
              style={{
                display: "flex",
                "justify-content": "space-around",
                "margin-top": "5%",
              }}
            >
              <p style={{ fontSize: "10px", color: "#718198" }}>
                "Helpful Information at Your Fingertips: Our FAQ Resource"
              </p>
            </div>
          </div>

          <div className="card card-1">
            <div style={{ display: "flex", "justify-content": "space-around" }}>
              <img style={{ width: "36%", padding: "7%" }} src={food} />
            </div>
            <div style={{ display: "flex", "justify-content": "space-around" }}>
              <p style={{ color: "#384F6F", fontSize: "14px" }}>
                Check Order Status
              </p>
            </div>

            <div
              style={{
                display: "flex",
                "justify-content": "space-around",
                "margin-top": "5%",
              }}
            >
              <p style={{ fontSize: "10px", color: "#718198" }}>
                "Order Status Updates: Stay Informed About Your Purchase"
              </p>
            </div>
          </div>

          <div className="card card-1">
            <div style={{ display: "flex", "justify-content": "space-around" }}>
              <img style={{ width: "36%", padding: "7%" }} src={headphone} />
            </div>
            <div style={{ display: "flex", "justify-content": "space-around" }}>
              <p style={{ color: "#384F6F", fontSize: "14px" }}>
                Contact Customer Service
              </p>
            </div>

            <div
              style={{
                display: "flex",
                "justify-content": "space-around",
                "margin-top": "5%",
              }}
            >
              <p style={{ fontSize: "10px", color: "#718198" }}>
                "Need Help? We're Here for You: Contact Our Customer Service
                Team"
              </p>
            </div>
          </div>
        </div>
      )}

      {props.imageUploadUI && (
        <div className="modal" style={{ backgroundColor: "#ffff" }}>
          <TopNav />
          <h1
            style={{
              color: "#384F6F",
              fontSize: "16px",
              fontWeight: "500",
              "padding-left": "5%",
            }}
          >
            Check Order Status
          </h1>
          <p style={{ color: "#718198", fontSize: "13px", padding: "5%" }}>
            Upload the screenshot of your payment transaction.
          </p>

          <div
            style={{
              width: "90%",
              borde: "1px soild",
              height: "60%",
              border: "2px dotted",
              "margin-left": "5%",
              "border-radius": "5%",
              "border-radius": "5%",
            }}
          >
            <div
              style={{
                display: "flex",
                "align-items": "center",
                "justify-content": "space-around",
                "margin-top": "40%",
              }}
            >
              <div>
                {!props.previewImage && (
                  <label
                    htmlFor="fileInput"
                    className="button-17"
                    style={{
                      width: "100%",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 46%)",
                      borderRadius: "7px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Click to browse
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={props.UploadImgFun}
                    />
                  </label>
                )}
                {props.previewImage && (
                  <div style={{ "margin-left": "29%" }}>
                    <img
                      src={props.previewImage}
                      style={{
                        width: "59%",
                        marginTop: "-51%",
                        marginLeft: "3%",
                      }}
                    />
                    <label
                      htmlFor="fileInput"
                      className="button-17"
                      style={{
                        //boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
                        boxShadow:"rgb(0 0 0 / 46%) 0px 4px 12px",
                        borderRadius: "7px",
                        fontSize: "14px",
                        cursor: "pointer",
                        bottom: "30%",
                        left: "52%",
                        position: "absolute",
                      }}
                    >
                      Change Image
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={props.UploadImgFun}
                      />
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              "margin-top": "9%",
            }}
          >
            <button
              className="button-17"
              style={{
                width: "90%",
                backgroundColor: "#00B177",
                color: "white",
              }}
              onClick={props.UploadImgBtn}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HelpAndSupport;
