export const FETCH_USER_DETAILS_REQUEST="FETCH_USER_DETAILS_REQUEST"
export const FETCH_USER_DETAILS_SUCCESS="FETCH_USER_DETAILS_SUCCESS"
export const FETCH_USER_DETAILS_FAILURE="FETCH_USER_DETAILS_FAILURE"


export const fetchUserDetailsRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_USER_DETAILS_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchUserDetailsSuccess=(UserDetails)=>{
return{
    type:FETCH_USER_DETAILS_SUCCESS,
    UserDetails:UserDetails
}
}

export const fetchUserDetailsFailure=(error)=>{
    return{
    type:FETCH_USER_DETAILS_FAILURE,
    error:error
    }
}


////////////////////////////////////////////
export const FETCH_USER_LOGOUT_REQUEST="FETCH_USER_LOGOUT_REQUEST"
export const FETCH_USER_LOGOUT_SUCCESS="FETCH_USER_LOGOUT_SUCCESS"
export const FETCH_USER_LOGOUT_FAILURE="FETCH_USER_LOGOUT_FAILURE"


export const fetchLogoutCurrentDeviceRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_USER_LOGOUT_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchLogoutCurrentDeviceSuccess=(UserDetails)=>{
return{
    type:FETCH_USER_LOGOUT_SUCCESS,
    UserDetails:UserDetails
}
}

export const fetchLogoutCurrentDeviceFailure=(error)=>{
    return{
    type:FETCH_USER_LOGOUT_FAILURE,
    error:error
    }
}

//////////////////////////////////////////////////////////////////////
////////////////////////////////////////////
export const FETCH_USER_ALL_LOGOUT_REQUEST="FETCH_USER_ALL_LOGOUT_REQUEST"
export const FETCH_USER_ALL_LOGOUT_SUCCESS="FETCH_USER_ALL_LOGOUT_SUCCESS"
export const FETCH_USER_ALL_LOGOUT_FAILURE="FETCH_USER_ALL_LOGOUT_FAILURE"


export const fetchLogoutAllDeviceRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_USER_ALL_LOGOUT_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchLogoutAllDeviceSuccess=(UserDetails)=>{
return{
    type:FETCH_USER_ALL_LOGOUT_SUCCESS,
    UserDetails:UserDetails
}
}

export const fetchLogoutAllDeviceFailure=(error)=>{
    return{
    type:FETCH_USER_ALL_LOGOUT_FAILURE,
    error:error
    }
}


//////////////////////////////////////////////////////////////////////
////////////////////////////////////////////
export const FETCH_USER_LOGIN_COUNT_REQUEST="FETCH_USER_ALL_LOGOUT_REQUEST"
export const FETCH_USER_LOGIN_COUNT_SUCCESS="FETCH_USER_LOGIN_COUNT_SUCCESS"
export const FETCH_USER_LOGIN_COUNT_FAILURE="FETCH_USER_LOGIN_COUNT_FAILURE"


export const fetchLogInDeviceCountRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_USER_LOGIN_COUNT_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchLogInDeviceCountSuccess=(UserDetails)=>{
return{
    type:FETCH_USER_LOGIN_COUNT_SUCCESS,
    UserDetails:UserDetails
}
}

export const fetchLogInDeviceCountFailure=(error)=>{
    return{
    type:FETCH_USER_LOGIN_COUNT_FAILURE,
    error:error
    }
}




//
