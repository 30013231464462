import { Link, useNavigate } from "react-router-dom";
import TagpayIcon from "../../assets/tagpay.png"
import backArrow from '../../assets/backArrow.png'
import Addbtn from '../../assets/addbtn.png'
import nextArrow from '../../assets/nextArrow.png'
import WaitingLoder from "../WaitingLoader/component";
const Tagpay=(props)=>{
const navigate=useNavigate()
const goToTagHistory=(e,uid)=>{
  localStorage.setItem("TagUUID",uid)
  navigate(`/taghistory/${uid}`)
  
}

  const TopNav=()=>{


    return(  
      
      <>
     {props.loader&&<WaitingLoder/>}
      <div className="_2456r">
    <div className="_3nxa7" data-testid="listing-header-location">
      <button
        className="_1NdRR"
        aria-label="Selected address is Delhi, India; Double tap to change address."
        id="change-address-btn-id"
      >
        <div style={{display:"flex","align-items":"center"}}>
        <Link to={'/profile'}><img  style={{height:"23px"}}src={backArrow}/></Link>
       <h3 style={{"margin-left": "10px"}}>Tag List</h3>
       <div style={{marginLeft:"10px"}}>
       
        </div>
        </div>
      </button>
   
        <span className="icon-Offers-outline _2P0Ff" />
      <Link  style={{textDecoration:"none"}}to="/addtag"><div style={{display:"flex","margin-right":"-23px", "align-items":"center"}}>
         <img style={{ "margin-right":"5px",width:"55px"}}src={Addbtn}/>
       <h3 style={{width:"200%"}}>ADD TAG</h3>
      </div>
      </Link>
     
    </div>
    </div>
    </>
    )
  }

  
    return(
    <>
       {true?
    <>
     
     <div
        style={{
          background:
            "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
            //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.PreCardItems[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.PreCardItems[0]&&"8.5rem",
        }}
       className={props.login|| props.loginOtp?"h-screen overflow-y-auto ":" pb-36 overflow-y-auto h-screen"}
    
    
      >
        <div className="_2yoTv ns7Wo">
          <div>
            <TopNav/>
           {/*  <TopNavBar title={'Tag List'}  /> */}

            <div>
              <div className="_2NVhy _3hjyL">
               
              <div
                          className="styles_slider__podEu"
                          data-testid="grid-slider"
                          style={{marginTop:"20%"}}
                        >
                   
                       
                         {props.tagList.map((el, index) => {
                          //console.log("Ellll--->",el)
                         return(
                        
                          <>
                           {el.active&& <div
                            className="styles_row__1ivP1 shadow-box"
                            data-testid="grid-row"
                            style={{
                              marginTop: "4%",
                              "margin-right": "12px",
                              "margin-left": "12px",
                             
                              height: "181px",
                              "border-radius":" 29px",   
                              background:"white"                   
                            }}
                          >
                            <div
                              className="styles_slide__2c207"
                              style={{ marginRight: 0 }}
                            >
                              <div>
                                <div
                                  className="styles_cardWithPadding__JE1QE"
                                  data-testid="home-restaurant-card-wrapper"
                                  style={{
                                    width: "calc((100vw - 0px) - 12px)",
                                  }}
                                >
                                  <a
                                    data-testid="resturant-card-anchor-container"
                                    className="styles_container__fLC0R"
                                    aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                    tabIndex={0}
                                    role="button"
                                  >
                                    <div
                                      className="styles_imgContainer__1uHo5"
                                      aria-hidden="true"
                                    ></div>
                                <div>
                                  <div style={{display:"flex","justify-content": "space-between",marginRight:"18px","margin-top": "17px"}}>
                                    <div style={{display:"flex"}}>
                                        <img style={{height:"60px"}} src={TagpayIcon}/>
                                   <div style={{"margin-left":"11px"}}>
                                   <h2>Payment Method</h2>
                                   <p style={{color:"#718198"}}>Tag ID: {el.uid}</p>
                                   <p style={{fontSize:"20px",fontWeight:"700"}}>₹{el.balance}</p>
                                    </div>     
                                  
                                  
                                   </div>
                                   
                                  </div>
                                  <div style={{display:"flex","justify-content": "space-between","margin-top": "17px",marginRight:"11px","align-items":"center"}}>
                                  <div  onClick={(e)=>{goToTagHistory(e,el.uid)}}  >
                               <button style={{display: "flex",
"width": "145px",
"padding":"9px 16px",
"justify-content": "center",
"align-items":"center",
"border-radius": "20px",
color:"var(--primary-5, #00B177)",
background: "white",  
border:"1px solid",
gap:"8px"}} >Recharge</button></div>
                                  <div  onClick={(e)=>{goToTagHistory(e,el.uid)}}  >
                               <button style={{display: "flex",
"width": "145px",
"padding":"9px 16px",
"justify-content": "center",
"align-items":"center",
"border-radius": "20px",
color:"white",
background: "var(--primary-5, #00B177)",
gap:"8px"}} >VIEW</button></div>
                                  </div>

                                </div>
                                  </a>
                                </div>
                                <div />
                              </div>
                            </div>
                          </div>}
                        
                          </>
                          )
                          })}
                          <div style={{marginTop:"10px"}}>
                           
                          <p style={{fontSize:"14px",textAlign:"center",color:"#718198"}}>Or</p>
                          <div style={{display:"flex","justify-content": "space-around"}}>
                          <div style={{display:"flex","align-items": "center"}}>
                          <Link to="/tagdirrecharge" style={{fontSize:"14px",textAlign:"center",color:"#718198"}}>Recharge Another Tag</Link>
                         
                          <img style={{width:"10px",height:"10px",marginLeft:"5px"}} src={nextArrow}/>
                          </div>
                          </div>
                          </div>
                        </div>
                        
              </div>
            </div>
          </div>
        </div>
      </div>
    </>:<></>}
    </>
    )
}

export default Tagpay;