import { FETCH_ORDER_HISTORY_LIST_FAILURE, FETCH_ORDER_HISTORY_LIST_REQUEST, FETCH_ORDER_HISTORY_LIST_SUCCESS } from "./action";


const initialState = {
    //////////////VM LIST VARIABLES///////////////
      OrederHistoryList: [],
      loading_Order_history:false,
      error_Order_history:null
     
}    



const OrderHistoryReducer = (state = initialState, action) => {
    console.log("action")
    switch (action.type) {
//////////////////////SWITCH CASES FOR ACTIVE ORDERS //////////////////////////////////////
case FETCH_ORDER_HISTORY_LIST_REQUEST:
    return {
      ...state,
      
      loading_Order_history:false,
      error_Order_history:null
    };

  case FETCH_ORDER_HISTORY_LIST_SUCCESS: 
    return {
      ...state,
      
      OrederHistoryList: action&&action.OrderHistory&&action.OrderHistory.data&&action.OrderHistory.data.response,
      loading_Order_history:false,
      error_Order_history:null
    
    
    };

  case FETCH_ORDER_HISTORY_LIST_FAILURE: 
  return {
    ...state,
 
      loading_Order_history:false,
      error_Order_history:action.error
   
  }
  
  
  
  
  
  
    default:
      return state;

  }
};

export default OrderHistoryReducer;
