import React from "react";
import { Link } from "react-router-dom";
import backArrow from "../../assets/backArrow.png";
import Addbtn from "../../assets/addbtn.png";
import nextArrow from "../../assets/nextArrow.png";
import faqArrow  from '../../assets/faqArrow.png'
import { useState } from "react";
import '../FaqDetails/faqdetail.css'
const FaqDetails = (props) => {


  const [showParagraphs, setShowParagraphs] = useState(
    Array(props&&props.topicDetailsList&&props.topicDetailsList.length).fill(false) // Initialize an array of booleans for each item
  );

  const toggleParagraph = (index) => {
    const updatedShowParagraphs = [...showParagraphs];
    updatedShowParagraphs[index] = !showParagraphs[index];
    setShowParagraphs(updatedShowParagraphs);
  };


  const viewMoreButtonStyles = {
    display: "flex",
    width: "90px",
    padding: "0px 12px 0px 16px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "10px",
    height:"50px",
    border: "1px solid rgba(148, 163, 184, 0.54)",
    background: "rgba(148, 163, 184, 0.06)",
  };

const Steps=(text)=>{
  const steps =text[0]&&text.split("\n").filter((line) => line.trim() !== "");


return steps
}

  const TopNav = () => {
    return (
      <>
        <div className="_2456r">
          <div className="_3nxa7" data-testid="listing-header-location">
            <button
              className="_1NdRR"
              aria-label="Selected address is Delhi, India; Double tap to change address."
              id="change-address-btn-id"
            >
              <div style={{ display: "flex", "align-items": "center" }}>
                <Link to={"/faq"}>
                  <img style={{ height: "23px" }} src={backArrow} />
                </Link>
                <h3 style={{ "margin-left": "10px" }}>Help & Support</h3>
                <div style={{ marginLeft: "10px" }}></div>
              </div>
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>


      <TopNav />

      <div
        style={{
          background:
            "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
          //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.PreCardItems[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.PreCardItems[0]&&"8.5rem",
        }}
        className={"h-screen overflow-y-auto pd-36"}
      >
        <div style={{ padding: "5%", marginTop: "77px" }}>
          <h1
            style={{
              fontSize: "14px",
              fontWeight: "600",
              padding: "2%",
              letterSpacing: "2%",
              marginBottom: "5%",
              padding: "2%",
              color: "#333F4E",
            }}
          >
            Need help with orders?
          </h1>

      



            <div
            style={{
              width: "100%",
              background: "white",
             // height: "195px",
              top: "146px",
              left: "11px",
              "border-radius": "18px",
            }}
          >
     {props&&props.topicDetailsList.map((el, index) => (
        <div key={index}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
             
             
            }}
          >
            <div style={{width:"80%"}}>
            <h1
              style={{
                padding: "5%",
                fontSize: "12px",
                fontWeight: "500",
                color: "#384F6FC4",
              }}
            >
              {el.question}
            </h1>
            </div>
            <img
              style={{
                width: "10px",
                height: "10px",
                transform: showParagraphs[index] ?"rotate(-90deg)":"",
                cursor: "pointer", "margin-left":"5%"
              }}
              src={
                faqArrow
              }
              onClick={() => toggleParagraph(index)}
            />
          </div>
          {showParagraphs[index] && (
            <>{el.isGif?
         <><div className="image-modal">
      <div className="image-modal-content">
      <div  onClick={() => toggleParagraph(index)} className="close-button-div"><span className="close-button" /* onClick={onClose} */>
          &times;
        </span>
        </div>
       { <img  style={{"max-width":"100%","height":"100%","border-radius":"15px"}}src={showParagraphs[index]?`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.solution}`:""} alt="Modal" />}
      </div>
    </div></>
    :   <>
    <p
      style={{
        fontSize: '12px',
        paddingLeft: '5%',
        paddingTop: '5%',
        paddingBottom: '5%',
        paddingRight: '5%',
      }}
    >
    {el.solution}
    </p>
    
    </>}
    <a style={{"color":"#718198",textDecoration:"none"}} href={`https://api.whatsapp.com/send/?phone=917289005034&text=Hello%2C I+need+assistance -->${el.question} &type=phone_number&app_absent=0`}> <p style={{padding:"5%",color:"#0075FF",textDecoration:"underline",fontWeight:"400",fontSize:"12px"}}>Need more assistance? </p></a>
</>
           
          )}
          <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
            <hr style={{ border: '1px solid #384F6F17' }}></hr>
          </div>
        </div>
      ))}
        


            
          </div>
        </div>
   
      </div>
    </>
  );
};
export default FaqDetails;
