import {
 
    FETCH_GLOBAL_SEARCH_REQUEST,
    FETCH_GLOBAL_SEARCH_SUCCESS,
    FETCH_GLOBAL_SEARCH_FAILURE,
    FETCH_VM_WISE_INVENTROY_REQUEST,
    FETCH_VM_WISE_INVENTROY_SUCCESS,
    FETCH_VM_WISE_INVENTROY_FAILURE,
    FETCH_ADD_TO_CART_REQUEST,
    FETCH_ADD_TO_CART_FAILURE,
    FETCH_ADD_TO_CART_SUCCESS,
    FETCH_PRE_CART__GLOBAL_REQUEST,
    FETCH_PRE_CART__GLOBAL_SUCCESS,
    FETCH_PRE_CART__GLOBAL_FAILURE,
    FETCH_ACTIVE_ORDERS_GLOBAL_REQUEST,
    FETCH_ACTIVE_ORDERS_GLOBAL_SUCCESS,
    FETCH_ACTIVE_ORDERS_GLOBAL_FAILURE
  } from "./action";
  
  const initialState = {

  
    ////////////// GLOBAL SEARCH VARIABLES//////////////
    GlobalSearchData:{
        
        CategoryDetails:[],
        ItemDetails:[],
        MachineDetails:[],
        loading_Global_Search: false,
        error_Global_Search: null,
        },

  VmWiseInventoryData:{
        
            VmWiseInventoryList:[],
            loading_Vm_Wise_Inventory:false,
            error_Vm_Wise_Inventory:null

            
            },

AddToCartData:{
        
              AddToCartList:[],
              loading_Add_To_Cart:false,
              error_Add_To_Cart:null
  
              
              },

PreCartGlobalData:{
          
                PreCartListGlobal:[],
                PreCartMachineID:null,
                loading_Pre_Cart_Global: false,
                error_Global_Cart_Global: null,
                }
            ,
                

                

    
        
ActiveOrdersGlobalData: {
        
                ActiveOrderItemsListGlobal: [],
               
                loading_active_orders_Global: false,
                error_active_orders_Global: null,
              }
  
  };
  
  const globalSearchReducer = (state = initialState, action) => {
    console.log("preCartdata globalSearchReducer action---",action);
  
    switch (action.type) {

  
  
  //////////////////////SWITCH CASES FOR PREVIOUS CARD DATA//////////////////////////////////////
  case FETCH_GLOBAL_SEARCH_REQUEST:
      return {
        GlobalSearchData:{
            ...state.GlobalSearchData,
            loading_Global_Search: false,
            error_Global_Search: null,

        }
      };
  
    case FETCH_GLOBAL_SEARCH_SUCCESS: 
      return {
        ...state,
       

        GlobalSearchData:{
            ...state.GlobalSearchData,
            CategoryDetails:action.GlobalSearchData&&action.GlobalSearchData.data.data.categoryDetails,
            ItemDetails:action.GlobalSearchData&&action.GlobalSearchData.data.data.itemDetails,
            MachineDetails:action.GlobalSearchData&&action.GlobalSearchData.data.data.machineDetails,
           
           
            loading_Global_Search: false,
            error_Global_Search: null,
            },
            
   
      
      
      };
  
    case FETCH_GLOBAL_SEARCH_FAILURE: 
  
      return {

        ...state,
        GlobalSearchData:{
            ...state.GlobalSearchData,
        loading_Global_Search: false,
        error_Global_Search: action.error,
        }
      };
   
    
    

  //////////////////////SWITCH CASES FOR VM WISE INVENTORY DATA//////////////////////////////////////
  
  
  case FETCH_VM_WISE_INVENTROY_REQUEST:
      return {

        ...state,
        VmWiseInventoryData:{
            
            loading_Vm_Wise_Inventory: false,
            error_Vm_Wise_Inventory: null,

        }
      };
  
    case FETCH_VM_WISE_INVENTROY_SUCCESS: 
      return {

        ...state,
    VmWiseInventoryData:{
          
            VmWiseInventoryList:action.VmWiseInventory&&action.VmWiseInventory.data.data,
            loading_Vm_Wise_Inventory: false,
            error_Vm_Wise_Inventory: null,
            },
            
   
      
      
      };
  
    case FETCH_VM_WISE_INVENTROY_FAILURE: 
  
      return {

        ...state,
        VmWiseInventoryData:{
          
            loading_Vm_Wise_Inventory: false,
            error_Vm_Wise_Inventory: action.error,
     
        }
    };
   
    






  //////////////////////SWITCH CASES FOR ADD TO CART  DATA//////////////////////////////////////
  
  
  case FETCH_ADD_TO_CART_REQUEST:
      return {

        ...state,
        AddToCartData:{
            
            loading_Add_To_Cart: false,
            error_Add_To_Cart: null,

        }
      };
  
    case FETCH_ADD_TO_CART_SUCCESS: 
      return {

        ...state,
    AddToCartData:{
          
            AddToCartList:action&&action.addtocartRes&&action.addtocartRes,
            loading_Add_To_Cart: false,
            error_Add_To_Cart: null,
            },
            
   
      
      
      };
  
    case FETCH_ADD_TO_CART_FAILURE: 
  
      return {
        ...state,
        AddToCartData:{
          
            loading_Add_To_Cart: false,
            error_Add_To_Cart: action.error,
     
        }
    };







  //////////////////////SWITCH CASES FOR PREVIOUS  CART  DATA//////////////////////////////////////
  
  
  case FETCH_PRE_CART__GLOBAL_REQUEST:
      return {

        ...state,
        PreCartGlobalData:{
            
            loading_Pre_Cart_Global: false,
            error_Global_Cart_Global: null,

        }
      };
  
    case FETCH_PRE_CART__GLOBAL_SUCCESS: 
      return {

        ...state,
        PreCartGlobalData:{
          
          PreCartListGlobal:action&&action.preCartdataGlobal.data.data.items,
          PreCartMachineID:action&&action.preCartdataGlobal.headers.machineid,
            loading_Pre_Cart_Global: false,
            error_Global_Cart_Global: null,
            },
            
   
      
      
      };
  
    case FETCH_PRE_CART__GLOBAL_FAILURE: 
  
      return {
        ...state,
        PreCartGlobalData:{
          
          loading_Pre_Cart_Global: false,
          error_Global_Cart_Global: action.error,
     
        }
    };


    
//////////////////////SWITCH CASES FOR ACTIVE ORDERS //////////////////////////////////////
case FETCH_ACTIVE_ORDERS_GLOBAL_REQUEST:
    return {
      ...state,
      ActiveOrdersGlobalData: {
        
        
       
        loading_active_orders_Global: false,
        error_active_orders_Global: null,
      },
    }

  case FETCH_ACTIVE_ORDERS_GLOBAL_SUCCESS: 
    return {
      ...state,
      
      ActiveOrdersGlobalData: {
        
        ActiveOrderItemsListGlobal: action&& action.ActiveOrdersGlobal&&action.ActiveOrdersGlobal.data&&action.ActiveOrdersGlobal.data.data,
       
        loading_active_orders_Global: false,
        error_active_orders_Global: null,
      },
     
    
    
    };

  case FETCH_ACTIVE_ORDERS_GLOBAL_FAILURE: 
  return {
    ...state,
    ActiveOrdersGlobalData: {
    
      loading_active_orders_Global: true,
      error_active_orders_Global: action.error,
    },
   
  }
  
    
    
      default:
        return state;
  
    }
  };
  
  export default globalSearchReducer;
  