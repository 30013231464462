///////////////////////////////////ACTIVE ORDERS  ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_GLOBAL_SEARCH_REQUEST="FETCH_GLOBAL_SEARCH_REQUEST"
export const FETCH_GLOBAL_SEARCH_SUCCESS="FETCH_GLOBAL_SEARCH_SUCCESS"
export const FETCH_GLOBAL_SEARCH_FAILURE="FETCH_GLOBAL_SEARCH_FAILURE"


 
export const fetchGlobalSearchRequest=(data)=>{

    return{
        type:FETCH_GLOBAL_SEARCH_REQUEST,
        data:data
    }

    
   
}

export const fetchGlobalSearchSuccess=(GlobalSearchData)=>{
return{
    type:FETCH_GLOBAL_SEARCH_SUCCESS,
    GlobalSearchData:GlobalSearchData
}
}

export const fetchGlobalSearchFailure=(error)=>{
    return{
    type:FETCH_GLOBAL_SEARCH_FAILURE,
    error:error
    }
}





////////////////////////////////////////////////////////////// /////////////////////////////////////////
///////////////////////////////////ACTIVE ORDERS  ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_VM_WISE_INVENTROY_REQUEST="FETCH_VM_WISE_INVENTROY_REQUEST"
export const FETCH_VM_WISE_INVENTROY_SUCCESS="FETCH_VM_WISE_INVENTROY_SUCCESS"
export const FETCH_VM_WISE_INVENTROY_FAILURE="FETCH_VM_WISE_INVENTROY_FAILURE"


 
export const fetchVmWiseInventoryRequest=(data)=>{

    return{
        type:FETCH_VM_WISE_INVENTROY_REQUEST,
        data:data
    }

    
   
}

export const fetchVmInventroySuccess=(VmWiseInventory)=>{
return{
    type:FETCH_VM_WISE_INVENTROY_SUCCESS,
    VmWiseInventory:VmWiseInventory
}
}

export const fetchVmInventroyFailure=(error)=>{
    return{
    type:FETCH_VM_WISE_INVENTROY_FAILURE,
    error:error
    }
}


/////////////////////////////////// ADD_TO_CART  ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_ADD_TO_CART_REQUEST="FETCH_ADD_TO_CART_REQUEST"
export const FETCH_ADD_TO_CART_SUCCESS="FETCH_ADD_TO_CART_SUCCESS"
export const FETCH_ADD_TO_CART_FAILURE="FETCH_ADD_TO_CART_FAILURE"


 
export const fetchAddToCartRequest=(data)=>{

    return{
        type:FETCH_ADD_TO_CART_REQUEST,
        data:data
    }

    
   
}

export const fetchAddToCartSuccess=(addtocartRes)=>{
return{
    type:FETCH_ADD_TO_CART_SUCCESS,
    addtocartRes:addtocartRes
}
}

export const fetchAddToCartFailure=(error)=>{
    console.log(" action fetchUserSaga error",error)
    return{
    type:FETCH_ADD_TO_CART_FAILURE,
    error:error
    }
}





/////////////////////////////////// PREVIOUS CART  ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_PRE_CART__GLOBAL_REQUEST="FETCH_PRE_CART__GLOBAL_REQUEST"
export const FETCH_PRE_CART__GLOBAL_SUCCESS="FETCH_PRE_CART__GLOBAL_SUCCESS"
export const FETCH_PRE_CART__GLOBAL_FAILURE="FETCH_PRE_CART__GLOBAL_FAILURE"


 
export const fetchPreCartGlobalRequest=(data)=>{

    return{
        type:FETCH_PRE_CART__GLOBAL_REQUEST,
        data:data
    }

    
   
}

export const fetchPreCartGlobalSuccess=(preCartdataGlobal)=>{
    console.log("preCartdata",preCartdataGlobal)
return{
    type:FETCH_PRE_CART__GLOBAL_SUCCESS,
    preCartdataGlobal:preCartdataGlobal
}
}

export const fetchPreCartGlobalFailure=(error)=>{
    return{
    type:FETCH_PRE_CART__GLOBAL_FAILURE,
    error:error
    }
}


///////////////////////////////////ACTIVE ORDERS  ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_ACTIVE_ORDERS_GLOBAL_REQUEST="FETCH_ACTIVE_ORDERS_GLOBAL_REQUEST"
export const FETCH_ACTIVE_ORDERS_GLOBAL_SUCCESS="FETCH_ACTIVE_ORDERS_GLOBAL_SUCCESS"
export const FETCH_ACTIVE_ORDERS_GLOBAL_FAILURE="FETCH_ACTIVE_ORDERS_GLOBAL_FAILURE"


 
export const fetchActiveOrdersGlobalRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_ACTIVE_ORDERS_GLOBAL_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchActiveOrdersGlobalSuccess=(ActiveOrdersGlobal)=>{
return{
    type:FETCH_ACTIVE_ORDERS_GLOBAL_SUCCESS,
    ActiveOrdersGlobal:ActiveOrdersGlobal
}
}

export const fetchActiveOrdersGlobalFailure=(error)=>{
    return{
    type:FETCH_ACTIVE_ORDERS_GLOBAL_FAILURE,
    error:error
    }
}