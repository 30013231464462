import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchGlobalSearchRequest ,
  fetchVmWiseInventoryRequest,fetchAddToCartRequest,
  fetchActiveOrdersGlobalRequest,
  fetchPreCartGlobalRequest
  


} from "./action";

import { fetchClearPreCardRequest } from "../VmInventory/action";

import { useNavigate } from 'react-router-dom';

import {  toast } from 'react-toastify';

import { fetchInventory,addtocart } from "./action";
import GlobalSearch from "./component";
import { mpClick, mpGlobalAddToCart, mpGlobalAddToCartError, mpSearch, mpSearchResult, mpSearchResultClicked } from "../../Mixpanel";

const GlobalSearchContainer = (props) => {

  //////////////////////////// GLOBAL SEARCH ///////////////////////////////////////
const CategoryDetails=props&&props.props&&
                      props.props.globalSearch&&
                      props.props.globalSearch.GlobalSearchData&&
                      props.props.globalSearch.GlobalSearchData.CategoryDetails

const ItemDetails=props&&props.props&&
                      props.props.globalSearch&&
                      props.props.globalSearch.GlobalSearchData&&
                      props.props.globalSearch.GlobalSearchData.ItemDetails

const MachineDetails=props&&props.props&&
                      props.props.globalSearch&&
                      props.props.globalSearch.GlobalSearchData&&
                      props.props.globalSearch.GlobalSearchData.MachineDetails

const VmWiseInventroyList=props&&props.props&&
                          props.props.globalSearch&&
                          props.props.globalSearch.VmWiseInventoryData&&
                          props.props.globalSearch.VmWiseInventoryData.VmWiseInventoryList

                          
const PreCartListGlobal=props&&props.props&&
                        props&&props.props&&
                        props.props.globalSearch&&
                        props.props.globalSearch.PreCartGlobalData&&
                        props.props.globalSearch.PreCartGlobalData.PreCartListGlobal

const PreCartMachineID=props&&props.props&&
                        props&&props.props&&
                        props.props.globalSearch&&
                        props.props.globalSearch.PreCartGlobalData&&
                        props.props.globalSearch.PreCartGlobalData.PreCartMachineID


const ActiveOrderItemsListGlobal=props&&props.props&&
                        props&&props.props&&
                        props.props.globalSearch&&
                        props.props.globalSearch.ActiveOrdersGlobalData&&
                        props.props.globalSearch.ActiveOrdersGlobalData.ActiveOrderItemsListGlobal                     


                      

  console.log("ActiveOrderItemsListGlobal",ActiveOrderItemsListGlobal)


  const navigate =useNavigate()

  const [flagMp,setFlagMp]=useState(true)

const [globalSearchProName,setGlobalSearchProName]=useState("")
const [vmwiseInventoryFlag,setVmwiseInventoryFlag]=useState(false)
const [globalsearchTerm,setGlobalsearchTerm]=useState(null)
const [globalvmList,setGlobalvmList]=useState([])
const [cartCount,setCartCount]=useState(null);
const [carttotalAmount,setCarttotalAmount]=useState(null)

const [replaceCard,setReplceCard]=useState(false)
const [slotId,setSlotdId]=useState(null)

const handleResponseGlobalSearch=(res,searchTerm)=>{
console.log("handleResponseGlobalSearch",res.data.data.categoryDetails.length+res.data.data.itemDetails.length+res.data.data.machineDetails.length)
   
 	mpSearchResult({EventName:"Global_search_successful",data:{search_query:searchTerm,count:res.data.data.categoryDetails.length+res.data.data.itemDetails.length+res.data.data.machineDetails.length}})

  setVmwiseInventoryFlag(false)



}

const handleErrorActiveOrders=(res)=>{
  toast.error(`${res}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
}

const handleErrorVmWiseInventory=(res)=>{
  toast.error(`${res}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });

}
const handleResponseVmWiseInventory=(res)=>{
  setVmwiseInventoryFlag(true)
  fetchPreCard()
console.log("handleResponseVmWiseInventory",res)
setFlagMp(false)

}
const globalSearchflagBackbtn=(e)=>{
  e.preventDefault()
 navigate("/")
}
useEffect(()=>{


  globalSearch()
  fetchPreCard()
  fetchActiveOrder()
},[])



const handleErrorClearPreCard=()=>{

}


const handleResponseClearPreCard=(res)=>{
  setReplceCard(false)
  console.log("slotDAta",slotId)
  addintoCart("tahzeev",slotId.sId,slotId.vmId,slotId.count)

fetchPreCard()
//AddIntoCart("tahzeeb",addThisSlot.index,addThisSlot.sId,0)

setReplceCard(false)

}


      const fetchClearPreCard = () => {
        const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      
        props.fetchClearPreCardRequest({
          url: `/consumerbe/api/v2/cart`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
            //HEADER ERROR
            "x-device-id":localStorage.getItem("uuid")
          },
          handleErrorClearPreCard ,
          handleResponseClearPreCard ,
        });
      };


let totalitemcount=0,totalAmount=0
useEffect(()=>{
  
  PreCartListGlobal&&PreCartListGlobal.forEach(item => {
    const count = item.count;
    const amount = item.amount;
    const itemTotal = count * amount;
    totalitemcount+=item.count
    totalAmount += itemTotal;
  });
  setCartCount(totalitemcount)
  setCarttotalAmount(totalAmount)
  console.log("props&&props.totalitemcount",totalitemcount,totalAmount)
  console.log("handleResponse");
  console.log("handleResponsePreCard" );

},[PreCartListGlobal])







const globalSearchflag=(e)=>{
  e.preventDefault()
 
}
const globalSearch = (searchTerm) => {

  setVmwiseInventoryFlag(false)
  setGlobalSearchProName(searchTerm)
  setGlobalsearchTerm(searchTerm)
  if(globalSearchProName)
  {setGlobalSearchProName((prevValue) => prevValue.slice(0, -1));
  }
 



  const UserJWTAuth = localStorage.getItem('UserJWTAuth');
  const lat = localStorage.getItem('lat');
  const lng = localStorage.getItem('lng');
  
  console.log("searchTerm ",searchTerm)
  if(searchTerm){mpSearch({EventName:"Global_search_performed",data:{search_query:searchTerm}})
}
  props.fetchGlobalSearchRequest({
    url: `/consumerbe/api/v2/search/global?searchQuery=${searchTerm}&lat=${lat}&lng=${lng}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //"x-device-id": localStorage.getItem("uuid"),
    },
    handleErrorActiveOrders ,
    handleResponseGlobalSearch:(res)=>{handleResponseGlobalSearch(res,searchTerm)} ,
  });
};

const backFromGlobalSearch=()=>{
  setVmwiseInventoryFlag(false)

}

const vmWiseInvertory = (e,id,type,name) => {
  
  mpSearchResultClicked({EventName:"Global_search_result_clicked",data:{ResultType:name,ResultId:id}})
  e.preventDefault()  
  setGlobalSearchProName(name)

const UserJWTAuth = localStorage.getItem('UserJWTAuth');
const lat = localStorage.getItem('lat');
const lng = localStorage.getItem('lng'); 

  

  props.fetchVmWiseInventoryRequest({
    url: `/consumerbe/api/v2/outlet-wise/inventory?page=0&size=100&recentLimit=6&lat=${lat}&lng=${lng}&${type}=${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
    },
    
    handleErrorVmWiseInventory ,
    handleResponseVmWiseInventory ,
  }); 
};




const addintoCart = (e,sId,vmId,count,name,mrp) => {

  console.log("khan--e,sId,vmId,count",e,sId,vmId,count,name,mrp)
  localStorage.setItem("Global_Slot_id",sId)
  //e.preventDefault()  

  mpGlobalAddToCart({EventName:"Add_to_cart",data:{itemID:sId,itemName:name,itemPrice:mrp,vmId:vmId,sourcePage:"Global_Search" }})

 

const UserJWTAuth = localStorage.getItem('UserJWTAuth');
const lat = localStorage.getItem('lat');
const lng = localStorage.getItem('lng'); 

  

  props.fetchAddToCartRequest({
    url: `/consumerbe/api/v2/cart?machineId=${vmId}`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
    },
    request: [
      {
        slotId: parseInt(sId), // Parsing sId to an integer
        count: count + 1,      // Incrementing the count by 1
      },
    ],
    
    handleErrorAddToCart:(res)=>handleErrorAddToCart(res,sId,vmId,count) ,
    handleResponseAddToCart:(res)=> handleResponseAddToCart(res,vmId),
  }); 
};

const handleErrorAddToCart=(res,sId,vmId ,count)=>{
  mpGlobalAddToCartError({EventName:"Add_To_Cart Error On Global",data:{Error:res.data.status.message}})
console.log("tahzeeb handleErrorAddToCart",res.data.status.message)
setSlotdId({sId:sId,vmId:vmId,count:count})
toast.error(`${res.data.status.message}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
if(!(res.data.status.code=="NOT_FOUND"))
{
setReplceCard(true)
}
}

const handleResponseAddToCart=(res,vmId)=>{
console.log("handleResponseAddToCart",res)
navigate(`/items?machineId=${vmId}`)
//fetchPreCard()
}

const SetVm=(e,id,name,address)=>{
  console.log("Setvm VM ID",id)
  navigate(`/items?machineId=${id}`,{ replace: true })
  localStorage.setItem("vmName",name)
  localStorage.setItem("vmAddress",address)
 

}



const fetchPreCard = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchPreCartGlobalRequest({
    url: `/consumerbe/api/v2/cart`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
    },
    handleErrorPreCardGlobal ,
    handleResponsePreCardGlobal ,
  });
};

const handleResponsePreCardGlobal=(res)=>{
console.log("handleResponsePreCardGlobal",res)
}

const handleErrorPreCardGlobal=(error)=>{
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    //localStorage.removeItem("UserJWTAuth")
    navigate("/login");
}
else{ toast.error(`${error.response.data.status.code}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}


  
}



/////////////////  ACTIVE ORDERS DATA FETCHING
const fetchActiveOrder = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchActiveOrdersGlobalRequest({
    url: `/consumerbe/api/v2/order/active`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
    },
    handleErrorActiveOrdersGlobal ,
    handleResponseActiveOrdersGlobal ,
  });
};

const handleResponseActiveOrdersGlobal=(res)=>{
console.log("handleResponseActiveOrders",res)
}

const handleErrorActiveOrdersGlobal=(res)=>{

  toast.error(`${res}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
console.log("handleErrorActiveOrdersGlobal",res)

}
  
  const removefromCart=( e,slotId,vmId,count)=>{

    navigate(`/items?machineId=${vmId}`)
  }
  
  
  


return(<>

<GlobalSearch
globalSearch={globalSearch}
globalSearchProName={globalSearchProName}
globalSearchflagBackbtn={globalSearchflagBackbtn}
globalSearchflag={globalSearchflag}
CategoryDetails={CategoryDetails}
ItemDetails={ItemDetails}
MachineDetails={MachineDetails}
globalsearchTerm={globalsearchTerm}
vmwiseInventoryFlag={vmwiseInventoryFlag}
globalvmList={globalvmList}
vmWiseInvertory={vmWiseInvertory}
VmWiseInventroyList={VmWiseInventroyList}
addintoCart={addintoCart}
PreCartListGlobal={PreCartListGlobal}
ActiveOrderItemsListGlobal={ActiveOrderItemsListGlobal}
cartCount={cartCount}
carttotalAmount={carttotalAmount}
PreCartMachineID={PreCartMachineID}
removefromCart={removefromCart}
SetVm={SetVm}
backFromGlobalSearch={backFromGlobalSearch}
fetchClearPreCard={fetchClearPreCard}
replaceCard={replaceCard}
setReplceCard={setReplceCard}
setFlagMp={setFlagMp}
flagMp={flagMp}

/>

</>)
}



function mapStateToProps( props) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    
    fetchGlobalSearchRequest,
    fetchVmWiseInventoryRequest,
    fetchAddToCartRequest,
    fetchPreCartGlobalRequest,
    fetchActiveOrdersGlobalRequest,
    fetchClearPreCardRequest
    
  })(GlobalSearchContainer);