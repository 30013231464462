import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { SEND_DELETE_ACCOUNT_REQUEST, sendDeleteAccountFailure, sendDeleteAccountSuccess } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
 



function* sendDeleteAccount(action) {
  console.log("fetchPreCard saga", action);
  try {
    const response = yield call(callApi, action);

    yield put(sendDeleteAccountSuccess(response));

    action.data.handleResponsedeleteAccountApi(response);
  } catch (error) {
    console.log("fetchPreCard error", error.message);
    
    yield put(sendDeleteAccountFailure(error.message));
    action.data.handleErrordeleteAccountApi(error)
  }
}


  function* DeleteAccountSaga() {
  
    yield takeLatest(SEND_DELETE_ACCOUNT_REQUEST,sendDeleteAccount)
    
    
  }
  
  export default DeleteAccountSaga;