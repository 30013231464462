import React, { useState } from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../ImagesAds/imageAds.css'
import MobileStepper from '@mui/material/MobileStepper';
import { useTheme } from '@mui/material/styles';
const Banner = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const steps = props.BannerData.length; // Number of items in your data array
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const maxSteps = 3; // Maximum number of dots to display



  return (
    <div className="carousel-container" style={{padding:"16px"}} >


// Assuming props.BannerData is your data array



// Render logic
{props && props.BannerData && props.BannerData.length > 0 && (
  <div>
    <Carousel
      autoPlay={true}
      interval={2000}
      infiniteLoop={true}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      showArrows={props.BannerData.length > 1}
    >
      {props.BannerData.slice(0, maxSteps).map((banner, index) => (
        <div key={index} className="carousel-slide">
          <img
            style={{ borderRadius: "16px" }}
            className="Carousel_image__1O_xL"
            src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${banner.bannerUrl}`}
            alt={`Banner ${index + 1}`}
          />
        </div>
      ))}
    </Carousel>
    {/* MUI MobileStepper */}
    <MobileStepper
      variant="dots"
      steps={Math.min(maxSteps, steps)} // Display maximum of 3 dots or number of items in data array, whichever is smaller
      position="static"
      activeStep={activeStep} // Set active step if needed
      nextButton={
        "Next button logic"
      }
      backButton={
        "Back button logic"
      }
    />
  </div>
)}




    </div>
  );
};

export default Banner;
