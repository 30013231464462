import BpHistoryHeader from "../Header/BpHistoryHeader"
import { Link } from "react-router-dom";
import BpIcon from "../../assets/bpIcon.png"
import PaidIcon from "../../assets/paidicon.png"
import Refundicon from "../../assets/refund.png"
import WaitingLoder from "../WaitingLoader/component";
import CancleIcon from '../../assets/cancle.png'
import "../BusinessPayment-history/bphistory.css"
import TagIcon from "../../assets/tagpay.png"
import LinkIcon from "../../assets/Link.png"
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { mpClick } from "../../Mixpanel";
const BpHistory=(props)=>{
  const navigator=useNavigate()
  const [indianTime, setIndianTime] = useState('');
  useEffect(() => {
    // Function to get the current Indian time
    function getCurrentIndianTime() {
      const now = new Date();
      const options = { timeZone: 'Asia/Kolkata', hour12: true };
      const indianDateTime = now.toLocaleString('en-IN', options);

      //setCurrentDateTime(indianDateTime);

      setIndianTime(indianDateTime);

      //setIndianTime(indianTime.toLocaleString());
      //setIndianTime(timeString);
    }

    // Call the function to get the current Indian time
    getCurrentIndianTime();

    // Update the Indian time every second
  

    // Clean up the interval on component unmount
   
  }, []);

  console.log("BpHistory",props&&props.bpHistory&&props.bpHistory.data&&props.bpHistory.data.payload[0])
  useEffect(()=>{
mpClick({EventName:"bp_history_displayed"})
if(!(props&&props.bpHistory&&props.bpHistory.data&&props.bpHistory.data.payload[0]))
{
  //navigator("/PaymentOption")
}


  },[])
  
  
  const bpbalance=localStorage.getItem("BpBalance")
   console.log("props TagpayHistory",props&&props.tagListHistory&&props.tagListHistory.data&&props.tagListHistory.data.payload

   )
    return(
    <>
       {true?
    <>
     
       {props.loader && <WaitingLoder />} 
      
      <div id="root" style={{ height: "100%",fontFamily:"Roboto"}}>

      {props.unlinkModal&&<div  className="modalDeactive"  /* style={{width:"90%",top:"20%", height:"392px",left:"5%"}} */ >
        <div className="modal-content-bpHistory">
          <img src={LinkIcon} style={{"margin-left":"33%","margin-top": "-74px",width:"105px"}} />
          <h3 style={{color:"#384F6F",
"text-align": "center",
"font-family":"Roboto",
"font-size": "14px",
"font-style": "normal",
"marginTop":"-10px",
"font-weight": "700",
"line-height":"28px","letter-spacing":"1px"}}>Request to Unlink business wallet</h3>
          <p style={{ "color": "rgba(56, 79, 111, 0.69)",
"text-align": "center",
"font-family":"Roboto",
"font-size":"12px","font-style":"normal","font-weight":"400","line-height":"17.5px"}}>Send request for admin to unlink your business wallet from the account.</p>
         <p style={{ "text-align":"center","margin-top": "11px","font-weight":"bold"}}></p>
          
          <div style={{display:"flex","justify-content":"space-around","margin-top": "8px",
     }}>
          <button onClick={(e)=>{props.setUnlinkModal(false)}} style={{   "display": "flex",
        "width":"128px",
        "height": "41px",
        "padding":"10px",
        "justify-content": "center",
        "align-items":"center",
        "gap": "10px",
        "flex-shrink":"0",
        color: "#FFF",
"text-align": "center",
"font-family": "Inter",
"font-size": "15px",
"font-style": "normal",
"font-weight":"800",
"line-height": "13.5px", /* 90% */
"letter-spacing":"1px"
        
        
        }} className="canclebtn">No</button>
            <button onClick={props.BpUnlink} className="deactivebtn" style={{display: "flex",
"width": "130px",
"height":"41px",
"padding": "10px",
"justify-content":"center","align-items":"center",
"gap": "10px",
"flex-shrink": "0",

"text-align": "center",
"font-family": "Inter",
"font-size":"15px",
"font-style": "normal",
"font-weight": "800",
"line-height":"13.5px", /* 90% */
"letter-spacing":"1px"


}}>Yes</button>
          </div>
          </div>
        </div>}
        {props.requestsumbitted&&<div  className="modalDeactive"  /* style={{width:"90%",top:"20%", height:"392px",left:"5%"}} */ >
        <div className="modal-content-bpHistory">
          <img src={LinkIcon} style={{"margin-left":"33%","margin-top": "-74px",width:"100px"}} />
          <h3 style={{"text-align":"center"}}>Request Already Submitted</h3>
          <p style={{ "text-align":"center","margin-top": "11px"}}>Your unlinking request has been received. please wait for admins approval.</p>
       
          
          <div style={{display:"flex","justify-content":"space-around","margin-top": "8px"}}>
          <button onClick={(e)=>{props.setRequestsumbitted(false)}} className="reqokbtn" style={{margin:"0px"}}>OK</button>
     
          </div>
          </div>
        </div>}
        <div className="_2yoTv ns7Wo">
          <div>
             <BpHistoryHeader title={'Business Payments'} route={"/PaymentOption"} height={"170px"} marginTop={"-57px"} />
 
            <div>
              <div className="_2NVhy _3hjyL">
               
              <div
                          className="styles_slider__podEu"
                          data-testid="grid-slider"
                          style={{marginTop:"20%"}}
                        >
                   
                       
                         {[1].map((el, index) => {
                          //console.log("Ellll--->",el)
                         return(
                         <div
                            className="styles_row__1ivP1 shadow-box"
                            data-testid="grid-row"
                            style={{
                              marginTop: "4%",
                              "margin-right": "12px",
                              "margin-left": "12px",
                              "margin-bottom": "22px",
                              height: "181px",
                              "border-radius":" 29px" ,
                              background:"white",
                              "z-index": "9",position:"fixed",
                              width:"93%"
                                                   
                            }}
                          >
                            <div
                              className="styles_slide__2c207"
                              style={{ marginRight: 0 }}
                            >
                              <div>
                                <div
                                  className="styles_cardWithPadding__JE1QE"
                                  data-testid="home-restaurant-card-wrapper"
                                  style={{
                                    width: "calc((100vw - 0px) - 12px)",
                                  }}
                                >
                                  <a
                                    data-testid="resturant-card-anchor-container"
                                    className="styles_container__fLC0R"
                                    aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                    tabIndex={0}
                                    role="button"
                                  >
                                    <div
                                      className="styles_imgContainer__1uHo5"
                                      aria-hidden="true"
                                    ></div>
                                <div>
                                  <div style={{display:"flex","justify-content": "space-between",marginRight:"69px","margin-top": "17px"}}>
                                    <div style={{display:"flex", "align-items":"center","justify-content":"space-around"}}>
                                        <img style={{width:"25%"}} src={BpIcon}/>
                                   <div style={{"margin-left":"11px"}}>
                                   <h2 style={{fontSize:"18px"}}>Business Wallet</h2>
                                   { <p style={{"margin-top":"7px"}}>{indianTime}</p> }
                                    </div>     
                                  
                                  
                                   </div>
                                   
                                  </div>
                                  <div style={{display:"flex","justify-content": "space-between","margin-top": "17px",marginRight:"37px","align-items":"center"}}>
                                  <div>
                                   { props&&props.paymentOptionsList&&props.paymentOptionsList[2].reason=="BALANC_REDACTED"?<> </>:  <div style={{"margin-left":"11px"}}>
                                 <p style={{color:"#718198"}}>Balance amount</p>
                                 <h1 style={{"color":"#333F4E","font-size": "20px"}}>₹ {""}{props&&props.paymentOptionsList&&props.paymentOptionsList[2].balance}</h1>
                                 
                                 </div>
                                 }
                            
                                  </div>
                                 <button onClick={(e)=>{props.UnLinkModalfun(e)}}  style={{display: "flex",
padding:"9px 32px","justify-content":"center","align-items":"center",
gap:"8px",

"border-radius": "20px",
background: " #00B177",


color: "#FFF",
"font-family":"Roboto",
"font-size": "15px",
"font-style": "normal",
"font-weight": "500",
"line-height":"20px", /* 133.333% */
"text-transform": "capitalize",


}}>UNLINK</button>
                                                                  </div>

                                </div>
                                  </a>
                                </div>
                                <div />
                              </div>
                            </div>
                          </div>)
                          })}
                        </div>
                        
              </div>
             <div  style={{paddingTop:"50%",background:"aliceblue",    height:"100vh"}}>{ !(props&&props.paymentOptionsList&&props.paymentOptionsList[2].reason=="BALANC_REDACTED")&& <div style={{padding:"5%",display:"flex","justify-content":"space-between","align-items":"center","color":"#384F6F",fontSize:"16px","font-family": "Roboto"}}><h4>Transactions History</h4></div>}
             { !(props&&props.paymentOptionsList&&props.paymentOptionsList[2].reason=="BALANC_REDACTED")&& props&&props.bpHistory&&props.bpHistory.data&&props.bpHistory.data.payload.map((el, index) => {
              return(
              <div className="_2NVhy _3hjyL">
              <div
                          className="styles_slider__podEu"
                          data-testid="grid-slider"
                          style={{marginTop:"-6%"}}
                        >
                                 <div
                            className="styles_row__1ivP1 shadow-box"
                            data-testid="grid-row"
                            style={{
                              marginTop: "4%",
                              "margin": "4% 12px 22px",
                              
                              height:"81px",
                              background: "white",
                              "border-radius":"16px",background:"#FFF","box-shadow":"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"                      
                            }}
                          >
                            <div style={{ display:"flex","align-items":"center",   "justify-content":"space-between",width:"100%",padding:"20px"}}>
                            <div style={{}}>
                              {el.opsType=="credit"?
                              <div style={{display:"flex","align-items":"center"}}>
                              <img style={{width:"44px",}} src={PaidIcon}/>
                              <div style={{ "margin-left":"19px"}}>
                                {el.opsType=="credit"?<h3 style={{color:"#333F4E",

/* SM14 */
"font-family": "Roboto",
"font-size":"14px","font-style":"normal","font-weight":"600",
"line-height": "150%", /* 21px */
"letter-spacing": "0.2px"}}>Paid amount</h3>:<h3 style={{color:"#333F4E",fontSize:"14px"}}>Refunded amount</h3>}
                                <p style={{"margin-top":"5px",fontSize:"12px","color":"#64748B"}}>ID: {el.orderId}</p>
                                </div>

                                </div>:<div style={{display:"flex","align-items":"center"}}>

                              <img style={{width:"44px",}} src={Refundicon}/>
                              <div style={{ "margin-left":"19px"}}>
                                {el.opsType=="credit"?<h3 style={{color:"#333F4E",

/* SM14 */
"font-family": "Roboto",
"font-size":"14px","font-style":"normal","font-weight":"600",
"line-height": "150%", /* 21px */
"letter-spacing": "0.2px"}}>Paid amount</h3>:<h3 style={{color:"#333F4E",fontSize:"14px"}}>Refunded amount</h3>}
                                <p style={{"margin-top":"5px",fontSize:"12px","color":"#64748B"}}>ID: {el.orderId}</p>
                                </div>
                              </div>
                              }
                              </div>
                              {/* <div> */}
                         

                                <div style={{}}>
                               {el.opsType=="credit"?<h3 style={{color: "#F43F5E",

/* M14 */
"font-family":"Roboto","font-size":"14px",
display: "flex",
    "justify-content":"flex-end",
"font-style": "normal",
"font-weight":"500","line-height":"150%","letter-spacing":"0.2px"}}>₹{""}{el.amount}</h3>:<h3 style={{display:"flex","justify-content":"flex-end",color: "#00B177",

/* M14 */
"font-family":"Roboto",
"font-size":"14px",
"font-style": "normal",
"font-weight": "500",
"line-height": "150%", /* 21px */
"letter-spacing":"0.2px"}}>₹{""}{el.amount}</h3>}
                                  <p style={{color: "#94A3B8",
"text-align": "right",
"font-family": "Roboto",
"font-size": "11px",
"font-style":"normal","font-weight":"400",
"line-height": "150%", /* 16.5px */
"letter-spacing":"0.2px"}}>{el.timestamp.slice(0, 10)}</p>
                                  </div>
                                  </div>

                               {/*  </div> */}



                          </div>
                          </div>

              </div>)
              })}</div>
            </div>
          </div>
        </div>
      </div>
    </>:<></>}
    </>
    )
}

export default BpHistory;