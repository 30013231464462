import {   FETCH_APPLY_COUPON_SUCCESS } from "./action";


const initialState = {
    //////////////Coupon VARIABLES///////////////
   CouponData:null
     
}    



const CouponReducer = (state = initialState, action) => {
    console.log("ApplyCouponDataReducer",action&&action.ApplyCoupon&&action.ApplyCoupon.data)
    switch (action.type) {
//////////////////////SWITCH CASES FOR ACTIVE ORDERS //////////////////////////////////////
  case FETCH_APPLY_COUPON_SUCCESS: 
    return {
      ...state,
      
      CouponData: action&&action.ApplyCoupon&&action.ApplyCoupon.data,
     
    
    
    
    };


  
  
  
  
  
  
    default:
      return state;

  }
};

export default CouponReducer;
