import React from "react";
import '../WaitingLoader/waitingLoader.css'
const WaitingLoder = () => {
  return (
    <>
    <div className="waitModal">
      <div className="waitingLoader"></div>
      </div>
    </>
  );
};
export default WaitingLoder