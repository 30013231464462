import { connect } from "react-redux";
import { sendDeleteAccountRequest } from "./action";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteAccount from "./component";

const DeleteAccountContainer = (props) => {
  const navigate=useNavigate()
  const [logoutModal, setLogutModal] = useState(false);
  const [reason,setReason]=useState(null)

useEffect(()=>{
  
  
},[])
const UserJWTAuth = localStorage.getItem('UserJWTAuth');
useEffect(()=>{
 
 console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
 if(!UserJWTAuth){
  localStorage.setItem("enteryUrl",'/deleteaccount')
   navigate('/')
 }
},[])

useEffect(()=>{
 
  console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
  if(localStorage.getItem('Guest_clientId')){
    //navigate('/login')
  }
 },[])
 
//





 









const handleErrordeleteAccountApi =(error)=>{
  console.log("khan handleErrorVmInventory", error.response.data.status.code);
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    //localStorage.removeItem("UserJWTAuth")
     // navigate("/login");
}
else{ toast.error(`${error&&error.response&&error.response.data&&error.response.data.status}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
  
  //setLoader(false)
  
}

const handleResponsedeleteAccountApi =(res)=>{
 setLogutModal(false)
 toast.success(`sucess`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
  //setLoader(false)
  //setLoader(false)
  //localStorage.removeItem('UserJWTAuth')
  navigate('/profile')
}

const deleteAccountApi = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.sendDeleteAccountRequest({
    url: `/auth/user/request/delete`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      
    },
    "request": {
    
        "reason": reason


      },
    handleErrordeleteAccountApi,
    handleResponsedeleteAccountApi  ,
  });
};













return(
    <DeleteAccount
    deleteAccountApi={deleteAccountApi}
    logoutModal={logoutModal}
    setLogutModal={setLogutModal}
    setReason={setReason}
 />
)


}



function mapStateToProps( props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    sendDeleteAccountRequest
   
  })(DeleteAccountContainer);