import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from '../src/Saga/store';

import swDev from './ServiceWorker/swDev';

// this provider to use provide store in root component and root component provide to all childs
ReactDOM.render(
  <Provider store={store}>
 <App/>

  </Provider>,
  document.getElementById('root')
)
swDev()





