import { LINK_BP_USER } from "./constant";

export function linkbpuser(
    method,
    url,
    headers,
    body,
    handleSuccess,
    handleError,
    //showToast,

) {
    console.log('method---',method,url,body)
    
    return {
      type:LINK_BP_USER,
      method,
      headers,
      url,
      body,  
      handleSuccess,
      handleError,
      //showToast,
    };
  }