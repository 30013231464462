import React from "react";
import { Typography,Box,Card ,Stack,Skeleton} from "@mui/material";
import VmImgNotFound from '../../../assets/vmImgnotfound.png'
const RecentVms=(props)=>{

    const cardStyle = {
        width: "109px", // Adjust the width as needed
        height:"128px",
        margin: '5px', // Optional margin between cards
        overflowX: 'auto',
        borderRadius: "15px",
        boxShadow: "0px 4px 10px 0px rgba(56, 79, 111, 0.12)"

      };
      
      function TruncatedText({ text, limit,color,size }) {
        if (text&&text.length <= limit) {
          return <span style={{ fontSize: size?size:"", color:color?color:"" }}>{text}</span>;
        }
        return (
          <span style={{ fontSize:size?size:"", color:color?color:"" }}>{`${text&&text.substring(
            0,
            limit
          )}`}{" "}...</span>
        );
      }

    return(<>
    <Box marginTop={"35px"}>
    <Typography color={"rgba(113, 129, 152, 1)"}fontWeight={"500"} marginTop={"5%"}>Recent Machines</Typography>
    {props&&props.vmListSkeleton?
    
   
  
 
  <>
 <Box style={{ display: 'flex', overflowX: "scroll" }} /* marginLeft={"-9px"} */ marginTop={"0%"}>
 <div style={{height: "154px",marginTop:"2%"}}>
    <Box sx={{ display: "flex" }}>
     {[...Array(50)].map((_, index) => (
       <Card key={index} sx={cardStyle}>
         <div style={{padding:"5%"}}>
          
             <Box sx={{paddingLeft:"5%",paddingRight:"5%",paddingBottom:"5%", marginTop:"-9px"}}>
               <div key={index}>
                 <Stack spacing={1}>
                   <Skeleton flex={4} className="container" height={80} />
                   <Skeleton variant="container" height={10} />
                   <Skeleton variant="container" height={10} />
                 </Stack>
               </div>
             </Box>
           
         </div>
       </Card>
     ))}
   </Box>
   </div>
 </Box>

</>: <Box style={{ display: 'flex', overflowX:"scroll"}}  marginTop={"8px"} height={"147px"}>
     <Box sx={{display:"flex"}}> {props&&props.recentMachines[0]&&props.recentMachines.map((data, index) => (
        <Card key={index} sx={cardStyle} onClick={(e)=>props&&props.VmsClick(e,data.id,data.name,data.address,"recent_vm_Section")}>
          <div style={{marginTop:"5px"}}>
            <Box >
              <Box sx={{padding:"5%"}}>
                <Box sx={{display:"flex"}} justifyContent={"center"}>
                <object
               
                data={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${data&&data.machineImagePath}`}
                type="image/png"
                style={{backgroundColor:"none",width:"89px",objectFit:"fill",height:"50px",borderRadius:"6px"}}
              >
                <img src={VmImgNotFound} alt="Product Img" style={{width:"100%",padding:"0px",height:"60px",objectFit:"fill",borderRadius:"6px"}} />{" "}
              </object>
                  
             </Box>
              
              <Typography variant="body2" textAlign={"center"} color={"rgba(56, 79, 111, 1)"} fontSize={"12px"} fontWeight={600} marginTop={"6px"} marginLeft={"5px"} marginRight={"5px"}>
               <TruncatedText text={data&&data.name} limit={9} />
              </Typography>
              <Typography variant="body2" textAlign={"center"} color={"rgba(113, 129, 152, 1)"} fontSize={"10px"} marginTop={"6px"}fontWeight={"400"}>
              <TruncatedText text={data&&data.address} limit={30} />
              </Typography>
              </Box>
            
         
            </Box>
          </div>
        </Card>
      ))}
      </Box>
    </Box>}

</Box>
 </>)
    
}
export default RecentVms;