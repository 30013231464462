import React, { useEffect, useState } from "react";

import {  toast } from 'react-toastify';


import { connect } from "react-redux";
import OrderSearchComponent from "./component";
import { fetchOrderSearchRequest } from "./action";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
const OrderSearch=(props)=>{
    const navigate=useNavigate()
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get('id');

    const [image, setImage] = useState(null);
    const [imagePath, setImagePath] = useState("");
    
    const [imageUploadUI,setImageUploadUI]=useState(true)
    const [selectedImage, setSelectedImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
  
  
    const [orderSuccessflag,setOrderSuccessflag]=useState(false)
    const [orderNotFoundflag,setOrderNotFoundflag]=useState(false)
    const [orderPlacedflag,setOrderPlacedflag]=useState(false)
    const [orderAbandonedflag,setOrderAbandonedflag]=useState(false)
    const [orderFailedflag,setOrderFailedflag]=useState(false)
    const [orderCancleflag,setOrderCancleflag]=useState(false)
    const [orderPartiallyflag,setOrderPartiallyflag]=useState(false)
  
  
  
    const [orderNotFoundREflag,setOrderNotFoundREflag]=useState(false)
    const [orderPlacedREflag,setOrderPlacedREflag]=useState(false)
    const [orderAbandonedREflag,setOrderAbandonedREflag]=useState(false)
    const [orderFailedREflag,setOrderFailedREflag]=useState(false)
    const [orderCancleREflag,setOrderCancleREflag]=useState(false)
    const [orderPartiallyREflag,setOrderPartiallyREflag]=useState(false)
    const [orderCreated,setOrderCreated]=useState(false)
    const [loader,setLoader]=useState(false)

useEffect(()=>{
    findOrder()
},[])
const handleResponseFindOrder=(response)=>{

    console.log("response.data.response.is_refunded", response.data.response.is_refunded==false);
    setImagePath(response.image_path);
    setLoader(false)

if(response.data.response.oder_status=="Payment failed"){
if(response.data.response.is_refunded=="true"){

}

setImageUploadUI(false)
setOrderCreated(true)


}
if(response.data.response.oder_status=="Payment failed"){
    

if(response.data.response.is_refunded==true){
setOrderFailedREflag(true)

}
setImageUploadUI(false)

setOrderCreated(true)

}
    if(response.data.response.oder_status=="Successful"){
      if(response.data.response.is_refunded=="true"){
        setOrderNotFoundREflag(true)

      }
    
    setImageUploadUI(false)
    setOrderSuccessflag(true)

    }

    if(response.data.response.oder_status=="Partially successful" ||response.data.response.oder_status=="Partially fulfilled"){
    
      if(response.data.response.is_refunded==true){
      setOrderNotFoundREflag(true)

      }
      setImageUploadUI(false)
      setOrderPartiallyflag(true)

      }

      if(response.data.statusMessage=="Unable to find order!"){
        if(response.data.response.is_refunded==true){

          setOrderNotFoundREflag(true)

        }
        
        setImageUploadUI(false)
       
        setOrderNotFoundflag(true)
   
        }
    

        
        if(response.data.response.oder_status=="Abandoned"){
          if(response.data.response.is_refunded==true){

            setOrderAbandonedREflag(true)
          }
    
          setImageUploadUI(false)
         
          setOrderAbandonedflag(true)
  
          }
      

          if(response.data.response.oder_status=="Cancelled" ||response.data.response.oder_status=="Vending failed"){
    
            if(response.data.response.is_refunded==true){

              setOrderCancleREflag(true)
            }
            setImageUploadUI(false)
        
            setOrderCancleflag(true)
            }
        

            
    
          
    

  


    
  


}
  
  const handleErrorFindOrder=(err)=>{
    
    setLoader(false)
    
    console.log("Error:tahzeeb", err.response.data.statusMessage);
    toast.error(`${err.response.data.statusMessage}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
    });
    navigate('/help&support')
  }


const findOrder=()=>{
    props.fetchOrderSearchRequest({
        url: `/support/help/self/order/search/id?id=${id}`,
        method: "GET",
        headers: {
          //Authorization: `Bearer ${UserJWTAuth}`,
           "x-app-platform": "web",
                 "x-app-version": "v1.0.1",
                 //HEADER ERROR
                 "x-device-id":localStorage.getItem("uuid")
        },
       
          
    
    
  
        handleErrorFindOrder ,
        handleResponseFindOrder,
      });
}


  return(
    <>
    <h1>tahzeb{id}</h1>
    <OrderSearchComponent 
      
       loader={loader}
       imageUploadUI={imageUploadUI}
       orderSuccessflag={orderSuccessflag}
       orderNotFoundflag={orderNotFoundflag}
       previewImage={previewImage}
       orderPlacedflag={orderPlacedflag}
       orderAbandonedflag={orderAbandonedflag}
       orderFailedflag={orderFailedflag}
       orderCancleflag={orderCancleflag}
       orderPartiallyflag={orderPartiallyflag}


 orderNotFoundREflag={orderNotFoundREflag}
 orderPlacedREflag={orderPlacedREflag}
 orderAbandonedREflag={orderAbandonedREflag}
 orderFailedREflag={orderFailedREflag}
 orderCancleREflag={orderCancleREflag}
 orderPartiallyREflag={orderPartiallyREflag}
 orderCreated={orderCreated}
 />
    </>
)

}

function mapStateToProps(props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    fetchOrderSearchRequest
    
  })(OrderSearch);