export const FETCH_ORDER_DETAILS_REQUEST="FETCH_ORDER_DETAILS_REQUEST"
export const FETCH_ORDER_DETAILS_SUCCESS="FETCH_ORDER_DETAILS_SUCCESS"
export const FETCH_ORDER_DETAILS_FAILURE="FETCH_ORDER_DETAILS_FAILURE"


export const fetchOrderDetailsRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_ORDER_DETAILS_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchOrderDetailsSuccess=(OrderDetails)=>{
return{
    type:FETCH_ORDER_DETAILS_SUCCESS,
    OrderDetails:OrderDetails
}
}

export const fetchOrderDetailsFailure=(error)=>{
    return{
    type:FETCH_ORDER_DETAILS_FAILURE,
    error:error
    }
}