import { Link } from "react-router-dom";
import backArrow from '../../assets/backArrow.png'
import { useNavigate } from "react-router-dom";
function PaymentOptionHeader(props) {
  const navigate=useNavigate()

  const goTOTagRecharge=(e)=>{

    console.log("goTOTagRecharge")
    localStorage.setItem("ChangeRechageMethod","/paymentOptionRecharge")
    navigate(props&&props.route)
  }

      function TruncatedHeading({ text, limit }) {
        if (text&&text.length <= limit) {
          return <h3 style={{color:"#583C43",
           
            "font-family": "Roboto",
            "font-size":"18px",
            "font-style":" normal",
            "font-weight":"500",
            "line-height": "normal",
            "letter-spacing": "0.36px"}}>{text}</h3>;
        }
        return <h3  style={{color:"#583C43",
       "width":"150%",
        "font-family": "Roboto",
        "font-size":"18px",
        "font-style":" normal",
        "font-weight":"500",
        "line-height": "normal",
        "letter-spacing": "0.36px"}}>{`${text.substring(0, limit)}...`}</h3>;
      }
      function TruncatedSubheading({ text, limit }) {
        if (text.length <= limit) {
          return <p style={{width:"200%",fontSize:"13px",color:"#583C43"}}>{text}</p>;
        }
        return <p style={{width:"200%",fontSize:"13px",color:"#583C43"}}>{`${text.substring(0, limit)}...`}</p>;
      }
      
      return (
        <div className='h-[60px] flex items-center daalchini-header-color '>
        <div  className="_2456r" style={{height:props.height}}>
    <div className="_3nxa7" data-testid="listing-header-location">

      <button
        className="_1NdRR"
        aria-label="Selected address is Delhi, India; Double tap to change address."
        id="change-address-btn-id"
      >
        <div style={{display:"flex","align-items":"center",marginTop:props.marginTop}}>
       <div onClick={(e)=>{goTOTagRecharge(e)}} ><img  style={{height:"18px","margin-right":"12px"}}src={backArrow} /></div>
       <div style={{marginLeft:"10px"}}>
        <TruncatedHeading  text={`${props.title}`} limit={20}></TruncatedHeading>
        {props&&props.subtitle&&<TruncatedSubheading  text={`${props.subtitle}`} limit={40}></TruncatedSubheading>}

      
        </div>
      
        </div>
        
      </button>
{/*       <Link to="/faqDetails/5" style={{color:"#583C43",fontSize:"14px",fontWeight:"500"}}>Help</Link>
 */}    
    </div>
    </div>
    </div>
      );
    }

    export default PaymentOptionHeader