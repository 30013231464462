export const SEND_SKIP_LOGIN_REQUEST="SEND_SKIP_LOGIN_REQUEST"
export const SEND_SKIP_LOGIN_SUCCESS="SEND_SKIP_LOGIN_SUCCESS"
export const SEND_SKIP_LOGIN_FAILURE="SEND_SKIP_LOGIN_FAILURE"


export const sendSkipLoginRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:SEND_SKIP_LOGIN_REQUEST,
        data:myurldata
    }

    
   
}

export const sendSkipLoginSuccess=(SkipLoginRes)=>{
return{
    type:SEND_SKIP_LOGIN_SUCCESS,
    SkipLoginRes:SkipLoginRes
}
}

export const sendSkipLoginFailure=(error)=>{
    return{
    type:SEND_SKIP_LOGIN_FAILURE,
    error:error
    }
}













export const SEND_USER_LOGIN_REQUEST="SEND_USER_LOGIN_REQUEST"
export const SEND_USER_LOGIN_SUCCESS="SEND_USER_LOGIN_SUCCESS"
export const SEND_USER_LOGIN_FAILURE="SEND_USER_LOGIN_FAILURE"


export const sendUserLoginRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:SEND_USER_LOGIN_REQUEST,
        data:myurldata
    }

    
   
}

export const sendUserLoginSuccess=(UserLogin)=>{
return{
    type:SEND_USER_LOGIN_SUCCESS,
    UserLogin:UserLogin
}
}

export const sendUserLoginFailure=(error)=>{
    return{
    type:SEND_USER_LOGIN_FAILURE,
    error:error
    }
}



export const SEND_SUBMIT_OTP_REQUEST="SEND_SUBMIT_OTP_REQUEST"
export const SEND_SUBMIT_OTP_SUCCESS="SEND_SUBMIT_OTP_SUCCESS"
export const SEND_SUBMIT_OTP_FAILURE="SEND_SUBMIT_OTP_FAILURE"


export const sendSubmitOtpRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:SEND_SUBMIT_OTP_REQUEST,
        data:myurldata
    }

    
   
}

export const sendSubmitOtpSuccess=(SubmitOtpRes)=>{
return{
    type:SEND_SUBMIT_OTP_SUCCESS,
    SubmitOtpRes:SubmitOtpRes
}
}

export const sendSubmitOtpFailure=(error)=>{
    return{
    type:SEND_SUBMIT_OTP_FAILURE,
    error:error
    }
}