import TagpayHistory from "./component"
import {fetchCreateOrderRequest,fetchTagHistoryRequest,fetchPaytmCaptureRequest,fetchTagDetailsRequest,fetchDeactiveTagRequest} from "./action"
import { connect } from "react-redux";
import {fetchClearPreCardRequest,sendAddIntoCartRequest} from '../VmInventory/action'
import {fetchInitiatedPaymentRequest } from '../Mycart/action1'
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { fetchTagListRequest } from "../TagpayList/action";
import {  toast } from 'react-toastify';

const TagpayHistoryContainer=(props)=>{
  const machineId=localStorage.getItem('machineId')
  const navigate=useNavigate()
  const [loader,setLoader]=useState(false)
  console.log("useParams",useParams())
  const {uId} =useParams()
    const [tagListHistory,setTagHistory]=useState([])
    const [deactiveModal,setDeactiveModal]=useState(false)
    const [finalAmount,setFinalAmount]=useState(20)
    const [WebSocketFlag,setWebSocketFlag]=useState(false)
    const [amount,setAmount]=useState()
    //const [rechargeOrderId,setRechargeOrderId]=useState()
    const [rechargeModal,setRechargeModal]=useState(false)
    const [tagBalance,setTagBalance]=useState()
    const [cardType,setCardType]=useState()
    const [tagDeatils,setTagDeatils]=useState()
    const [rechargeSucessModal,setRechargeSucessModal]=useState(false)
    const razorpayMethod=localStorage.getItem("default_Recharge_PgName")=="RECHARGE_R_CARD"?"card":localStorage.getItem("default_Recharge_PgName")=="RECHARGE_R_WALLET"?"wallet":"UPI"
    let rechargeOrderId
    useEffect(()=>{
      TagDeatils()
      TagHistory()
      getTagList()
      fetcClearPreCard()
    },[])
    useEffect(() => {
      console.log("CONSOLE NUMBER ->> 3");
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    }, []);

    useEffect(() => {
      let intervalId;
      const ws = new WebSocket(
        `wss://api-prod.daalchini.co.in/consumerbe/status/payment?${
          rechargeOrderId && rechargeOrderId
        }`
      );
  
      ws.onopen = () => {
        console.log("Connected to WebSocket server");
        intervalId = setInterval(() => {
          if(WebSocketFlag){
          ws.send("Hello, server tahzeeb!");
          }
        }, 2000);
      };
  
      ws.onmessage = (event) => {
        if(WebSocketFlag){
        console.log(`Received message: ${event.data}`);
        }
      };
  
      ws.onclose = () => {
        console.log("Disconnected from WebSocket server");
      };
  
      return () => {
        clearInterval(intervalId);
        ws.close();
      };
  
      
    }, [WebSocketFlag]);
   



  

  
  
  
    
    const handleErrorInitiatedPayment = (res) => {
      toast.error(`${res.data.status.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setLoader(false);
      
    };

    const handleResponseInitiatedPayment = (res) => {

      Webwalasocket(rechargeOrderId);
    }


    const InitiatedPaymentapi = () => {
      const UserJWTAuth = localStorage.getItem("UserJWTAuth");
      const defaultPgId = localStorage.getItem("defaultPgId");
      const defaultPgName = localStorage.getItem("defaultPgName");
      const OrderId = localStorage.getItem("orderId");
  
      props.fetchInitiatedPaymentRequest({
        url: `/consumerbe/api/v2/order/${rechargeOrderId}/payment`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
  
          "x-app-platform": "web",
          "x-app-version": "v1.0.1",
          //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid")
        },
        request: {
          pgId: 2,
        },
  
        handleErrorInitiatedPayment,
        handleResponseInitiatedPayment: (res) =>
          handleResponseInitiatedPayment(res),
      });
    };

    const Webwalasocket = (orderid) => {
      setLoader(true);
      const OrderId = localStorage.getItem("orderId");
      console.log("Webwalasocket data", OrderId);
      const ws = new WebSocket(
        `wss://api-prod.daalchini.co.in/consumerbe/status/payment?order_id=${orderid}`
      );
  
      console.log("next Linew  WWWWWWWWWWWWWWWWWWWWWWWww", ws);
      ws.onmessage = (event) => {
        console.log("Webwalasocket data", event);
        const data = JSON.parse(event.data);
        console.log("Webwalasocket data", data);
        if(data.success){
        setRechargeSucessModal(true)
        setWebSocketFlag(false)
        setLoader(false)
        }
    
     
   
  
    
  
      };
  
      console.log("next Linew");
    };

    const handleErrorAddIntoCart=(res)=>{

  
      setLoader(false)
      console.log("handleErrorAddIntoCart",res)
      toast.error(`${res.response.data.status.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });

    
    
    
    }
    const handleResponseAddIntoCart=(res)=>{
    console.log("handleResponseAddIntoCart",res)
      setLoader(false)
      CreateOrderRecharge()
     
     
    
    
    
    
    }
    
    
    
    
      const AddIntoCart = (e) => {
        setLoader(true)
        setWebSocketFlag(true)
        if(amount<1){
          setLoader(false)
          toast.error(`Add Amount`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
          return

        }
   
  
        setLoader(true)

            const UserJWTAuth = localStorage.getItem("UserJWTAuth");
          
            props.sendAddIntoCartRequest({
              url: `/consumerbe/api/v2/cart?machineId=-1`,
              method: "PATCH",
              headers: {
                Authorization: `Bearer ${UserJWTAuth}`,
                "x-app-platform": "web",
                "x-app-version": "v1.0.1",
                //HEADER ERROR
               "x-device-id":localStorage.getItem("uuid")
              },
              request: [
                {
                  slotId: 100, // Parsing sId to an integer
                  count: amount,      // Incrementing the count by 1
                },
              ],
              handleErrorAddIntoCart:(res)=>handleErrorAddIntoCart(res),
              handleResponseAddIntoCart: (res) => handleResponseAddIntoCart(res),
            });
    
    
    
    
       
        
          };
      





    const handleErrorClearPreCard=()=>{

    }
    
    
    const handleResponseClearPreCard=(res)=>{
    
 
   
    
    
    }
    
    
          const fetcClearPreCard = () => {
            const UserJWTAuth = localStorage.getItem("UserJWTAuth");
          
            props.fetchClearPreCardRequest({
              url: `/consumerbe/api/v2/cart`,
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${UserJWTAuth}`,
                "x-app-platform": "web",
                "x-app-version": "v1.0.1",
                //HEADER ERROR
                "x-device-id":localStorage.getItem("uuid")
              },
              handleErrorClearPreCard ,
              handleResponseClearPreCard ,
            });
          };
    





    const handleResponseTagList=(res)=>{


 
      res.data.response.map((tag, index) => {
        if (tag.uid === localStorage.getItem("TagUUID")) {
          console.log("tagDetails", tag.uid);
          
          setTagBalance(tag.balance)
          setCardType(tag.cardType)
        
        }
 
      });
      


    }
    
    const handleErrorTagList=(error)=>{
      if(error.response.status==401|| error.response.status==403){

        console.log("khan handleErrorTagHistory", error.response);
        //Logout
        //localStorage.removeItem("UserJWTAuth")
          //navigate("/login");
    }
    else{ toast.error(`${error.response.message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    }
    
    }
    
    
    const getTagList = () => {
     
      const UserJWTAuth = localStorage.getItem('UserJWTAuth');
      console.log("UserJWTAuth",UserJWTAuth)
      setLoader(true)
      console.log("inventory...container")
    
      props.fetchTagListRequest({
        url: `/auth/tag/linked`,
        method:  "GET",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
          "x-app-version": "v1.0.1",
           //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid")
    
        },
        
    
    
        handleErrorTagList,
        handleResponseTagList,
      });
    };
    




    const handleErrorCreateOrder=(res)=>{
      console.log(" tahzeeb handleErrorCreateOrder",res)
      //razorpay()

    }
    
    const handleResponseCreateOrder=(response)=>{
      console.log("tahzeeb handleResponseCreateOrder",response.data.data)
      rechargeOrderId=response&&response.data.data.orderId
      InitiatedPaymentapi(rechargeOrderId)
      razorpay()
     
      
    }
    
        const CreateOrderRecharge= (e) => {
         
    
     
          const UserJWTAuth = localStorage.getItem('UserJWTAuth');
          console.log("UserJWTAuth",UserJWTAuth)
          setLoader(true)
          console.log("inventory...container")

        
          props.fetchCreateOrderRequest({
            url:  `/consumerbe/api/v2/outlet/-1/order`,
            method:  "POST",
            headers: {
              Authorization: `Bearer ${UserJWTAuth}`,
                "x-app-platform": "web",
              "x-app-version": "v1.0.1",
               //HEADER ERROR
              "x-device-id":localStorage.getItem("uuid"),
             
        
            },
            request:{
             
              
         
                
                  "type": "recharge",
                  "rechargeOptions": {
                      "rfidCardId" :tagDeatils.cardId
                  
                }
              
              
                
              
              },
              
            
        
        
            handleErrorCreateOrder,
            handleResponseCreateOrder,
          });
        };
        





  
        





    const DeactiveModal=()=>{
      setDeactiveModal(true)
    }
    const handlePaymentSuccess = (response) => {
      console.log("handlePaymentSuccess response",response)
      setLoader(false)
      Webwalasocket(rechargeOrderId)
      //PaytmCapture(response.razorpay_payment_id)
      
      TagHistory()
      TagDeatils()
      
      setRechargeModal(false)

    };
    

    const handlePaymentError=()=>{
      setLoader(false)
      setRechargeModal(false)
      setWebSocketFlag(false)
    }
    
  const razorpay=()=>{
    console.log("razorpayMethod",razorpayMethod)
      const payAmount=amount*100
      console.log("razorpay response no")
      setTimeout(() => {
       // navigate('/order/status')
      }, 100000);
      const UserJWTAuth = localStorage.getItem('UserJWTAuth');
      const defaultPgName = localStorage.getItem(' defaultPgName ');
     
     
      const options = {
        key: "rzp_live_ZVpG4pXQKTLFCS",
        amount: `${payAmount}`,
        description:"tahzeeb order",
        //image: logo,
        handler: function (response) {
          console.log("razorpay response",response,response)
          let userKey;
          userKey = UserJWTAuth
          handlePaymentSuccess(response);
          
          const payload = {
            header: {
              oauthToken: UserJWTAuth,
              userkey: userKey,
            },
            data: {
              request: {
                orderId: rechargeOrderId,
                //paymentGateway: orderData.paymentGateway,
                paymentId: 9,
                paymentStatus: 9
                  ? "SUCESS"
                  : "FAILED",
              },
              onClose: function() {
                alert('Payment modal closed!');
               
                document.getElementById('razorpay-container').remove();
              },
              
          
             
            },
          };
          
          
    
          
        },
       
        prefill: {
          email: "info@daalchini.co.in",
            contact:
              JSON.parse(localStorage.getItem("userDetails")) &&
              JSON.parse(localStorage.getItem("userDetails")).mobileNo,
          method:razorpayMethod
            
            
        },
        
        theme: {
          color: "#432B2B",
          hide_topbar: false,
        },
        notes: {
          order_id:rechargeOrderId,
        },
        onpaymenterror: function (error) {
          handlePaymentError(error);
        },
        modal: {
          ondismiss: function (error) {
            console.log("error",error)
            handlePaymentError(error);
          }
        }
      
      };
      
      var rzp1 = new window.Razorpay(options);
      setLoader(false)
      rzp1.open();
    
    }

const handleErrorDeactiveTag=()=>{

}
   
const handleResponseDeactiveTag=()=>{
  navigate("/taglist")
      
}
    const Deactive= () => {
 
      const UserJWTAuth = localStorage.getItem('UserJWTAuth');
      console.log("UserJWTAuth",UserJWTAuth)
      setLoader(true)
      console.log("inventory...container")
    
      props.fetchDeactiveTagRequest({
        url:  `/auth/tag/${uId}/active`,
        method:  "DELETE",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
          "x-app-version": "v1.0.1",
           //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid"),
         
    
        },
    
          
        
    
    
        handleErrorDeactiveTag,
        handleResponseDeactiveTag,
      });
    };



  



const handleErrorTagHistory=(error)=>{

  console.log("khan handleErrorTagHistory", error.response);
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorTagHistory", error.response.data.status.code);
    //Logout
    //localStorage.removeItem("UserJWTAuth")
      //navigate("/login");
}
else{ toast.error(`${error.response.message}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
setLoader(false)
}

const handleResponseTagHistory=(response)=>{
setLoader(false)
  setTagHistory(response.data);
  
}

    const TagHistory= () => {

      let utcD,utcM,utcMM,utcSS,utcHH
      const currentDate = new Date();
      const utcDate = currentDate.getUTCDate();
      const utcMonth = currentDate.getUTCMonth() + 1; // Months are zero-based, so we add 1
      const type = typeof utcMonth;
      console.log("type",type);
      console.log("utcDate ",String(utcDate).length)
      if(String(utcDate).length==1){
        utcD="0"+utcDate
        console.log("utcDate",utcD)
      }
      else{
        utcD=utcDate
      }
     
      if(String(utcMonth).length==1){
      utcM="0"+utcMonth
        console.log("utcM--->",utcM)
      }
      else{
        utcM=utcMonth
      }
      const utcYear = currentDate.getUTCFullYear();
      console.log("tk my date",`${utcMonth}-${utcD}-${utcYear}`); // Output: '5/12/2023' (format: MM/DD/YYYY)
    
    // Get UTC time
    const utcHours = currentDate.getUTCHours();
    const utcMinutes = currentDate.getUTCMinutes();
    const utcSeconds = currentDate.getUTCSeconds();
    console.log("utcHours")
    if(String(utcMinutes).length==1){
    utcMM="0"+utcMinutes
    console.log("utcMM",utcMM)
    }
    else{
    utcMM=utcMinutes
    }
    
    
    if(String(utcSeconds).length==1){
    utcSS="0"+utcSeconds
    console.log("utcMM",utcMM)
    }
    else{
    utcSS=utcSeconds
    }
    
    
    if(String(utcHours).length==1){
    utcHH="0"+utcHours
    console.log("utcMM",utcMM)
    }
    else{
    utcHH=utcHours
    }
      console.log("currentDatecurrentDate",`${utcYear}-${utcM}-${utcD} ${utcHH}:${utcMM}:${utcSS}`)
    
    
      let diffUtcM,diffUtcY
    if((utcM-1>0)){
    
      diffUtcM=utcM-1
      if(String(diffUtcM).length==1){
        diffUtcM="0"+diffUtcM
          console.log("diffUtcM--->",diffUtcM)
        }
        else{
          diffUtcM=diffUtcM
        }
      diffUtcY=utcYear
    }
    else{
    
      diffUtcM=12
      diffUtcY=utcYear-1
    
    }


        
 
      const UserJWTAuth = localStorage.getItem('UserJWTAuth');
      console.log("UserJWTAuth",UserJWTAuth)
      setLoader(true)
      console.log("inventory...container")
    
      props.fetchTagHistoryRequest({
        url:  `/consumer/api/v1/user/order/history`,
        method:  "POST",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
          "x-app-version": "v1.0.1",
           //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid"),
         
    
        },
        request:{    
        
          "pgId": 11 ,
          "uid":`${uId}`,
          
            "from":`${diffUtcY}-${diffUtcM}-${utcD} ${utcHH}:${utcMM}:${utcSS}`,"to":`${utcYear}-${utcM}-${utcD} ${utcHH}:${utcMM}:${utcSS}`
            
      
      
            
      
        
            },
          
        
    
    
        handleErrorTagHistory,
        handleResponseTagHistory,
      });
    };
    
  const handleErrorTagDeatils=(error)=>{
    if(error.response.status==401|| error.response.status==403){

      console.log("khan handleErrorVmInventory", error.response.data.status.code);
      //Logout
      //localStorage.removeItem("UserJWTAuth")
        //navigate("/login");
  }
  else{ toast.error(`${error.response.data.status.code}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
  }
  }
  const handleResponseTagDeatils=(res)=>{
    console.log("TagDeatils tahzeeb",res.data.response)
    setTagDeatils(res.data.response)
  }

    const TagDeatils= () => {
 
      const UserJWTAuth = localStorage.getItem('UserJWTAuth');
      console.log("UserJWTAuth",UserJWTAuth)
      setLoader(true)
      console.log("inventory...container")
    
      props.fetchTagDetailsRequest({
        url:  `/auth/tag/${localStorage.getItem("TagUUID")}`,
        method:  "GET",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
          "x-app-version": "v1.0.1",
           //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid"),
         
    
        },
      
          
        
    
    
        handleErrorTagDeatils,
        handleResponseTagDeatils,
      });
    };


useEffect(()=>{

  if(localStorage.getItem("ChangeRechageMethod")=="/paymentOptionRecharge"){
    setRechargeModal(true)
  }



},[]) 















    return(
        <TagpayHistory
        tagListHistory={tagListHistory}
        Deactive={Deactive}
        deactiveModal={deactiveModal}
        DeactiveModal={DeactiveModal}
        setDeactiveModal={setDeactiveModal}
       
        setAmount={setAmount}
        amount={amount}
        CreateOrderRecharge={CreateOrderRecharge}
        setRechargeModal={setRechargeModal}
        rechargeModal={rechargeModal}
        tagBalance={tagBalance}
        cardType={cardType}
        tagDeatils={tagDeatils}
        setRechargeSucessModal={setRechargeSucessModal}
        rechargeSucessModal={rechargeSucessModal}
        AddIntoCart={AddIntoCart}
        loader={loader}
       
        />


    )
}
function mapStateToProps(props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    
    fetchCreateOrderRequest,
    fetchTagHistoryRequest,
    fetchPaytmCaptureRequest,
    fetchTagDetailsRequest,
    fetchTagListRequest,
    fetchClearPreCardRequest,
    sendAddIntoCartRequest,
    fetchInitiatedPaymentRequest,
    fetchDeactiveTagRequest
  })(TagpayHistoryContainer);

