///////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_ACTIVE_ORDERS_LIST_REQUEST="FETCH_ACTIVE_ORDERS_LIST_REQUEST"
export const FETCH_ACTIVE_ORDERS_LIST_SUCCESS="FETCH_ACTIVE_ORDERS_LIST_SUCCESS"
export const FETCH_ACTIVE_ORDERS_LIST_FAILURE="FETCH_ACTIVE_ORDERS_LIST_FAILURE"


export const fetchActiveOrdersRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_ACTIVE_ORDERS_LIST_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchActiveOrdersSuccess=(ActiveOrders)=>{
return{
    type:FETCH_ACTIVE_ORDERS_LIST_SUCCESS,
    ActiveOrders:ActiveOrders
}
}

export const fetchActiveOrdersFailure=(error)=>{
    return{
    type:FETCH_ACTIVE_ORDERS_LIST_FAILURE,
    error:error
    }
}




///////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_CANCEL_ORDER_REQUEST="FETCH_CANCEL_ORDER_REQUEST"
export const FETCH_CANCEL_ORDER_SUCCESS="FETCH_CANCEL_ORDER_SUCCESS"
export const FETCH_CANCEL_ORDER_FAILURE="FETCH_CANCEL_ORDER_FAILURE"


export const fetchCancelOrderRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_CANCEL_ORDER_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchCancelOrderSuccess=(CancelOrder)=>{
return{
    type:FETCH_CANCEL_ORDER_SUCCESS,
    CancelOrder:CancelOrder
}
}

export const fetchCancelOrderFailure=(error)=>{
    return{
    type:FETCH_CANCEL_ORDER_FAILURE,
    error:error
    }
}