export const FETCH_ORDER_HISTORY_LIST_REQUEST="FETCH_ORDER_HISTORY_LIST_REQUEST"
export const FETCH_ORDER_HISTORY_LIST_SUCCESS="FETCH_ORDER_HISTORY_LIST_SUCCESS"
export const FETCH_ORDER_HISTORY_LIST_FAILURE="FETCH_ORDER_HISTORY_LIST_FAILURE"


export const fetchOrdersHistoryRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_ORDER_HISTORY_LIST_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchOrdersHistorySuccess=(OrderHistory)=>{
return{
    type:FETCH_ORDER_HISTORY_LIST_SUCCESS,
    OrderHistory:OrderHistory
}
}

export const fetchOrdersHistoryFailure=(error)=>{
    return{
    type:FETCH_ORDER_HISTORY_LIST_FAILURE,
    error:error
    }
}