import TopNavbar from "../../header"
import close from '../../../assets/close.png'

import {
    AppBar, Box, Button, Toolbar, Typography, CssBaseline, CircularProgress
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import location_banner from '../../../assets/location_banner.png'
import QrMark from '../../../assets/qrmark.png'
import { toast } from "react-toastify";
import { useState } from "react";
import Webcam from "react-webcam";
import QrReader from "react-web-qr-reader";
//import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
const LocationPage = (props) => {
    const navigate=useNavigate()
    const [showScanner, setShowScanner] = useState(true);
    const [cameraError, setCameraError] = useState(false);
    const [show_location_btn,setShow_location_btn]=useState(true)
    const [show_Qr_Modal,setShow_Qr_Modal]=useState(false)
    const handleError = (error) => {
        console.log("Camera Error", error);
        toast.error(`${error}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setCameraError(true);
        //props.setShow_Qr_Modal(true);
      };
      const [cameraFacingMode, setCameraFacingMode] = useState('environment'); // 'user' for front camera, 'environment' for rear camera
      const videoConstraints = {
        facingMode: 'environment',  // This sets the camera to use the back camera
      };


      function handleScan(result) {
        console.log("contactless",result)
          if (result) {
            // Do something with the result
            console.log("PickupOrderContainer---------->",result);
      
            const machineIdIndex = result&&result.data.indexOf("machineId=");
      
            if (machineIdIndex !== -1) {
              const machineId = result.data.substring(machineIdIndex + 10);
              console.log("======================================",machineId); 
              navigate(`items?machineId=${machineId}`)
             
              } else {
             
                toast.error(`${"Machine ID Not Found"}`, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
              });
           
            }
      
            
            
          }
        }
    
    function ShowQrModalFun(){

        console.log("clicked ShowQrModalFun")



          
        setShow_location_btn(false); // HINDE LOCATION PAGE
      
        setShow_Qr_Modal(true);// Show Qr Modal
      
      } 
      const  CloseQrModalFun=(e)=>{



          
        setShow_location_btn(true); // HINDE LOCATION PAGE
      
        setShow_Qr_Modal(false);// Show Qr Modal
      
      } 

    return (<>

{show_Qr_Modal && (
        <div>

<AppBar position="sticky" style={{ backgroundColor: "#fae14b", boxShadow: "none" }}>
      
<Toolbar style={{ overflowY: "none" }} >
                    <img
                        src={close}  // Replace with the actual path to your logo image
                        alt="Logo"
                        style={{ maxHeight: 20, marginRight: 10 }}
                        onClick={(e)=>{CloseQrModalFun(e)}}
                    />
                </Toolbar>
         
        </AppBar>     
  {!cameraError ? (
            <div>
              <div className="mt-[60px] h-full w-full backdrop-blur-md absolute top-0 left-0"></div>

               <Webcam  videoConstraints={videoConstraints}  className="object-cover h-screen w-screen" />  
              <div className="mt-[80px] w-[200px] h-[200px] border-4 border-[var(--color-primary)] rounded-md flex justify-center items-center absolute top-1/4 left-1/4  ">
                {showScanner && (
                      <QrReader
                      delay={50}
                      onError={handleError}
                      onScan={handleScan}
                      facingMode={cameraFacingMode}
                      style={{width:"100%",border:"none!important"}}
                      //className="object-cover rounded-md w-full h-full"
                               />
                )}
              </div> 
            </div>
          ) : (
            <>
              {" "}
              <p style={{ textAlign: "center", paddingTop: "50%" }}>
                {" "}
                No Camra Permission Reset Your Setting
              </p>
            </>
          )}
        </div>
      )}


      
      {show_location_btn&&<>  <TopNavbar LoctionPinHeader={false} QrMarkHeader={false}/>
      
        <Box sx={{display:{ xs: "block", sm: "none" } }}>



            <Box
                sx={{
                    backgroundImage: ' linear-gradient(to bottom, #ffffff, #f4fcff)',
                    minHeight: '100vh',
                    padding: "5%",

                    color: '#fff', // Set text color to white
                }}
            >


                {/* Your content below the header goes here */}
                <Typography variant="h4" fontSize={"20px"} marginTop={{ xs: '5%', md: '0%' }} fontWeight={600} textAlign={"center"} color={"rgba(56, 79, 111, 1)"} component="div" gutterBottom>
                    Request to location access
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center" }} marginTop={{ xs: '5%', md: '0%' }}>
                    <img
                        src={location_banner} // Replace with the actual image URL
                        alt="Your Image Alt Text"
                        style={{ width: '100%', maxWidth: '600px', padding: "5%" }} // Adjust the width as needed
                    />
                </Box>
                <Typography variant="h4" textAlign={"center"} marginTop={{ xs: '5%', md: '0%' }} fontSize={"13px"} color={"rgba(100, 116, 139, 1)"} component={"div"} >We need to know where you are in order to find nearby vending machines</Typography>

                {props&&props.location_Btn_disable?<Button
                    variant="contained"
                    sx={{height:"49px", marginTop: { xs: '10%', md: '0%' }, width: '100%', backgroundColor: 'rgb(188, 189, 189)' }}
                    onClick={"handleButtonClick"}
                >
                    <CircularProgress style={{ color: "green" ,width:"28px",height:"28px"}} />
                </Button>:   <Button
                    variant="contained"
                    sx={{ height:"49px",marginTop: { xs: '10%', md: '0%' }, width: '100%', background: 'rgba(0, 177, 119, 1)' }}
                    onClick={props&&props.getFirstLocation}
                >
                   <Typography fontWeight={600} fontSize={"12px"}> Enable location access</Typography>
                </Button>}
                <Typography  fontSize={"14px"}  fontWeight={400} textAlign={"center"} marginTop={"10%"} color={"rgba(148, 163, 184, 1)"}>or</Typography>
                <Typography fontSize={"13px"} fontWeight={400} textAlign={"center"} marginTop={"10%"} color={"rgba(148, 163, 184, 1)"}> scan Qr Code to see the products</Typography>
               <Box sx={{display:"flex"}} justifyContent={"center"}>
                <Box  onClick={(e)=>{ShowQrModalFun(e)}} sx={{display:"flex",backgroundColor: "rgb(255 255 255)",width:"79px",height:"77px",borderRadius:"10px",boxShadow:"0px 4px 17px 0px rgba(148, 163, 184, 0.54)"}} justifyContent="center" alignItems={"center"} marginTop={{ xs: '5%', md: '0%' }}>
                <img
                        src={QrMark} 
                        alt="Your Image Alt Text"
                        style={{ width: '52px', maxWidth: '600px', padding: "5%" }} // Adjust the width as needed
                    />
                </Box>
                </Box>
            </Box>

        </Box> </>}


    </>)

}

export default LocationPage;