import Banner_Container from "../Banners/container";
import HeaderSecondary from "../Header/HeaderSecondary";
import Procced from "../Procced/component";
import ActiveOrdersBar from "../ActiveOrdersBar/component";
import WaitingLoder from "../WaitingLoader/component"
import VmInventoryHeader from "../Header/VmInventoryHeader";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReplceCard from '../../assets/ReplceCard.png'
import MixPnl, { mpCart, mpClick, mpSearch, mpSearchResult } from "../../Mixpanel";
import DiscountLabel from '../../assets/DiscountLabel.png'
import { Search } from "@mui/icons-material";
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import {
  AppBar, Box, Button,CardMedia,Skeleton, Card,CardContent, Typography, CssBaseline, CircularProgress,TextField,InputAdornment
} from '@mui/material';
import GiftCardReplace from "./ComboReplacePop";
import ComboReplace from "./ComboReplacePop";
const VMInventory = (props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [data,setData]=useState([])
  const [searchText,setSearchText]=useState('')

    const handleClear=()=>{
      props&&props.setSearchTerms('')
    }

  function TruncatedVMnameHeading({ text, limit }) {
    if (text && text.length <= limit) {
      return <>{text}</>;
    }
    return <>{`${text.substring(0, limit)}...`}</>;
  }

  function TruncatedVMAddressHeading({ text, limit }) {
    if (text && text.length <= limit) {
      return <>{text}</>;
    }
    return <>{`${text.substring(0, limit)}...`}</>;
  }

    function TruncatedVmname({ text, limit }) {
        if (text&&text.length <= limit) {
          return <span style={{ fontSize: "14px", color: "#384F6F" }}>{text}</span>;
        }
        return (
          <span style={{ fontSize: "14px", color: "#384F6F" }}>{`${text&&text.substring(
            0,
            limit
          )}`}</span>
        );
      }
      function TruncatedDescription({ text, limit }) {
        if (text && text.length <= limit) {
          return (
            <
          
            >
              {text}
            </>
          );
        }
        return (
          <>{`${text&&text.substring(0, limit)}...`}</>
        );
      }

      function TruncatedVMnameHeading({ text, limit }) {
        if (text && text.length <= limit) {
          return <>{text}</>;
        }
        return <>{`${text&&text.substring(0, limit)}...`}</>;
      }
    
      function TruncatedVMAddressHeading({ text, limit }) {
        if (text && text.length <= limit) {
          return <>{text}</>;
        }
        return <>{`${text&&text.substring(0, limit)}...`}</>;
      }
    function TruncatedTray({ text, limit }) {
        if (text && text.length <= limit) {
          return (
            <
          
            >
              {text}
            </>
          );
        }
        return (
          <>{`${text&&text.substring(0, limit)}`}</>
        );
      }
      function TruncatedHeading({ text, limit }) {
        if (text && text.length <= limit) {
          return (
            <h3
              style={{
                fontSize: "12px",
                "margin-bottom": "5px",
                color: "#384F6F",
                fontFamily: "Roboto",
              }}
            >
              {text}
            </h3>
          );
        }
        return (
          <h3
            style={{ fontSize: "12px", color: "#384F6F", "margin-bottom": "5px" }}
          >{`${text.substring(0, limit)}...`}</h3>
        );
      }


      const ComboData= props&&props.comboList&&props.comboList[0]&&props.comboList.map((p) => {


          
          if (((p.id) == (props&&props.comboCart&&props.comboCart.id))&&props.preMachineID==props.currentMachineID) {
          
            
            //setTotalAmount(totalamount+p.offer_price)
           
            
            return { ...p, mycount: props&&props.comboCart&&props.comboCart.count};
          }
          return p;
        });
      


      const ComboComponent = ({ data }) => {


        return (
          <>
<Box>
        {(data&&data[0])&&<Typography style={{color:"rgba(113, 129, 152, 1)",fontSize:"17px",fontWeight:"500",marginTop:"5%"}}>Combo Offer</Typography>}

          <Box
            className="styles_slider__podEu"
            data-testid="grid-slider"
            style={{ marginTop: "10px" }}
          >
      
     
            {data && data[0]&&
              data.map((el, index) => {
              
                return (
                
                    <Box
                   
                   marginBottom={"5%"}
                   boxShadow={"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"}
                  
                   borderRadius={"16px"}
                      width={"100%"}

                      style={{background:"white"}}
              
                    >
             
                    
                 <Box margin={"10px"}>
                              <Box
                               
                              >
                                <Box
                                  className="styles_ImageContainer__2rk9a"
                                 
                                  style={{
                                    background: "rgb(255, 255, 255)",
                                    height: "129px",
                                    width:"114px",
                                    "margin-bottom": "11px"                                  
                                  }}
                                >
                                  <img
                                    className="styles_Image__1fplJ"
                                    loading="lazy"
                                  
                                    src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.image}`}
                                  />
                                </Box>
                      
                              </Box>
                              <Box
                              
                                className="styles_containerRestaurant__3vhx3"
                                style={{ padding: "12px!important" }}
                              >
                                <div
                                  className="styles_containerImageBadge__14fk3"
                                  style={{ fontFamily: "Roboto" }}
                                >
                                  <div
                                    data-testid="resturant-card-name"
                                    className="styles_restaurantName__29jAP"
                                    style={{ fontSize: "14px",marginTop:"10px" }}
                                  >
                                    <TruncatedVmname
                                      text={el.name}

                                      limit={25}
                                      
                                    />
                                  </div>
                         {/*          <div
                                    style={{ color: "#94A3B8", fontSize: "12px",marginRight:"8px" }}
                                  >
                                    Tray <TruncatedTray text={el&&el.slot_id&&el.slot_id.toString()} limit={1}/>
                                  </div> */}
                                </div>
  
                                <div
                                 
                                  style={{  }}
                                >
                                  <span
                                    data-testid="restaurant-card-cuisines"
                                    style={{
                                      fontSize: "12px",
                                      color: "rgba(148, 163, 184, 1)",
                                      fontFamily: "Roboto",
                                      
  
  
  "font-style": "normal",
  "font-weight": "400",
  "line-height": "20px", 
                                    }}
                                  >
                                 <span style={{visibility:""}}><TruncatedDescription  text={el&&el.description} limit={25} /></span> 
                                  <Typography fontSize={"12px"} style={{color:"rgba(113, 129, 152, 1)"}}>{el.activeCount>0&&<span>{el.activeCount}{" "}Left</span>}</Typography>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    "margin-top": el.active_count>1?"0px":"42px",
                                  }}
                                >
                                <div style={{display:"flex",    "justify-content": "space-evenly",
    "align-items": "center"}}>
                                  <h2
                                    style={{ color:"#718198",
                                    
                                      /* SM14 */
                                      "font-family":"Roboto",
                                      "font-size": "14px",
                                      "font-style":"normal",
                                      "font-weight":"600",
                                      "line-height": "150%" ,/* 21px */
                                      "letter-spacing":"0.2px"}}
                                  >
                                    Rs.{""} {el&&el.price.toFixed(2)}
                                  </h2>
                                  &nbsp;  &nbsp; 
                                  <h2
                                    style={{ color:"#718198",
                                     
                                      /* SM14 */
                                      "font-family":"Roboto",
                                      "font-size": "10px",
                                      "font-style":"normal",
                                      "font-weight":"600",
                                      "line-height": "150%" ,/* 21px */
                                      'textDecoration':"line-through",
                                      "color": "#1D243378"

                                      }}
                                  >
                                   ₹{""} {el&&el.actualPrice.toFixed(2)}
                                  </h2>
                                  </div>

                                 
                                  {
                                  
                                  
                                  el.mycount > 0 ?(
                                    <div
                                      style={{
                                        border: "2px solid rgb(0, 177, 119)",
                                        display: "flex",
                                        width: "100px",
                                        height:"32px",
                                        backgroundColor: "rgb(0, 177, 119)",
                                        color: "white",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        padding: "10px",
                                       
                                        borderRadius: "7px",
                                        
                                      }}
                                    >
                                      <h3
                                        onClick={(e) => {
                                          props.ComboCartRemove(
                                            e,
                                            index,
                                            el.mycount,
                                            el.id
                                          );
                                        }}
                                      >
                                        -
                                      </h3>
                              
                                                                    {el.id==props&&props.comboCart.id?props.comboCart.count:el.mycount} 
                                     
                                      <h3
                                        onClick={(e) => {
                                          props.ComboCartADD(
                                            e,
                                            index,
                                            el.mycount,
                                            el.id
                                          );
                                        }}
                                      >
                                        +
                                      </h3>
                                    </div>
                                  
                                ) : (
                                    <>
                                      {el.activeCount>0? (
                                        <div
                                          style={{
                                            border: "2px solid rgb(0, 177, 119)",
                                            display: "flex",
                                            width: "100px",
                                            
                                            height:"32px",
                                            "background-color":
                                              "rgba(0, 177, 119, 0.04)",
                                            color: "#00B177",
  
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                            padding: "10px",
                                            borderRadius: "7px",
                                           
                                            fontSize: "14px",
                                            "font-weight": "500",
                                          }}
                                          //onClick={(e)=>{props.increaseQuantity(e,index,el.slot_id)}}
                                          
                                          onClick={(e) =>
                                            props.ComboCartADD(
                                              e,
                                              index,
                                              el.mycount,
                                              el.id
          
                                            )
                                          }
                                        >
                                          ADD
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100px",
                                            height:"32px",
                                            "background-color": "#8080803d",
                                            color: "white",
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                            padding: "10px",
                                            borderRadius: "7px",
                                           
                                            "font-weight": "500",
                                          }}
                                        >
                                          ADD
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </Box>
                              </Box>
                    
                          <div />
                        
                      </Box>
                 
                 
                );
              })}
          
          </Box>
        </Box>
            <>
        
     
        
        </>
          
          </>
        


        );
      };


      const TodaySpecialComponent = ({ data }) => {

   

          return (
            <>
  <Box>
          <Typography style={{color:"rgba(113, 129, 152, 1)",fontSize:"17px",fontWeight:"500",marginTop:"5%"}}>Today’s Special</Typography>

            <Box
              className="styles_slider__podEu"
              data-testid="grid-slider"
              style={{ marginTop: "10px" }}
            >
       
              {data && data[0]&&
                data.map((el, index) => {
                  return (
                  
                      <Box
                     
                     marginBottom={"5%"}
                     boxShadow={"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"}
                    
                     borderRadius={"16px"}
                        width={"100%"}

                        style={{background:"white"}}
                
                      >
               
                      
                   <Box margin={"10px"}>
                                <Box
                                 
                                >
                                  <Box
                                    className="styles_ImageContainer__2rk9a"
                                   
                                    style={{
                                      background: "rgb(255, 255, 255)",
                                      height: "92px",
                                    }}
                                  >
                                    <img
                                      className="styles_Image__1fplJ"
                                      loading="lazy"
                                    
                                      src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.image_path}`}
                                    />
                                  </Box>
                        
                                </Box>
                                <Box
                                
                                  className="styles_containerRestaurant__3vhx3"
                                  style={{ padding: "12px!important" }}
                                >
                                  <div
                                    className="styles_containerImageBadge__14fk3"
                                    style={{ fontFamily: "Roboto" }}
                                  >
                                    <div
                                      data-testid="resturant-card-name"
                                      className="styles_restaurantName__29jAP"
                                      style={{ fontSize: "14px" }}
                                    >
                                      <TruncatedVmname
                                        text={el.mv_name}
                                        limit={25}
                                      />
                                    </div>
                                    <div
                                      style={{ color: "#94A3B8", fontSize: "12px",marginRight:"8px" }}
                                    >
                                      Tray <TruncatedTray text={el&&el.slot_id&&el.slot_id.toString()} limit={1}/>
                                    </div>
                                  </div>
    
                                  <div
                                   
                                    style={{  }}
                                  >
                                    <span
                                      data-testid="restaurant-card-cuisines"
                                      style={{
                                        fontSize: "12px",
                                        color: "rgba(148, 163, 184, 1)",
                                        fontFamily: "Roboto",
                                        
    
    
    "font-style": "normal",
    "font-weight": "400",
    "line-height": "20px", 
                                      }}
                                    >
                                   <span style={{visibility:"hidden"}}><TruncatedDescription  text={el.mv_description} limit={25} /></span> 
                                    <Typography fontSize={"12px"} style={{color:"rgba(113, 129, 152, 1)"}}>{el.active_count>0&&<span>{el.active_count}{" "}Left</span>}</Typography>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "flex-end",
                                      "margin-top": el.active_count>1?"0px":"11px",
                                    }}
                                  >
                                    <h2
                                      style={{ color:"#718198",
                                        "text-align": "right",
                                        /* SM14 */
                                        "font-family":"Roboto",
                                        "font-size": "14px",
                                        "font-style":"normal",
                                        "font-weight":"600",
                                        "line-height": "150%" ,/* 21px */
                                        "letter-spacing":"0.2px"}}
                                    >
                                      Rs.{""} {el&&el.offer_price&&el.offer_price.toFixed(2)}
                                    </h2>
                                   
                                    {el.mycount > 0 ? (
                                      <div
                                        style={{
                                          border: "2px solid rgb(0, 177, 119)",
                                          display: "flex",
                                          width: "100px",
                                          height:"32px",
                                          backgroundColor: "rgb(0, 177, 119)",
                                          color: "white",
                                          justifyContent: "space-around",
                                          alignItems: "center",
                                          padding: "10px",
                                         
                                          borderRadius: "7px",
                                          
                                        }}
                                      >
                                        <h3
                                          onClick={(e) => {
                                            props.TodayRemovefromCart(
                                              e,
                                              el.index,
                                              el.slot_id,
                                              el.mycount,
                                              data.mv_name,
                                              data.mv_id,
                                              data.offer_price
                                            );
                                          }}
                                        >
                                          -
                                        </h3>
                                        {el.mycount}
                                        <h3
                                          onClick={(e) => {
                                            props.TodayAddIntoCart(
                                              e,
                                              el.index,
                                              el.slot_id,
                                              el.mycount,
                                              data.mv_name,
                                              data.mv_id,
                                              data.offer_price
                                            );
                                          }}
                                        >
                                          +
                                        </h3>
                                      </div>
                                    ) : (
                                      <>
                                        {el.active_count>0? (
                                          <div
                                            style={{
                                              border: "2px solid rgb(0, 177, 119)",
                                              display: "flex",
                                              width: "100px",
                                              
                                              height:"32px",
                                              "background-color":
                                                "rgba(0, 177, 119, 0.04)",
                                              color: "#00B177",
    
                                              justifyContent: "space-around",
                                              alignItems: "center",
                                              padding: "10px",
                                              borderRadius: "7px",
                                             
                                              fontSize: "14px",
                                              "font-weight": "500",
                                            }}
                                            //onClick={(e)=>{props.increaseQuantity(e,index,el.slot_id)}}
                                            
                                            onClick={(e) =>
                                              props.TodayAddIntoCart(
                                                e,
                                                el.index,
                                                el.slot_id,
                                                el.mycount,
                                                data.mv_name,
                                                data.mv_id,
                                                data.offer_price
            
                                              )
                                            }
                                          >
                                            ADD
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100px",
                                              height:"32px",
                                              "background-color": "#8080803d",
                                              color: "white",
                                              justifyContent: "space-around",
                                              alignItems: "center",
                                              padding: "10px",
                                              borderRadius: "7px",
                                             
                                              "font-weight": "500",
                                            }}
                                          >
                                            ADD
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </Box>
                                </Box>
                      
                            <div />
                          
                        </Box>
                   
                   
                  );
                })}
            
            </Box>
          </Box>
              <>
          
       
          
          </>
            
            </>
          
  

          );
        };
  
  
  
  
  




      const matchingData =
      props && props.cartItemsList && props.cartItemsList[0]
        ? props.cartItemsList
        : props.cartItemsList1;
 
  
    const mergedData =
      props &&
      props.filteredproducts &&
      props.filteredproducts.map((item) => {
        const matchingItem = matchingData&&matchingData.find(
          (matchItem) =>
            matchItem.slotId === item.slot_id&&props.preMachineID===props.currentMachineID
        );
    
        return {
          ...item,
          mycount:
            matchingItem && matchingItem.count && matchingItem.count
              ? matchingItem.count
              : item.mycount,
        };
      });



      let slot=localStorage.getItem("Global_Slot_id")

      const sortedArray1 =
      mergedData &&
      mergedData.sort((a, b) => {
    
        if (a.slot_id === parseInt(slot)) return -1;
        if (b.slot_id === parseInt(slot)) return 1;
        return 1;
    });  


      const sortedArray=sortedArray1
      useEffect(()=>{
        {props&&props.searchTerms&&mpSearchResult({EventName:"Item_search_successful",data:{search_query:props&&props.searchTerms,count:sortedArray&&sortedArray.length}})}

      },[props.searchTerms])




      const InventoryComponent = () => {
        
      
     

          
          let slot=localStorage.getItem("Global_Slot_id")

    
       const sortedArray1 =
          data &&
          data.sort((a, b) => {
         
            if (a.slot_id === parseInt(slot)) return -1;
            if (b.slot_id === parseInt(slot)) return 1;
            return 1;
        });  
    
             
               
        return (
          <Box
            data-testid="grid-container"
            aria-hidden="false"
            style={{
              "paddingBottom":Boolean((props&&props.ActiveOrders&&props.ActiveOrders[0])&&(props&&props.PreCartVmInventoryList&&props.PreCartVmInventoryList[0]))?"7.5rem":Boolean(props&&props.ActiveOrders&&props.ActiveOrders[0])?"4.5rem":props&&props.PreCartVmInventoryList&&props.PreCartVmInventoryList[0]&&"3.5rem",

            }}
          >
            <Box
              className="styles_slider__podEu"
              data-testid="grid-slider"
              style={{ marginTop: "10px" }}
            >

              {sortedArray && sortedArray[0]&&
                sortedArray.map((el, index) => {
                  return (
                  
                      <Box
                     
                     marginBottom={"5%"}
                     boxShadow={"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"}
                    
                     borderRadius={"16px"}
                        width={"100%"}

                        style={{background:"white"}}
                        height={el.offer_details&&"138px"}
                
                      >
               
                      
                   <Box margin={"10px"}>
                                <Box
                                 
                                >
                                  <Box
                                    className="styles_ImageContainer__2rk9a"
                                   
                                    style={{
                                      background: "rgb(255, 255, 255)",
                                      height: el.active_count>1?"91px":"87px",
                                    
                                    }}
                                  >
                                    <img
                                      className="styles_Image__1fplJ"
                                      loading="lazy"
                                    
                                      src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.image_path}`}
                                    />
                                  </Box>
                        
                                </Box>
                                <Box
                                
                                  className="styles_containerRestaurant__3vhx3"
                                  style={{ padding: "12px!important" }}
                                >
                                  <div
                                    className="styles_containerImageBadge__14fk3"
                                    style={{ fontFamily: "Roboto" }}
                                  >
                                    <div
                                      data-testid="resturant-card-name"
                                      className="styles_restaurantName__29jAP"
                                      style={{ fontSize: "14px" }}
                                    >
                                      <TruncatedVmname
                                        text={el.mv_name}
                                        limit={25}
                                      />
                                    </div>
                                    <div
                                      style={{ color: "#94A3B8", fontSize: "12px",marginRight:"8px" }}
                                    >
                                      Tray <TruncatedTray text={el.slot_id.toString()} limit={1}/>
                                    </div>
                                  </div>
    
                                  <div
                                   
                                    style={{  }}
                                  >
                                    <span
                                      data-testid="restaurant-card-cuisines"
                                      style={{
                                        fontSize: "12px",
                                        color: "rgba(148, 163, 184, 1)",
                                        fontFamily: "Roboto",
                                        
    
    
    "font-style": "normal",
    "font-weight": "400",
    "line-height": "20px", 
                                      }}
                                    >
                                    <TruncatedDescription  text={el.mv_description} limit={25} />
                                    <Typography fontSize={"12px"} style={{color:"rgba(113, 129, 152, 1)"}}>{el.active_count>0&&<span>{el.active_count}{" "}Left</span>}</Typography>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "flex-end",
                                      "margin-top": el.active_count>1?"0px":"11px",
                                    }}
                                  >
                                    <h2
                                      style={{ color:"#718198",
                                        "text-align": "right",
                                        /* SM14 */
                                        "font-family":"Roboto",
                                        "font-size": "14px",
                                        "font-style":"normal",
                                        "font-weight":"600",
                                        "line-height": "150%" ,/* 21px */
                                        "letter-spacing":"0.2px"}}
                                    >
                                      Rs.{""} {el.offer_price.toFixed(2)}
                                    </h2>
                                   
                                    {el.mycount > 0 ? (
                                      <div
                                        style={{
                                          border: "2px solid rgb(0, 177, 119)",
                                          display: "flex",
                                          width: "100px",
                                          height:"32px",
                                          backgroundColor: "rgb(0, 177, 119)",
                                          color: "white",
                                          justifyContent: "space-around",
                                          alignItems: "center",
                                          padding: "10px",
                                         
                                          borderRadius: "7px",
                                          
                                        }}
                                      >
                                        <h3
                                          onClick={(e) => {
                                            props.RemovefromCart(
                                              e,
                                              el.index,
                                              el.slot_id,
                                              el.mycount,
                                              data.mv_name,
                                              data.mv_id,
                                              data.offer_price
                                            );
                                          }}
                                        >
                                          -
                                        </h3>
                                        {el.mycount}
                                        <h3
                                          onClick={(e) => {
                                            props.AddIntoCart(
                                              e,
                                              el.index,
                                              el.slot_id,
                                              el.mycount,
                                              data.mv_name,
                                              data.mv_id,
                                              data.offer_price
                                            );
                                          }}
                                        >
                                          +
                                        </h3>
                                      </div>
                                    ) : (
                                      <>
                                        {el.active_count>0 && el.active ? (
                                          <div
                                            style={{
                                              border: "2px solid rgb(0, 177, 119)",
                                              display: "flex",
                                              width: "100px",
                                              
                                              height:"32px",
                                              "background-color":
                                                "rgba(0, 177, 119, 0.04)",
                                              color: "#00B177",
    
                                              justifyContent: "space-around",
                                              alignItems: "center",
                                              padding: "10px",
                                              borderRadius: "7px",
                                             
                                              fontSize: "14px",
                                              "font-weight": "500",
                                            }}
                                            //onClick={(e)=>{props.increaseQuantity(e,index,el.slot_id)}}
                                            
                                            onClick={(e) =>
                                              props.AddIntoCart(
                                                e,
                                                el.index,
                                                el.slot_id,
                                                el.mycount,
                                                data.mv_name,
                                                data.mv_id,
                                                data.offer_price
            
                                              )
                                            }
                                          >
                                            ADD
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100px",
                                              height:"32px",
                                              "background-color": "#8080803d",
                                              color: "white",
                                              justifyContent: "space-around",
                                              alignItems: "center",
                                              padding: "10px",
                                              borderRadius: "7px",
                                             
                                              "font-weight": "500",
                                            }}
                                          >
                                            ADD
                                          </div>
                                        )}

                                      </>
                                     
                                    )}
                                  </div>
                                 
                               
                                </Box>
                               
                              
                                </Box>
                               {el.offer_details&& <hr style={{border:"1px dashed rgba(148, 163, 184, 0.6)",marginTop:"2%"}}/>}
                               {el.offer_details&& <div style={{display:"flex",marginLeft:"3%"}}><img  style={{ width:"21px",height:"21px"}} src={DiscountLabel}/> <h1 style={{marginLeft:"2%",fontWeight:"600",color:"rgba(0, 161, 255, 1)"}}>{el.offer_details.flatAmount}{el.offer_details.flatTypeId==2&&"%"} {el.offer_details.flatTypeId==1&&"Rs"}{" "}{el.offer_details.couponType=="CashBack"&&"Cashback"} {el.offer_details.couponType=="Discount"&&"OFF"} </h1> </div>}
                                
                      
                          
                        </Box>
                   
                   
                  );
                })}
            </Box>
          </Box>
        );
      };
    



      const BuyAgian=()=>{
        return(
        
        <>
        
        <Box>
                      {!props.searchTerms&&<span
                        className="styles_headerContainerSubtitle__1WRg5"
                        aria-hidden="true"
                      >
                        {props&&props.buyAgainList&&props.buyAgainList[0]&&<h3
                          style={{
                            marginTop: "10%",
                          
                            fontSize: "17px",
                            color: "#94A3B",
                            fontFamily: "Roboto",
                            "letter-spacing": "0.2px",
                            "font-weight": "500",
                           
                            color: " rgba(113, 129, 152, 1)",
                          }}
                        >
                         Buy Again!{" "}
                        </h3>}
                      </span>}
                      <Box style={{width:"100%",overflowX:"scroll"}} >
      
      
      
                      { !props.searchTerms&&<Box
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "scroll",
          width: "100%",
         
        }}
      >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "scroll",
          width: "100%",
          height:"189px",
          marginTop:"3%"
          
        }}
      >
        {props&&props.buyAgainList&&props.buyAgainList[0]&&props.buyAgainList.map((el,index) =>(
          
         <Box 
            //key={index}
            style={{
              width: "141px",
              
              padding: "10px",
              borderRadius: "16px",
              border: "1px solid rgba(56, 79, 111, 0.08)",
              background: "#FFF",
              boxShadow: "0px 4px 10px 0px rgba(56, 79, 111, 0.12)",
              marginRight: "10px", // Optional margin between divs
              height:"171px"
      
            }}
          >
            <Box style={{    display: "flex","justify-content":"space-around"}}>
          <img style={{width: "73px","height":"73px",borderRadius:"10px"}} src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.image_path}`}
      />
      </Box>
      
      <h1 style={{color:"#333F4E","text-align":"center","font-family":"Roboto","font-size":"13px","font-style":"normal","font-weight":"500"}}><TruncatedVMnameHeading text={el.mv_name} limit={10}/></h1>
      <h1 style={{color:  "#718198","font-family":"Roboto",
      "font-size":"10px",
      "font-style":"normal",
      "font-weight": "400",
      "line-height":"16px",textAlign:"center",    width:'100%'}}><TruncatedVMAddressHeading text={`Rs. ${el.offer_price.toFixed(2)}`} limit={34}/></h1>
      {mergedData&&mergedData.map((data,index) =>(
       
      
      <>{el.slot_id==data.slot_id&&<div>
      
       {data.mycount>0?
       <div style={
        {width:"108px",
        "margin-top": "5px",
      height:"32px",
      "top": "496px",
      left: "26px",
      "padding":" 4px 16px 0px 16px",
      "border-radius": "8px",
      textAlign:"center",
      border: "1px",
      gap: "10px",
      border: "1px solid rgba(0, 177, 119, 1)",
      "background-color": "rgb(18, 154, 105)"
      }}><div style={{display:"flex", 
      "justify-content": "space-around",}}>{console.log("BUyAgain match slot",el,data)}
       <h3       style={{color:"white"}}        onClick={(e) => {
        props.RemovefromCart(
          e,
          index,
          data.slot_id,
          data.mycount,
          data.mv_name,
          data.mv_id,
          data.offer_price
        );
      }}>-</h3><span   style={{color:"white"}}>{data.mycount}</span><h3    style={{color:"white"}} onClick={(e) => {
        props.AddIntoCart(
          e,
          index,
          data.slot_id,
          data.mycount,
          data.mv_name,
          data.mv_id,
          data.offer_price
        )}}>+</h3></div></div>:  <>
        {el.active_count>0?
        <div 
         
         onClick={(e) =>
           props.AddIntoCart(
             e,
             data.index,
             data.slot_id,
             data.mycount
           )
         }
         
         style={{
           width:"108px",
           "margin-top": "5px",
         background:"linear-gradient(0deg, rgba(0, 177, 119, 0.04), rgba(0, 177, 119, 0.04))",
         color: "rgb(0, 177, 119)",
         
         height:"32px",
         "top": "496px",
         left: "26px",
         "padding":" 4px 16px 0px 16px",
         "border-radius": "8px",
         textAlign:"center",
         border: "1px",
         gap: "10px",
         border: "1px solid rgba(0, 177, 119, 1)"
         }}>
         
         
         
         ADD</div>:<div 
         
       
         
         style={{
           width:"108px",
           "margin-top": "5px",
           backgroundColor:"rgba(128, 128, 128, 0.24)",
         color: "#ffffff",
         
         height:"32px",
         "top": "496px",
         left: "26px",
         "padding":" 4px 16px 0px 16px",
         "border-radius": "8px",
         textAlign:"center",
         border: "1px",
         gap: "10px",
        
         }}>
         
         
         
         ADD</div>}</>
        
      }</div> }</>
      
      ))}
      
      
          </Box> 
        ))}
      </div> 
      
      </Box> }
       
      
      
                      
                      </Box>
                    </Box>
        
        </>)
      }

      const ClearModalClose=()=>{

        props.setReplceCard(false);
        mpClick({EventName:"Clear_Cart_Modal_Closed"})
  
      }

const searchTerms=(e)=>{
  props.setSearchTerms(e.target.value)
  mpSearch({EventName:"Item_search_performed",data:{search_query:e.target.value}})
  

}





    
  return (
    <>
  {props && props.replaceCard && (
            <div className="modal">
              <div className="modal-content">
                <img
                  src={ReplceCard}
                  style={{ position: "absolute", top: "-41px", left: "39%",    width: "73px" }}
                />
                <div style={{ "text-align": "center", "margin-top": "15%" }}>
                  <h3 style={{color: "#384F6F",
"text-align": "center",
"font-family": "Roboto",
"font-size":"16px",
"font-style": "normal",
"font-weight": "700",
"line-height": "28px", /* 175% */
"letter-spacing":"1px"}}>Replace cart items ?</h3>
                  <p style={{ marginTop: "3%" ,color: "rgba(56, 79, 111, 0.69)",
"text-align":"center",
"font-family": "Roboto",
"font-size":"12px",
"font-style": "normal",
"font-weight": "400",
"line-height":"20px"}}>
                  Your current cart will be cleared when you add items from a different vending machine.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    "justify-content": "space-around",
                    marginTop: "3%",
                  }}
                >
                  <button
                    onClick={(e) => {
                     ClearModalClose(e)
                    }}
                    className="yesbtn"
                    style={{display: "flex",
                      "width":"108px",
                      "height": "41px",
                      "padding":"10px",
                      "justify-content": "center",
                      "align-items":"center",
                      "gap": "10px",
                      "flex-shrink":"0",}}
                  >
                    NO
                  </button>
                  <button onClick={props&&props.replaceMyCard} className="nobtn" style={{display: "flex",
width:"108px",
"height": "41px",
"padding":"10px",
"justify-content": "center",
"align-items": "center",
gap: "10px","flex-shrink":"0"}}>
                    YES
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* props&&props.comboReplaceflag */ false&&<ComboReplace close={props&&props.setComboReplaceflag}  replaceMyCombo={props&&props.replaceMyCombo}/>}
<VmInventoryHeader
              title={props && props.MachineName
              }
              subtitle={props && props.MachineAddress}
              route="/"
            />
      {props.loader && <WaitingLoder />}

    
   {props&&props.activeOrderList&&props.activeOrderList[0]&&<ActiveOrdersBar
        
        itemname={props && props.activeOrderList[0]&&props.activeOrderList[0].lineItems[0].productName}
        itemlength={props && props.activeOrderList[0]&&props.activeOrderList[0].lineItems.length}
        order_id={props && props.activeOrderList[0]&&props.activeOrderList[0].orderId}
        SourcePage="Vm_Inventory_Page"
        orderLength={props && props.activeOrderList&&props.activeOrderList.length}
        Top={props&&props.activeOrderList&&props.activeOrderList[0]?props&&props.cartItemsList&&props.cartItemsList[0]?"70px" :props&&props.comboCart==null?"21px":"70px":"65"}
        />}

       {props&&props.cartItemsList&&props.cartItemsList[0]&&   <Procced
                cartItemsList={props&&props.cartItemsList&&props.cartItemsList}
                cartCount={props&&props.totalitemcount}
                totalamount={props&&props.carttotalAmount}
                bottom={"-5px"} 
                mixpanelEvent={"Cart_icon_clicked"}
              />}


{props&&props.comboCart&&props.comboCart&&   <Procced
                //cartItemsList={props&&props.comboCart&&props.comboCart.count}
                cartCount={props&&props.comboCart&&props.comboCart.count}
                totalamount={props&&props.comboCart&&props.comboCart.totalPrice}
                bottom={"-5px"} 
                mixpanelEvent={"Cart_icon_clicked"}
              />} 


  
<Box    sx={{display:{xs:"block",md:"",sm:"block"},
        //backgroundImage: ' linear-gradient(to bottom, #ffffff, #f4fcff)',
        background: "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
        minHeight: '100vh',
        padding: "12px",

        color: '#fff', // Set text color to white
    }}
>
   
            <MixPnl page={"Vm_page_opened"}/>

              <Banner_Container vmid={props&&props.machineId} />
         
          
              <TextField
     /*  onClick={(e) => globalSearchflag(e)} */
      sx={{
        background: "white",
        borderRadius: "10px",
        border: "none",
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'your-border-color', // customize border color
          },
          '& input': {
            fontSize: '15px', // customize font size
            color: 'background: rgba(56, 79, 111, 1)', // customize font color
          },
        },
      }}
      variant="outlined"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search/>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {props&&props.searchTerms && (
              <IconButton
                edge="end"
                onClick={handleClear}
              >
                <ClearIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      placeholder="Find items or machine"
      value={props&&props.searchTerms}
      onChange={(e) => {searchTerms(e)}}
    />
      
          

              {props&&props.buyAgainList&&props.buyAgainList[0]&&<BuyAgian/>}
              {!props.searchTerms&&<ComboComponent data={ComboData}/>}
              { !props.searchTerms&&props&&props.final_VmInventorySpecialList&&props.final_VmInventorySpecialList[0]&&<TodaySpecialComponent data={props&&props.final_VmInventorySpecialList&&props.final_VmInventorySpecialList[0]&&props.final_VmInventorySpecialList}/>}


                        <div>
            
                          <span
                            className="styles_headerContainerSubtitle__1WRg5"
                            aria-hidden="true"
                          >
                            <h3
                              style={{
                                marginTop: "5%",
                              
                                marginBottom: "5%",

                                color: "#718198",

                                /* M17 */
                                "font-family": "Roboto",
                                fontSize: "17px",
                                "font-style": "normal",
                                "font-weight": "500",
                                "line-height": "normal",
                              }}
                            >
                              Explore Delicious Products{" "}
                            </h3>
                          </span>
                        </div>

       


          {props.Final_VmInventoryList &&<InventoryComponent /> }
          {searchQuery&&!searchResults[0]&&<div style={{height:"50vh"}}><h1></h1></div>}



      </Box>
    </>
  );
};

export default VMInventory
