import { connect } from "react-redux";
import Userprofile from "./component";
import { fetchUserDetailsRequest,fetchLogoutCurrentDeviceRequest ,fetchLogoutAllDeviceRequest,fetchLogInDeviceCountRequest} from "./action";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import clearAllCookies from "../Cookies";
const UserprofileContainer = (props) => {
  const navigate=useNavigate()

const [loader,setLoader]=useState(false)
const [profileData,setProfileData]=useState('')
const [activeSessionCount,setActiveSessionCount]=useState('')
useEffect(()=>{
  fetchUserDeatls()
  
},[])






const UserJWTAuth = localStorage.getItem('UserJWTAuth');
useEffect(()=>{
 
 console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
 if(!UserJWTAuth){
   navigate('/')
 }
},[])

useEffect(()=>{
 
  console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
  if(localStorage.getItem('Guest_clientId')){
    //navigate('/login')
  }
 },[])
 
//


const goToPaymentsOption=()=>{
   

  localStorage.setItem("paytmOptionFrom",window.location.pathname)
  navigate('/paymentOption')
  
    }


    const goToAddTag=()=>{
     
      navigate('/taglist')
      

    }

const fetchUserDeatls = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchUserDetailsRequest({
    url: `/auth/user/profile/details`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      
    },
    "request": {
      request:{

        "request": {
          "oauthToken":`Bearer ${UserJWTAuth}`,
          }
      }
      


      },
    handleErrorUserDetails,
    handleResponseUserDetails ,
  });
};


const handleErrorUserDetails=(error)=>{
  console.log("khan handleErrorVmInventory", error.response.data.status.code);
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    //localStorage.removeItem("UserJWTAuth")
     navigate("/login");
}
else{ toast.error(`${error&&error.response&&error.response.data&&error.response.data.status}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
  
  setLoader(false)
  
}

const handleResponseUserDetails=(res)=>{
  console.log("handleResponseUserHistory",res.data.response)
  setProfileData(res.data.response)
  setLoader(false)
}




const LogoutCurrentDevice = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");
//https://api-prod.daalchini.co.in
  props.fetchLogoutCurrentDeviceRequest({
    url: `/auth/user/logout`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      
    },
    "request": {

      


      },
    handleErrorLogoutCurrentDevice,
    handleResponseLogoutCurrentDevice  ,
  });
};


const handleErrorLogoutCurrentDevice =(error)=>{
  console.log("khan handleErrorVmInventory", error.response.data.status.code);
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    //localStorage.removeItem("UserJWTAuth")
     navigate("/login");
}
else{ toast.error(`${error&&error.response&&error.response.data&&error.response.data.status}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
  
  setLoader(false)
  
}

const handleResponseLogoutCurrentDevice =(res)=>{
clearAllCookies()
  setLoader(false)
  localStorage.removeItem('UserJWTAuth')
  navigate('/login')
  //clearAllCookies()
  window.location.reload()
 
}

const handleErrorLogoutAllDevice =(error)=>{
  console.log("khan handleErrorVmInventory", error.response.data.status.code);
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    localStorage.removeItem("UserJWTAuth")
     navigate("/login");
  
 
}
else{ toast.error(`${error&&error.response&&error.response.data&&error.response.data.status}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
  
  setLoader(false)
  
}

const handleResponseLogoutAllDevice =(res)=>{
  clearAllCookies()
  setLoader(false)
  setLoader(false)
  localStorage.removeItem('UserJWTAuth')
  navigate('/login')
  window.location.reload()
}

const LogoutAllDevice = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchLogoutAllDeviceRequest({
    url: `/auth//user/logout/all`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      
    },
    "request": {
    
      


      },
    handleErrorLogoutAllDevice,
    handleResponseLogoutAllDevice  ,
  });
};













return(
    <Userprofile
    profileData={profileData}
    goToPaymentsOption={goToPaymentsOption}
    goToAddTag={goToAddTag}
    LogoutAllDevice={LogoutAllDevice}
    LogoutCurrentDevice={LogoutCurrentDevice}/>
)


}



function mapStateToProps( props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    fetchUserDetailsRequest,
    fetchLogInDeviceCountRequest,
    fetchLogoutCurrentDeviceRequest,
    fetchLogoutAllDeviceRequest
   
  })(UserprofileContainer);
