import { connect } from "react-redux";
import Userprofile from "./component";
import {fetchCancelOrderRequest,fetchActiveOrdersRequest} from "../ActiveOrder/action1"
import { useEffect, useState } from "react";
import ActiveOrder from "./component";
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import { mpActiveOrder, mpClick } from "../../Mixpanel";
const ActiveOrderContainer = (props) => {
const OrderId=localStorage.getItem("orderId")
const [activeOrderList,setActiveOrderList]=useState(null)
const [activeOrderList1,setActiveOrderList1]=useState(null)
const [timer,setTimer]=useState("")
const [cancleflag,setCancleflag]=useState(false)
const [cancleOrderID,setCancleOrderID]=useState(false)
const [cancleErrorflag,setCancleErrorflag]=useState(false)
const [cancleErrorMsg,setCancleErrorMsg]=useState("")
const [cancleSuccessflag,setCancleSuccessflag]=useState(false)
const [cancleSuccessMsg,setCancleSuccessMsg]=useState("")
const [orderEopchCreatedTime,setOrderEopchCreatedTime]=useState("")
const [remainTime,setRemainTime]=useState("")
const [currentM,setCurrentMinutes]=useState(0)
const [diffInMs,setDiffInMs]=useState(0)
const [epochtime,setEpochTime]=useState(0)
var currentMinutes=0
let amounts = [];
let epochtimes=[]
const [totalAm,setTotalAm]=useState([])
const navigate=useNavigate()

useEffect(()=>{
  mpActiveOrder({EventName:"Active_order_ page_opened",})
},[])

useEffect(()=>{

   ActiveOrdersList()
  
  const currentEpochTime = Math.floor(Date.now() / 1000); // current epoch time in seconds
  //console.log("currentEpochTime",currentEpochTime);
  const currentDate = new Date(); // create a new Date object with the current date and time
  const currentUTCDate = currentDate.toISOString(); // get the ISO string representation of the current date in UTC format
  //console.log("currentDate",currentUTCDate)
 const utcTime1 = new Date(currentUTCDate).getTime(); // Convert first UTC time to Unix timestamp
 const utcDate = new Date("2023-04-10T16:20:45.231Z");


 const options = { timeZone: "Asia/Kolkata" }; // set the time zone to IST
 const localISTString = utcDate.toLocaleString("en-US", options);

  const utcTime2 = new Date(activeOrderList&&activeOrderList.pickupTime).getTime(); // Convert second UTC time to Unix timestamp
  const differenceInSeconds = Math.abs(utcTime1 - utcTime2) / 1000; // Find the absolute difference in seconds

},[])




const handleErrorCancleOrder=(res)=>{

  setCancleflag(false)
  setCancleErrorflag(true)
  setCancleErrorMsg(res.status.message)
  
  mpClick({EventName:"Order_cancellation_failed"})
}

const handleResponseCancleOrder=(res)=>{


  if(res.data.status.success){
    setActiveOrderList(res.data.data)
    setCancleflag(false)
    setCancleSuccessflag(true)
    setCancleSuccessMsg("Order Cancelled")
    mpClick({EventName:"Order_cancellation_successful"})
    }
}

const CancleOrder = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  mpClick({EventName:"Cancel_order_clicked"})
  mpClick({EventName:"Cancel_order_initiated"})

  props.fetchCancelOrderRequest({
    url: `/consumerbe/api/v2/order/${cancleOrderID}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },
  
    handleErrorCancleOrder ,
    handleResponseCancleOrder,
  });
};








const handleErrorActiveOrdersList=(error)=>{
  if(error.response.status==401 || error.response.status==403){

   
    //Logout
    //localStorage.removeItem("UserJWTAuth")
      navigate("/login");
}
else{ toast.error(`${error.response.data.status.code}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}

}

const handleResponseActiveOrdersList=(response)=>{

    console.log("Get response fetchOrders",response.data.data[0].createdAt);
    setOrderEopchCreatedTime(response.data.data.pickupTimeMillis)
    var utcTime = "2023-05-26T03:23:48Z";

  // Create a new Date object with the UTC time
 
    setActiveOrderList1(response.data.data)
    setCancleErrorMsg()
  
    setEpochTime(response.data.data.pickupTimeMillis)

   
    for (const order of response.data.data) {
      console.log("Amount for Object",)
      const pickuptime=order.pickupTimeMillis
      const lineItems = order.lineItems || [];
      const totalAmount = lineItems.reduce((sum, item) => {
        const unitCount = item.unitCount || 0;
        const offerPrice = item.offerPrice || 0;
        return sum + unitCount * offerPrice;
      }, 0);
      amounts.push(totalAmount);
      console.log("pickuptime",pickuptime)
      epochtimes.push(pickuptime);
    }
    
    setTotalAm(amounts)
    // Print the amounts
    for (let i = 0; i < amounts.length; i++) {
      console.log(`Amount for Object ${i + 1}: ${amounts[i]}`);
    }
    console.log("pickuptime epochtimes",epochtimes)
    setEpochTime(epochtimes)
  
  
}

const ActiveOrdersList = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchActiveOrdersRequest({
    url: `/consumerbe/api/v2/order/active`,
    method:  "GET",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
        "x-device-id":localStorage.getItem("uuid")
    },
    "request": {
      request:{}
      


      },
      handleErrorActiveOrdersList ,
      handleResponseActiveOrdersList,
  });
};

























const CancleOrderset=(e,id)=>{
  console.log("CancleOrderset",id)
  setCancleOrderID(id)
  setCancleflag(true)
}


const amount=()=>{
  console.log("Amount for Object",activeOrderList1)
  const json_data = JSON.parse(activeOrderList1);
  console.log("Amount for Object",json_data)
  // Calculate the amount for each object
  const amounts = [];
  for (const order of json_data) {
    const lineItems = order.lineItems || [];
    const totalAmount = lineItems.reduce((sum, item) => {
      const unitCount = item.unitCount || 0;
      return sum + unitCount * order.amount;
    }, 0);
    amounts.push(totalAmount);
  }
  setTotalAm(amounts)
  
  // Print the amounts
  for (let i = 0; i < amounts.length; i++) {
    console.log(`Amount for Object ${i + 1}: ${amounts[i]}`);
  }


}


  const fetchOrders=()=>{
    const UserJWTAuth = localStorage.getItem('UserJWTAuth');
 
  return props.fetchInventory(
    "GET",

  `/consumerbe/api/v2/order/${OrderId}`,
    { "headers": {
      'Authorization':`Bearer ${UserJWTAuth}`,
      //'x-app-platform':'web',
      'x-app-version':'v1.0.1',
     
      
    }
  },
    {
      "request": {
    
      


      }
      },
      response => {
     
        setOrderEopchCreatedTime(response.data.data.pickupTimeMillis)
        setActiveOrderList(response.data.data)
        setCancleErrorMsg()
        
      
        setEpochTime(response.data.data.pickupTimeMillis)
     
       
      },
      err => {
        
      }
    )
  
  
  
  }



useEffect(()=>{

  console.log("currentMinutes",diffInMs)
 

},[diffInMs,epochtime])


  const totalAmount = activeOrderList&&activeOrderList.lineItems.reduce((accumulator, item) => accumulator + item.offerPrice*item.unitCount, 0);

console.log("props epochtime----> ",epochtime)
return(
    <ActiveOrder
    activeOrderList={activeOrderList}
    activeOrderList1={activeOrderList1}
    CancleOrder={CancleOrder}
    timer={timer}
    cancleflag={cancleflag}
    CancleOrderset={CancleOrderset}
    setCancleErrorflag={setCancleErrorflag}
    cancleErrorflag={cancleErrorflag}
    setCancleErrorMsg={setCancleErrorMsg}
    cancleErrorMsg={cancleErrorMsg}
    cancleSuccessflag={cancleSuccessflag}
    cancleSuccessMsg={cancleSuccessMsg}
    setCancleSuccessflag={setCancleSuccessflag}
    totalAmount={totalAmount}
    orderEopchCreatedTime={orderEopchCreatedTime}
    remainTime={remainTime}
    currentMinutes={currentM}
    amounts={totalAm}
    epochtime={epochtime}
    setCancleflag={setCancleflag}
    
    />

)


}



function mapStateToProps({ props }) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    fetchActiveOrdersRequest,
    fetchCancelOrderRequest
   
  })(ActiveOrderContainer);
  

