import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import backArrow from "../../assets/backArrow.png"
import nextArrow from '../../assets/nextArrow.png'
import Trash from '../../assets/Trash.png'

function DeleteAccount(props) {
  console.log("props Userprofile", props);
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  const navigator = useNavigate();
  
//
const [logoutBtn, setLogoutBtn] = useState(false);
  const TopNav = () => {
    return (
      <>
        <div className="_2456r">
          <div
            className="_3nxa7"
            style={{ "align-items": "center" }}
            data-testid="listing-header-location"
          >
         
            <button
              className="_1NdRR"
              aria-label="Selected address is Delhi, India; Double tap to change address."
              id="change-address-btn-id"

            >
                <div style={{display:"flex",alignItems: "center", justifyContent: "space-around",marginLeft:"-15px"}}>
                <Link to="/settings"><img  style={{width:"20px"}}src={backArrow}/></Link>   
              <div style={{ display: "flex", "align-items": "center" }}>
                <h2
                  style={{
                   
                    fontSize: "18px",
                    color: "#583C43",
                    fontWeight: "500",
                    marginLeft: "-15px"
                  }}
                >
                Delete Account
                </h2>
                </div>

                <div style={{ marginLeft: "10px" }}></div>
              </div>
            </button>

            <span className="icon-Offers-outline _2P0Ff" />
         
          </div>
        </div>
      </>
    );
  };


  return (
    <>
      {props.logoutModal && (
        <div className="logoutmodal">
          <div className="modal-content-userprofile">
            <img style={{    width: "104px",
    position: "absolute",
    top: "-52px",
    left: "34%"}} src={Trash}/>
            <div style={{ display: "flex", "justify-content": "space-around" ,marginTop:"33px"}}>
              <h1 style={{ fontFamily: "Roboto",
fontSize: "16px",
fontWeight: "700",
lineHeight: "28px",
letterSpacing: "1px",
textAlign: "center",
color: "#384F6F"

 }}>
              Delete Account?
              </h1>
            </div>
           <p style={{fontFamily: "Roboto",
fontSize: "12px",
fontWeight:"400",
lineHeight: "20px",
color: "#384F6F",

textAlign: "center"}}>Are you sure want to delete your account?</p>
         
  <div style={{display:"flex",justifyContent:"space-round",    marginTop:"33px"}}>        
  <button style={{width: "118px",
height:"41px",
top: "150px",
left: "13px",
padding: "10px",
borderRadius: "15px",
color:"white",
textAlign:"center",
backgroundColor: "#00B177",
fontWeight:"800"


}} onClick={(e)=>{props.setLogutModal(false)}}>No</button>
<button style={{width:"118px",
height: "41px",
top: "150px",
fontWeight:"800",
left: "142px",
padding: "10px",
borderRadius: "15px",
border: "1px solid  #00B177",
color:"#00B177",
textAlign:"center"

}} onClick={props.deleteAccountApi}>Yes</button>
</div> 
           
          </div>
        </div>
      )}

    
      <TopNav />
     
      <div
        style={{
          background:
            "radial-gradientlogoutModal(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
          //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.PreCardItems[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.PreCardItems[0]&&"8.5rem",
        }}
        className={
          props.login || props.loginOtp
            ? "h-screen overflow-y-auto "
            : "pt-4  overflow-y-auto h-screen"
        }
      >

        <div style={{padding:"5%", marginTop:"15%",marginBottom:"22%"}}>
            <h1 style={{fontFamily: "Roboto",
fontSize: "14px",
color:"#384F6F",

fontWeight:"500"}}>Could You Please Tell Us Why You're Deleting Your Account?</h1>
            <div style={{height:"200px",backgroundColor:"white",marginTop:"5%",border: "1px solid #94A3B88A",borderRadius:"9px"}}>

                 <textarea  onChange={(e)=>{props.setReason(e.target.value)}} placeholder="Write here...." style={{    width:"100%",
    height: "193px",
    padding:"3%",}}/> 

            
            </div>
            <div style={{marginTop:"10%"}}>
                <h1 style={{fontFamily: "SF Pro Display",
"font-size":"14px",
fontWeight: "500",
lineHeight: "17px",letterSpacing:"0px",
textAlign:"left"}}
>Terms:</h1>
                <div style={{paddingLeft:"2%"}} >
                   
                    <h1 style={{display:"flex"}}>• <h1 style={{paddingLeft:"2%",//styleName: R13;
fontFamily: "Roboto",
fontSize: "13px",
fontWeight: "400",
lineHeight: "20px",
letterSpacing:"0.20000000298023224px"}}>Backup Your Data: Please ensure to backup any necessary information (order history, invoices, etc.) before deletion. Deletion is final and cannot be undone.</h1></h1>
                    <h1 style={{display:"flex"}}>• <h1 style={{paddingLeft:"2%",//styleName: R13;
fontFamily: "Roboto",
fontSize: "13px",
fontWeight: "400",
lineHeight: "20px",
letterSpacing:"0.20000000298023224px"}}>Processing Time: Account deletion requests are processed within 60 days.</h1></h1>
                    <h1 style={{display:"flex"}}>• <h1 style={{paddingLeft:"2%",//styleName: R13;
fontFamily: "Roboto",
fontSize: "13px",
fontWeight: "400",
lineHeight: "20px",
letterSpacing:"0.20000000298023224px"}}>Loss of Benefits: Deletion will result in the loss of all account benefits, including wallet points, order history, and saved preferences.</h1></h1>
                  
                  <h1 style={{display:"flex"}}>• <h1 style={{paddingLeft:"2%",//styleName: R13;
fontFamily: "Roboto",
fontSize: "13px",
fontWeight: "400",
lineHeight: "20px",
letterSpacing:"0.20000000298023224px"}}>Pending Transactions: Ensure all transactions, including returns or refunds, are completed before requesting account deletion.</h1></h1>

<h1 style={{display:"flex"}}>• <h1 style={{paddingLeft:"2%",//styleName: R13;
fontFamily: "Roboto",
fontSize: "13px",
fontWeight: "400",
lineHeight: "20px",
letterSpacing:"0.20000000298023224px"}}>Data Retention: Some of your data may temporarily remain in our backups as per our data retention policy.</h1></h1>

<h1 style={{display:"flex"}}>• <h1 style={{paddingLeft:"2%",//styleName: R13;
fontFamily: "Roboto",
fontSize: "13px",
fontWeight: "400",
lineHeight: "20px",
letterSpacing:"0.20000000298023224px"}}>Right to Refuse: We reserve the right to refuse deletion requests if there are unresolved issues or ongoing disputes.</h1></h1>


<h1 style={{display:"flex"}}>• <h1 style={{paddingLeft:"2%",//styleName: R13;
fontFamily: "Roboto",
fontSize: "13px",
fontWeight: "400",
lineHeight: "20px",
letterSpacing:"0.20000000298023224px"}}>Terms Update: These terms may be updated.</h1></h1>




<h1 style={{display:"flex"}}>• <h1 style={{paddingLeft:"2%",//styleName: R13;
fontFamily: "Roboto",
fontSize: "13px",
fontWeight: "400",
lineHeight: "20px",
letterSpacing:"0.20000000298023224px"}}>Consent: By tapping "Delete Account," you agree to these terms and acknowledge the permanent loss of your account and its data.</h1></h1>












                
                </div>
            </div>

            <button style={{
              width:"100%",
height:"48px",

padding: "12px, 24px, 12px, 24px",
borderRadius: "12px",
gap: "10px",
color:"white",
backgroundColor:"#F33051",
fontSize:"16px",
fontWeight:"600",
textAlign:"center",
/* position: "absolute",
    bottom: "33px" */
    marginTop:"11%"
}} onClick={(e)=>{props.setLogutModal(true)}}>Delete Account</button>



        </div>


    </div>

  


      

 
    </>
  );
}

export default DeleteAccount;
