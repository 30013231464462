import { Mail, Notifications, LocationOn } from "@mui/icons-material";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { QrCode } from '@mui/icons-material';
import '../components/VmList1/Vmlist.css'
import {
    AppBar,
    Avatar,
    Badge,
    Box,
    InputBase,
    Menu,
    MenuItem,
    styled,
    Toolbar,
    Typography,
} from "@mui/material";
import React, { useState } from "react";

import DaalchiniLogo from '../assets/DaalchiniLogo.png'
import LocationPin from '../assets/locationPin.png'
import LoctionPinHeader from '../assets/locat.png'
import QrMarkHeader from '../assets/qrmark.png'

const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",


});


const Search = styled("div")(({ theme }) => ({
    backgroundColor: "white",
    padding: "0 10px",
    borderRadius: theme.shape.borderRadius,
    width: "40%",
}));

const Icons = styled(Box)(({ theme }) => ({
    display: "none",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.up("sm")]: {
        display: "flex",
    },
}));

const UserBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "10px",
    [theme.breakpoints.up("sm")]: {
        display: "none",
    },
}));
const TopNavbar = (props) => {
    const [open, setOpen] = useState(false);
    const [openLocationModal, setOpenLocationModal] = useState(false);

    const handleOpen = () => {
        setOpenLocationModal(true);
    };

    const handleClose = () => {
        props&&props.shareLocation()
        setOpenLocationModal(false);
    };

    const LocationModal = (props) => {
       return( <Dialog style={{ overflowY: "none" }} overflowY={"none"} open={openLocationModal} onClose={handleClose}>
            <Box>
            <img
                src={LocationPin} // Replace with the URL or path to your image
                alt="Modal Image"
                className="location-modal-img"
            />
            </Box>

            <Box className="location-modal-content-box" 
            style={{
                padding: "5%",
            }}>
            
            <div style={{ textAlign: "center", marginTop: "8%" }}>
                <Typography lineHeight={"28px"} fontFamily={"Roboto"} fontWeight={700} color={"rgb(56, 79, 111);"} letterSpacing={"0.5px"} fontSize={15} variant="h1">Find Nearby Vending Machines</Typography>
                <Typography style={{ marginTop: "5%", paddingLeft: "10%", paddingRight: "10%" }} lineHeight={"15px"} fontFamily={"Roboto"} fontWeight={400} color={"rgba(56, 79, 111, 0.69)"} letterSpacing={"0.5px"} fontSize={12} variant="h1">Share Your Current Location to Find the Nearest Options</Typography></div>
                <Button className="share-btn" onClick={handleClose} style={{ backgroundColor: "rgb(0, 177, 119)", width: "100%", fontWeight: 800, borderRadius: "10px", position: "static", padding: "0.75rem", marginTop: "33px" }} variant="contained" > Share</Button>
            </Box>





        </Dialog>)
    }


    return (
   
        <AppBar position="sticky" style={{ backgroundColor: "#fae14b", boxShadow: "none" }}>
        <LocationModal/>
            <StyledToolbar >
                <Toolbar style={{ overflowY: "none" }} >
                    <img
                        src={DaalchiniLogo}  // Replace with the actual path to your logo image
                        alt="Logo"
                        style={{ maxHeight: 30, marginRight: 10 }}
                    />
                </Toolbar>

                <Search sx={{ display: { xs: "none", sm: "none",md:"block" } }} >
                    <InputBase placeholder="search..." />
                </Search>
                
                <Icons>
                    <Badge badgeContent={4} color="error">
                        <Mail />
                    </Badge>
                    <Badge badgeContent={2} color="error">
                        <Notifications />
                    </Badge>
                    <Avatar
                        sx={{ width: 30, height: 30 }}
                        src="https://images.pexels.com/photos/846741/pexels-photo-846741.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                        onClick={(e) => setOpen(true)}
                    />
                </Icons>

                <Box style={{display:"flex"}}>
             {props&&props.LoctionPinHeader&&       
                  <img
                        src={LoctionPinHeader}  // Replace with the actual path to your logo image
                        alt="LoctionPinHeader"
                        style={{ height: "24px",width:"20px",    marginRight:"25px" }}
                        onClick={handleOpen} 
                    />
            }
                    
               {  props&&props.QrMarkHeader&&   <img
                        src={QrMarkHeader}  // Replace with the actual path to your logo image
                        alt="QrMarkHeader"
                        style={{ height: "26px",width:"26px" }}
                        onClick={props&&props.ShowQRFun} 
                    />
               }
                </Box>


            </StyledToolbar>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                open={open}
                onClose={(e) => setOpen(false)}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem>Profile</MenuItem>
                <MenuItem>My account</MenuItem>
                <MenuItem>Logout</MenuItem>
            </Menu>
        </AppBar>
    );
};

export default TopNavbar;