import React, { useEffect, useState } from "react";
import HelpAndSupport from "./component";
import {  toast } from 'react-toastify';

import {fetchOrderSearchImageRequest }from './action'
import { connect } from "react-redux";
const HelpAndSupportContainer = (props) => {
    console.log("props helpandsupport container",props)
  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState("");
  
  const [imageUploadUI,setImageUploadUI]=useState(true)
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);


  const [orderSuccessflag,setOrderSuccessflag]=useState(false)
  const [orderNotFoundflag,setOrderNotFoundflag]=useState(false)
  const [orderPlacedflag,setOrderPlacedflag]=useState(false)
  const [orderAbandonedflag,setOrderAbandonedflag]=useState(false)
  const [orderFailedflag,setOrderFailedflag]=useState(false)
  const [orderCancleflag,setOrderCancleflag]=useState(false)
  const [orderPartiallyflag,setOrderPartiallyflag]=useState(false)



  const [orderNotFoundREflag,setOrderNotFoundREflag]=useState(false)
  const [orderPlacedREflag,setOrderPlacedREflag]=useState(false)
  const [orderAbandonedREflag,setOrderAbandonedREflag]=useState(false)
  const [orderFailedREflag,setOrderFailedREflag]=useState(false)
  const [orderCancleREflag,setOrderCancleREflag]=useState(false)
  const [orderPartiallyREflag,setOrderPartiallyREflag]=useState(false)
  const [orderCreated,setOrderCreated]=useState(false)









  const handleResponseOrderSerachImage=(response)=>{

    console.log("response.data.response.is_refunded", response.data.response.is_refunded==false);
    setImagePath(response.image_path);
    setLoader(false)

if(response.data.response.oder_status=="Payment failed"){
if(response.data.response.is_refunded=="true"){

}

setImageUploadUI(false)
setOrderCreated(true)


}
if(response.data.response.oder_status=="Payment failed"){
    

if(response.data.response.is_refunded==true){
setOrderFailedREflag(true)

}
setImageUploadUI(false)

setOrderCreated(true)

}
    if(response.data.response.oder_status=="Successful"){
      if(response.data.response.is_refunded=="true"){
        setOrderNotFoundREflag(true)

      }
    
    setImageUploadUI(false)
    setOrderSuccessflag(true)

    }

    if(response.data.response.oder_status=="Partially successful" ||response.data.response.oder_status=="Partially fulfilled"){
    
      if(response.data.response.is_refunded==true){
      setOrderNotFoundREflag(true)

      }
      setImageUploadUI(false)
      setOrderPartiallyflag(true)

      }

      if(response.data.statusMessage=="Unable to find order!"){
        if(response.data.response.is_refunded==true){

          setOrderNotFoundREflag(true)

        }
        
        setImageUploadUI(false)
       
        setOrderNotFoundflag(true)
   
        }
    

        
        if(response.data.response.oder_status=="Abandoned"){
          if(response.data.response.is_refunded==true){

            setOrderAbandonedREflag(true)
          }
    
          setImageUploadUI(false)
         
          setOrderAbandonedflag(true)
  
          }
      

          if(response.data.response.oder_status=="Cancelled" ||response.data.response.oder_status=="Vending failed"){
    
            if(response.data.response.is_refunded==true){

              setOrderCancleREflag(true)
            }
            setImageUploadUI(false)
        
            setOrderCancleflag(true)
            }
        

            
    
          
    

  


    
  


}
  
  const handleErrorOrderSerachImage=(err)=>{
    setLoader(false)
    
    console.log("Error:tahzeeb", err.response.data.statusMessage);
    toast.error(`${err.response.data.statusMessage}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
    });
  }
  const UploadImgBtn = () => {
   
    if (!image) {
      console.log("No image selected");
      return;
    }
    setLoader(true)

    let formData = new FormData();
    formData.append("image", image);

    console.log("imageform--", formData);
    const UserJWTAuth = localStorage.getItem('UserJWTAuth');
  
    props.fetchOrderSearchImageRequest({
      url: "/support/help/self/order/search/image",
      method: "POST",
      headers: {
        //Authorization: `Bearer ${UserJWTAuth}`,
         "x-app-platform": "web",
               "x-app-version": "v1.0.1",
               //HEADER ERROR
               "x-device-id":localStorage.getItem("uuid")
      },
      "request":formData
        ,
  
  

      handleErrorOrderSerachImage ,
      handleResponseOrderSerachImage ,
    });
  };






  const UploadImgFun = (e) => {
    console.log("eee--->", e.target.files[0]);
    setImage(e.target.files[0]);
    const file = e.target.files[0];

    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };


 



  const [loader,setLoader]=useState(false)

  useEffect(() => {
    console.log("HelpAndSupportContainer");
  }, []);



 

  return (
    <>
      <h1></h1>
      <HelpAndSupport
        UploadImgBtn={UploadImgBtn}
        UploadImgFun={UploadImgFun}
        loader={loader}
        imageUploadUI={imageUploadUI}
        orderSuccessflag={orderSuccessflag}
        orderNotFoundflag={orderNotFoundflag}
        previewImage={previewImage}
        orderPlacedflag={orderPlacedflag}
        orderAbandonedflag={orderAbandonedflag}
        orderFailedflag={orderFailedflag}
        orderCancleflag={orderCancleflag}
        orderPartiallyflag={orderPartiallyflag}


  orderNotFoundREflag={orderNotFoundREflag}
  orderPlacedREflag={orderPlacedREflag}
  orderAbandonedREflag={orderAbandonedREflag}
  orderFailedREflag={orderFailedREflag}
  orderCancleREflag={orderCancleREflag}
  orderPartiallyREflag={orderPartiallyREflag}
  orderCreated={orderCreated}



      />
    </>
  );
};

function mapStateToProps(props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    fetchOrderSearchImageRequest
    
  })(HelpAndSupportContainer);
