import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import nextArrow from "../../assets/nextArrow.png";

import paymenticon from "../../assets/paymentsIcon.png";
import Tagpay from "../../assets/tagpayicon.png";
import GlobalSearchHeader from "../Header/GlobalSearchHeader";
import Aboutusicon from "../../assets/aboutusicon.png";
import faqicon from "../../assets/faqicon.png";
import usericon from "../../assets/user.png";
import TagIcon from "../../assets/tagpayicon.png";
import GiftCard from '../../assets/giftCard.png'

import BottomNarBar from "../BottomNav/Bottom";
import helpfaq from "../../assets/helpfaq.png";
import "./userprofile.css";

function Userprofile(props) {
  console.log("props Userprofile", props);
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  const navigator = useNavigate();
  const [logoutModal, setLogutModal] = useState(false);
//
const [logoutBtn, setLogoutBtn] = useState(false);
const goToGiftCard=()=>{
  navigator('/giftcard')
}
useEffect(()=>{
  window.scrollTo(0, 0); 
},[])
  const TopNav = () => {
    return (
      <>
        <div className="_2456r">
          <div
            className="_3nxa7"
            style={{ "align-items": "center" }}
            data-testid="listing-header-location"
          >
            <button
              className="_1NdRR"
              aria-label="Selected address is Delhi, India; Double tap to change address."
              id="change-address-btn-id"
            >
              <div style={{ display: "flex", "align-items": "center" }}>
                <h2
                  style={{
                    marginLeft: "25px",
                    fontSize: "18px",
                    color: "#583C43",
                    fontWeight: "500",
                  }}
                >
                  Profile
                </h2>

                <div style={{ marginLeft: "10px" }}></div>
              </div>
            </button>

            <span className="icon-Offers-outline _2P0Ff" />

          <div onClick={(e)=>{setLogoutBtn(true)}}><svg

              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 3.25C8.41421 3.25 8.75 2.91421 8.75 2.5C8.75 2.08579 8.41421 1.75 8 1.75C7.58579 1.75 7.25 2.08579 7.25 2.5C7.25 2.91421 7.58579 3.25 8 3.25Z"
                stroke="#583C43"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 8.75C8.41421 8.75 8.75 8.41421 8.75 8C8.75 7.58579 8.41421 7.25 8 7.25C7.58579 7.25 7.25 7.58579 7.25 8C7.25 8.41421 7.58579 8.75 8 8.75Z"
                stroke="#583C43"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 14.25C8.41421 14.25 8.75 13.9142 8.75 13.5C8.75 13.0858 8.41421 12.75 8 12.75C7.58579 12.75 7.25 13.0858 7.25 13.5C7.25 13.9142 7.58579 14.25 8 14.25Z"
                stroke="#583C43"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            </div> 
          </div>
        </div>
      </>
    );
  };
  const LogoutModalFun = () => {
    setLogutModal(true);
    setLogoutBtn(false)
  };
const DeviceCount=()=>{
  if(props&&props.profileData&&props.profileData.activeSessionCount&&props.profileData.activeSessionCount.length==1){
return props&&props.profileData&&props.profileData.activeSessionCount&&props.profileData.activeSessionCount
  }
  else{
    return `${props&&props.profileData&&props.profileData.activeSessionCount&&props.profileData.activeSessionCount}`
  }
}
  return (
    <>
      {logoutModal && (
        <div className="logoutmodal">
          <div className="modal-content-userprofile">
            <div style={{ display: "flex", "justify-content": "space-around" ,marginBottom:"14px"}}>
              <h1 style={{ fontSize: "21px", fontWeight: "700" }}>
                Log out from ?
              </h1>
            </div>
            <hr />
            <div onClick={(e)=>{props.LogoutCurrentDevice()}}style={{ display: "flex", "justify-content": "space-around", marginBottom:"9px",marginTop:"9px"}}>
              <h1 style={{ fontSize: "12px", fontWeight: "700",color:"#F33051" }}>
              Current Device 
              </h1>
            </div>
            <hr/>
            <div onClick={(e)=>{props.LogoutAllDevice()}} style={{ display: "flex", "justify-content": "space-around" , marginBottom:"9px",marginTop:"9px"}}>
              <h1 style={{ fontSize: "12px", fontWeight: "700",color:"#F33051" }}>
                {console.log("activeSessionCount.length",DeviceCount())}
              All Devices ({DeviceCount()})
              </h1>
            </div>
            <hr/>
            <div onClick={(e)=>{setLogutModal(false)}}style={{ display: "flex", "justify-content": "space-around" ,marginTop:"14px" }}>
              <h1 style={{ fontSize: "12px", fontWeight: "700" ,color:"#64748B"}}>
              Cancel
              </h1>
            </div>
          </div>
        </div>
      )}

      {logoutBtn&&<div
        style={{
          background: "#ffffff",
          position: "fixed",
          "z-index": "99",
          right: "17px",
          width: "95px",
          borderRadius: "8px",
          top: "47px",
        }}
      >
        <div
          style={{
          /*   display: "flex",
            "align-items": "center",
            "justify-content": "space-around", */
            height: "62px",
          }}
        >
          <div>
          <h1
            style={{ textAlign: "center", fontWeight: "500", fontSize: "14px" ,padding:"5px"}}
            onClick={(e) => {
              LogoutModalFun();
            }}
          >
            Log out
          </h1>
          <hr/>
         <Link to="/settings"> <h1
            style={{ textAlign: "center", fontWeight: "500", fontSize: "14px" ,padding:"5px"}}
      
          >
            Settings

          </h1>
          </Link>
          </div>
        </div>
      </div>}
      <TopNav />
      <BottomNarBar />
      <div onClick={(e)=>{setLogoutBtn(false)}}
        style={{
          background:
            "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
          //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.PreCardItems[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.PreCardItems[0]&&"8.5rem",
        }}
        className={
          props.login || props.loginOtp
            ? "h-screen overflow-y-auto "
            : "pt-4 pb-36 overflow-y-auto h-screen"
        }
      >
        <div
          style={{
            marginTop: "59px",
            padding: "21px",
            display: "flex",
            "align-items": "center",
            "justify-content": "flex-star",
            fontFamily: "Roboto",
          }}
        >
          <div style={{ height: "67px", width: "67px" }}>
            <img
              style={{
                width: "37rem",
                "max-width": "100%",
                "border-radius": "33px",
              }}
              src={usericon}
            />
          </div>
          <div style={{ "margin-left": "21px" }}>
            <h3 style={{ "text-transform": "capitalize", color: "#384F6F" }}>
              {props && props.profileData.name}
            </h3>
            <p style={{ color: "#718198", fontSize: "13px" }}>
              {props && props.profileData.mobileNo}
            </p>
          </div>
        </div>

        <div
          className="styles_row__1ivP1 shadow-box"
          data-testid="grid-row"
          style={{
            marginBottom: 24,
            backgroundColor: "white",
            "border-radius": "10px",
            "margin-right": "12px",
            "margin-left": "12px",
            "border-radius": "16px",
            background: "#FFF",
            "box-shadow": "0px 2px 24px 0px rgba(0, 0, 0, 0.08)",
            "border-left": "4px solid",
          }}
        >
          <div onClick={props.goToPaymentsOption}>
            <div className="styles_slide__2c207" style={{ marginRight: 0 }}>
              <div>
                <div
                  className="styles_cardWithPadding__JE1QE"
                  data-testid="home-restaurant-card-wrapper"
                  style={{
                    width: "calc((100vw - 0px) - 12px)",
                    "margin-top": "6px",
                  }}
                >
                  <div
                    data-testid="resturant-card-anchor-container"
                    className="styles_container__fLC0R"
                    tabIndex={0}
                    role="button"
                  >
                    <div
                      className="styles_imgContainer__1uHo5"
                      style={{
                        display: "flex",
                        "align-items": "center",
                        width: "99%",
                      }}
                    >
                      <div
                        className=""
                        data-testid="resturant-card-image-container"
                        style={{
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "space-evenly",
                        }}
                      >
                        <img
                          loading="lazy"
                          data-testid="resturant-card-image"
                          src={paymenticon}
                          style={{ width: "46px", height: "46px" }}
                        />
                      </div>
                      <div>
                        <h3
                          style={{
                            color: "#384F6F",
                            "font-family": "Roboto",
                            "font-size": "15px",
                            "font-style": "normal",
                            "font-weight": "400",
                            "line-height": "20px",
                            "margin-left": "24px",
                          }}
                        >
                          Payment Settings
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div />
              </div>
            </div>
          </div>
        </div>
      

        <div
          className="styles_row__1ivP1 shadow-box"
          data-testid="grid-row"
          style={{
            marginBottom: 24,
            backgroundColor: "white",
            "border-radius": "10px",
            "margin-right": "12px",
            "margin-left": "12px",
            "border-radius": "16px",
            background: "#FFF",
            "box-shadow": "0px 2px 24px 0px rgba(0, 0, 0, 0.08)",
            "border-left": "4px solid #00B177",
          }}
        >
          <div onClick={props.goToAddTag}>
            <div className="styles_slide__2c207" style={{ marginRight: 0 }}>
              <div>
                <div
                  className="styles_cardWithPadding__JE1QE"
                  data-testid="home-restaurant-card-wrapper"
                  style={{
                    width: "calc((100vw - 0px) - 12px)",
                    "margin-top": "6px",
                  }}
                >
                  <div
                    data-testid="resturant-card-anchor-container"
                    className="styles_container__fLC0R"
                    tabIndex={0}
                    role="button"
                  >
                    <div
                      className="styles_imgContainer__1uHo5"
                      style={{
                        display: "flex",
                        "align-items": "center",
                        width: "99%",
                      }}
                    >
                      <div
                        className=""
                        data-testid="resturant-card-image-container"
                        style={{
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "space-evenly",

                          width: "48px",
                          height: "44px",
                          background: "ghostwhite",
                          "border-radius": "24px",
                        }}
                      >
                        <img
                          loading="lazy"
                          data-testid="resturant-card-image"
                          src={TagIcon}
                          style={{ width: "40px", height: "32px" }}
                        />
                      </div>
                      <div>
                        <h3
                          style={{
                            color: "#384F6F",
                            "font-family": "Roboto",
                            "font-size": "15px",
                            "font-style": "normal",
                            "font-weight": "400",
                            "line-height": "20px",
                            "margin-left": "24px",
                          }}
                        >
                          TagPay
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div />
              </div>
            </div>
          </div>
        </div>
        <div
          className="styles_row__1ivP1 shadow-box"
          data-testid="grid-row"
          style={{
            marginBottom: 24,
            backgroundColor: "white",
            "border-radius": "10px",
            "margin-right": "12px",
            "margin-left": "12px",
            "border-radius": "16px",
            background: "#FFF",
            "box-shadow": "0px 2px 24px 0px rgba(0, 0, 0, 0.08)",
            "border-left": "4px solid",
          }}
        >
          <div onClick={goToGiftCard}>
            <div className="styles_slide__2c207" style={{ marginRight: 0 }}>
              <div>
                <div
                  className="styles_cardWithPadding__JE1QE"
                  data-testid="home-restaurant-card-wrapper"
                  style={{
                    width: "calc((100vw - 0px) - 12px)",
                    "margin-top": "6px",
                  }}
                >
                  <div
                    data-testid="resturant-card-anchor-container"
                    className="styles_container__fLC0R"
                    tabIndex={0}
                    role="button"
                  >
                    <div
                      className="styles_imgContainer__1uHo5"
                      style={{
                        display: "flex",
                        "align-items": "center",
                        width: "99%",
                      }}
                    >
                            <div
                        className=""
                        data-testid="resturant-card-image-container"
                        style={{
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "space-evenly",

                          width: "48px",
                          height: "44px",
                          background: "ghostwhite",
                          "border-radius": "24px",
                        }}
                      >
                        <img
                          loading="lazy"
                          data-testid="resturant-card-image"
                          src={GiftCard}
                          style={{ width: "40px", height: "32px" }}
                        />
                      </div>
                      <div>
                        <h3
                          style={{
                            color: "#384F6F",
                            "font-family": "Roboto",
                            "font-size": "15px",
                            "font-style": "normal",
                            "font-weight": "400",
                            "line-height": "20px",
                            "margin-left": "24px",
                          }}
                        >
                         Gift Card 
                        </h3>
                        <p style={{fontSize:"12px",fontWeight:"400",marginLeft:"24px",color:"rgba(56, 79, 111, 0.7)"}}>Claim, and Keep Track of Gift Cards</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div />
              </div>
            </div>
          </div>
        </div> 

        <div
          className="styles_row__1ivP1 shadow-box"
          data-testid="grid-row"
          style={{
            marginBottom: 24,
            backgroundColor: "white",
            "border-radius": "10px",
            "margin-right": "12px",
            "margin-left": "12px",
            "border-radius": "16px",
            background: "#FFF",
            "box-shadow": "0px 2px 24px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <div className="styles_slide__2c207" style={{ marginRight: 0 }}>
            <div>
                 <div
              className="styles_cardWithPadding__JE1QE"
              data-testid="home-restaurant-card-wrapper"
              style={{
                width: "calc((100vw - 0px) - 12px)",
                
              }}
            >
              <div
                data-testid="resturant-card-anchor-container"
                className="styles_container__fLC0R"
                tabIndex={0}
                role="button"
              >
                <div
                  className="styles_imgContainer__1uHo5"
                  style={{ display: "flex", "align-items": "center" ,width:"100%",width:"99%",
                  }}
                >
                  <div
                    className="styles_ImageContainer__2rk9a"
                    data-testid="resturant-card-image-container"
                    style={{
                      background: "white",
                      width: "55px",
                      height: "56px",
                      "border-radius": "27px",
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-evenly",
                    }}
                  >
                    <img
                      className="styles_Image__1fplJ"
                      loading="lazy"
                      data-testid="resturant-card-image"
                      src={helpfaq}
                      style={{ width: "36px", height: "36px",background:"white" }}

                    />
                  </div>
                 <Link to='/faq' style={{textDecoration:"none",width:"100%",color:"black"}}> <div style={{display:"flex",width:"93%","justify-content":"space-between"}}>
                  <h3 style={{ "margin-left": "21px", color: "#384F6F",
"text-align": "center",
"font-family": "Roboto",
"font-size": "15px",
"font-style": "normal",
"font-weight":"400",
"line-height": "normal",marginLeft:"31px" }}>Help & Support</h3>
           <img  style={{width:"10px"}}src={nextArrow}/> 
                  </div>
                  </Link>
                </div>
              </div>
            </div> 
              <div />
            </div>
            <div>
              <div
                className="styles_cardWithPadding__JE1QE"
                data-testid="home-restaurant-card-wrapper"
                style={{
                  width: "calc((100vw - 0px) - 12px)",
                }}
              >
                <div
                  data-testid="resturant-card-anchor-container"
                  className="styles_container__fLC0R"
                  tabIndex={0}
                  role="button"
                >
                  <div
                    className="styles_imgContainer__1uHo5"
                    style={{
                      display: "flex",
                      "align-items": "center",
                      width: "100%",
                      width: "99%",
                      marginLeft: "9px",
                    }}
                  >
                    <div
                      className="styles_ImageContainer__2rk9a"
                      data-testid="resturant-card-image-container"
                      style={{
                        background: "white",
                        width: "55px",
                        height: "56px",
                        "border-radius": "27px",
                        display: "flex",
                        "align-items": "center",
                      }}
                    >
                      <img
                        className="styles_Image__1fplJ"
                        loading="lazy"
                        data-testid="resturant-card-image"
                        src={faqicon}
                        style={{
                          width: "36px",
                          height: "36px",
                          background: "white",
                        }}
                      />
                    </div>
                    <a
                      style={{
                        textDecoration: "none",
                        color: "black",
                        width: "100%",
                      }}
                      href="https://www.daalchini.co.in/faq.html"
                    >
                      {" "}
                      <div
                        style={{
                          width: "91%",
                          display: "flex",
                          "justify-content": "space-between",
                        }}
                      >
                        <h3
                          style={{
                            color: "#384F6F",
                            "text-align": "center",
                            "font-family": "Roboto",
                            "font-size": "15px",
                            "font-style": "normal",
                            "font-weight": "400",
                            "line-height": "normal",
                            "margin-left": "27px",
                          }}
                        >
                          FAQ'S
                        </h3>
                        <img style={{ width: "10px" }} src={nextArrow} />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div />
            </div>
            <div>
              <div
                className="styles_cardWithPadding__JE1QE"
                data-testid="home-restaurant-card-wrapper"
                style={{
                  width: "calc((100vw - 0px) - 12px)",
                }}
              >
                <div
                  data-testid="resturant-card-anchor-container"
                  className="styles_container__fLC0R"
                  tabIndex={0}
                  role="button"
                >
                  <div
                    className="styles_imgContainer__1uHo5"
                    style={{
                      display: "flex",
                      "align-items": "center",
                      width: "100%",
                      width: "99%",
                    }}
                  >
                    <div
                      className="styles_ImageContainer__2rk9a"
                      data-testid="resturant-card-image-container"
                      style={{
                        background: "white",
                        width: "55px",
                        height: "56px",
                        "border-radius": "27px",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-evenly",
                      }}
                    >
                      <img
                        className="styles_Image__1fplJ"
                        loading="lazy"
                        data-testid="resturant-card-image"
                        src={Aboutusicon}
                        style={{
                          width: "36px",
                          height: "36px",
                          background: "white",
                        }}
                      />
                    </div>
                    <a
                      href="https://www.daalchini.co.in/"
                      style={{
                        textDecoration: "none",
                        width: "100%",
                        color: "black",
                      }}
                    >
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          width: "93%",
                          "justify-content": "space-between",
                        }}
                      >
                        <h3
                          style={{
                            "margin-left": "21px",
                            color: "#384F6F",
                            "text-align": "center",
                            "font-family": "Roboto",
                            "font-size": "15px",
                            "font-style": "normal",
                            "font-weight": "400",
                            "line-height": "normal",
                            marginLeft: "31px",
                          }}
                        >
                          About Us
                        </h3>
                        <img style={{ width: "10px" }} src={nextArrow} />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div />
            </div>
            <div>
              <div />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Userprofile;
