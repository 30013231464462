import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { GET_GIFT_CARD_CLAIM_REQUEST, GET_GIFT_CARD_HISTORY_REQUEST, POST_GIFT_CARD_REQUEST } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
//////////////////////////////////////////////////////////////////////////////////////////////////
  







  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* postgiftCard(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      //yield put(sendSkipLoginSuccess(response));
  
      action.data.handleResponseGiftCardApi(response);
    } catch (error) {
      console.log("fetchPreCard error", error);
      //yield put(sendSkipLoginFailure(error.message));
      action.data.handleErrorGiftCardApi(error)
    }
  }

  

  function* getGiftCardHistory(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      //yield put(sendSkipLoginSuccess(response));
  
      action.data.handleResponseGiftCardHistoryApi(response);
    } catch (error) {
      console.log("fetchPreCard error", error);
      //yield put(sendSkipLoginFailure(error.message));
      action.data.handleErrorGiftCardHistoryApi(error)
    }
  }

  function* getGiftCardClaimaction(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      //yield put(sendSkipLoginSuccess(response));
  
      action.data.handleResponseGiftCardClaimApi(response);
    } catch (error) {
      console.log("fetchPreCard error", error);
      //yield put(sendSkipLoginFailure(error.message));
      action.data.handleErrorGiftCardClaimApi(error)
    }
  }

  function* GiftCard() {

    yield takeLatest(POST_GIFT_CARD_REQUEST,postgiftCard)
    yield takeLatest(GET_GIFT_CARD_HISTORY_REQUEST,getGiftCardHistory)
    yield takeLatest(GET_GIFT_CARD_CLAIM_REQUEST,getGiftCardClaimaction)

    
    
  }
  
  export default GiftCard;