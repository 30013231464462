import { Box } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import gift from '../../assets/giftlogo.svg'
import Gift1 from '../../assets/GIFT3.png'
import giftwrap from '../../assets/giftwrap.png'
import giftWork from '../../assets/giftworks.png'
import '../../components/GiftCard/gift.css'
import aa from '../../assets/aa.gif'
import giftcardbg from '../../assets/bg2.svg'

import Cross from '../../assets/cross1.png'

import axios from "axios";
import Hand from '../../assets/Hand.png';
import HandW from '../../assets/Handw.png';
import Vector from '../../assets/Vector.png';
import Cel from '../../assets/cel.gif'
import jump from '../../assets/jump.gif'
import { sendGiftRequest, getGiftHistoryRequest, getGiftClaimRequest } from "./action";
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import Tick from '../../assets/tick.png'
import backArrow from '../../assets/backArrow.png'
import { Link } from "react-router-dom";
import WaitingLoder from "../WaitingLoader/component";

// Initialize empty arrays for true and false claims

const GiftCard = (props) => {

  const inputRef = useRef(null);
  const [flag, setFlag] = useState(false)
  const [flag2, setFlag2] = useState(false)
  const [giftCode, setGiftCode] = useState(null)
  const [wrapFlag, setwrapFlag] = useState(true)
  const [giftHistory, SetGiftCardHistory] = useState(null)
  const [trueClaims, setTrueClaims] = useState([]);
  const [falseClaims, setFalseClaims] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [loading, setLoading] = useState(false)
  const [claimAmount,setClaimAmount]=useState(null)
  useEffect(() => {
    GiftCardHistory()

  }, [])

  useEffect(() => {
    if (flag) {
      inputRef.current.focus(); // Focus on input when modal opens
    }
  }, [flag]);

  const GiftCardInput = (e) => {
    setErrorMsg(null)
    setErrorFlag(false)
    setGiftCode(e.target.value)

  }


  const GiftCardCall = () => {
    console.log("GiftCardCall")
    GiftCardApi()


  }

  const close2 = () => {
    window.location.reload()
  }

  const close = () => {
    setErrorFlag(false)
    setErrorMsg(null)
    setGiftCode(null)
    setFlag(false)
    document.body.style.overflow = '';


  }

  const handleResponseGiftCardApi = (res) => {
    console.log("handleResponseGiftCardApi",res.data.data)

    if(res.data.data){
    setLoading(false)
    setFlag(false)
    setFlag2(true)
    

    setTimeout(() => {
      setwrapFlag(false)
    }, 500);



    giftHistory()
  }
  else{
    setErrorFlag(true)
    setErrorMsg(res.data.status.message)
  }
  }

  const handleErrorGiftCardApi = (error) => {

    setErrorFlag(true)
    setErrorMsg(error && error.response && error.response.data.status && error.response.data.status.message)

    /*     console.log("error", error && error.response && error.response.data && error.response.data.status.message)
     */
    /*   toast.error(`${error.response.data.status.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      }) */


  }

  const handleResponseGiftCardHistoryApi = (res) => {
    setLoading(false)
    console.log("handleResponseGiftCardHistoryApi res", res.data.data)
    SetGiftCardHistory(res.data.data)

  }

  const handleErrorGiftCardHistoryApi = (error) => {

    setLoading(false)

    // console.log("error", error && error.response && error.response.data && error.response.data.status.message)

    /*   toast.error(`${error.response.data.status.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      }) */


  }

  const GiftCardApi = () => {
    localStorage.setItem("defaultPgName", "WALLET_LOYALTY")
    localStorage.setItem("defaultPgId", 9)
    const UserJWTAuth = localStorage.getItem("UserJWTAuth")
    props.sendGiftRequest({
      url: `/consumerbe/api/v2/voucher/apply`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,

        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
      },
      "request": {



        "code": giftCode







      },
      handleErrorGiftCardApi,
      handleResponseGiftCardApi: (res) => handleResponseGiftCardApi(res),
    });
  };

  const handleResponseGiftCardClaimApi = (res) => {
    console.log("handleResponseGiftCardClaimApi",res.data.data.value)
    setClaimAmount(res.data.data.value)
    setLoading(false)
    setFlag(false)
    setFlag2(true)

    setTimeout(() => {
      setwrapFlag(false)
    }, 500);

  }

  const handleErrorGiftCardClaimApi = (error) => {
    setLoading(false)
    /*     console.log("error", error && error.response && error.response.data && error.response.data.status.message)
     */
    toast.error(`${error.response.data.status.message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    })
  }
  const GiftCardHistory = () => {
    setLoading(true)
    //localStorage.setItem("defaultPgName", "WALLET_LOYALTY")
    //localStorage.setItem("defaultPgId", 9)
    const UserJWTAuth = localStorage.getItem("UserJWTAuth")
    props.getGiftHistoryRequest({
      url: `/consumerbe/api/v2/voucher/history`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,

        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
      },

      handleErrorGiftCardHistoryApi: (error) => handleErrorGiftCardHistoryApi(error),
      handleResponseGiftCardHistoryApi: (res) => handleResponseGiftCardHistoryApi(res),
    });
  };
  const openModal = (e) => {
    document.body.style.overflow = 'hidden';
    setFlag(true)
  }



  useEffect(() => {
    console.log("useEffect--->")


  }, [flag, flag2])


  const GiftCardClaim = (giftCode) => {

    setLoading(true)

    const UserJWTAuth = localStorage.getItem("UserJWTAuth")
    props.getGiftClaimRequest({
      url: `/consumerbe/api/v2/voucher/claim`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,

        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
      },
      "request": {



        "code": giftCode







      },
      handleErrorGiftCardClaimApi,
      handleResponseGiftCardClaimApi: (res) => handleResponseGiftCardClaimApi(res),
    });
  };




  const handleClaim = (e, code) => {



    console.log("code", code)
    GiftCardClaim(code)


  }
  useEffect(() => {
    console.log("trueClaim", trueClaims)
  }, [])

  return (
    <div>
      {loading && <WaitingLoder />}

      {flag2 && <> <div className="giftmodal" style={{ display: "flex", justifyContent: "center" }}>
        {false && <div className="innergiftmodal">
          <img src={aa} />
        </div>}
        <div>
          <div style={{
            position: "absolute",
            right: "20px", top: "20px"
          }}><img src={Cross} onClick={(e) => { close2(e) }} style={{ width: "30px" }} /></div>
          {true &&
            <div style={{ width: "143px", height: "147px", border: "1px solid rgba(56, 79, 111, 0.44)", borderRadius: "41px", margin: "3%", marginTop: "47%", background: "white" }}>
              {wrapFlag && <img style={{
                "border-top-right-radius": "33px",
                "border-top-left-radius": "33px"
              }} src={giftwrap} />}
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "143px", height: "147px" }}>
                <div style={{ height: "143px" }}>

                  {!wrapFlag && <>
                    <img src={Cel} />

                    <div style={{ display: "flex", justifyContent: "center" }}>{claimAmount?<h1 style={{fontSize:"22px","font-family":"Roboto",
fontSize: "30px",
fontWeight: "600",    marginTop:"10%"}}>₹{" "}{claimAmount}</h1>:<img src={Tick} style={{ width: "50px", marginTop: "11px" }} />}</div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center",

                      marginTop: "-60px"
                    }}>
                      <img src={jump} />
                    </div>
                  </>}
                </div>
              </div>





            </div>}
          {false && <h1 style={{ fontSize: "20px", color: "white", fontWeight: "600", textAlign: "center" }}>Tap to Claim</h1>}
          {<h1 style={{
            fontSize: "20px", color: "white", fontWeight: "600", textAlign: "center", position: "absolute",
            width: "100%",
            left: "0"
          }}>Gift Card from a friend enjoy!</h1>}




        </div>

        <div className="gift-modal-content " style={{ "border-top-right-radius": "30px",height:"61%", "border-top-left-radius": "30px" }}>
          <div style={{ padding: "7%", }}>

    <h1 style={{
        fontSize: "14px",
        fontWeight: "500",
        color: "rgba(56, 79, 111, 1)",
        marginBottom: "10px"
    }}>
        Points To Note:
    </h1>
    <div style={{
        color: "rgba(113, 129, 152, 1)",
        fontSize: "12px",
        fontWeight: "400",
        display:"flex"
    }}>
        <span style={{ fontSize: "20px" ,"margin-top":"-6px","margin-right":"5px" }}>•</span> Validity: This gift card is valid until the expiry date given on the app, after which it cannot be redeemed or extended.
    </div>
    <div style={{
        color: "rgba(113, 129, 152, 1)",
        fontSize: "12px",
        fontWeight: "400",
         display:"flex"
    }}>
        <span style={{ fontSize: "20px","margin-top":"-6px","margin-right":"5px"  }}>•</span> Redemption: This gift card can only be redeemed at Daalchini and cannot be exchanged for cash.
    </div>
    <div style={{
        color: "rgba(113, 129, 152, 1)",
        fontSize: "12px",
        fontWeight: "400",
         display:"flex"
    }}>
        <span style={{ fontSize: "20px","margin-top":"-6px","margin-right":"5px"  }}>•</span> Limitations: The value of this gift card cannot be used to purchase other gift cards.
    </div>
    <div style={{
        color: "rgba(113, 129, 152, 1)",
        fontSize: "12px",
        fontWeight: "400",
         display:"flex"
    }}>
        <span style={{ fontSize: "20px","margin-top":"-6px","margin-right":"5px"  }}>•</span> Loss or Theft: Daalchini is not responsible for lost or stolen gift cards. Protect this card as you would cash.
    </div>
    <div style={{
        color: "rgba(113, 129, 152, 1)",
        fontSize: "12px",
        fontWeight: "400",
         display:"flex"
    }}>
        <span style={{ fontSize: "20px","margin-top":"-6px","margin-right":"5px"  }}>•</span> Changes: Daalchini reserves the right to amend these terms and conditions at any time without prior notice.
    </div>

          </div>


        </div>
      </div></>}
      {flag && <> <div className="giftmodal" style={{ display: "flex", justifyContent: "center" }}>

     

        <div className="gift-modal-content " style={{ borderTopRightRadius: "30px", borderTopLeftRadius: "30px" }}>

          <div style={{ padding: "5%" }}>
          <div>
          <div style={{ marginTop: "200px",    "margin-top":"-108px","margin-left":"45%" }}><img src={Cross} onClick={(e) => { close() }} style={{ width: "40px" }} /></div>




        </div>
            <div style={{ display: "flex" , "margin-top":"76px","margin-left":"11px"}}><h1 style={{ fontSize: "16px", fontWeight: "500" }}>Claim Gift Card Here &nbsp;</h1><img style={{ width: "20px" }} src={Hand} />

            </div>
            <div className="tahzeebhello" style={{
              position: "absolute",
              top: "26px", width: "90%", marginTop: "8%",
              "box-shadow": "none",
            }}>
              <div className="tahzeebhello" style={{ border: errorFlag && "2px solid red", borderRadius: "18px", fontSize: "13px", borderRadius: "20px", display: "flex", "box-shadow": "0px 1px 41.2px 0px rgba(0, 0, 0, 0.08)" }}>
                <input
                  ref={inputRef}
                  className="tahzeebhello"
                  value={giftCode || ''}
                  onChange={GiftCardInput}
                  type="text"
                  placeholder="Enter 8 Digit Gift Card Code"
                  style={{ "box-shadow": "none", }}
                />
                {giftCode && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "green", position: "absolute", top: "2%", right: "5%", height: "35px", width: "35px", borderRadius: "40px" }} onClick={e => { GiftCardCall(e) }}><div><img style={{ width: "12px", height: "12px" }} src={Vector} /></div></div>}

              </div>
              <div style={{
    marginTop: "10%",
    overflow: "auto",
    maxHeight: "calc(100vh - 20vh)", // Adjust based on your layout needs
    padding: "10px", // Optional: Add padding for spacing
}}>
    <h1 style={{
        fontSize: "14px",
        fontWeight: "500",
        color: "rgba(56, 79, 111, 1)",
        marginBottom: "10px"
    }}>
        Points To Note:
    </h1>
    <div style={{
        color: "rgba(113, 129, 152, 1)",
        fontSize: "12px",
        fontWeight: "400",display:"flex"
    }}>
        <span style={{ fontSize: "20px",    "margin-top":"-6px","margin-right":"5px" }}>•</span> Validity: This gift card is valid until the expiry date given on the app, after which it cannot be redeemed or extended.
    </div>
    <div style={{
        color: "rgba(113, 129, 152, 1)",
        fontSize: "12px",
        fontWeight: "400",
        display:"flex"
    }}>
        <span style={{ fontSize: "20px","margin-top":"-6px","margin-right":"5px" }}>•</span> Redemption: This gift card can only be redeemed at Daalchini and cannot be exchanged for cash.
    </div>
    <div style={{
        color: "rgba(113, 129, 152, 1)",
        fontSize: "12px",
        fontWeight: "400"
        ,display:"flex"
    }}>
        <span style={{ fontSize: "20px","margin-top":"-6px","margin-right":"5px" }}>•</span> Limitations: The value of this gift card cannot be used to purchase other gift cards.
    </div>
    <div style={{
        color: "rgba(113, 129, 152, 1)",
        fontSize: "12px",
        fontWeight: "400",
        display:"flex"
    }}>
        <span style={{ fontSize: "20px","margin-top":"-6px","margin-right":"5px" }}>•</span> Loss or Theft: Daalchini is not responsible for lost or stolen gift cards. Protect this card as you would cash.
    </div>
    <div style={{
        color: "rgba(113, 129, 152, 1)",
        fontSize: "12px",
        fontWeight: "400",
        display:"flex"
    }}>
        <span style={{ fontSize: "20px","margin-top":"-6px","margin-right":"5px" }}>•</span> Changes: Daalchini reserves the right to amend these terms and conditions at any time without prior notice.
    </div>
</div>


            </div>
            <p style={{ marginTop: "20%", marginLeft: "2%", color: "red" }}>{errorMsg}</p>

          </div>
        </div>

      </div></>}


      <Box style={{
        backgroundImage: `url(${giftcardbg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '100%',
        height: '100vh',  // Adjust as needed
      }}>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5%" }}><Link to="/profile"><img src={backArrow} style={{ width: "22px", height: "22px" }} /></Link>
          <h1 style={{ color: "rgba(88, 60, 67, 1)", fontSize: "14px" }}></h1></div>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "-60px" }}>
          <img src={gift} />

        </div>
        <div style={{ display: "flex", "justify-content": "center", position: "relative", "bottom": "73px" }}>
          <div style={{ width: "100%" }} >
            <div style={{ display: "flex", justifyContent: "center" }}><div style={{ width: "190px", height: "50px", background: "rgba(56, 79, 111, 1)", color: "white", borderRadius: "18px", display: "flex", justifyContent: "center" }} >
              <div style={{ display: "flex", padding: "5%", }}>  <h1 style={{ fontSize: "14px", fontWeight: "500", textAlign: "center", marginRight: "5px" }}>Claim Gift Card Here </h1><img style={{ width: "20px", height: "20px" }} src={HandW} /></div>
            </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div onClick={(e) => { openModal(e) }} className="hello" style={{
                position: "absolute",
                top: "26px", width: "90%"
              }}>
                <input className="hello" value={""} type="text" placeholder="Enter 8 Digit Gift Card Code" />

              </div>
            </div>
          </div>

        </div>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "-16px" }}>
          <div style={{ width: "90%" }}>
            <div style={{ borderRadius: "18px", "box-shadow": "0px 4px 35.4px 0px rgba(0, 0, 0, 0.1)", padding: "5%", background: "white" }}>
              <div style={{ display: "flex", objectFit: "contain" }}>
                <div>
                  <h1 style={{ fontWeight: "500", fontSize: "16px" }}>How it works?</h1>


                  <p style={{ fontWeight: "400", fontSize: "12px" }}>
                    Claim your Daalchini gift card above and Buy your favorite snacks at any vending machine.
                  </p>
                </div>
                <img style={{ width: "100px" }} src={giftWork} />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "6%" }}>
          <div style={{ padding: "5%", width: "100%" }}>
            <div style={{ borderTopLeftRadius: "18px", borderTopRightRadius: "18px", "box-shadow": "0px 4px 35.4px 0px rgba(0, 0, 0, 0.1)", background: "white" }}>
              <h1 style={{ fontSize: "16px", fontWeight: "500", color: "#384F6F", padding: "6%" }} >Your Gift Cards ({giftHistory && giftHistory.activeVouchers.length})</h1>
              <div style={{ display: "flex", "flex-wrap": "wrap", "justify-content": "space-between", padding: "5%" }}>

                {
                  giftHistory && giftHistory.activeVouchers.map((data, index) => (

                    <div style={{}}>
                      {console.log("STRing", data.LeftExpiryDays.length)}
                      <>

                        {!(data.isclaim) ? <div onClick={(e) => {data && data.LeftExpiryDays > 0 && handleClaim(e, data && data.code) }} style={{ width: "143px", height: "147px", border: "1px solid rgba(56, 79, 111, 0.44)", borderRadius: "41px", marginBottom: "15%" }}>
                          <img style={{
                            "mix-blend-mode": data && data.LeftExpiryDays > 0 ? "multiply" : "luminosity",
                            "border-top-right-radius": "33px",
                            "border-top-left-radius": "33px"
                          }} src={giftwrap} />

                          <div style={{ background: data && data.LeftExpiryDays > 0 ? "rgba(250, 225, 75, 1)" : "rgb(251 247 226)", "border-top-left-radius": "7px", "border-bottom-left-radius": "7px", position: "absolute", "margin-top": "-123px", "margin-left": "90px", width: "50px",    height: "15px", borderRadius: "7px" }}><h1 style={{ color: data && data.LeftExpiryDays > 0 ? "rgba(240, 129, 69, 1)" : "rgb(127 124 123)", fontWeight: "500", "text-align": "center",fontSize:"11px" }}>
                            {data && data.LeftExpiryDays > 0 ? <>{data && data.LeftExpiryDays} d left</> : "Expired"}</h1>
                          </div>

                        </div> : <div><div style={{ width: "143px", height: "147px", border: "1px solid rgba(56, 79, 111, 0.44)", borderRadius: "41px", marginBottom: "15%" }}>
                         
                         
<div style={{ background: data && data.LeftExpiryDays > 0 ? "rgba(250, 225, 75, 1)" : "rgb(251 247 226)", "border-top-left-radius": "7px", "border-bottom-left-radius": "7px",  position:"absolute",marginTop:"25px", "margin-left": "90px",zIndex:999, width: "50px",height: "15px", borderRadius: "7px" }}><h1 style={{ color: data && data.LeftExpiryDays > 0 ? "rgba(240, 129, 69, 1)" : "rgb(127 124 123)", fontSize:"11px",fontWeight: "500", "text-align": "center" }}>
                            {data && data.LeftExpiryDays > 0 ? <>{data && data.LeftExpiryDays} d left</> : "Expired"}</h1>
                          </div>
                          <img style={{
                            "mix-blend-mode": "multiply",
                            "border-top-right-radius": "33px",
                            "border-top-left-radius": "33px"
                          }} src={Gift1} />

                          <div style={{ background: "rgba(250, 225, 75, 1)", "border-top-left-radius": "7px", "border-bottom-left-radius": "7px", position: "absolute", "margin-top": "-24px", width: "140px" }}><h1 style={{ color: "rgba(240, 129, 69, 1)", fontWeight: "500", textAlign: "center" }}>Redeemed</h1></div>

                          <div style={{ padding: "5%", "padding-left": "12%" }}>
                            <h1 style={{ fontWeight: "600", fontSize: "14px" }}>₹{data.value}</h1>
                            <p style={{ fontWeight: "400", fontSize: "8px" }}>Gift Card from a friend
                              Enjoy!</p>
                          </div>
                        </div></div>}

                      </>
                    </div>
                  ))}

{
                  giftHistory && giftHistory.expiredVouchers.map((data, index) => (

                    <div style={{}}>
                      {console.log("STRing", data.LeftExpiryDays.length)}
                      <>

                        {!(data.isclaim) ? <div onClick={(e) => {data && data.LeftExpiryDays > 0 && handleClaim(e, data && data.code) }} style={{ width: "143px", height: "147px", border: "1px solid rgba(56, 79, 111, 0.44)", borderRadius: "41px", marginBottom: "15%" }}>
                          <img style={{
                            "mix-blend-mode": data && data.LeftExpiryDays > 0 ? "multiply" : "luminosity",
                            "border-top-right-radius": "40px",
                            "border-top-left-radius": "40px"
                          }} src={giftwrap} />

                          <div style={{ background: data && data.LeftExpiryDays > 0 ? "rgba(250, 225, 75, 1)" : "rgb(251 247 226)", "border-top-left-radius": "7px", "border-bottom-left-radius": "7px", position: "absolute", "margin-top": "-123px", "margin-left": "88px", width: "50px", height: "15px",borderRadius: "7px" }}><h1 style={{ color: data && data.LeftExpiryDays > 0 ? "rgba(240, 129, 69, 1)" : "rgb(127 124 123)", fontWeight: "500", fontSize:"11px","text-align": "center" }}>
                            {data && data.LeftExpiryDays > 0 ? <>{data && data.LeftExpiryDays} d left</> : "Expired"}</h1>
                          </div>

                        </div> : <div><div style={{ width: "143px", height: "147px", border: "1px solid rgba(56, 79, 111, 0.44)", borderRadius: "41px", marginBottom: "15%" }}>
                         
                         
<div style={{ background: data && data.LeftExpiryDays > 0 ? "rgba(250, 225, 75, 1)" : "rgb(229 229 229)", "border-top-left-radius": "7px", "border-bottom-left-radius": "7px",  position:"absolute",marginTop:"25px", "margin-left": "90px",zIndex:999, width: "50px",height: "15px", borderRadius: "7px" }}><h1 style={{ color: data && data.LeftExpiryDays > 0 ? "rgba(240, 129, 69, 1)" : "rgb(127 124 123)",fontSize:"11px", fontWeight: "500", "text-align": "center" }}>
                            {data && data.LeftExpiryDays > 0 ? <>{data && data.LeftExpiryDays} d left</> : "Expired"}</h1>
                          </div>
                          <img style={{
                            "mix-blend-mode": "luminosity",
                            "border-top-right-radius": "40px",
                            "border-top-left-radius": "40px"
                          }} src={Gift1} />

                          <div style={{ background: "rgb(229 229 229)", "border-top-left-radius": "7px", "border-bottom-left-radius": "7px", position: "absolute", "margin-top": "-24px", width: "140px" }}><h1 style={{ color: "rgb(117 117 117)", fontWeight: "500", textAlign: "center" }}>Redeemed</h1></div>

                          <div style={{ padding: "5%", "padding-left": "12%" }}>
                            <h1 style={{ fontWeight: "600", fontSize: "14px",color:"rgb(127, 124, 123)" }}>₹{data.value}</h1>
                            <p style={{ fontWeight: "400", fontSize: "8px",color:"rgb(127, 124, 123)"  }}>Gift Card from a friend
                              Enjoy!</p>
                          </div>
                        </div></div>}

                      </>
                    </div>
                  ))}


              </div>
            </div>
          </div>
        </div>
      </Box>

    </div>)
}



function mapStateToProps(props) {
  return {
    props,
  };
}
export default connect(mapStateToProps, {
  sendGiftRequest,
  getGiftHistoryRequest,
  getGiftClaimRequest
})(GiftCard);




{/*    {true && <div style={{ width: "143px", height: "147px", border: "1px solid rgba(56, 79, 111, 0.44)", borderRadius: "41px",marginBottom:"15%" }}>
                          <img style={{
                            "mix-blend-mode": "multiply",
                            "border-top-right-radius": "33px",
                            "border-top-left-radius": "33px"
                          }} src={Gift1} />
                          <div style={{ background: "rgba(250, 225, 75, 1)", "border-top-left-radius": "7px", "border-bottom-left-radius": "7px", position: "absolute", "margin-top": "-59px", "margin-left": "85px" }}><h1 style={{ color: "rgba(240, 129, 69, 1)", fontWeight: "500" }}>11d left</h1></div>

                          <div style={{ padding: "5%" }}>
                            <h1 tyle={{ fontWeight: "600", fontSize: "14px" }}>₹100.00</h1>
                            <p style={{ fontWeight: "400", fontSize: "8px" }}>Gift Card from a friend
                              Enjoy!</p>
                          </div>
                        </div>} */}