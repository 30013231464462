import React from "react";
import './procced.css'
import { Link } from "react-router-dom";
import arrow from '../../assets/nextArrow.png'
import proArrow from '../../assets/proArrow.png'
import { useNavigate } from "react-router-dom";
import {  mpCartClicked } from "../../Mixpanel";
function Procced(props) {
const navigate=useNavigate()
  console.log("procced",props.bottom?props.bottom:"36px")

  const goMyCart=()=>{
    
    localStorage.setItem("mycart", true)
   
    mpCartClicked({EventName:props.mixpanelEvent,data:{ItemsInCart:props.cartItemsList,TotalCartValue:props.totalamount}})
    navigate('/myCart')
  }
  
  return (
    <div className="proccedModal" style={{fontFamily:"Roboto",bottom:props.bottom?props.bottom:"36px"}}>

      <div style={{position:"absolute",top:"30px",backgroundColor:"blue","background-color":"#129A69",color:"white",height:"44px",width: "95%",marginLeft:"11px","border-radius": "14px"}}>
       <div style={{display:"flex","margin-top":"13px","justify-content":"space-around"}}>
        
        <div><h3 style={{fontSize:"14px"}}>{props.cartCount} {" "}item{" "}<span style={{color: "rgba(255, 255, 255, 0.70)",
"font-family": "Roboto",
"font-size":"14px",
"font-style":"normal",
"font-weight": "400",
"line-height":"normal",
"letter-spacing":"0.2px"}}></span>(Rs.{" "}{(props.totalamount)})
</h3></div>

        <div style={{display:"flex"}}>

        <div className="vl"></div>
          
         <div style={{"text-decoration": "NONE"}}    onClick={(e)=>{goMyCart(e)}}><div style={{display:"flex", "align-items":"center"}}><h3 style={{color:"white","font-size":"14px","text-decoration":"none"}}>Proceed To Pay</h3><img src={proArrow} style={{ "margin-left":"5px"}}/> </div></div>
          </div>
       
       
       
       </div>
      </div>
      
    </div>
  );
}

export default Procced;
