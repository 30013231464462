import TagAdd from "./component"
import {fetchSearchTokenRequest,fetchSecretKeyLinkRequest,fetchSecretKeyActiveRequest} from "./action"
import { connect } from "react-redux";
import {  toast } from 'react-toastify';
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
const TagAddContainer=(props)=>{
  const navigate=useNavigate()
    const [tokenNumber,setTokenNumber]=useState([])
 const [pageCount,setPageCount]=useState(1)

 const [taguid,setTaguid]=useState("")
 const[loader,setLoader]=useState(false)
 const [tagActive,setTagActive]=useState(false)
 const [askLink,setAskLink]=useState(false)
 const [secretkey,setSecretkey]=useState(null)
    useEffect(()=>{
       
    },[])

    const handleChangeOtp = (otpcode) => {
      console.log(" sumbitOtp  Otpcode---------",otpcode)
      setSecretkey(otpcode)
    }
    const handleResponseSearchToken=(response)=>{

      console.log("Get response SearchToken",response.data.response);
      setTaguid(response.data.response.uid)
      setTagActive(response.data.response.active)
      localStorage.setItem("TagUUID",response.data.response.uid)
      if(response.data.response.active==true){
        setPageCount(pageCount+1)
       
      }
      else{
        toast.error(`TAG NOT ACTIVE`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
      });
     
      }
      setLoader(false)

    
    }
    
    const handleErrorSearchToken=(error)=>{

      setLoader(false)
      if(error.response.status==401|| error.response.status==403){

        console.log("khan handleErrorVmInventory", error.response.data.status.code);
        //Logout
        //localStorage.removeItem("UserJWTAuth")
          navigate("/login");
    }
    else{ toast.error(`${error.response.data.status.code}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    }
   
    
    }
    
    
    const SearchToken = () => {
     
      const UserJWTAuth = localStorage.getItem('UserJWTAuth');
      console.log("UserJWTAuth",UserJWTAuth)
      setLoader(true)
      console.log("inventory...container")
    
      props.fetchSearchTokenRequest({
        url: `/auth/tag/search?qrData=${tokenNumber}`,
        method:  "GET",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
          "x-app-version": "v1.0.1",
           //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid")
    
        },
        
    
    
        handleErrorSearchToken,
        handleResponseSearchToken,
      });
    };
    
   
    
    const handleResponseSecretKeyLink=(response)=>{

      setLoader(false)
            console.log("Get response SearchToken",response.data.response);
           
            setPageCount(pageCount+1)
            navigate("/taglist")
    
    }
    
    const handleErrorSecretKeyLink=(err)=>{

      setLoader(false)
      navigate("/taglist")
      console.log("SearchToken ERROR",err.response.data.statusMessage);
      toast.error(`${err.response.data.statusMessage}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
    });
    
    }


    
    
    const LinkTagFun = () => {
     
      const UserJWTAuth = localStorage.getItem('UserJWTAuth');
      console.log("UserJWTAuth",UserJWTAuth)
      setLoader(true)
      console.log("inventory...container")
    
      props.fetchSecretKeyLinkRequest({
        url: `/auth/tag/${taguid}/link`,
        method:  "POST",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
            "x-app-platform": "web",
          "x-app-version": "v1.0.1",
           //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid"),
          "x-tag-secret": `${secretkey}`
    
        },
        request:{request:{"replaceCurrentAccount":true}},
          
        
    
    
        handleErrorSecretKeyLink,
        handleResponseSecretKeyLink,
      });
    };
    

////////////////////////////////////////////////////////////////////

const handleResponseSecretKeyActive=(response)=>{

  setLoader(false)

  console.log("Get response SearchToken",response.data.response);
 
  setPageCount(pageCount+1)
  LinkTagFun()


 setTimeout(() => {
  navigate("/taglist")
 }, 1000);
  

}

const handleErrorSecretKeyActive=(err)=>{
  setLoader(false)
  navigate("/taglist")
  console.log("SearchToken ERROR",err.response.data.statusMessage);
  toast.error(`${err.response.data.statusMessage}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
});

}


const SubmitSecretKeyActive = () => {
 
  const UserJWTAuth = localStorage.getItem('UserJWTAuth');
  console.log("UserJWTAuth",UserJWTAuth)
  setLoader(true)
  console.log("inventory...container")

  props.fetchSecretKeyActiveRequest({
    url:  `/auth/tag/${taguid}/active`,
    method:  "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid"),
      "x-tag-secret": `${secretkey}`

    },
    request:{"replaceCurrentAccount":false},
      
    


    handleErrorSecretKeyActive,
    handleResponseSecretKeyActive,
  });
};




   
    const handleChangeRfIDToken=(e)=>{
      console.log("handleChangeRfIDToken",e.target.value)
      setTokenNumber(e.target.value)
    }
    const handleChangeSecretkey=(secretkey)=>{
      console.log("handleChangeSecretkey",secretkey)
      setSecretkey(secretkey)
    }


    return(
        <TagAdd
      
        handleChangeRfIDToken={handleChangeRfIDToken}
        SearchToken={SearchToken}
        pageCount={pageCount}
       
        handleChangeSecretkey={handleChangeSecretkey}
       
        loader={loader}
        askLink={askLink}
        setTokenNumber={setTokenNumber}
        tokenNumber={tokenNumber}
        handleChangeOtp={handleChangeOtp}
        secretkey={secretkey}
        LinkTagFun={LinkTagFun}
        />


    )
}
function mapStateToProps({ props }) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    
    fetchSecretKeyActiveRequest,
    fetchSecretKeyLinkRequest,
    fetchSearchTokenRequest,
  
  })(TagAddContainer);

