export const FETCH_BP_SUBMIT_OTP_REQUEST="FETCH_BP_SUBMIT_OTP_REQUEST"
export const FETCH_BP_SUBMIT_OTP_SUCCESS="FETCH_BP_SUBMIT_OTP_SUCCESS"
export const FETCH_BP_SUBMIT_OTP_FAILURE="FETCH_BP_SUBMIT_OTP_FAILURE"


export const fetchBpSubmitOtpRequest =(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_BP_SUBMIT_OTP_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchBpSubmitOtpSuccess=(OrderHistory)=>{
return{
    type:FETCH_BP_SUBMIT_OTP_SUCCESS,
    OrderHistory:OrderHistory
}
}

export const fetchBpSubmitOtpFailure=(error)=>{
    return{
    type:FETCH_BP_SUBMIT_OTP_FAILURE,
    error:error
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_LINK_BP_REQUEST="FETCH_LINK_BP_REQUEST"
export const FETCH_LINK_BP_SUCCESS="FETCH_LINK_BP_SUCCESS"
export const FETCH_LINK_BP_FAILURE="FETCH_LINK_BP_FAILURE"


export const fetchLinkBpRequest =(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_LINK_BP_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchLinkBpSuccess=(OrderHistory)=>{
return{
    type:FETCH_LINK_BP_SUCCESS,
    OrderHistory:OrderHistory
}
}

export const fetchLinkBpFailure=(error)=>{
    return{
    type:FETCH_LINK_BP_FAILURE,
    error:error
    }
}






////////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_BP_RESEND_OTP_REQUEST="FETCH_BP_RESEND_OTP_REQUEST"
export const FETCH_BP_RESEND_OTP_SUCCESS="FETCH_BP_RESEND_OTP_SUCCESS"
export const FETCH_BP_RESEND_OTP_FAILURE="FETCH_BP_RESEND_OTP_FAILURE"


export const fetchBpResendOtpRequest =(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_BP_RESEND_OTP_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchBpResendOtpSuccess=(OrderHistory)=>{
return{
    type:FETCH_BP_RESEND_OTP_SUCCESS,
    OrderHistory:OrderHistory
}
}

export const fetchBpResendOtpFailure=(error)=>{
    return{
    type:FETCH_BP_RESEND_OTP_FAILURE,
    error:error
    }
}
//fetchBpResendOtpRequest