
////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_TAG_HISTORY_REQUEST="FETCH_TAG_HISTORY_REQUEST"
export const FETCH_TAG_HISTORY_SUCCESS="FETCH_TAG_HISTORY_SUCCESS"
export const FETCH_TAG_HISTORY_FAILURE="FETCH_TAG_HISTORY_FAILURE"


export const fetchTagHistoryRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_TAG_HISTORY_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchTagHistorySuccess=(TagHistory)=>{
return{
    type:FETCH_TAG_HISTORY_SUCCESS,
    TagHistory:TagHistory
}
}

export const fetchTagHistoryFailure=(error)=>{
    return{
    type:FETCH_TAG_HISTORY_FAILURE,
    error:error
    }
}





////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_CREATE_RECHARGE_ORDER_REQUEST="FETCH_CREATE_RECHARGE_ORDER_REQUEST"
export const FETCH_CREATE_RECHARGE_ORDER_SUCCESS="FETCH_CREATE_RECHARGE__ORDER_SUCCESS"
export const FETCH_CREATE_RECHARGE_ORDER_FAILURE="FETCH_CREATE_RECHARGE_ORDER_FAILURE"


export const fetchCreateOrderRequest=(myurldata)=>{
    console.log("fetchCreateOrderRequest myurldata---->",myurldata)
    return{
        type:FETCH_CREATE_RECHARGE_ORDER_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchCreateOrderSuccess=(CreateOrder)=>{
return{
    type:FETCH_CREATE_RECHARGE_ORDER_SUCCESS,
    CreateOrder:CreateOrder
}
}

export const fetchCreateOrderFailure=(error)=>{
    return{
    type:FETCH_CREATE_RECHARGE_ORDER_FAILURE,
    error:error
    }
}



////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_PAYTM_CAPTURE_REQUEST="FETCH_PAYTM_CAPTURE_REQUEST"
export const FETCH_PAYTM_CAPTURE_SUCCESS="FETCH_PAYTM_CAPTURE_SUCCESS"
export const FETCH_PAYTM_CAPTURE_FAILURE="FETCH_PAYTM_CAPTURE_FAILURE"


export const fetchPaytmCaptureRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_PAYTM_CAPTURE_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchPaytmCaptureSuccess=(CreateOrder)=>{
return{
    type:FETCH_PAYTM_CAPTURE_SUCCESS,
    CreateOrder:CreateOrder
}
}

export const fetchPaytmCaptureFailure=(error)=>{
    return{
    type:FETCH_PAYTM_CAPTURE_FAILURE,
    error:error
    }
}

//


export const FETCH_TAG_DETAILS_REQUEST="FETCH_TAG_DETAILS_REQUEST"
export const FETCH_TAG_DETAILS_SUCCESS="FETCH_TAG_DETAILS_SUCCESS"
export const FETCH_TAG_DETAILS_FAILURE="FETCH_TAG_DETAILS_FAILURE"


export const fetchTagDetailsRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_TAG_DETAILS_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchTagDetailsSuccess=(CreateOrder)=>{
return{
    type:FETCH_TAG_DETAILS_SUCCESS,
    CreateOrder:CreateOrder
}
}

export const fetchTagDetailsFailure=(error)=>{
    return{
    type:FETCH_TAG_DETAILS_FAILURE,
    error:error
    }
}




export const FETCH_DEACTIVE_TAG_REQUEST="FETCH_DEACTIVE_TAG_REQUEST"
export const FETCH_DEACTIVE_TAG_SUCCESS="FETCH_DEACTIVE_TAG_SUCCESS"
export const FETCH_DEACTIVE_TAG_FAILURE="FETCH_DEACTIVE_TAG_FAILURE"


export const fetchDeactiveTagRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_DEACTIVE_TAG_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchDeactiveTagSuccess=(CreateOrder)=>{
return{
    type:FETCH_DEACTIVE_TAG_SUCCESS,
    CreateOrder:CreateOrder
}
}

export const fetchDeactiveTagFailure=(error)=>{
    return{
    type:FETCH_DEACTIVE_TAG_FAILURE,
    error:error
    }
}
//fetchDeactiveTagRequest