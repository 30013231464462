///////////////////////////////////FETCH COMBO ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_COMBO_CART_REMOVE_REQUEST="FETCH_COMBO_CART_REMOVE_REQUEST"
export const FETCH_COMBO_CART_REMOVE_SUCCESS="FETCH_COMBO_CART_REMOVE_SUCCESS"
export const FETCH_COMBO_CART_REMOVE_FAILURE="FETCH_COMBO_CART_REMOVE_FAILURE"



 
export const sendComboCartREMOVERequest=(data)=>{
    console.log("etchPreCardRequest myurldata---->",data)
    return{
        type:FETCH_COMBO_CART_REMOVE_REQUEST,
        data:data
    }

    
   
}



export const sendComboCartREMOVESuccess=(cart)=>{
    return{
        type:FETCH_COMBO_CART_REMOVE_SUCCESS,
        cart:cart
    }
    }
    
    export const sendComboCartREMOVEFailure=(error)=>{
        return{
        type:FETCH_COMBO_CART_REMOVE_FAILURE,
        error:error
        }
    }
    
    

///////////////////////////////////FETCH COMBO ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_COMBO_CART_ADD_REQUEST="FETCH_COMBO_CART_ADD_REQUEST"
export const FETCH_COMBO_CART_ADD_SUCCESS="FETCH_COMBO_CART_ADD_SUCCESS"
export const FETCH_COMBO_CART_ADD_FAILURE="FETCH_COMBO_CART_ADD_FAILURE"



 
export const sendComboCartADDRequest=(data)=>{
    console.log("etchPreCardRequest myurldata---->",data)
    return{
        type:FETCH_COMBO_CART_ADD_REQUEST,
        data:data
    }

    
   
}



export const sendComboCartADDSuccess=(cart)=>{
    return{
        type:FETCH_COMBO_CART_ADD_SUCCESS,
        cart:cart
    }
    }
    
    export const sendComboCartADDFailure=(error)=>{
        return{
        type:FETCH_COMBO_CART_ADD_FAILURE,
        error:error
        }
    }
    
    
    

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////





///////////////////////////////////FETCH COMBO ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_COMBO_REQUEST="FETCH_COMBO_REQUEST"
export const FETCH_COMBO_SUCCESS="FETCH_COMBO_SUCCESS"
export const FETCH_COMBO_FAILURE="FETCH_COMBO_FAILURE"



 
export const fetchComboRequest=(data)=>{
    console.log("etchPreCardRequest myurldata---->",data)
    return{
        type:FETCH_COMBO_REQUEST,
        data:data
    }

    
   
}



export const fetchComboSuccess=(cart)=>{
    return{
        type:FETCH_COMBO_SUCCESS,
        cart:cart
    }
    }
    
    export const fetchComboFailure=(error)=>{
        return{
        type:FETCH_COMBO_FAILURE,
        error:error
        }
    }
    
    
    

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////











///////////////////////////////////FETCH VM INVENTORY ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_MOVE_CART_REQUEST="FETCH_MOVE_CART_REQUEST"
export const FETCH_MOVE_CART_SUCCESS="FETCH_MOVE_CART_SUCCESS"
export const FETCH_MOVE_CART_FAILURE="FETCH_MOVE_CART_FAILURE"



 
export const fetchMoveCartRequest=(data)=>{
    console.log("etchPreCardRequest myurldata---->",data)
    return{
        type:FETCH_MOVE_CART_REQUEST,
        data:data
    }

    
   
}



export const fetchMoveCartSuccess=(cart)=>{
    return{
        type:FETCH_MOVE_CART_SUCCESS,
        cart:cart
    }
    }
    
    export const fetchMoveCartFailure=(error)=>{
        return{
        type:FETCH_MOVE_CART_FAILURE,
        error:error
        }
    }
    
    
    

///////////////////////////////////FETCH VM INVENTORY ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_VM_INVENTORY_REQUEST="FETCH_VM_INVENTORY_REQUEST"
export const FETCH_VM_INVENTORY_SUCCESS="FETCH_VM_INVENTORY_SUCCESS"
export const FETCH_VM_INVENTORY_FAILURE="FETCH_VM_INVENTORY_FAILURE"


 
export const fetchVmInventoryRequest=(data)=>{
    console.log("etchPreCardRequest myurldata---->",data)
    return{
        type:FETCH_VM_INVENTORY_REQUEST,
        data:data
    }

    
   
}

export const fetchVmInventorySuccess=(VmInventory)=>{
return{
    type:FETCH_VM_INVENTORY_SUCCESS,
    VmInventory:VmInventory
}
}

export const fetchVmInventoryFailure=(error)=>{
    return{
    type:FETCH_VM_INVENTORY_FAILURE,
    error:error
    }
}





///////////////////////////////////FETCH VM INVENTORY  SPECIAL ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_VM_INVENTORY_SPECIAL_REQUEST="FETCH_VM_INVENTORY_SPECIAL_REQUEST"
export const FETCH_VM_INVENTORY_SPECIAL_SUCCESS="FETCH_VM_INVENTORY_SPECIAL_SUCCESS"
export const FETCH_VM_INVENTORY_SPECIAL_FAILURE="FETCH_VM_INVENTORY_SPECIAL_FAILURE"


 
export const fetchVmInventorySpecialRequest=(data)=>{
    console.log("etchPreCardRequest myurldata---->",data)
    return{
        type:FETCH_VM_INVENTORY_SPECIAL_REQUEST,
        data:data
    }

    
   
}

export const fetchVmInventorySpecialSuccess=(VmInventorySpecial)=>{
return{
    type:FETCH_VM_INVENTORY_SPECIAL_SUCCESS,
    VmInventorySpecial:VmInventorySpecial
}
}

export const fetchVmInventorySpecialFailure=(error)=>{
    return{
    type:FETCH_VM_INVENTORY_SPECIAL_FAILURE,
    error:error
    }
}






///////////////////////////////////FETCH VM INVENTORY  SPECIAL ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_PRE_CART_VM_INVENTORY_REQUEST="FETCH_PRE_CART_VM_INVENTORY_REQUEST"
export const FETCH_PRE_CART_VM_INVENTORY_SUCCESS="FETCH_PRE_CART_VM_INVENTORY_SUCCESS"
export const FETCH_PRE_CART_VM_INVENTORY_FAILURE="FETCH_PRE_CART_VM_INVENTORY_FAILURE"


 
export const fetchPreCartVmInventoryRequest=(data)=>{
    console.log("etchPreCardRequest myurldata---->",data)
    return{
        type:FETCH_PRE_CART_VM_INVENTORY_REQUEST,
        data:data
    }

    
   
}

export const fetchPreCartVmInventorySuccess=(PreCartVmInventory)=>{
return{
    type:FETCH_PRE_CART_VM_INVENTORY_SUCCESS,
    PreCartVmInventory:PreCartVmInventory
}
}

export const fetchPreCartVmInventoryFailure=(error)=>{
    return{
    type:FETCH_PRE_CART_VM_INVENTORY_FAILURE,
    error:error
    }
}



///////////////////////////////////  ADD TO CART ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const SEND_ADD_INTO_CART_REQUEST="SEND_ADD_INTO_CART_REQUEST"
export const SEND_ADD_INTO_CART_SUCCESS="SEND_ADD_INTO_CART_SUCCESS"
export const SEND_ADD_INTO_CART_FAILURE="SEND_ADD_INTO_CART_FAILURE"


 
export const sendAddIntoCartRequest=(data)=>{
    console.log("etchPreCardRequest myurldata---->",data)
    return{
        type:SEND_ADD_INTO_CART_REQUEST,
        data:data
    }

    
   
}

export const sendAddIntoCartSuccess=(AddIntoCartRes)=>{
return{
    type:SEND_ADD_INTO_CART_SUCCESS,
    AddIntoCartRes:AddIntoCartRes
}
}

export const sendAddInotoCartFailure=(error)=>{
    return{
    type:SEND_ADD_INTO_CART_FAILURE,
    error:error
    }
}



/////////////////////////////////// REMOVE FROM CART ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const SEND_REMOVE_FROM_CART_REQUEST="SEND_REMOVE_FROM_CART_REQUEST"
export const SEND_REMOVE_FROM_CART_SUCCESS="SEND_REMOVE_FROM_CART_SUCCESS"
export const SEND_REMOVE_FROM_CART_FAILURE="SEND_REMOVE_FROM_CART_FAILURE"


 
export const sendRemoveFromCartRequest=(data)=>{
    console.log("etchPreCardRequest myurldata---->",data)
    return{
        type:SEND_REMOVE_FROM_CART_REQUEST,
        data:data
    }

    
   
}

export const sendRemoveFromCartSuccess=(RemoveFromCartRes)=>{
return{
    type:SEND_REMOVE_FROM_CART_SUCCESS,
    RemoveFromCartRes:RemoveFromCartRes
}
}

export const sendRemoveFromCartFailure=(error)=>{
    return{
    type:SEND_REMOVE_FROM_CART_FAILURE,
    error:error
    }
}



///////////////////////////////////  ADD TO CART ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const SEND_TODAY_ADD_INTO_CART_REQUEST="SEND_TODAY_ADD_INTO_CART_REQUEST"
export const SEND_TODAY_ADD_INTO_CART_SUCCESS="SEND_TODAY_ADD_INTO_CART_SUCCESS"
export const SEND_TODAY_ADD_INTO_CART_FAILURE="SEND_TODAY_DD_INTO_CART_FAILURE"


 
export const sendTodayAddIntoCartRequest=(data)=>{
    console.log("etchPreCardRequest myurldata---->",data)
    return{
        type:SEND_TODAY_ADD_INTO_CART_REQUEST,
        data:data
    }

    
   
}

export const sendTodayAddIntoCartSuccess=(TodayAddIntoCartRes)=>{
return{
    type:SEND_TODAY_ADD_INTO_CART_SUCCESS,
    TodayAddIntoCartRes:TodayAddIntoCartRes
}
}

export const sendTodayAddInotoCartFailure=(error)=>{
    return{
    type:SEND_TODAY_ADD_INTO_CART_FAILURE,
    error:error
    }
}



/////////////////////////////////// REMOVE FROM CART ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const SEND_TODAY_REMOVE_FROM_CART_REQUEST="SEND_TODAY_REMOVE_FROM_CART_REQUEST"
export const SEND_TODAY_REMOVE_FROM_CART_SUCCESS="SEND_TODAY_REMOVE_FROM_CART_SUCCESS"
export const SEND_TODAY_REMOVE_FROM_CART_FAILURE="SEND_TODAY_REMOVE_FROM_CART_FAILURE"


 
export const sendTodayRemoveFromCartRequest=(data)=>{
    console.log("etchPreCardRequest myurldata---->",data)
    return{
        type:SEND_TODAY_REMOVE_FROM_CART_REQUEST,
        data:data
    }

    
   
}

export const sendTodayRemoveFromCartSuccess=(TodayRemoveFromCartRes)=>{
return{
    type:SEND_TODAY_REMOVE_FROM_CART_SUCCESS,
    TodayRemoveFromCartRes:TodayRemoveFromCartRes
}
}

export const sendTodayRemoveFromCartFailure=(error)=>{
    return{
    type:SEND_TODAY_REMOVE_FROM_CART_FAILURE,
    error:error
    }
}

///////////////////////////////////ACTIVE ORDERS  ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_ACTIVE_ORDERS_VM_INVENTORY_REQUEST="FETCH_ACTIVE_ORDERS_VM_INVENTORY_REQUEST"
export const FETCH_ACTIVE_ORDERS_VM_INVENTORY_SUCCESS="FETCH_ACTIVE_ORDERS_VM_INVENTORY_SUCCESS"
export const FETCH_ACTIVE_ORDERS_VM_INVENTORY_FAILURE="FETCH_ACTIVE_ORDERS_VM_INVENTORY_FAILURE"


 
export const fetchActiveOrdersVmInventoryRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_ACTIVE_ORDERS_VM_INVENTORY_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchActiveOrdersVmInventorySuccess=(ActiveOrdersVmInventory)=>{
return{
    type:FETCH_ACTIVE_ORDERS_VM_INVENTORY_SUCCESS,
    ActiveOrdersVmInventory:ActiveOrdersVmInventory
}
}

export const fetchActiveOrdersVmInventoryFailure=(error)=>{
    return{
    type:FETCH_ACTIVE_ORDERS_VM_INVENTORY_FAILURE,
    error:error
    }
}




///////////////////////////////////ACTIVE ORDERS  ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_CLEAR_PRE_CARD_INVENTORY_REQUEST="FETCH_CLEAR_PRE_CARD_INVENTORY_REQUEST"
export const FETCH_CLEAR_PRE_CARD_INVENTORY_SUCCESS="FETCH_CLEAR_PRE_CARD_INVENTORY_SUCCESS"
export const FETCH_CLEAR_PRE_CARD_INVENTORY_FAILURE="FETCH_CLEAR_PRE_CARD_INVENTORY_FAILURE"


 
export const fetchClearPreCardRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_CLEAR_PRE_CARD_INVENTORY_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchClearPreCardSuccess=(ClearPreCard)=>{
return{
    type:FETCH_CLEAR_PRE_CARD_INVENTORY_SUCCESS,
    ClearPreCard:ClearPreCard
}
}

export const fetchClearPreCardFailure=(error)=>{
    return{
    type:FETCH_CLEAR_PRE_CARD_INVENTORY_FAILURE,
    error:error
    }
}

///////////////////////////////////ACTIVE ORDERS  ACTIONS///////////////////////////////////////////////////////////////////////////////////////

export const FETCH_BUY_AGAIN_REQUEST="FETCH_BUY_AGAIN_REQUEST"
export const FETCH_BUY_AGAIN_SUCCESS="FETCH_BUY_AGAIN_SUCCESS"
export const FETCH_BUY_AGAIN_FAILURE="FETCH_BUY_AGAIN_FAILURE"


 
export const fetchBuyAgainRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_BUY_AGAIN_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchBuyAgainSuccess=(ClearPreCard)=>{
return{
    type:FETCH_BUY_AGAIN_SUCCESS,
    ClearPreCard:ClearPreCard
}
}

export const fetchBuyAgainFailure=(error)=>{
    return{
    type:FETCH_BUY_AGAIN_FAILURE,
    error:error
    }
}

//