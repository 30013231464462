import { useEffect, useState } from "react";
import { connect } from "react-redux";
import BusinessPayment from "./component";
import { linkbpuser } from "./action";
import { fetchBpSubmitOtpRequest,fetchLinkBpRequest,fetchBpResendOtpRequest } from "./action1";
import {  toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { mpClick } from "../../Mixpanel";
const BusinessPaymentContainer = (props) => {
 const navigator = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const UserJWTAuth = localStorage.getItem('UserJWTAuth');
  const [loader,setLoader]=useState(false)
   useEffect(()=>{
    
    console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
    if(!UserJWTAuth){
      navigator('/')
    }
  },[])

  useEffect(() => {
   
    
t()
    console.log("remainingTime>=0",remainingTime)

  }, [isDisabled]);



const t=()=>{
  let interval;
  if (isDisabled ) {
    interval = setInterval(() => {
     
    timmer()
    }, 1000); // Update the remaining time every second
  }

  return () => {
    clearInterval(interval);
  };
}  

const timmer=()=>{

    setRemainingTime(prevTime => prevTime - 1);
   
    console.log("remainingTime>=0",remainingTime)
    
} 


  const handleSendOTP = () => {

    

    ResendOtpBpApi()

    setIsDisabled(false);
    setRemainingTime(60);
    t()

    setTimeout(() => {
      setIsDisabled(true);

    }, 60000);
 
  };


  const [pageCounter,setPageCounter]=useState(0)
  const navigate = useNavigate();





const [otpcode, setOtpcode] = useState("");
const [email, setEmail] = useState("");
const [emailFlag,setEmailFlag]=useState(true);
const [optflag,setOtpfalg]=useState(false);
const [verificationAttemptId,setVerificationAttemptId]=useState("")

const handleChangeOtp = (otpcode) => {
  console.log(" sumbitOtp  Otpcode---------",otpcode)
  setOtpcode(otpcode)
}

const handlePageCounter=(count)=>{
  console.log("link bp page counter",count)
  setPageCounter(count);

}
useEffect(()=>{

},[pageCounter])
const handleChangeEmail=(e)=>{
    console.log('BpEmail',e.target.value)
    setEmail(e.target.value)
}
const handleSubmit = (e) => {
    if(!localStorage.getItem("uuid")){
   
    }
    e.preventDefault();
    const EMAIL_REGEX = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/
      );
    console.log("Emailvaild",EMAIL_REGEX.test(email))
    if (EMAIL_REGEX.test(email)) {
        console.log(email, " -> is valid");
        
        //setEmailOneValid(true);
      } else {
        console.log("email is invalid");
        //setEmailOneValid(false);
      }

      LinkBpApi()
      
    // submit form
  };





  const sumbitOtp = () => {
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  mpClick({EventName:"Otp_Submitted"})
    props.fetchBpSubmitOtpRequest({
      url:  "/auth/user/business-profile/code/verify",
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      },
      "request": {
        "request": {
          "verificationAttemptId": verificationAttemptId,
          "passCode": otpcode
            }
  
  
        },
        handleErrorBpVerification ,
        handleResponseBpVerification ,
    });
  };
  
  
  const handleErrorBpVerification=(res)=>{
    console.log("res")
    setLoader(false)
    mpClick({EventName:"Bp_link_failed"})
  }
  
  const handleResponseBpVerification=(response)=>{
    console.log("Get LinkBpApi---------",response.data.status,response.data.status=="SUCCESS");
    if(response.data.status=="FAILURE"){
      toast.error(`${response.data.statusMessage}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      mpClick({EventName:`bp link error${response.data.statusMessage}`})
    }else
   {
   

    if(response.data.status=="SUCCESS"){
   
      mpClick({EventName:"Bp_link_successful"})
      setEmailFlag(false);
    
      setOtpfalg(false)
      navigate('/paymentOption',{ replace: true })
    }
  }
    
  }
  
  







const LinkBpApi = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchLinkBpRequest({
    url:  "/auth/user/business-profile/register",
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },
    "request": {
      "request": {
        "businessEmail": email,
        "sourcePlatform": "web",
        "x-device-id": localStorage.getItem("uuid")
        }
      


      },
      handleErrorLinkBp ,
      handleResponseLinkBp ,
  });
};


const handleErrorLinkBp=(error)=>{
  console.log("res")
  setLoader(false)

  if(error.response.status==401 || error.response.status==403){

   
    //Logout
    //localStorage.removeItem("UserJWTAuth")
      navigate("/login");
}
else{ toast.error(`${error.response.data.status.code}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
}

const handleResponseLinkBp=(response)=>{
  console.log("Get LinkBpApi",response.data.status,response.data);
  setVerificationAttemptId(response&&response.data&&response.data.response&&response.data.response.verificationAttemptId)
if(response.data.status=="SUCCESS"){

setEmailFlag(false);
setOtpfalg(true)
setIsDisabled(true)
setPageCounter(2);

}


 
  else{ 
    if(response.data.status=="FAILURE"){
    toast.error(`${response.data.statusMessage}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
}
}


setLoader(false)
}












//https://api-prod.daalchini.co.in



const ResendOtpBpApi = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchBpResendOtpRequest({
    url:  "/auth/user/business-profile/code/resend",
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },
    "request": {
      "request": {
        "verificationAttemptId": verificationAttemptId
        }


      },
      handleErrorResendOtpBp ,
      handleResponseResendOtpBp ,
  });
};


const handleErrorResendOtpBp=(response)=>{
  console.log("res")
  setLoader(false)
}

const handleResponseResendOtpBp=(response)=>{

  setVerificationAttemptId(response.data.response.verificationAttemptId)
   
}






return(
  <BusinessPayment 
  
  otpcode={otpcode}
  handleChangeOtp={handleChangeOtp}
  handleChangeEmail={handleChangeEmail}
  handleSubmit={handleSubmit}
  optflag={optflag}
  emailFlag={emailFlag}
  sumbitOtp={sumbitOtp}
  pageCounter={pageCounter}
  setPageCounter={setPageCounter}
  handlePageCounter={handlePageCounter}
  ResendOtpBpApi={ResendOtpBpApi}
  email={email}
  remainingTime={remainingTime}
  isDisabled={isDisabled}
  handleSendOTP={handleSendOTP}
  loader={loader}
 

  />
)

}




function mapStateToProps(props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    linkbpuser,


    fetchBpSubmitOtpRequest,
    fetchLinkBpRequest,
    fetchBpResendOtpRequest
    
  })(BusinessPaymentContainer);