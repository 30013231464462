import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { FETCH_FAQ_DETAILS_REQUEST, fetchFaqDetailsFailure, fetchFaqDetailsSuccess } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  








function* fetcheFaqDetails(action) {
  console.log("fetchPreCard saga", action);
  try {
    const response = yield call(callApi, action);

    yield put(fetchFaqDetailsSuccess(response));

    action.data.handleResponseFaqDetails(response);
  } catch (error) {
    console.log("fetchPreCard error", error.message);
   
    yield put(fetchFaqDetailsFailure(error.message));
    action.data.handleErrorFaqDetails(error);
  }
}

  function* FaqDetailsSaga() {
    yield takeLatest(FETCH_FAQ_DETAILS_REQUEST, fetcheFaqDetails);
    ///yield takeLatest(FETCH_ORDER_HISTORY_LIST_FAQ_REQUEST,fetcheOrderHistory)
    
  }
  
  export default FaqDetailsSaga;