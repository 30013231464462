import { FETCH_ACTIVE_ORDERS_VM_INVENTORY_FAILURE, FETCH_ACTIVE_ORDERS_VM_INVENTORY_REQUEST, FETCH_ACTIVE_ORDERS_VM_INVENTORY_SUCCESS, FETCH_PRE_CART_VM_INVENTORY_REQUEST, FETCH_PRE_CART_VM_INVENTORY_SUCCESS, FETCH_VM_INVENTORY_FAILURE, FETCH_VM_INVENTORY_REQUEST, FETCH_VM_INVENTORY_SPECIAL_FAILURE, FETCH_VM_INVENTORY_SPECIAL_REQUEST, FETCH_VM_INVENTORY_SPECIAL_SUCCESS, FETCH_VM_INVENTORY_SUCCESS, SEND_ADD_INTO_CART_FAILURE, SEND_ADD_INTO_CART_REQUEST, SEND_ADD_INTO_CART_SUCCESS, SEND_REMOVE_FROM_CART_FAILURE, SEND_REMOVE_FROM_CART_REQUEST, SEND_REMOVE_FROM_CART_SUCCESS, SEND_TODAY_ADD_INTO_CART_FAILURE, SEND_TODAY_ADD_INTO_CART_REQUEST, SEND_TODAY_ADD_INTO_CART_SUCCESS, SEND_TODAY_REMOVE_FROM_CART_FAILURE, SEND_TODAY_REMOVE_FROM_CART_REQUEST, SEND_TODAY_REMOVE_FROM_CART_SUCCESS } from "./action";

const initialState = {
    //////////////Vm Inventory List VARIABLES///////////////
      VmInventoryList: [],
      MachineName:null,
      MachineAddress:null,
      VmInventoryMachineId:null,
      loading_VmInventoryList: false,
      error_VmInventoryList: null,


      VmInventorySpecialList: [],
      loading_VmInventorySpecialList: false,
      error_VmInventorySpecialList: null,



      PreCartVmInventoryList:[],
      PreCartMachineIdVmInventory:null,
      loading_PreCartVmInventory: false,
      error_PreCartVmInventory: null,
      PreCart:null,



      AddIntoCartResList:[],
      loading_AddIntoCart: false,
      error_AddIntoCart: null,




      RemoveFromCartResList:[],
      loading_RemoveFromCart: false,
      error_RemoveFromCart: null,




      TodayAddIntoCartResList:[],
      loading_TodayAddIntoCart: false,
      error_TodayAddIntoCart: null,



      ActiveOrdeVmInventory:[],
      loading_active_orders_vm_inventory: true,
      error_active_orders_vm_inventory: null,
    
}


const VmInventoryReducer = (state = initialState, action) => {
  
    switch (action.type) {
  
  ///////////////// SWITCH CASE FOR VM INVENTORY LIST ////////////////////////////////////////
  
      case FETCH_VM_INVENTORY_REQUEST:
        return {
          ...state,
          loading_VmInventoryList: false,
          error_VmInventoryList: null,
        };
  
      case FETCH_VM_INVENTORY_SUCCESS: 
        return {
          ...state,
        
          VmInventoryList: action.VmInventory,
          VmInventoryMachineId:action&&action.VmInventory&&action.VmInventory.headers&&action.VmInventory&&action.VmInventory.headers.machineid,
          MachineName: action&&action.VmInventory&&action.VmInventory.headers&&action.VmInventory.headers.name,
          MachineAddress: action&&action.VmInventory&&action.VmInventory.headers&&action.VmInventory.headers.address,
          loading_VmInventoryList: false,
          error_VmInventoryList: null,
  
        
        };
  
      case FETCH_VM_INVENTORY_FAILURE: 
        return {
          ...state,
          loading_VmInventoryList: false,
          error_VmInventoryList:action.error,
        };





  ///////////////// SWITCH CASE FOR TODAY SPECIAL VM INVENTORY LIST ////////////////////////////////////////
  
  case FETCH_VM_INVENTORY_SPECIAL_REQUEST:
    return {
      ...state,
      loading_VmInventorySpecialList: false,
      error_VmInventorySpecialList: null,
    };

  case FETCH_VM_INVENTORY_SPECIAL_SUCCESS: 
    return {
      ...state,
    
      VmInventorySpecialList: action&& action.VmInventorySpecial&&action.VmInventorySpecial.data.data,
      loading_VmInventorySpecialList: false,
      error_VmInventorySpecialList: null,

    
    };

  case FETCH_VM_INVENTORY_SPECIAL_FAILURE: 
    return {
      ...state,
      loading_VmInventorySpecialList: false,
      error_VmInventoryLSpecialist:action.error,
    };



  ///////////////// SWITCH CASE FOR PREVIOUS CART FOR VM INVENTORY LIST ////////////////////////////////////////
  
  case FETCH_PRE_CART_VM_INVENTORY_REQUEST:
    return {
      ...state,
      loading_PreCartVmInventory: false,
      error_PreCartVmInventory: null,
    };

  case FETCH_PRE_CART_VM_INVENTORY_SUCCESS: 
    return {
      ...state,
      
      PreCartVmInventoryList:action&& action.PreCartVmInventory&& action.PreCartVmInventory.data&&action.PreCartVmInventory.data&&action.PreCartVmInventory.data.data&&action.PreCartVmInventory.data.data.items,
      PreCart:action&& action.PreCartVmInventory,
      PreCartMachineIdVmInventory:action&& action.PreCartVmInventory&&action.PreCartVmInventory.headers&&action.PreCartVmInventory.headers.machineid,
      loading_PreCartVmInventory: false,
      error_PreCartVmInventory: null,

    
    };

  case FETCH_VM_INVENTORY_SPECIAL_FAILURE: 
    return {
      ...state,
      loading_PreCartVmInventory: false,
      error_PreCartVmInventory: action.error
    };

///////////////// SWITCH CASE FOR ADD IN TO CART FOR VM INVENTORY LIST ////////////////////////////////////////
  
case SEND_ADD_INTO_CART_REQUEST:
  return {
    ...state,
    loading_AddIntoCart: false,
    error_AddIntoCart: null,
  };

case SEND_ADD_INTO_CART_SUCCESS: 
  return {
    ...state,
    
    AddIntoCartResList:action&& action.PreCartVmInventory&& action.PreCartVmInventory.data&&action.PreCartVmInventory.data&&action.PreCartVmInventory.data.data&&action.PreCartVmInventory.data.data.items,
    loading_AddIntoCart: false,
    error_AddIntoCart: null,

  
  };

case SEND_ADD_INTO_CART_FAILURE: 
  return {
    ...state,
    loading_AddIntoCart: false,
    error_AddIntoCart: action.error
  };





///////////////// SWITCH CASE FOR REMOVE FROM CART FOR VM INVENTORY LIST ////////////////////////////////////////

case SEND_REMOVE_FROM_CART_REQUEST:
  return {
    ...state,
    loading_RemoveFromCart: false,
    error_RemoveFromCart: null,
  };

case SEND_REMOVE_FROM_CART_SUCCESS: 
  return {
    ...state,
    
    RemoveFromCartResList:action&& action.PreCartVmInventory&& action.PreCartVmInventory.data&&action.PreCartVmInventory.data&&action.PreCartVmInventory.data.data&&action.PreCartVmInventory.data.data.items,
    loading_RemoveFromCart: false,
    error_RemoveFromCart: null,

  
  };

case SEND_REMOVE_FROM_CART_FAILURE: 
  return {
    ...state,
    loading_RemoveFromCart: false,
    error_RemoveFromCart: action.error
  };






  ///////////////// SWITCH CASE FOR TODAY SPECIAL ADD IN TO CART FOR VM INVENTORY LIST ////////////////////////////////////////
  
  case SEND_TODAY_ADD_INTO_CART_REQUEST:
    return {
      ...state,
      loading_TodayAddIntoCart: false,
      error_TodayAddIntoCart: null,
    };

  case SEND_TODAY_ADD_INTO_CART_SUCCESS: 
    return {
      ...state,
      
      TodayAddIntoCartResList:action&& action.PreCartVmInventory&& action.PreCartVmInventory.data&&action.PreCartVmInventory.data&&action.PreCartVmInventory.data.data&&action.PreCartVmInventory.data.data.items,
      loading_TodayAddIntoCart: false,
      error_TodayAddIntoCart: null,
    
    };

  case SEND_TODAY_ADD_INTO_CART_FAILURE: 
    return {
      ...state,
      loading_TodayAddIntoCart: false,
      error_TodayAddIntoCart: action.error
    };





  ///////////////// SWITCH CASE FOR REMOVE FROM CART FOR VM INVENTORY LIST ////////////////////////////////////////
  
  case SEND_TODAY_REMOVE_FROM_CART_REQUEST:
    return {
      ...state,
      loading_TodayRemoveFromCart: false,
      error_TodayRemoveFromCart: null,
    };

  case SEND_TODAY_REMOVE_FROM_CART_SUCCESS: 
    return {
      ...state,
      
      TodayRemoveFromCartResList:action&& action.PreCartVmInventory&& action.PreCartVmInventory.data&&action.PreCartVmInventory.data&&action.PreCartVmInventory.data.data&&action.PreCartVmInventory.data.data.items,
      loading_TodayRemoveFromCart: false,
      error_TodayRemoveFromCart: null,

    
    };

  case SEND_TODAY_REMOVE_FROM_CART_FAILURE: 
    return {
      ...state,
      loading_TodayRemoveFromCart: false,
      error_TodayRemoveFromCart: action.error
    };


//////////////////////SWITCH CASES FOR ACTIVE ORDERS VM INVENTORY //////////////////////////////////////
case FETCH_ACTIVE_ORDERS_VM_INVENTORY_REQUEST:
    return {
      ...state,
      loading_active_orders_vm_inventory: true,
      error_active_orders_vm_inventory: null,
    };

  case FETCH_ACTIVE_ORDERS_VM_INVENTORY_SUCCESS: 
    return {
      ...state,
      ActiveOrdeVmInventory: action && action.ActiveOrdersVmInventory.data.data,
       
      loading_active_orders_vm_inventory: true,
      error_active_orders_vm_inventory: null,
      
     
    
    
    };

  case FETCH_ACTIVE_ORDERS_VM_INVENTORY_FAILURE: 
  return {
    ...state,
   
    loading_active_orders_vm_inventory: true,
    error_active_orders_vm_inventory: null,
    
   
  }

 



        default:
            return state;
  
    }  
}

export default VmInventoryReducer;