import React from "react";
import BillLogo from '../../assets/billLogo.png'
import '../BusinessPayment/businesspayement.css'
import Calculater from '../../assets/calculater.png'
import phone from '../../assets/phone.png'
import Watch from '../../assets/watch.png'
import inputMailIcon from '../../assets/inputMailIcon.png'
import mail from '../../assets/mail.png'
import OtpInput from "../../react-otp-input"; 
import { useState } from "react";
import backArrow from '../../assets/backArrow.png'
 import { Link } from "react-router-dom";
import WaitingLoder from "../WaitingLoader/component";
import { mpClick } from "../../Mixpanel";
import bpImage from '../../assets/bpImage.png'
import paper from '../../assets/paper.png'
import { Box, Button, Typography } from "@material-ui/core";
import calculater from '../../assets/calculater.png'
import watch from '../../assets/watch.png'
let route
const BusinessPayment=(props)=>{

  const AddAccount=(e)=>{
    mpClick({EventName:"Bp_link_initiated"})
    props.setPageCounter(0)
  }
    console.log("BusinessPayment",props)
  if(localStorage.getItem("OrderId")){
    route='/myCart'
   }
   else{
     route=null
   }

  const TopNavbar=()=>{
    return (
      <div className="_2456r">
  <div className="_3nxa7" data-testid="listing-header-location">
    <button
      className="_1NdRR"
      aria-label="Selected address is Delhi, India; Double tap to change address."
      id="change-address-btn-id"
    >
      <div style={{display:"flex","align-items":"center",width:"151%"}}>
     <Link  to={route?route:"/paymentOption"}><img onClick={(e)=>{props.setPageCounter(e,props.pageCounter-1)}}style={{height:"18px", "margin-right":"12px"}}src={backArrow}/></Link>
     <div style={{marginLeft:"10px"}}>
      <h3  style={{color:  "#583C43",
"text-align": "center",
"font-family": "Roboto",
"font-size": "18px",
"font-style": "normal",
"font-weight":"500",

"line-height": "normal",
"letter-spacing":"0.36px"}}>Business Payment Account</h3>
     
      </div>
      </div>
    </button>
  
  </div>
  </div>
    );
  }
  const TopNavbar1=()=>{
    return (
      <div className="_2456r">
  <div className="_3nxa7" data-testid="listing-header-location">
    <button
      className="_1NdRR"
      aria-label="Selected address is Delhi, India; Double tap to change address."
      id="change-address-btn-id"
    >
      <div style={{display:"flex",width:"151%"}}>
     <Link ><img onClick={(e)=>{AddAccount(e)}}style={{height:"18px","margin-right":"12px"}}src={backArrow}/></Link>
     <div style={{marginLeft:"10px"}}>
      <h3  style={{color:  "#583C43",
"text-align": "center",
"font-family": "Roboto",
"font-size": "18px",
"font-style": "normal",
"font-weight":"500",

"line-height": "normal",
"letter-spacing":"0.36px"}}>Link Business Expense Account</h3>
     
      </div>
      </div>
    </button>
  
  </div>
  </div>
    );
  }

  const TaNav2BackFun=(e,count)=>{
    console.log("TaNav2BackFun count",count)
    props.setPageCounter(count)
  }

  const TopNavbar2=()=>{
    return (
      <div className="_2456r">
  <div className="_3nxa7" data-testid="listing-header-location">
    <button
      className="_1NdRR"
      aria-label="Selected address is Delhi, India; Double tap to change address."
      id="change-address-btn-id"
    >
      <div style={{display:"flex",width:"151%"}}>
     <a href='/link/bp' ><img /* onClick={(e)=>{TaNav2BackFun(e,0)}} */style={{height:"18px","margin-right":"12px"}}src={backArrow}/></a>
     <div style={{marginLeft:"10px"}}>
      <h3 style={{color:  "#583C43",
"text-align": "center",
"font-family": "Roboto",
"font-size": "18px",
"font-style": "normal",
"font-weight":"500",

"line-height": "normal",
"letter-spacing":"0.36px"}}>Email Verification</h3>
     
      </div>
      </div>
    </button>
  
  </div>
  </div>
    );
  }
 
    return(
      
      
      <div>


{props.loader && <WaitingLoder />} 
      

    {props&&props.pageCounter==0?<div>
      <TopNavbar />
      <Box    sx={{display:{xs:"block",md:"",sm:"block"},
        //backgroundImage: ' linear-gradient(to bottom, #ffffff, #f4fcff)',
        background: "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
        minHeight: '100vh',
        padding: "12px",

        color: '#fff', // Set text color to white
    }}
>
     
    <Box  style={{boxShadow:"none"}} display={"flex"} justifyContent={"center"} marginTop={"30%"}><img style={{width:"187px",height:"120px",    "mix-blend-mode":"multiply"}} src={bpImage}/></Box>

<Box  display={"flex"} justifyContent={"center"} style={{boxShadow:"none"}} marginTop={"5%"} >
  <Box>
  <Box>
  <Typography style={{fontSize:"20px",fontWeight:"700",color:"rgba(56, 79, 111, 1)"}}>Enjoy Daalchini at Work</Typography>
  <Typography style={{color: "rgba(113, 129, 152, 1)" ,fontSize:"12px",fontWeight:"400"} }>Daalchini partners with business to pay for food at work</Typography>
  </Box>

  <Box>
<Box display={"flex"} alignItems={"center"} marginTop={"5%"}>
  <Box style={{background:"#80808029"}} display={"flex"} justifyContent={"center"}  alignItems={"center"} width={"48px"} height={"48px"}  borderRadius={"30px"}><img  style={{width:"18px",height:"24px", "object-fit":"contain"}}src={paper}/></Box>
  <Typography style={{color:"rgba(56, 79, 111, 1) ", fontSize:"14px",marginLeft:"20px"}}>Bills businesses for using<br/>
Daalchini at work</Typography>

</Box>
  </Box>
  <Box display={"flex"} alignItems={"center"}  marginTop={"5%"}>
  <Box style={{background:"#80808029"}} display={"flex"} justifyContent={"center"}  alignItems={"center"} width={"48px"} height={"48px"}  borderRadius={"30px"}><img  style={{width:"18px",height:"24px", "object-fit":"contain"}}src={calculater}/></Box>
  <Typography style={{color:"rgba(56, 79, 111, 1) ", fontSize:"14px",marginLeft:"20px"}}>Helps maintain businesses<br/>
expense account</Typography>

</Box>
<Box display={"flex"} alignItems={"center"}  marginTop={"5%"} >
  <Box style={{background:"#80808029"}} display={"flex"} justifyContent={"center"}  alignItems={"center"} width={"48px"} height={"48px"}  borderRadius={"30px"}><img  style={{width:"18px",height:"24px", "object-fit":"contain"}}src={watch}/></Box>
  <Typography style={{color:"rgba(56, 79, 111, 1) ", fontSize:"14px",marginLeft:"20px"}}>Saves employee time</Typography>

</Box>
  </Box>

</Box>
<Box display={"flex"} justifyContent={"center"}>
<Button onClick={(e)=>{props.handlePageCounter(1)}} style={{marginTop:"20%",background:"rgba(0, 177, 119, 1)",borderRadius:"12px",height:"48px",fontWeight:"600",color:"white",width:"90%"}}>Link Business Expense Account</Button></Box>
<Typography  style={{display:"flex",justifyContent:"center",color:"rgba(148, 163, 184, 1)", margin:"5%"}}>Or</Typography>
<a  href="https://www.daalchini.co.in/" style={{display:"flex",justifyContent:"center",color:"rgba(100, 116, 139, 1)", margin:"5%"}}>Avail this service for your Business</a>

   </Box>
      </div>:
      <div>
      {props&&props.emailFlag&&props&&props.pageCounter==1&&<div>
        <TopNavbar1 />
      
        <div style={{"padding-top": "81px",height:"100vh",background: "rgb(245,233,233)",
background: "radial-gradient(circle, rgba(245,233,233,0.4066001400560224) 0%, rgba(185,245,247,0.3393732492997199) 100%)"}} >
          
        <img
        /*   src={Businesspament} */
         /*  onClick={handleLinkCloseModal} */
          className="RegCloseImg"
        />
        <div className="REG">
          <div className="RedImgdiv">
            <img src={phone} className="RegImg" />
          </div>
          <diV style={{    display:"flex"}}>
          <div className="EnjoyDiv">
            <h1 style={{ color: "#384F6F",

/* B20 */
"font-family": "Roboto",
"font-size":"20px",
"font-style": "normal",
"font-weight": "700",
"line-height": "normal", }}>Hi,</h1>
            <p style={{ color: "#718198",

/* R12 */
"font-family": "Roboto",
"font-size": "12px",
"font-style": "normal",
"font-weight": "400",
"line-height": "150%",
"letter-spacing":"0.2px" }}>
            Please enter your business payments details
            </p>
           
          </div>
          </diV>
          <div style={{display:"flex","justify-content":"center", "margin-top":"44px"}}>
          <div style={{

display: "flex",
"width": "86%",
"height":"44px",
"padding": "4px 16px",
"flex-direction": "column",
"justify-content":"center",
"align-items": "flex-start",
"gap": "10px",
"flex-shrink": "0",
"background":"white",
          
          
          
          
          
          "border-radius":"12px",
"border": "1px solid rgba(148, 163, 184, 0.54)",

/* 16 */
"box-shadow":"0px 2px 21px 0px rgba(0, 0, 0, 0.08)"}}>
            <div style={{display:"flex",width:"100%"}}>
             
          <img  style={{ width:"27px",height:"20px"}}src={inputMailIcon}/>
          <input placeholder="Enter your company email ID" style={{border:"none", "margin-left":"5px","font-size":"15px",width:"100%"}} onChange={(e)=>{props.handleChangeEmail(e)}}/>
            </div>
            </div>
            </div>

          
         
            <div style={{position:"absolute",bottom:"51px",width:"100%",   "right":"2%"}}>
          <a href="https://www.daalchini.co.in/terms.html" className="whatsappLink" style={{display: "flex","justify-content":"center",marginTop:"21px",position:"absolute","color":"#b3a1a1",
    "margin-left": "50px",
    "margin-top": "13px"}}>
          <p> By linking your account, you agree to  Terms & Conditions.</p>         </a>
          {props&&props.email.length>5 ?<button onClick={props.handleSubmit} className="verybtn" style={{display:"flex","justify-content": "center" ,  "margin":"33px","border-radius": "13px"}}>
            LINK  ACCOUNT
          </button>
          :
          <button className="verybtn" style={{display:"flex","justify-content": "center" ,  "margin":"33px","border-radius": "13px",backgroundColor:"#82938d"}}>
            LINK  ACCOUNT
          </button>}
          </div>
        </div>
      </div>
      </div>}
      {props&&props.optflag&&props&&props.pageCounter==2&&<div>
        <TopNavbar2/>
        <div style={{"padding-top": "81px",height:"100vh",background: "rgb(245,233,233)",
background: "radial-gradient(circle, rgba(245,233,233,0.4066001400560224) 0%, rgba(185,245,247,0.3393732492997199) 100%)"}} >
       
          
     
        <div className="REG">
          <div style={{    display:"flex","justify-content":"space-around"}}>  
          <div className="RedImgdiv" style={{width:"111px",height:"111px", "border-radius":"72px" ,  display:"flex","align-items":"center"}}>
            <img  style={{ width:"100%",height:"100%"}}src={mail} className="RegImg" />
          </div>
           </div> 
          <diV style={{    display:"flex","justify-content": "center","margin-top":"8%","text-align":"center"}}>
          <div className="">
            <h1 style={{ color:"#384F6F","font-family":"Roboto","font-size":"20px","font-style":"normal","font-weight":"700","line-height":"normal" }}>Verify your Email</h1>
            <p style={{ color: "#64748B",

/* R14 */
"font-family":"Roboto",
"font-size":"14px","font-style":"normal","font-weight":"400",
"line-height":" 150%", /* 21px */
"letter-spacing":"0.2px" }}>
            Enter 5 digit OTP send to your email ID
            </p>
           
          </div>
          </diV>
          <div style={{display:"flex","justify-content": "center",marginTop:"15%"}}>
          <OtpInput
        value={props.otpcode}
     
        onChange={props.handleChangeOtp}
        numInputs={6}
        separator={<span style={{ width: "8px" }}></span>}
        isInputNum={true}
        shouldAutoFocus={true}
        inputStyle={{
         
          borderRadius: "8px",
          width: "44px",
          height: "44px",
          fontSize: "12px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue",
          border: "1px solid #6c5858"
        }}
        focusStyle={{
          border: "1px solid #CFD3DB",
          outline: "none"
        }}
      />
      </div>
      <div style={{    display: "flex","margin-top":"12%","justify-content":"center"}}>
      <p >Didn’t receive code?{props.remainingTime>0?<span>Resend</span>:<span style={{color:"rgb(0, 177, 119)"}} onClick={props.handleSendOTP}>Resend</span>}</p> 
     
      </div>
      {!props.disabled&&<p style={{    display:"flex","justify-content":"center"}}><span>{props.remainingTime>0&&`${props.remainingTime}s`}</span></p>}
     <div style={{display:"flex","justify-content":"center", "margin-top":"44px"}}>
     
        
  
            </div> 

          
         
            <div style={{position:"absolute",bottom:"51px",width:"100%","right":"2%"}}>
          <p href="whatsapp://" className="whatsappLink" style={{display: "flex","justify-content":"center",marginTop:"21px",position:"absolute","color":"#b3a1a1",
    "margin-left": "50px",
    "margin-top": "13px"}}>
          By linking your account, you agree to Terms & Conditions.          </p>
         {props.otpcode.length==6? <button onClick={props.sumbitOtp} className="verybtn" style={{display:"flex","justify-content": "center" ,  "margin":"37px","border-radius": "13px",}}>
          Verify Account
          </button>: <button  className="verybtn" style={{display:"flex","justify-content": "center" ,  "margin":"37px","border-radius": "13px",backgroundColor:"#BCBDBD"}}>
          Verify Account
          </button>}
          </div>
        </div>
      </div>
      </div>}
      </div>
      }



      </div>


    )
}

export default BusinessPayment;