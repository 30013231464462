import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import nextArrow from '../../assets/nextArrow.png'
import GlobalSearchHeader from "../Header/GlobalSearchHeader";
import BackArrow from "../../assets/backArrow.png"
import { useParams } from 'react-router-dom';
import { mpClick, mpHelp } from "../../Mixpanel";

function OrderDeatails(props) {
  console.log("props Orders",props.orderdetail.vendingMachineAddress)
  const UserJWTAuth = localStorage.getItem('UserJWTAuth');
  const navigate=useNavigate()
  const orderId = useParams();
  console.log("orderId",orderId.orderId)

  function TruncatedHeading({ text, limit }) {
    if (text&&text.length <= limit) {
      return <h3 style={{"color":"#583C43"}}>{text}</h3>;
    }
    return <h3 style={{"color":"#583C43"}}>{`${text.substring(0, limit)}...`}</h3>;
  }

  function TruncatedHeading1({ text, limit }) {
    if (text&&text.length <= limit) {
      return <p style={{"color":"#583C43"}}>{text}</p>;
    }
    return <p style={{"color":"#583C43"}}>{`${text.substring(0, limit)}...`}</p>;
  }
  const CreatedTime=(props)=>{

    console.log("TIME",props.time)
    var date = new Date(props.time);
  
    // Convert to Indian time string
    var indianTime = date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
    
    console.log("indianTime",indianTime);

  return(
  
  
    <>
    <>{indianTime}</>
    </>
  )
  
   }
  
   const helpFun=(e,order_id)=>{
    mpHelp({EventName:"Help_clicked",data:{order_id:order_id}})

    window.location.replace(`https://api.whatsapp.com/send/?phone=917289005034&text=Hello%2C I+need+assistance+with+my+order.+My+order+ID +is + ${order_id}&type=phone_number&app_absent=0`)
  }
  const TopNav = () => {
    return (
      <>
      
        <div className="_2456r" style={{height:"111px",display:"block"}}>
          <div className="_3nxa7" style={{"margin-top":"57px"}}>
            <button
              className="_1NdRR"
              aria-label="Selected address is Delhi, India; Double tap to change address."
              id="change-address-btn-id"
              style={{width:"100%","margin-top":"-45px"}}
            >
              <div style={{ display: "flex", "align-items": "center" }}>
                <Link to={"/orders"}>
                  <img 
                  src={BackArrow} 
                  style={{height:"18px","margin-right":"12px" }} />
                </Link>
                <div style={{ marginLeft: "10px" }}>
                  <div><TruncatedHeading text={`VM_ID=${props&&props.orderdetail&&props.orderdetail.vendingMachineId}`} limit={10}/></div>
               <TruncatedHeading1 text={props&&props.orderdetail&&props.orderdetail.vendingMachineAddress} limit={30}/>
               </div>
              </div>
            </button>
            

         
          </div>
         <div style={{display:"flex","padding":"5%","justify-content":"space-between","align-items":"center"}}> <h3 style={{fontSize:"12px","color":"#583C43"}}>Order ID: #{orderId.orderId}</h3>
         
         <p style={{"color":"#583C43",fontSize:"12px"}}><CreatedTime time={props&&props.orderdetail&&props.orderdetail.createdAt}/></p>
         </div>
       
        </div>
      
      </>
    );
  };

  return (
 
      
    <div /* id="root" */ style={{ height: "100%" }}>
    <div className="_2yoTv ns7Wo">
      <div>
       <TopNav/> 
    
        <div>
          <div className="_2NVhy _3hjyL">
            <div>
              <div style={{ padding: 0 }}>
                <div>
                  <div />
                </div>
              </div>
              <div style={{ padding: 0 }}>
                <div>
                  <div />
                  <div
                    data-testid="grid-header"
                    className="styles_headerContainer__2UgeD"
                  />
                </div>
              </div>
              <div style={{ padding: "8px 0px 0px" }}>
                <div>
                  <div />
                </div>
              </div>
              <div>
                <div>
                  <div />
                  < div style={{"margin-top": "60px"}}>
                 {/*  <ImageAd /> */}

            


                  </div>
                  
           
                </div>
              </div>
              <div style={{ padding: "8px 0px 0px" }}>
                <div>
                  <div />
                  <div
                    data-testid="grid-header"
                    className="styles_headerContainer__2UgeD"
                    style={{"margin-top": "40px"}}
                  >
                  </div>
                </div>
              </div>

              <div style={{ padding: 0 }}>
             

                <div>
                  <div />
                
                  <div
                    data-testid="grid-container"
                    aria-hidden="false"
                    style={{ paddingBottom: props.cartCount?127:40, paddingRight: 0 }}
                  >
                    <div
                      className="styles_slider__podEu"
                      data-testid="grid-slider"
                    >
                      
                      {props&&props.orderdetail&&props.orderdetail.lineItems.map((el,index) => {
                        return (
                        <div
                          className="styles_row__1ivP1 shadow-box"
                          data-testid="grid-row"
                          style={{ marginBottom: 24, marginTop: "4%" ,"border-radius":"10px","margin-right": "12px","margin-left": "12px"}}
                        >
                          <div
                            className="styles_slide__2c207"
                            style={{ marginRight: 0 }}
                          >
                            <div>
                              <div
                                className="styles_cardWithPadding__JE1QE"
                                data-testid="home-restaurant-card-wrapper"
                                style={{
                                  width: "calc((100vw - 0px) - 12px)",
                                }}
                              >
                                <div
                                style={{marginTop:"21px"}}
                                  data-testid="resturant-card-anchor-container"
                                  className="styles_container__fLC0R"
                                  aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                  tabIndex={0}
                                  role="button"
                                >
                                  <div
                                    className="styles_imgContainer__1uHo5"
                                    aria-hidden="true"
                                  >
                                    <div
                                      className="styles_ImageContainer__2rk9a"
                                      data-testid="resturant-card-image-container"
                                      style={{
                                        background: "rgb(251, 238, 215)",
                                      }}
                                    >
                                      <img
                                      style={{    height:"100%"}}
                                        className="styles_Image__1fplJ"
                                        loading="lazy"
                                        data-testid="resturant-card-image"
                                        src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.imageUrl}`}                                     
                                      />
                                    </div>
                                 
                              
                                  </div>
                                  <div
                                    aria-hidden="true"
                                    className="styles_containerRestaurant__3vhx3"
                                  >
                                    <div className="styles_containerImageBadge__14fk3">
                                      <div
                                        data-testid="resturant-card-name"
                                        className="styles_restaurantName__29jAP"
                                        style={{fontSize:"14px","color":"#384F6F"}}
                                      >
                                        {el.productName}
                                      </div>
                                      
                                    </div>

                                    <div>
                                                                             <h3 style={{ "margin-top" :"5px",fontSize:"14px",color:"#718198"}}>{el.unitCount} Items</h3>
                                    
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginTop:"5px"
                                      }}

                                    >
                                        <h2 style={{fontSize:"13px",color:"#00B177"}}>Rs.{""}{el.offerPrice.toFixed(2)}</h2>
                                      <h2 style={{color:"rgb(0, 177, 119)","margin-right":"24px",fontSize:"13px"}}>{props.orderdetail&&props.orderdetail.status}</h2>
                                
                                      

                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    </div>
                    <div
                      className="styles_row__1ivP1 shadow-box"
                      data-testid="grid-row"
                      style={{
                        marginBottom: "-5px",
                        marginTop: "0%",
                        "border-radius": "10px",
                        "margin-right": "12px",
                        "margin-left": "12px",
                        height: "37px",
                        marginBottom: 5,
                        
                      }}
                    >
                      <div
                        className="styles_slide__2c207"
                        style={{ marginRight: 0 }}
                      >
                        <div>
                          <div
                            className="styles_cardWithPadding__JE1QE"
                            data-testid="home-restaurant-card-wrapper"
                            style={{
                              width: "calc((100vw - 0px) - 12px)",
                            }}
                          >
                            <a
                              data-testid="resturant-card-anchor-container"
                              className="styles_container__fLC0R"
                              aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                              tabIndex={0}
                              role="button"
                            >
                              <div
                                className="styles_imgContainer__1uHo5"
                                aria-hidden="true"
                              ></div>
                              <div
                                style={{
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "align-items": "center",
                                  "margin-right": "61px",
                                  marginTop: "10px",
                                }}
                              >
                                <h2 style={{fontSize:"14px","color":"#384F6F"}}>Grand Total</h2>
                                <h2 style={{fontSize:"14px","color":"#384F6F"}}>₹ {props &&props.totalAmount&& props.totalAmount.toFixed(2)}</h2>
                              </div>
                            </a>
                          </div>
                          <div />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style={{    display:"flex","justify-content":"space-around"}}>
                <div>
                <div style={{"color":"#718198",textDecoration:"none"}} onClick={(e)=>{helpFun(e,orderId.orderId)}}>
              <p  style={{border:"1px solid","display":"flex","align-items":"center","justify-content":"flex-start",padding:"6px","color":"#718198",border:"1px solid rgb(113, 129, 152)","border-radius":"5px"}}>Help & Support</p>
              </div>
              </div>
              </div>
              <div className="g4ROG">
                <div className="XI5jB" />
                <div className="_3oHpz">
                  <div className="_3SqwT _3gWWx" />
                  <div className="_3SqwT _24uP7" />
                  <div className="_3SqwT _344W8" />
                  <div className="_3SqwT sorPS" />
                  <div className="_3SqwT _6BrSD" />
                  <div className="_3SqwT O0X9l" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
 


}

export default OrderDeatails;
