import {
    FETCH_BANNER_REQUEST,
    FETCH_BANNER_SUCCESS,
    FETCH_BANNER_FAILURE,
} from "./action";

const initialState = {
    BannerList: [],

    loading: false,
    error: null,
};

const bannerListReducer = (state = initialState, action) => {
    console.log("vmListReducer action---", action);

    switch (action.type) {
        case FETCH_BANNER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                BannerList: action,
            };

        case FETCH_BANNER_FAILURE: // error case if any error happen in fetching time than we can set global state or variable liek this
            return {
                ...state,
                loading: true,
                error: action.error,
            };
        default:
            return state;
    }
};

export default bannerListReducer;
