 import React from 'react'
import { AiOutlineClose } from 'react-icons/ai';


const HeaderSecondary = ({onClick}) => {
  return (
    <div className='h-[60px] flex items-center daalchini-header-color '>
        <AiOutlineClose size={25} className='ml-5' onClick={onClick}/>
        <p className='font-body font-medium mt-1 ml-2 text-gray-800'>Scan QR Code</p>
    </div>
  )
}

export default HeaderSecondary 

