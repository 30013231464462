import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { FETCH_ORDER_HISTORY_LIST_REQUEST, fetchOrdersHistoryFailure, fetchOrdersHistorySuccess } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetcheOrderHistory(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchOrdersHistorySuccess(response));
  
      action.data.handleResponseOrderHistory(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      yield put(fetchOrdersHistoryFailure(error.message));
    }
  }

  function* OrderHistorySaga() {
    yield takeLatest(FETCH_ORDER_HISTORY_LIST_REQUEST, fetcheOrderHistory);
    
  }
  
  export default OrderHistorySaga;