import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {  FETCH_PAYTM_AUTH_REQUEST, FETCH_VERIFY_PAYTM_AUTH_REQUEST, fetchOrdersHistoryFailure, fetchPatmAuthFailure, fetchPatmAuthSuccess, fetchVerifyPaytmOtpFailure, fetchVerifyPaytmOtpSuccess } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetchPatmAuth(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchPatmAuthSuccess(response));
  
      action.data.handleResponsePaytmAuth(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorPaytmAuth(error)

      yield put(fetchPatmAuthFailure(error.message));
    }
  }




  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchVerifyPatmAuth(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchVerifyPaytmOtpSuccess());
  
      action.data.handleResponseVerifyPaytmOtp(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
    action.data.handleErrorVerifyPaytmOtp(error)
      yield put(fetchVerifyPaytmOtpFailure(error.message));
    }
  }




  function* PaytmAuthSaga() {
    yield takeLatest(FETCH_VERIFY_PAYTM_AUTH_REQUEST, fetchVerifyPatmAuth);
    yield takeLatest(FETCH_PAYTM_AUTH_REQUEST,fetchPatmAuth)
    
  }
  
  export default PaytmAuthSaga;