export const FETCH_TAG_LIST_REQUEST="FETCH_TAG_LIST_REQUEST"
export const FETCH_TAG_LIST_SUCCESS="FETCH_TAG_LIST_SUCCESS"
export const FETCH_TAG_LIST_FAILURE="FETCH_TAG_LIST_FAILURE"


export const fetchTagListRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_TAG_LIST_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchTagListSuccess=(TagList)=>{
return{
    type:FETCH_TAG_LIST_SUCCESS,
    TagList:TagList
}
}

export const fetchTagListFailure=(error)=>{
    return{
    type:FETCH_TAG_LIST_FAILURE,
    error:error
    }
}


//////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_VERIFY_PAYTM_AUTH_REQUEST="FETCH_VERIFY_PAYTM_AUTH_REQUEST"
export const FETCH_VERIFY_PAYTM_AUTH_SUCCESS="FETCH_VERIFY_PAYTM_AUTH_SUCCESS"
export const FETCH_VERIFY_PAYTM_AUTH_FAILURE="FETCH_VERIFY_PAYTM_AUTH_FAILURE"


export const fetchVerifyPaytmOtpRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_VERIFY_PAYTM_AUTH_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchVerifyPaytmOtpSuccess=(PatmAuth)=>{
return{
    type:FETCH_VERIFY_PAYTM_AUTH_SUCCESS,
    PatmAuth:PatmAuth
}
}

export const fetchVerifyPaytmOtpFailure=(error)=>{
    return{
    type:FETCH_VERIFY_PAYTM_AUTH_FAILURE,
    error:error
    }
}