import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import PaymentOptionHeader from "../../components/Header/PaymentOptionHeader";
import WaitingLoder from "../WaitingLoader/component";
import DaalchiniWallet from '../../assets/Dp.png'
import nextArrow from '../../assets/nextArrow.png'
import Bp from '../../assets/BP.png'
import Tick from '../../assets/tick.png'
import Paytm from '../../assets/Paytm.png'
import card from '../../assets/card.png'
import sodexo from '../../assets/sodexo.png'
import wallet from '../../assets/wallet.png'
import { Link } from "react-router-dom";
import Error from '../../assets/Error.png'
import { useNavigate } from "react-router-dom";

const image_base_url = "https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/";

function PaymentOptions(props) {
const navigate=useNavigate()

const [default_Recharge_PgName,setDefault_Recharge_PgName] = useState(localStorage.getItem('default_Recharge_PgName'))
    const setDefaultRechargePgId=(e,defaultPgName)=>{
        localStorage.setItem("default_Recharge_PgName",defaultPgName)
        setDefault_Recharge_PgName(defaultPgName)
        localStorage.setItem("ChangeRechageMethod","/paymentOptionRecharge")
        navigate(`/taghistory/${TagUUID}`)
    }
    useEffect(()=>{

    },[ localStorage.getItem('default_Recharge_PgName')])

;
  const TagUUID=localStorage.getItem('TagUUID');
  console.log("props PaymentOptions",props&& props.paymentOptionsList
  );
 

  return (
    <>
    {props.loader && <WaitingLoder />}
     <div>
            <PaymentOptionHeader title={"Payment Settings"} route={`/taghistory/${TagUUID}`} />

            <div>
    
      <div
          style={{
            background:
              "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
              //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.ActiveOrderItemsListGlobal[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.ActiveOrderItemsListGlobal[0]&&"8.5rem",
          }}
          className=" pb-1 pt-1 overflow-y-auto h-screen"

        >
      <div style={{ fontFamily:"Roboto"}}>
       
          
              <div className="_2NVhy _3hjyL">
                <div>
               
           
              

                  <div style={{ padding: 0 }}>
                    <div>
                     
                
                      <div
                        data-testid="grid-container"
                        aria-hidden="false"
                      
                      >
                        <div
                          className="styles_slider__podEu"
                          data-testid="grid-slider"
                        >
                      
                          <div
                            className="styles_row__1ivP1 shadow-box"
                            data-testid="grid-row"
                            style={{
                              marginBottom: "-5px",
                              backgroundColor:"white",
                              marginTop: "4%",
                           
                              "margin-right": "12px",
                              "margin-left": "12px",
                             
                              marginBottom: 5,
                              "background-color": "#fffff",               
                              
                              "border-radius":"16px",background:"#FFF","box-shadow":"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"
                            
                          ,width:"130px"
                            
                            }}
                          >
                            
                            <div
                              className="styles_slide__2c207"
                              style={{ marginRight: 0 }}
                            >
                             
                              <div>
                                <div
                                  className="styles_cardWithPadding__JE1QE"
                                  data-testid="home-restaurant-card-wrapper"
                                  style={{
                                    width: "calc((100vw - 0px) - 12px)",
                                  }}
                                >
                              
                                  <div className="styles_container__fLC0R"
                                
                                    
                                   
                                  >
                          
                             <div>
                                <div onClick={(e)=>{setDefaultRechargePgId(e,"R_UPI")}} style={{display:"flex",}}>
                                 
                                <div  > <img style={{width:"78px","border-radius" : "64px"}}  src="https://aniportalimages.s3.amazonaws.com/media/details/ANI-20221205070213.jpg"/>{ (default_Recharge_PgName=="R_UPI")&& <div style={{position: "absolute","margin-left":"131px","margin-top": "0px"}}> <img  style={{width:"20px",height:"20px"}} src={Tick}/></div>}
                                <p style={{fontSize:"13px"}}>UPI(Other Apps)</p>
                                
                                </div>
                 
                                </div>
                             </div>
                                  </div>
                                </div>
                                <div />
                              </div>
                            </div>
                          </div>
                  
             
                          <div style={{"margin-top": "-44px"}}>
                          <span
                            className="styles_headerContainerSubtitle__1WRg5"
                            aria-hidden="true"
                          >
                            <h3 style={{ marginTop: "15%", marginLeft: "5%",color: "#718198",
"font-family":"SF Pro Display",
"font-size": "16px",
"font-style":"normal",
"font-weight": "500",
"line-height":"normal"}}>
                            Other payment method
                            </h3>
                          </span>
                        </div>
                          <div
                            className="styles_row__1ivP1 shadow-box"
                            data-testid="grid-row"
                            style={{
                              marginTop: "4%",
                              backgroundColor:"white",
                              "margin-right": "12px",
                              "margin-left": "12px",
                              "margin-bottom": "22px",
                              height: "171px",
                              "border-top-right-radius": "11px",
                              "border-top-left-radius": "11px",
                              "border-radius":"16px",background:"#FFF","box-shadow":"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"
                            }}
                          >
                            <div
                              className="styles_slide__2c207"
                              style={{ marginRight: 0 }}
                            >
                              <div>
                                <div
                                  className="styles_cardWithPadding__JE1QE"
                                  data-testid="home-restaurant-card-wrapper"
                                  style={{
                                    width: "calc((100vw - 0px) - 12px)",
                                  }}
                                >
                                  <a
                                    data-testid="resturant-card-anchor-container"
                                    className="styles_container__fLC0R"
                                    aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                    tabIndex={0}
                                    role="button"
                                  >
                               
                                 <div onClick={(e)=>{setDefaultRechargePgId(e,"RECHARGE_R_CARD")}} style={{display:"flex","justify-content": "space-between","margin-top": "27px"}}>
                                    <div style={{display:"flex","align-items":"center"}}>
                                      <img style={{width:"28px",height:"28px"}} src={card}/>
                                      <div style={{display:"flex"}}>
                                        <div style={{ "margin-left": "21px"}}> <h3 style={{"letter-spacing": "1px","font-size": "16px",color:"#384F6F"}}>
                                        Debit/Credit Cards</h3>
                                      <h4 style={{"font-size": "13px",color:"#718198"}}>Visa, MasterCard, Rupay & more...</h4></div>
                                      { (default_Recharge_PgName=="RECHARGE_R_CARD")&& <div style={{position: "absolute","margin-left":"131px","margin-top": "0px"}}> <img  style={{width:"20px",height:"20px","margin-left": "91px"}} src={Tick}/></div>}
                                      </div>
                                    </div>
                                    <div>
                                      <img  style={{width:"11px","margin-right":"21px","margin-top":"11px"}}src={nextArrow}/>
                                    </div>
                                  
                                   </div>

                                   <div onClick={(e)=>{setDefaultRechargePgId(e,"RECHARGE_R_WALLET")}}  style={{display:"flex","justify-content": "space-between","margin-top": "27px"}}>
                                    <div style={{display:"flex","align-items":"center"}}>
                                      <img style={{width:"28px",height:"28px"}} src={wallet}/>
                                      <div style={{display:"flex"}}>
                                        <div style={{ "margin-left": "21px"}}> <h3 style={{"letter-spacing": "1px","font-size": "16px",color:"#384F6F"}}>
                                        Wallets</h3>
                                      <h4 style={{"font-size": "13px",color:"#718198"}}>AmazonPay, PhonePe & more...</h4></div>
                                      { (default_Recharge_PgName=="RECHARGE_R_WALLET")&& <div style={{position: "absolute","margin-left":"131px","margin-top": "0px"}}> <img  style={{width:"20px",height:"20px"}} src={Tick}/></div>}
                                      </div>
                                    </div>
                                    <div>
                                      <img  style={{width:"11px","margin-right":"21px","margin-top":"11px"}}src={nextArrow}/>
                                    </div>
                                   </div>

                           
                                  </a>
                                </div>
                                <div />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </>
  );
}

export default PaymentOptions;
