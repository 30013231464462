import { connect } from "react-redux";
import Userprofile from "./component";
import { fetchCouponsRequest,fetchApplyCouponRequest} from "./action";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import clearAllCookies from "../Cookies";
import Coupons from "./component";
const CouponsContainer = (props) => {
  const navigate=useNavigate()

const [loader,setLoader]=useState(true)
const [CouponsList,setCouponsList]=useState([])
const [activeSessionCount,setActiveSessionCount]=useState('')
const [couponName,setCouponName]=useState('')
const [showCouponName,setShowCouponName]=useState(null)
const [showCouponFlag,setShowCouponFlag]=useState(false)


useEffect(()=>{
  fetchCoupons()
  
},[])






const UserJWTAuth = localStorage.getItem('UserJWTAuth');
useEffect(()=>{
 
 console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
 if(!UserJWTAuth){
   navigate('/')
 }
},[])

useEffect(()=>{
 
  console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
  if(localStorage.getItem('Guest_clientId')){
    //navigate('/login')
  }
 },[])
 
//

const couponNameInput=(name)=>{
  console.log("tak name",name)
  setCouponName(name)

}

const ApplyCoupon=(e,c)=>{
console.log("ApplyCoupon",c)
let coupon;
   if(c==true){
    coupon=couponName

    console.log("ApplyCoupon",c)

   }
   else{

    coupon=c
    setCouponName('')

    console.log("ApplyCoupon",c)

   }
   localStorage.setItem("appliedCouponName",coupon)
      const UserJWTAuth = localStorage.getItem("UserJWTAuth");
    
      props.fetchApplyCouponRequest({
        url: `/consumerbe/api/v2/cart/coupon`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
          
          "x-app-platform": "web",
          "x-app-version": "v1.0.1",
           //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid")
          
        },
        "request": {
       
    
            "couponName":coupon,
            "pgName":parseInt(localStorage.getItem("defaultPgId")),
          
          
    
    
          },
        handleErrorApplyCoupon:(error)=>handleErrorApplyCoupon(error),
        handleResponseApplyCoupons:(res)=>handleResponseApplyCoupons(res) ,
      });
    };
    

const handleResponseApplyCoupons=(res,coupon)=>{
  setCouponName('')
  console.log("setShowCouponName",coupon)

  setShowCouponFlag(true)

  localStorage.setItem("appliedCouponRes",JSON.stringify(res))

}
const handleErrorApplyCoupon=(error)=>{

  setCouponName('')


  toast.error(`${error&&error.response&&error.response.data&&error.response.data.status.message}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });

}


const fetchCoupons = () => {
    console.log("fetchCoupons")
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchCouponsRequest({
    url: `/consumerbe/api/v2/coupon`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      
    },
    "request": {
      request:{

        "request": {
          "oauthToken":`Bearer ${UserJWTAuth}`,
          }
      }
      


      },
    handleErrorCoupons:(error)=>handleErrorCoupons(error),
    handleResponseCoupons ,
  });
};


const handleErrorCoupons=(error)=>{
  console.log("khan handleErrorVmInventory", error);
  setLoader(false)
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    localStorage.removeItem("UserJWTAuth")
     navigate("/login");
}
else{ toast.error(`${error&&error.response&&error.response.data&&error.response.data.status}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
  
  setLoader(false)
  
}

const handleResponseCoupons=(res)=>{
  console.log("CouponsList",res.data.data)
  setCouponsList(res.data.data)
  setLoader(false)
  setLoader(false)
}
















return(
    <Coupons
    CouponsList={CouponsList}
    ApplyCoupon={ApplyCoupon}
    couponName={couponName}
    couponNameInput={couponNameInput}
    showCouponName={showCouponName}
    showCouponFlag={showCouponFlag}
    loader={loader}

    />
)


}



function mapStateToProps( props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    fetchCouponsRequest,
    fetchApplyCouponRequest
   
  })(CouponsContainer);
