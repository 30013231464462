import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Procced from "../Procced/component";
import LinkIcon from "../../assets/Link.png"
import PaymentOptionHeader from "../../components/Header/PaymentOptionHeader";
import WaitingLoder from "../WaitingLoader/component";
import DaalchiniWallet from '../../assets/Dp.png'
import nextArrow from '../../assets/nextArrow.png'
import Bp from '../../assets/BP.png'
import Tick from '../../assets/tick.png'
import Paytm from '../../assets/Paytm.png'
import card from '../../assets/card.png'
import plxlogo from '../../assets/plx-logo.svg'
import wallet from '../../assets/wallet.png'
import { Link } from "react-router-dom";
import './paymentOption.css'
import Error from '../../assets/Error.png'
import { useNavigate } from "react-router-dom";
import { mpClick } from "../../Mixpanel";
import { Card } from "@material-ui/core";

const image_base_url = "https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/";

function PaymentOptions(props) {
  const navigate = useNavigate()
  const gotoHistory = () => {
    mpClick({ EventName: "Dp_page_Clicked" })
    navigate('/dphistory')
  }
  const defaultPgName = localStorage.getItem('defaultPgName');
  console.log("props PaymentOptions", props && props.paymentOptionsList
  );

  const LinkBpFun = (e) => {
    mpClick({ Event: "Bp_page_clicked" })
    navigate('/link/bp')


  }
useEffect(()=>{
  window.scrollTo(0, 0); 
},[])
  return (
    <>
      {props.loader && <WaitingLoder />}
      <div>
        <PaymentOptionHeader title={"Payment Settings"} route={`${localStorage.getItem('paytmOptionFrom')}`} />

        <div>
          {props.loader && <WaitingLoder />}
          {props.sodexoUnlinkModal && <div className="modalDeactive"  /* style={{width:"90%",top:"20%", height:"392px",left:"5%"}} */ >
            <div className="modal-content-bpHistory" style={{ height: "180px" }}>
              <img src={LinkIcon} style={{ "margin-left": "33%", "margin-top": "-74px", width: "105px" }} />
              <h3 style={{
                color: "#384F6F",
                "text-align": "center",
                "font-family": "Roboto",
                "font-size": "14px",
                "font-style": "normal",
                "marginTop": "-10px",
                "font-weight": "700",
                "line-height": "28px", "letter-spacing": "1px"
              }}>Unlink Pluxee</h3>
              <p style={{
                "color": "rgba(56, 79, 111, 0.69)",
                "text-align": "center",
                "font-family": "Roboto",
                "font-size": "12px", "font-style": "normal", "font-weight": "400", "line-height": "17.5px"
              }}>Are you sure you want to unlink your Pluxee Wallet?</p>
              <p style={{ "text-align": "center", "margin-top": "11px", "font-weight": "bold" }}></p>

              <div style={{
                display: "flex", "justify-content": "space-around", "margin-top": "8px",
              }}>
                <button onClick={(e) => { props.setSodexoUnlinkModal(false) }} style={{
                  "display": "flex",
                  "width": "128px",
                  "height": "41px",
                  "padding": "10px",
                  "justify-content": "center",
                  "align-items": "center",
                  "gap": "10px",
                  "flex-shrink": "0",
                  color: "#FFF",
                  "text-align": "center",
                  "font-family": "Inter",
                  "font-size": "15px",
                  "font-style": "normal",
                  "font-weight": "800",
                  "line-height": "13.5px", /* 90% */
                  "letter-spacing": "1px"


                }} className="canclebtn">No</button>
                <button onClick={props.UnLinkLinkSodexo} className="deactivebtn" style={{
                  display: "flex",
                  "width": "130px",
                  "height": "41px",
                  "padding": "10px",
                  "justify-content": "center", "align-items": "center",
                  "gap": "10px",
                  "flex-shrink": "0",

                  "text-align": "center",
                  "font-family": "Inter",
                  "font-size": "15px",
                  "font-style": "normal",
                  "font-weight": "800",
                  "line-height": "13.5px", /* 90% */
                  "letter-spacing": "1px"


                }}>Yes</button>
              </div>
            </div>
          </div>}
          <div
            style={{
              background:
                "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
              //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.ActiveOrderItemsListGlobal[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.ActiveOrderItemsListGlobal[0]&&"8.5rem",
            }}
            className=" pb-1 pt-1 overflow-y-auto h-screen"

          >
            <div style={{ fontFamily: "Roboto" }}>


              <div className="_2NVhy _3hjyL">
                <div>




                  <div style={{ padding: 0 }}>
                    <div>


                      <div
                        data-testid="grid-container"
                        aria-hidden="false"

                      >
                        <div
                          className="styles_slider__podEu"
                          data-testid="grid-slider"
                        >

                          <div
                            className="styles_row__1ivP1 shadow-box"
                         
                            style={{
                              marginBottom: "-5px",
                              backgroundColor: "white",
                              marginTop: "4%",

                              "margin-right": "12px",
                              "margin-left": "12px",

                              marginBottom: 5,
                              "background-color": "#fffff",

                              "border-radius": "16px", background: "#FFF", "box-shadow": defaultPgName=="R_UPI"?"rgb(107 198 87 / 70%) 0px 4px 10.4px 1px":"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"

                              , width: "130px",
                              border: defaultPgName=="R_UPI"&&"1px solid green"

                            }}
                          >

                            <div
                              className="styles_slide__2c207"
                              style={{ marginRight: 0 }}
                            >

                              <div>
                                <div
                                  className="styles_cardWithPadding__JE1QE"
                                  data-testid="home-restaurant-card-wrapper"
                                  style={{
                                    width: "calc((100vw - 0px) - 12px)",
                                  }}
                                >

                                  <div className="styles_container__fLC0R"
                                  



                                  >

                                    <div>
                                      <div style={{ display: "flex", }}>

                                        <div onClick={(e) => { props.SetpaymentGatewayId(e, props && props.paymentOptionsList && props.paymentOptionsList[1].pgId, props && props.paymentOptionsList && props.paymentOptionsList[1].pgName, "R_UPI") }}> 
                                        <img style={{ width: "78px", "border-radius": "64px" }} src="https://aniportalimages.s3.amazonaws.com/media/details/ANI-20221205070213.jpg" />
     
                                          <p style={{ fontSize: "13px" }}>UPI(Other Apps)</p>

                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div />
                              </div>
                            </div>
                          </div>
                          <div style={{ "margin-top": "-44px" }}>
                            <span
                              className="styles_headerContainerSubtitle__1WRg5"
                              aria-hidden="true"
                            >
                              <h3 style={{
                                marginTop: "15%", marginLeft: "5%", color: "#718198",
                                "font-family": "SF Pro Display",
                                "font-size": "16px",
                                "font-style": "normal",
                                "font-weight": "500",
                                "line-height": "normal"
                              }}>
                                Wallet
                              </h3>
                            </span>
                          </div>
                          <div

                            style={{
                              marginBottom: "-5px",
                              backgroundColor: "white",
                              marginTop: "4%",
                              "border-radius": "10px",
                              "margin-right": "12px",
                              "margin-left": "12px",
                             /*  height: "245px", */
                              marginBottom: 5,


                              "border-radius": "16px",
                              "background": " #FFF",
                              "box-shadow": "0px 4px 10px 0px rgba(56, 79, 111, 0.12)",
                              "border-top-right-radius": "11px",
                              "border-top-left-radius": "11px",
                              "border-radius": "16px", background: "#FFF", "box-shadow": "0px 4px 10px 0px rgba(56, 79, 111, 0.12)"
                            }}
                          >

                            <div style={{ width: "100%",  "box-shadow": defaultPgName=="WALLET_LOYALTY"&&"0px 4px 10.399999618530273px 1px rgba(0, 177, 119, 0.23)" ,borderRadius:defaultPgName=="WALLET_LOYALTY"&&"15px"}}>
                            <div  >

                              <div style={{ border:defaultPgName=="WALLET_LOYALTY"&&"1px solid rgba(0, 177, 119, 0.5)", borderRadius: "15px", display: "flex", "justify-content": "space-between", "align-items": "center", padding: "10px" }}>
                                <div  onClick={(e) => { props.SetpaymentGatewayId(e, props && props.paymentOptionsList && props.paymentOptionsList[3].pgId, props && props.paymentOptionsList && props.paymentOptionsList[3].pgName) }} style={{ display: "flex", "align-items": "center",width:"96%" }}>
                                   <img style={{ width: "32px", height: "27px" }} src={DaalchiniWallet} />
                                  <div style={{ marginLeft: "10px" }}><h1 style={{ color: "rgba(51, 63, 78, 1)", fontSize: "16px", fontWeight: "500" }}>Daalchini</h1>
                                    <p style={{ color: "rgba(113, 129, 152, 1)", fontSize: "13px", fontWeight: "400" }}> Balance ₹{" "}{props&&props.paymentOptionsList&&props.paymentOptionsList[3].balance}</p></div>
                                </div>
                               <img onClick={(e)=>{gotoHistory()}} style={{ width: "10px", height: "16px" }} src={nextArrow} />

                              </div>
                            </div>
                            </div>
                         
                            {((props && props.paymentOptionsList && props.paymentOptionsList[2].active))?
                               <div style={{ width: "100%", "box-shadow": defaultPgName=="WALLET"&&"0px 4px 10.399999618530273px 1px rgba(0, 177, 119, 0.23)",borderRadius:defaultPgName=="WALLET"&&"15px"}}>
                                <div >

                                <div  style={{ border:defaultPgName=="WALLET"&&"1px solid rgba(0, 177, 119, 0.5)", borderRadius: "15px", display: "flex",  "align-items": "center", padding: "10px" }}>
                                <div onClick={(e) => { props.SetpaymentGatewayId(e, props && props.paymentOptionsList && props.paymentOptionsList[2].pgId, props && props.paymentOptionsList && props.paymentOptionsList[2].pgName) }}  style={{ display: "flex", "align-items": "center",width:"96%" }}> <img style={{ width: "32px", height: "27px" }} src={Bp} />
                                  <div style={{ marginLeft: "10px" }}><h1 style={{ color: "rgba(51, 63, 78, 1)", fontSize: "16px", fontWeight: "500" }}>Business Payments</h1>
                                         { props&&props.paymentOptionsList&&props.paymentOptionsList[2].reason=="BALANCE_REDACTED"? <></>: <h4 style={{"font-size": "13px",color:"#718198"}}>Balance ₹{""}{props.paymentOptionsList[2].balance}</h4>}
</div>
                                </div>

                                <Link  to="/bphistory"><img style={{ width: "10px", height: "16px" }} src={nextArrow} /></Link>
                              </div>
                                </div>
                                



                              </div> :

                              <>{(props && props.paymentOptionsList && props.paymentOptionsList[2].reason == "NOT_LINKED") ?
                                <>    <div style={{ width: "100%" }}>
                                  <div style={{  borderRadius: "15px", display: "flex", "justify-content": "space-between", "align-items": "center", padding: "10px" }}>
                                    <div style={{ display: "flex", "align-items": "center" }}> <img style={{ width: "32px", height: "27px" }} src={Bp} />
                                      <div style={{ marginLeft: "10px" }}><h1 style={{ color: "rgba(51, 63, 78, 1)", fontSize: "16px", fontWeight: "500" }}>Business Payments</h1>
                                        <p style={{ color: "rgba(113, 129, 152, 1)", fontSize: "13px", fontWeight: "400" }}>business payment accounts</p></div>
                                    </div>

                                    <div className="linkbtn" style={{}}>
                                      <div onClick={(e) => LinkBpFun(e)}> <button style={{ color: "#00B177" }}>Link</button></div>
                                    </div>
                                  </div>
                                </div>


                                </> :

                                <div style={{ width: "100%" ,
                              }}>
                                  <div style={{  borderRadius: "15px", display: "flex", "justify-content": "space-between", "align-items": "center", padding: "10px" }}>
                                    <div style={{ display: "flex", "align-items": "center" }}> <img style={{ width: "32px", height: "27px" }} src={Bp} />
                                      <div style={{ marginLeft: "10px" }}><h1 style={{ color: "rgba(51, 63, 78, 1)", fontSize: "16px", fontWeight: "500" }}>Business Payments</h1>
                                        <p style={{ "font-size": "13px", color: "red" }}>{props && props.paymentOptionsList && props.paymentOptionsList[2].reason} </p></div>
                                    </div>


                                  </div>
                                </div>



                              }</>
                            }
                              {   (props&&props.paymentOptionsList&&props.paymentOptionsList[0].active)? 
                                     
                                  
                                      <div className="tahzeeb" onClick={(e)=>{props.SetpaymentGatewayId(e,props&&props.paymentOptionsList&&props.paymentOptionsList[0].pgId,props&&props.paymentOptionsList&&props.paymentOptionsList[0].pgName)}} style={{ width: "100%","box-shadow": defaultPgName=="PAYTM"&&"0px 4px 10.399999618530273px 1px rgba(0, 177, 119, 0.23)",borderRadius:defaultPgName=="PAYTM"&&"15px"}}>
                                     <div style={{ border: defaultPgName=="PAYTM"&&"1px solid rgba(0, 177, 119, 0.5)", borderRadius: "15px", display: "flex", "justify-content": "space-between", "align-items": "center", padding: "10px" }}>
                                       <div style={{ display: "flex", "align-items": "center" }}> <img style={{ width: "32px", height: "27px" }} src={Paytm} />
                                         <div style={{ marginLeft: "10px" }}><h1 style={{ color: "rgba(51, 63, 78, 1)", fontSize: "16px", fontWeight: "500" }}>Paytm</h1>
                                         <p style={{  "font-size":"13px",color:"rgb(113, 129, 152)"}}>Balance₹{" "}{props&&props.paymentOptionsList&&props.paymentOptionsList[0].balance}</p></div>
                                       </div>
                                       <img style={{ width: "10px", height: "16px" }} src={nextArrow} />
                                     </div>
                                   </div>
                                
                            
                                  
                                   :

                                    <div style={{ width: "100%",marginBottom:"2px" }}>
                                     <div style={{  borderRadius: "15px", display: "flex", "justify-content": "space-between", "align-items": "center", padding: "10px" }}>
                                       <div style={{ display: "flex", "align-items": "center" }}> <img style={{ width: "32px", height: "27px" }} src={Paytm} />
                                         <div style={{ marginLeft: "10px" }}><h1 style={{ color: "rgba(51, 63, 78, 1)", fontSize: "16px", fontWeight: "500" }}>Paytm</h1>
                                        </div>
                                       </div>
                                       { (props&&props.paymentOptionsList&&!props.paymentOptionsList[0].reason)&&<div  className="linkbtn" >
                                      <Link to="/paytm">
                                   <button style={{color:"#00B177"}}>Link</button>
                                   </Link>
                                    </div>}
                                    { (props&&props.paymentOptionsList&&props.paymentOptionsList[0].reason=="REAUTHORIZE")&&
                                    
                                    
                                    <div  className="linkbtn" style={{"border-color":"red", "background-color":"rgb(177 0 0 / 5%)"}}>
                                      <Link to="/paytm">
                                   <button style={{color: "#FF8989",
"text-align":"center",
"font-family":"SF Pro Display",
"font-size":"12px",
"font-style": "normal",
"font-weight":"500",
"line-height":"150%", /* 18px */"letter-spacing":"0.2px"}}>Reauthorise</button>
                                   </Link>
                                    </div>} 
                               
                                    
                                     </div>
                                     { (props&&props.paymentOptionsList&&props.paymentOptionsList[0].reason=="REAUTHORIZE")&&
                                     <div style={{padding:"3%"}}>
                                      <div style={{"border-radius":"6px",border:"1px solid #FDD",
background:"rgba(255, 0, 0, 0.05)",width:"100%"}}
                                     >
                                      
                                   <div style={{display:"flex","align-items":"center",marginLeft:"12px"}}> <img style={{width:"23px",height:"23px",marginLeft:"2%"}} src={Error}/><div style={{  "margin-left":"12px","margin-top": "3%","margin-bottom":"3%"}}><h1 style={{color: "#333F4E",
                                      "font-family":"SF Pro Display",
                                      "font-size": "16px",
                                      "font-style":"normal",
                                      "font-weight": "500",
                                      "line-height":"normal"}}>Reauthorize your account </h1>
                                      
                                      <p style={{color: "#718198","font-family":"SF Pro Display","font-size":"13px","font-style":"normal","font-weight":"400","line-height":"150%", /* 19.5px */"letter-spacing":"0.2px"}}>
                                        Your payment profile has expired.<br/>
please reauthorize to continue using your<br/>
payment profile.</p>
                                      
                                      </div></div>  
                                      
                                      
                                      
                                      </div></div>}
                                   </div>
                                      
                                      
                                      
                                  
                                
                                   
                                    
                                 
                                 
                    
                                    
                                   
                                
                                  
                                    }
                             
                                   
                                  
                          

                          </div>
                          <div >
                        
                              <h3 style={{
                                marginTop: "20px", marginLeft: "5%", color: "#718198",
                                "font-family": "SF Pro Display",
                                "font-size": "16px",
                                "font-style": "normal",
                                "font-weight": "500",
                                "line-height": "normal"
                              }}>
                                Other payment method
                              </h3>
                          
                          </div>
                          <div

style={{
  marginBottom: "-5px",
  backgroundColor: "white",
  marginTop: "4%",
  "border-radius": "10px",
  "margin-right": "12px",
  "margin-left": "12px",
 /*  height: "245px", */
  marginBottom: 5,


  "border-radius": "16px",
  "background": " #FFF",
  "box-shadow": "0px 4px 10px 0px rgba(56, 79, 111, 0.12)",
  "border-top-right-radius": "11px",
  "border-top-left-radius": "11px",
  "border-radius": "16px", background: "#FFF", "box-shadow": "0px 4px 10px 0px rgba(56, 79, 111, 0.12)"
}}
>

<div style={{ width: "100%",  "box-shadow": defaultPgName=="R_CARD"&&"0px 4px 10.399999618530273px 1px rgba(0, 177, 119, 0.23)" ,borderRadius:defaultPgName=="R_CARD"&&"15px"}}>
<div onClick={(e) => { props.SetpaymentGatewayId(e, props && props.paymentOptionsList && props.paymentOptionsList[1].pgId, props && props.paymentOptionsList && props.paymentOptionsList[1].pgName, "R_CARD")  }} >

  <div style={{ border:defaultPgName=="R_CARD"&&"1px solid rgba(0, 177, 119, 0.5)", borderRadius: "15px", display: "flex", "justify-content": "space-between", "align-items": "center", padding: "10px" }}>
    <div style={{ display: "flex", "align-items": "center" }}> <img style={{ width: "32px", height: "27px" }} src={card} />
      <div style={{ marginLeft: "10px" }}><h1 style={{ color: "rgba(51, 63, 78, 1)", fontSize: "16px", fontWeight: "500" }}> Debit/Credit Cards</h1>
        <p style={{ color: "rgba(113, 129, 152, 1)", fontSize: "13px", fontWeight: "400" }}>Visa, MasterCard, Rupay & more...</p></div>
    </div>

    <img style={{ width: "10px", height: "16px" }}  src={nextArrow} />
  </div>
</div>
</div>


   <div style={{ width: "100%", "box-shadow": defaultPgName=="R_WALLET"&&"0px 4px 10.399999618530273px 1px rgba(0, 177, 119, 0.23)",borderRadius:defaultPgName=="R_WALLET"&&"15px"}}>
    <div onClick={(e) => { props.SetpaymentGatewayId(e, props && props.paymentOptionsList && props.paymentOptionsList[1].pgId, props && props.paymentOptionsList && props.paymentOptionsList[1].pgName, "R_WALLET") }} >

    <div style={{ border:defaultPgName=="R_WALLET"&&"1px solid rgba(0, 177, 119, 0.5)", borderRadius: "15px", display: "flex", "justify-content": "space-between", "align-items": "center", padding: "10px" }}>
    <div style={{ display: "flex", "align-items": "center" }}> <img style={{ width: "32px", height: "27px" }} src={wallet} />
      <div style={{ marginLeft: "10px" }}><h1 style={{ color: "rgba(51, 63, 78, 1)", fontSize: "16px", fontWeight: "500" }}>Wallets</h1>
       <h4 style={{"font-size": "13px",color:"#718198"}}>AmazonPay, PhonePe & more... </h4>
</div>
    </div>

    <img style={{ width: "10px", height: "16px" }} src={nextArrow} />
  </div>
    </div>
    



  </div> 

  {(props && props.paymentOptionsList && props.paymentOptionsList[5] && props.paymentOptionsList[5].active) ?<div style={{ width: "100%", "box-shadow": (defaultPgName == "SODEXO") &&"0px 4px 10.399999618530273px 1px rgba(0, 177, 119, 0.23)",borderRadius:(defaultPgName == "SODEXO") &&"15px"}}>
    <div  >

    <div style={{ border:(defaultPgName == "SODEXO") &&"1px solid rgba(0, 177, 119, 0.5)", borderRadius: "15px", display: "flex", "align-items": "center", padding: "10px" }}>
    <div onClick={(e) => { props.SetpaymentGatewayId(e, props && props.paymentOptionsList && props.paymentOptionsList[5].pgId, props && props.paymentOptionsList && props.paymentOptionsList[5].pgName) }}  style={{ display: "flex", "align-items": "center",width:"81%" }}> <img style={{ width: "32px", height: "27px" }} src={plxlogo} />
      <div style={{ marginLeft: "10px" }}><h1 style={{ color: "rgba(51, 63, 78, 1)", fontSize: "16px", fontWeight: "500" }}> Pluxee</h1>
                                              
       <h4 style={{"font-size": "13px",color:"#718198"}}>Pay with Pluxee</h4>
</div>
    </div>

    <div className="linkbtn"onClick={(e) => { props.setSodexoUnlinkModal(true) }} >
                                          <button  style={{ color: "#00B177" }}>UNLINK</button>
                                   
                                        </div>
  </div>
    </div>
    



  </div> :<>    <div  onClick={(e) => { props.SodexoLink(e) }} >

<div style={{ border:(defaultPgName == "SODEXO") &&"1px solid rgba(0, 177, 119, 0.5)", borderRadius: "15px", display: "flex", "justify-content": "space-between", "align-items": "center", padding: "10px" }}>
<div style={{ display: "flex", "align-items": "center" }}> <img style={{ width: "32px", height: "27px" }} src={plxlogo} />
  <div style={{ marginLeft: "10px" }}><h1 style={{ color: "rgba(51, 63, 78, 1)", fontSize: "16px", fontWeight: "500" }}> Pluxee</h1>
                                          
   <h4 style={{"font-size": "13px",color:"#718198"}}>Pay with Pluxee</h4>
</div>
</div>

<div className="linkbtn" >
                                          <button style={{ color: "#00B177" }}>Link</button>
                                        </div>
</div>
</div>




</>}




       
      


</div>
                  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default PaymentOptions;
