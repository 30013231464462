//https://api-prod.daalchini.co.in/auth/user/business-profile/deregister
// https://api-prod.daalchini.co.in

import BpHistoryComp from "./component"
import {fetchBpHistoryRequest,fetchBpPaymentOptionRequest,fetchBpUnLinkRequest} from "./action"
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import { mpClick } from "../../Mixpanel";
const BpHistoryContainer=(props)=>{
  const navigate=useNavigate()
  const machineId=localStorage.getItem('machineId')
  const navigator=useNavigate()
  const [loader,setLoader]=useState(false)
  console.log("useParams",useParams())
  const {uId} =useParams()
    const [unlinkModal,setUnlinkModal]=useState(false)
    const [bpHistory,setBpHistory]=useState([])
    const [requestsumbitted,setRequestsumbitted]=useState(false)
    const [paymentOptionsList,setPaymentOptionsList]=useState()
    const [finalAmount,setFinalAmount]=useState(20)

    const now = new Date();

const options = { timeZone: 'Asia/Kolkata' };
const indiaDateTimeStr = now.toLocaleString('en-IN', options);

console.log(indiaDateTimeStr); 
 
    useEffect(()=>{
        BpHistory()
        fetchPaymentOption()
    },[])
    useEffect(() => {
      console.log("CONSOLE NUMBER ->> 3");
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    }, []);

    const UnLinkModalfun=()=>{
      setUnlinkModal(true)
    }
    const handlePaymentSuccess = (response) => {
      //Webwalasocket(OrderId)
      console.log('Payment successful:', response);
      // Implement any additional logic for handling payment success here
    };


    const fetchPaymentOption = () => {
      const UserJWTAuth = localStorage.getItem('UserJWTAuth');
      const machineId = localStorage.getItem('machineId');
    
      props.fetchBpPaymentOptionRequest({
        url:  `/consumerbe/api/v2/payment/option?&balancePgArr=PAYTM%7CWALLET_LOYALTY%7CWALLET`,
        method:"GET",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
          "x-app-platform": "web",
          "x-app-version": "v1.0.1",
          //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid")
        },
        "request": {
          request:{}
          
    
    
          },
        handleErrorBpPaymentOption ,
        handleResponseBpPaymentOption ,
      });
    };
    

const handleErrorBpPaymentOption=(error)=>{
  if(error.response.status==401 || error.response.status==403){

   
    //Logout
    //localStorage.removeItem("UserJWTAuth")
      navigate("/login");
}
else{ toast.error(`${error.response.data.status.code}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}

}

const  handleResponseBpPaymentOption=(response)=>{
  localStorage.setItem("BpBalance",response&&response.data&&response.data.data[2].balance)
  setPaymentOptionsList(response.data.data);

}






const BpUnlink = () => {


  if(!(paymentOptionsList&&paymentOptionsList[2].reason=="UNLINK_REQUESTED"))
  {


    setUnlinkModal(false)
    const uuid = localStorage.getItem('uuid');
    const UserJWTAuth = localStorage.getItem('UserJWTAuth');
    console.log("UserJWTAuth",UserJWTAuth)

    props.fetchBpUnLinkRequest({
      url: `/auth/user/business-profile/deregister`,
      method:"POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
          "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      },
      request: {
        "request":{"sourcePlatform":"web","x-device-id":uuid}
        
  
  
        },
      handleErrorBpUnlink ,
      handleResponseBpUnlink ,
    });


  }
  else{
    setRequestsumbitted(true)
    setUnlinkModal(false)
    fetchPaymentOption()
  }


};

const handleResponseBpUnlink=(response)=>{
  mpClick({EventName:"bp_unlink_initiated"})
  console.log("Get response fetchTagList",response.data.response);
  navigator("/paymentOption")
  
}

const handleErrorBpUnlink=(response)=>{
  mpClick({EventName:"Error bp_unlink_initiated"})
}






const BpHistory = () => {

  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  const machineId = localStorage.getItem('machineId');
  let utcD,utcM,utcMM,utcSS,utcHH
  const currentDate = new Date();
  const utcDate = currentDate.getUTCDate();
  const utcMonth = currentDate.getUTCMonth() + 1; // Months are zero-based, so we add 1
  const type = typeof utcMonth;
  console.log("type",type);
  console.log("utcDate ",String(utcDate).length)
  if(String(utcDate).length==1){
    utcD="0"+utcDate
    console.log("utcDate",utcD)
  }
  else{
    utcD=utcDate
  }
 
  if(String(utcMonth).length==1){
  utcM="0"+utcMonth
    console.log("utcM--->",utcM)
  }
  else{
    utcM=utcMonth
  }
  const utcYear = currentDate.getUTCFullYear();
  console.log("my date",`${utcMonth}-${utcD}-${utcYear}`); // Output: '5/12/2023' (format: MM/DD/YYYY)

// Get UTC time
const utcHours = currentDate.getUTCHours();
const utcMinutes = currentDate.getUTCMinutes();
const utcSeconds = currentDate.getUTCSeconds();
if(String(utcMinutes).length==1){
utcMM="0"+utcMinutes
console.log("utcMM",utcMM)
}
else{
utcMM=utcMinutes
}


if(String(utcSeconds).length==1){
utcSS="0"+utcSeconds
console.log("utcMM",utcMM)
}
else{
utcSS=utcSeconds
}


if(String(utcHours).length==1){
utcHH="0"+utcHours
console.log("utcMM",utcMM)
}
else{
utcHH=utcHours
}

  
let diffUtcM,diffUtcY
if((utcM-1>0)){

  diffUtcM=utcM-1
  if(String(diffUtcM).length==1){
    diffUtcM="0"+diffUtcM
      console.log("diffUtcM--->",diffUtcM)
    }
    else{
      diffUtcM=diffUtcM
    }
  diffUtcY=utcYear
}
else{

  diffUtcM=12
  diffUtcY=utcYear-1

}

  console.log("currentDatecurrentDate",`${utcYear}-${utcM}-${utcD} ${utcHH}:${utcMM}:${utcSS}`)

  
    console.log("UserJWTAuth",UserJWTAuth)

  props.fetchBpHistoryRequest({
    url: '/consumer/api/v1/user/order/history',
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },
    "request": {
      "from":`${diffUtcY}-${diffUtcM}-${utcD} ${utcHH}:${utcMM}:${utcSS}`,"to":`${utcYear}-${utcM}-${utcD} ${utcHH}:${utcMM}:${utcSS}`,"pgId":8
      


      

  },
    handleErrorBpHistory ,
    handleResponseBpHistory ,
  });
};


const handleResponseBpHistory=(response)=>{
  setBpHistory(response.data);
}

const  handleErrorBpHistory=()=>{

  
}
 





    return(
        <BpHistoryComp
        bpHistory={bpHistory}
        unlinkModal={unlinkModal}
        setUnlinkModal={setUnlinkModal}
        BpUnlink={BpUnlink}
        UnLinkModalfun={UnLinkModalfun}
        requestsumbitted={requestsumbitted}
        setRequestsumbitted={ setRequestsumbitted}
        paymentOptionsList={paymentOptionsList}
        />


    )
}
function mapStateToProps(props) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    
    fetchBpHistoryRequest,fetchBpUnLinkRequest,
    fetchBpPaymentOptionRequest
  })(BpHistoryContainer);

