export const FETCH_SEARCH_TOKEN_REQUEST="FETCH_SEARCH_TOKEN_REQUEST"
export const FETCH_SEARCH_TOKEN_SUCCESS="FETCH_SEARCH_TOKEN_SUCCESS"
export const FETCH_SEARCH_TOKEN_FAILURE="FETCH_SEARCH_TOKEN_FAILURE"


export const fetchSearchTokenRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_SEARCH_TOKEN_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchSearchTokenSuccess=(SearchToken)=>{
return{
    type:FETCH_SEARCH_TOKEN_SUCCESS,
    SearchToken:SearchToken
}
}

export const fetchSearchTokenFailure=(error)=>{
    return{
    type:FETCH_SEARCH_TOKEN_FAILURE,
    error:error
    }
}


//////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_SECRET_KEY_LINK_REQUEST="FETCH_SECRET_KEY_LINK_REQUEST"
export const FETCH_SECRET_KEY_LINK_SUCCESS="FETCH_SECRET_KEY_LINK_SUCCESS"
export const FETCH_SECRET_KEY_LINK_FAILURE="FETCH_SECRET_KEY_LINK_FAILURE"


export const fetchSecretKeyLinkRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_SECRET_KEY_LINK_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchSecretKeyLinkSuccess=(SecretKeyLink)=>{
return{
    type:FETCH_SECRET_KEY_LINK_SUCCESS,
    SecretKeyLink:SecretKeyLink
}
}

export const fetchSecretKeyLinkFailure=(error)=>{
    return{
    type:FETCH_SECRET_KEY_LINK_FAILURE,
    error:error
    }
}



////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_SECRET_KEY_ACTIVE_REQUEST="FETCH_SECRET_KEY_ACTIVE_REQUEST"
export const FETCH_SECRET_KEY_ACTIVE_SUCCESS="FETCH_SECRET_KEY_ACTIVE_SUCCESS"
export const FETCH_SECRET_KEY_ACTIVE_FAILURE="FETCH_SECRET_KEY_ACTIVE_FAILURE"


export const fetchSecretKeyActiveRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_SECRET_KEY_ACTIVE_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchSecretKeyActiveSuccess=(SecretKeyActive)=>{
return{
    type:FETCH_SECRET_KEY_ACTIVE_SUCCESS,
    SecretKeyActive:SecretKeyActive
}
}

export const fetchSecretKeyActiveFailure=(error)=>{
    return{
    type:FETCH_SECRET_KEY_ACTIVE_FAILURE,
    error:error
    }
}


