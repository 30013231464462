import React, { useEffect } from "react";
import '../OrderStatus/orderstatus.css'
import OrderStatusSuccesslogo from '../../assets/orderStatus.png'
import Cross from '../../assets/cross.png'
import RightTick from '../../assets/rightTick.png'
import Contacticon from '../../assets/contact.png'
import Failed from '../../assets/orderStatusFailedlogo.png'
import { mpClick } from "../../Mixpanel";
const Orderstatus=()=>{
  
  useEffect(()=>{
   
  },[])

  const OrderId = localStorage.getItem('orderId');
  const finalAmount=parseInt(localStorage.getItem('finalAmount'));
  console.log("finalAmount",typeof(finalAmount))
 
  const OrderIdSuccess = localStorage.getItem('OrderIdSuccess');
  const websocketRes = JSON.parse(localStorage.getItem('websocketRes'));
  const defaultPgName = localStorage.getItem('defaultPgName');
  
  console.log("websocketRes tahzeeb",websocketRes)
  useEffect(()=>{
    if(OrderId &&websocketRes&&websocketRes.success)
    {mpClick({EventName:"Payment_successful"})
    mpClick({EventName:"Order_confirmed"})
  }
  else{
    mpClick({EventName:"Payment_failed"})
  }
  },[])
  
    return(
      <> 
      
        {OrderId &&websocketRes&&websocketRes.success? 

        <div  className="modal"  style={{ backgroundColor: "#eefaf9" }}>

              <div style={{display:"flex","margin-right": "151px",position: "absolute",top:"26%",fontFamily:"Roboto"}}>
                <img
                  /* onClick={closesodexPaymentFailed} */
                  style={{ width: "10%", padding: "15%" }}
                   //src={closeIcone} */
                />
    
                <div style={{ }}>
                  <div style={{ "margin-left": "50px"  }}>
                    <div style={{display:"flex",justifyContent:"center",width:"45%","border-radius":"47px",height: "81px","margin-left":"51px","margin-bottom":"6%"}}>
                    <img
                    
                      style={{ width: "80px",
                      padding:"10px"}}
                       src={OrderStatusSuccesslogo} 
                    />
                    </div>
                    
    
                    <div style={{ display: "flex", width:"151%","align-items":"rtflex-end" ,"align-items": "flex-end"}}>
                      <h4
                        style={{
                          color: "#f71010",
                          margin: "0px",
                          "font-size": "20px",
                          color: "#64748B",

                          /* M14 */
                          "font-family": "Roboto",
                          "font-size": "14px",
                          "font-style": "normal",
                          "font-weight": "500",
                          "line-height": "150%", /* 21px */
                          "letter-spacing":"0.2px",
                          "margin-left":"9%"                          
                        }}
                      >
                       Paid Successfuly to 
                      </h4>
                  
                    </div>
                  </div>
    
                  <div
                    style={{  marginRight: "13%", width: "120%" }}
                  >
                    <h1
                      style={{
                        "margin-bottom": "8px",
                       
                        color: "#722323",
                        "margin-left":"67px",
                        color: "#718198",
"text-align":"center",

/* B20 */
"font-family":"Roboto",
"font-size":"20px",
"font-style":"normal","font-weight":"700","line-height":"normal"
                      }}
                    >
                      Daalchini
                    </h1>
                

               <div style={{    display: "flex","align-items":"center","margin-left":"30%","margin-bottom":"5%"}}><h3 style={{color:"#384F6F","text-align":"center","font-family":"Roboto","font-size":"30px","font-style":"normal","font-weight":"700","line-height":"normal", }}>₹{finalAmount&&finalAmount.toFixed(2)}</h3>  <img
                        style={{
                          width: "23px",
                          height: "20px",
                          "margin-left":"10px"
                        
                        }}
                         src={RightTick} 
                      /></div> 
                    <div style={{ display: "flex", "font-size": "11px",width:"110%","margin-left":"12%" }}>
                    <h2 style={{ margin: "3px", "margin-left": "0px",color: "#718198",

/* SM14 */
"font-family":"Roboto",
"font-size": "14px",
"font-style": "normal",
"font-weight":"600",
"line-height": "150%", /* 21px */
"letter-spacing":"0.2px" }}>
                      Order Id :
                    </h2>
                    <h2 style={{ color: "#384F6F",

/* SM14 */
"font-family":"Roboto","font-size":"14px","font-style":"normal","font-weight":"600","line-height":"150%","letter-spacing":"0.2px" }}>
                      {websocketRes.orderId}
                      {/*  {sodexoPaymentFailDetails &&
                        sodexoPaymentFailDetails.data.orderId}  */}
                    </h2>
                    </div>
                    <div style={{ display: "flex", "font-size": "11px",width:"110%" ,"margin-left":"12%"}}>
                    <h2 style={{ margin: "3px", "margin-left": "0px",color: "#718198",

/* R13 */
"font-family":"Roboto",
"font-size":"13px",
"font-style": "normal",
"font-weight": "400",
"line-height": "150%","letter-spacing":"0.2px" }}>
                      Payment Method:
                    </h2>
                      <h2 style={{ margin: "3px",color: "#384F6F",

/* M13 */"font-family":"Roboto",
"font-size": "13px",
"font-style": "normal",
"font-weight": "500",
"line-height":"150%",
"letter-spacing":"0.2px" }}>{websocketRes.pgName}</h2>
                    </div>
                    <div style={{ display: "flex", "font-size": "12px" }}>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style={{display:"flex",bottom:"21%",position:"absolute",left:"39%",padding:"5px","border-color":"#c4cbd8"}}><img src={Contacticon}/>
                <div style={{"margin-left":"11px",color:" #9aa0a6"}}>Contact Us</div>
                </div>
              </div>
            </div>
            
            :
            
            <div  className="modal"  style={{ backgroundColor: "#eefaf9" }}>
            <div style={{display:"flex","margin-right": "151px",position: "absolute",top:"26%",fontFamily:"Roboto"}}>
              <img
                /* onClick={closesodexPaymentFailed} */
                style={{ width: "10%", padding: "15%" }}
                 //src={closeIcone} */
              />
  
              <div style={{ }}>
                <div style={{ "margin-left": "55px"  }}>
                  <div style={{display:"flex",justifyContent:"center",width:"41%","border":"none",height: "75px","margin-left":"51px","margin-bottom":"6%"}}>
                  <img
                  
                    style={{ }}
                     src={Failed} 
                  />
                  </div>
                  
  
                  <div style={{ display: "flex", width:"100%","justify-content":"center" ,"align-items": "flex-end"}}>
                    <h4
                      style={{
                        color: "#f71010",
                        margin: "0px",
                        "font-size": "20px",

                        color: "#F43F5E",
                        marginLeft:"16px",

/* M14 */
"font-family":"Roboto","font-size":"14px",
"font-style": "normal",
"font-weight":"500",
"line-height": "150%", /* 21px */
"letter-spacing":"0.2px"
                      }}
                    >
                    Payment Failed!
                    </h4>
                
                  </div>
                </div>
  
                <div
                  style={{  marginRight: "13%", width: "120%" }}
                >
                  <h1
                    style={{
                      "margin-bottom": "8px",
                     
                     
                      "margin-left":"33px",
                      "color":"#718198",
"text-align":"center",

/* B20 */
"font-family":"Roboto"
,"font-size": "20px",
"font-style":"normal",
"font-weight": "700",
"line-height": "normal"
                    }}
                  >
                    Daalchini
                  </h1>
              

             <div style={{    display: "flex","align-items":"center","margin-left":"28%","margin-bottom":"5%"}}><h3 style={{color:"#384F6F","text-align":"center","font-family":"Roboto","font-size":"30px","font-style":"normal","font-weight":"700","line-height":"normal", }}>₹{finalAmount&&finalAmount.toFixed(2)}</h3>  <img
                      style={{
                        width: "23px",
                        height: "20px",
                        "margin-left":"10px"
                      
                      }}
                       src={Cross} 
                    /></div> 
                  <div style={{ display: "flex", "font-size": "11px",width:"110%" ,    "margin-left":"10%" ,"align-items":"center"}}>
                    <h2 style={{ margin: "3px", "margin-left": "0px",color: "#718198",

/* SM14 */
"font-family":"Roboto",
"font-size": "14px",
"font-style": "normal",
"font-weight":"600",
"line-height": "150%", /* 21px */
"letter-spacing":"0.2px" }}>
                      Order Id :
                    </h2>
                    <h2 style={{ color: "#384F6F",

/* SM14 */
"font-family":"Roboto","font-size":"14px","font-style":"normal","font-weight":"600","line-height":"150%","letter-spacing":"0.2px" }}>
                      {websocketRes.orderId}
                      {/*  {sodexoPaymentFailDetails &&
                        sodexoPaymentFailDetails.data.orderId}  */}
                    </h2>
                  </div>
                  <div style={{ display: "flex", "font-size": "11px",width:"110%" ,"margin-left":"10%"}}>
                    <h2 style={{ margin: "3px", "margin-left": "0px",color: "#718198",

/* R13 */
"font-family":"Roboto",
"font-size":"13px",
"font-style": "normal",
"font-weight": "400",
"line-height": "150%","letter-spacing":"0.2px" }}>
                      Payment Method:
                    </h2>
                    <h2 style={{ margin: "3px",color: "#384F6F",

/* M13 */"font-family":"Roboto",
"font-size": "13px",
"font-style": "normal",
"font-weight": "500",
"line-height":"150%",
"letter-spacing":"0.2px" }}>{websocketRes.pgName}</h2>
                  </div>
                  <div style={{ display: "flex", "font-size": "12px" }}>
                    
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style={{display:"flex",bottom:"21%",position:"absolute",left:"33%",padding:"5px","border-color":"#c4cbd8"}}><img src={Contacticon}/>
              <div style={{"margin-left":"11px",color:" #9aa0a6"}}>Contact Us</div>
              </div>
            </div>
          </div>
            
            
            }
    </>

    )
}
export default Orderstatus