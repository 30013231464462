export const FETCH_PAYTM_AUTH_REQUEST="FETCH_PAYTM_AUTH_REQUEST"
export const FETCH_PAYTM_AUTH_SUCCESS="FETCH_PAYTM_AUTH_SUCCESS"
export const FETCH_PAYTM_AUTH_FAILURE="FETCH_PAYTM_AUTH_FAILURE"


export const fetchPatmAuthRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_PAYTM_AUTH_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchPatmAuthSuccess=(PatmAuth)=>{
return{
    type:FETCH_PAYTM_AUTH_SUCCESS,
    PatmAuth:PatmAuth
}
}

export const fetchPatmAuthFailure=(error)=>{
    return{
    type:FETCH_PAYTM_AUTH_FAILURE,
    error:error
    }
}


//////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_VERIFY_PAYTM_AUTH_REQUEST="FETCH_VERIFY_PAYTM_AUTH_REQUEST"
export const FETCH_VERIFY_PAYTM_AUTH_SUCCESS="FETCH_VERIFY_PAYTM_AUTH_SUCCESS"
export const FETCH_VERIFY_PAYTM_AUTH_FAILURE="FETCH_VERIFY_PAYTM_AUTH_FAILURE"


export const fetchVerifyPaytmOtpRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_VERIFY_PAYTM_AUTH_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchVerifyPaytmOtpSuccess=(PatmAuth)=>{
return{
    type:FETCH_VERIFY_PAYTM_AUTH_SUCCESS,
    PatmAuth:PatmAuth
}
}

export const fetchVerifyPaytmOtpFailure=(error)=>{
    return{
    type:FETCH_VERIFY_PAYTM_AUTH_FAILURE,
    error:error
    }
}