import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SodexoPaymentStatus from "./component";
import { useNavigate } from "react-router-dom";

const SodexoPayStatusContainer = (props) => {
const navigate = useNavigate();
const [orderdetail,setOrderdetail]=useState('')
const machineId = localStorage.getItem('machineId');
const {orderId} =useParams()
useEffect(()=>{
  const sodexoPaymentStatus = JSON.parse(localStorage.getItem('sodexoPaymentStatus'));
    setTimeout(() => {
        if(sodexoPaymentStatus&&sodexoPaymentStatus.data.success){
        console.log("Orderstatus navigate")
        navigate('/activeOrder',{ replace: true })
        }
        else{
         navigate(`/items?machineId=${machineId}`,{ replace: true })
        }
      }, 3000);
  
},[])









return(
    <SodexoPaymentStatus/>
   
)


}



function mapStateToProps({ props }) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    
   
  })(SodexoPayStatusContainer);