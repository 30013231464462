import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import backArrow from "../../assets/backArrow.png"
import nextArrow from '../../assets/nextArrow.png'


function Settings(props) {
  console.log("props Userprofile", props);
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  const navigator = useNavigate();
  const [logoutModal, setLogutModal] = useState(false);
  useEffect(()=>{
    
    console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
    if(!UserJWTAuth){
      localStorage.setItem("enteryUrl","/settings")
      navigator('/login')
    }
  },[])

const [logoutBtn, setLogoutBtn] = useState(false);
  const TopNav = () => {
    return (
      <>
        <div className="_2456r">
          <div
            className="_3nxa7"
            style={{ "align-items": "center" }}
            data-testid="listing-header-location"
          >
         
            <button
              className="_1NdRR"
              aria-label="Selected address is Delhi, India; Double tap to change address."
              id="change-address-btn-id"

            >
                <div style={{display:"flex",alignItems: "center", justifyContent: "space-around",marginLeft:"-34px"}}>
                  <Link to="/profile"> <img  style={{width:"20px"}}src={backArrow}/></Link>
              <div style={{ display: "flex", "align-items": "center" }}>
                <h2
                  style={{
                   
                    fontSize: "18px",
                    color: "#583C43",
                    fontWeight: "500",
                    marginLeft: "-58px"
                  }}
                >
                Settings
                </h2>
                </div>

                <div style={{ marginLeft: "10px" }}></div>
              </div>
            </button>

            <span className="icon-Offers-outline _2P0Ff" />
         
          </div>
        </div>
      </>
    );
  };


  return (
    <>
      {logoutModal && (
        <div className="logoutmodal">
          <div className="modal-content-userprofile">
            <div style={{ display: "flex", "justify-content": "space-around" ,marginBottom:"14px"}}>
              <h1 style={{ fontSize: "21px", fontWeight: "700" }}>
                Log out from ?
              </h1>
            </div>
            <hr />
            <div onClick={(e)=>{props.LogoutCurrentDevice()}}style={{ display: "flex", "justify-content": "space-around", marginBottom:"9px",marginTop:"9px"}}>
              <h1 style={{ fontSize: "12px", fontWeight: "700",color:"#F33051" }}>
              Current Device 
              </h1>
            </div>
            <hr/>
            <div onClick={(e)=>{props.LogoutAllDevice()}} style={{ display: "flex", "justify-content": "space-around" , marginBottom:"9px",marginTop:"9px"}}>
              <h1 style={{ fontSize: "12px", fontWeight: "700",color:"#F33051" }}>
               
              All Devices 
              </h1>
            </div>
            <hr/>
            <div onClick={(e)=>{setLogutModal(false)}}style={{ display: "flex", "justify-content": "space-around" ,marginTop:"14px" }}>
              <h1 style={{ fontSize: "12px", fontWeight: "700" ,color:"#64748B"}}>
              Cancel
              </h1>
            </div>
          </div>
        </div>
      )}

    
      <TopNav />
     
      <div onClick={(e)=>{setLogoutBtn(false)}}
        style={{
          background:
            "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
          //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.PreCardItems[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.PreCardItems[0]&&"8.5rem",
        }}
        className={
          props.login || props.loginOtp
            ? "h-screen overflow-y-auto "
            : "pt-4 pb-36 overflow-y-auto h-screen"
        }
      >
        <div style={{padding:"3%"}}>
            <div style={{width: "100%",
height:"70px",
marginTop: "70px",
backgroundColor:"white",
borderRadius:"16px"
}}>
   <Link to="/deleteAccount"><div style={{display:"flex", 
    alignItems: "center",
    justifyContent:"space-between",
    padding:"6%"}}>
    <h1>Delete Account</h1>
    <img  style={{width:"10px"}}src={nextArrow}/>
    </div>
    </Link> 
    </div>
    </div>
  


      

 
      </div>
    </>
  );
}

export default Settings;
