import DpHistoryHeader from "../Header/DpHistoryHeader";
import { Link } from "react-router-dom";
import BpIcon from "../../assets/bpIcon.png";
import PaidIcon from "../../assets/paidicon.png";
import Refundicon from "../../assets/refund.png";
import WaitingLoder from "../WaitingLoader/component";
import CancleIcon from "../../assets/cancle.png";
import "../BusinessPayment-history/bphistory.css";
import TagIcon from "../../assets/tagpay.png";
import LinkIcon from "../../assets/Link.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RechargeSuccess from "../../assets/rechargeSuccess.png";
import changeArrow from "../../assets/changeArrow.png";
import { mpClick } from "../../Mixpanel";
const DpHistory = (props) => {
  const navigator = useNavigate();
  const [indianTime, setIndianTime] = useState("");
  useEffect(() => {
    // Function to get the current Indian time
    function getCurrentIndianTime() {
      const now = new Date();
      const options = { timeZone: "Asia/Kolkata", hour12: true };
      const indianDateTime = now.toLocaleString("en-IN", options);

      //setCurrentDateTime(indianDateTime);

      setIndianTime(indianDateTime);

      //setIndianTime(indianTime.toLocaleString());
      //setIndianTime(timeString);
    }

    // Call the function to get the current Indian time
    getCurrentIndianTime();

    // Update the Indian time every second

    // Clean up the interval on component unmount
  }, []);
  function TruncatedOrderId({ text, limit }) {
    if (text&&text.length <= limit) {
      return <>{text}</>;
    }
    return <>{`${text.substring(0, limit)}...`}</>;
  }
  const CloseRechargeModal = () => {
    props.setRechargeModal(false);
    localStorage.removeItem("ChangeRechageMethod");
  };

  const handleAmount = (e) => {
    console.log("handleAmount", e.target.value);
  
    props.setAmount(e.target.value);
    
  };
  const handleSetelectAmount = (e, amount) => {
    props.setAmount(e.target.value);
  
    props.setAmount(amount);
  };

  const goToRechargePaymentOption = () => {
    //localStorage.setItem("goToRechargePaymentOption",)
    navigator("/paymentOptionRecharge");
  };

  console.log(
    "BpHistory",
    props &&
      props.bpHistory &&
      props.bpHistory.data &&
      props.bpHistory.data.payload[0]
  );
  useEffect(() => {
    if (
      !(
        props &&
        props.bpHistory &&
        props.bpHistory.data &&
        props.bpHistory.data.payload[0]
      )
    ) {
      //navigator("/PaymentOption")
    }
  }, []);

  const bpbalance = localStorage.getItem("BpBalance");
  console.log(
    "props TagpayHistory",
    props &&
      props.tagListHistory &&
      props.tagListHistory.data &&
      props.tagListHistory.data.payload
  );
  return (
    <>
    {props.loader && <WaitingLoder />}
      {true ? (
        <>
         
          {props && props.rechargeModal && (
            <div
              className="modalDeactive" /* style={{width:"90%",top:"20%", height:"392px",left:"5%"}} */
            >
              <div className="modal-content-tagRecharge">
                <div
                  onClick={(e) => {
                    CloseRechargeModal(e);
                  }}
                  style={{ display: "flex", "justify-content": "flex-end" }}
                >
                  {" "}
                  close
                </div>
                <div style={{ paddingTop: "5%" }}>
                  <h3
                    style={{
                      color: "var(--neutral-01, #333F4E)",
                      fontFamily: "Roboto",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                    }}
                  >
                    Select an amount to
                  </h3>
                  <p
                    style={{
                      color: "var(--neutral-03, #94A3B8)",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "150%", // You can also use '21px' directly here if needed
                      letterSpacing: "0.2px",
                    }}
                  >
                   Recharge Daalchini Points
                  </p>
                </div>
                <input
                maxlength={5}
                  style={{
                    display: "flex",
                    marginTop: "5%",
                    width: "100%",
                    padding: "11px 14px 11px 16px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "var(--neutral-01, #333F4E)",
                    fontFamily: "Roboto",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                    borderRadius: "8px",
                    border: "1px solid rgba(0, 177, 119, 0.70)",
                    background: "rgba(0, 177, 119, 0.05)",
                    marginLeft:"0px",
                   
                  }}
                  placeholder="Enter Amount"
                  value={props.amount}
                  onChange={(e) => {
                    handleAmount(e);
                  }}
                  type="text"
                  
                />
                <div
                  style={{
                    display: "flex",
                    "justify-content": "space-between",
                    marginTop: "5%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",

                      padding: "1px",
                      "justify-content": "center",
                      "align-items": "center",
                      gap: "18px",

                      "border-radius": "10px",
                      border: "1px solid rgba(148, 163, 184, 0.54)",
                      width: "73px",
                      height: "38px",
                    }}
                    onClick={(e) => {
                      handleSetelectAmount(e, 50);
                    }}
                  >
                    <h1>₹50</h1>
                  </div>
                  <div
                    style={{
                      display: "flex",

                      padding: "1px",
                      "justify-content": "center",
                      "align-items": "center",
                      gap: "18px",

                      "border-radius": "10px",
                      border: "1px solid rgba(148, 163, 184, 0.54)",
                      width: "73px",
                      height: "38px",
                    }}
                    onClick={(e) => {
                      handleSetelectAmount(e, 100);
                    }}
                  >
                    <h1>₹100</h1>
                  </div>

                  <div
                    style={{
                      display: "flex",

                      padding: "1px",
                      "justify-content": "center",
                      "align-items": "center",
                      gap: "18px",

                      "border-radius": "10px",
                      border: "1px solid rgba(148, 163, 184, 0.54)",
                      width: "73px",
                      height: "38px",
                    }}
                    onClick={(e) => {
                      handleSetelectAmount(e, 200);
                    }}
                  >
                    <h1>₹200</h1>
                  </div>
                  <div
                    style={{
                      display: "flex",

                      padding: "1px",
                      "justify-content": "center",
                      "align-items": "center",
                      gap: "18px",
                      width: "73px",
                      height: "38px",
                      "border-radius": "10px",
                      border: "1px solid rgba(148, 163, 184, 0.54)",
                    }}
                    onClick={(e) => {
                      handleSetelectAmount(e, 500);
                    }}
                  >
                    <h1>₹500</h1>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      "justify-content": "space-between",

                      "margin-top": "21px",
                      "align-items": "center",
                    }}
                  >
                    <div>
                      <h2
                        style={{
                          color: "#384F6F",

                          /* SM14 */
                          "font-family": "Roboto",
                          "font-size": "14px",
                          "font-style": "normal",
                          "font-weight": "600",
                          "line-height": "150%" /* 21px */,
                          "letter-spacing ": "0.2px",
                        }}
                      >
                        Payment Method
                      </h2>
                      <p
                        style={{
                          color: "var(--neutral-100, #718198)",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "150%", // You can also use '21px' directly here if needed
                          letterSpacing: "0.2px",
                        }}
                      >
                        {localStorage.getItem("default_Recharge_PgName") ==
                        "RECHARGE_R_CARD"
                          ? "CARD"
                          : localStorage.getItem("default_Recharge_PgName") ==
                            "RECHARGE_R_WALLET"
                          ? "WALLET"
                          : "UPI"}
                      </p>
                    </div>
                    {
                      <div
                        style={{
                          "text-decoration": "none",
                        }}
                        onClick={goToRechargePaymentOption}
                      >
                        <div
                          style={{
                            display: "flex",
                            "align-items": "center",
                          }}
                        >
                          <h3
                            style={{
                              fontSize: "13px",
                              color: "#718198",
                            }}
                            Change
                          >
                            Change
                          </h3>
                          <img
                            style={{
                              "margin-left": "5px",
                            }}
                            src={changeArrow}
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    "justify-content": "space-around",
                    "margin-top": "8px",
                  }}
                >
                  <button
                    onClick={(e) => {
                      props.AddIntoCart(e);
                    }}
                    className="rechargebtn"
                    style={{
                      color: "var(--neutral-white, #FFF)",
                      fontFamily: "Roboto",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "20px", // You can also use '133.333%' directly here if needed
                      textTransform: "uppercase",
                    }}
                  >
                    Proceed To Pay
                  </button>
                </div>
              </div>
            </div>
          )}

          <div id="root" style={{ height: "100%", fontFamily: "Roboto" }}>
            {props.rechargeSucessModal && (
              <div
                className="RechargeSuccessmodal" /* style={{width:"90%",top:"20%", height:"392px",left:"5%"}} */
              >
                <div className="modal-content-RechargeSuccessmodal">
                  <div
                    style={{
                      display: "flex",
                      "justify-content": "space-around",
                    }}
                  >
                    {" "}
                    <img src={RechargeSuccess} style={{ width: "72px" }} />
                  </div>
                  <h3
                    style={{
                      "text-align": "center",
                      color: "var(--neutral-01, #333F4E)",
                      "font-family": "Roboto",
                      "font-size": "20px",
                      "font-style": "normal",
                      "font-weight": "700",
                      "line-height": "normal",
                      marginTop: "11px",
                    }}
                  >
                    Success
                  </h3>
                  <p style={{ "text-align": "center", "margin-top": "21px" }}>
                    Tag has been successfully recharged with ₹
                    {props && props.amount}
                  </p>

                  <div
                    style={{
                      display: "flex",
                      "justify-content": "space-around",
                      "margin-top": "27px",
                    }}
                  >
                    <button
                      style={{
                        display: "flex",
                        height: "43px",
                        padding: "11px 24px",
                        "justify-content": "center",
                        "align-items": "center",
                        gap: "10px",
                        /* "flex":"1 0 0", */
                        "border-radius": "12px",
                        color: "white",
                        width: "50%",
                        background: "var(--primary-5, #00B177)",
                      }}
                      onClick={(e) => {
                        props.setRechargeSucessModal(false);
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            )}

            {props.requestsumbitted && (
              <div
                className="modalDeactive" /* style={{width:"90%",top:"20%", height:"392px",left:"5%"}} */
              >
                <div className="modal-content-bpHistory">
                  <img
                    src={LinkIcon}
                    style={{ "margin-left": "33%", "margin-top": "-74px" }}
                  />
                  <h3 style={{ "text-align": "center" }}>
                    Request Already Submitted
                  </h3>
                  <p style={{ "text-align": "center", "margin-top": "11px" }}>
                    Your unlinking request has been received. please wait for
                    admins approval.
                  </p>
                  <p
                    style={{
                      "text-align": "center",
                      "margin-top": "11px",
                      "font-weight": "bold",
                    }}
                  ></p>

                  <div
                    style={{
                      display: "flex",
                      "justify-content": "space-around",
                      "margin-top": "8px",
                    }}
                  >
                    <button
                      onClick={(e) => {
                        props.setRequestsumbitted(false);
                      }}
                      className="reqokbtn"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="_2yoTv ns7Wo">
              <div>
                <DpHistoryHeader
                  title={"Daalchini Points"}
                  route={"/PaymentOption"}
                  height={"170px"}
                  marginTop={"-57px"}
                />

                <div>
                  <div className="_2NVhy _3hjyL">
                    <div
                      className="styles_slider__podEu"
                      data-testid="grid-slider"
                      style={{ marginTop: "20%" }}
                    >
                      {[1].map((el, index) => {
                        //console.log("Ellll--->",el)
                        return (
                          <div
                            className="styles_row__1ivP1 shadow-box"
                            data-testid="grid-row"
                            style={{
                              marginTop: "4%",
                              "margin-right": "12px",
                              "margin-left": "12px",
                              "margin-bottom": "22px",
                              height: "181px",
                              "border-radius": " 29px",
                              background: "white",
                              "z-index": "9",
                              position: "fixed",
                              width: "93%",
                            }}
                          >
                            <div
                              className="styles_slide__2c207"
                              style={{ marginRight: 0 }}
                            >
                              <div>
                                <div
                                  className="styles_cardWithPadding__JE1QE"
                                  data-testid="home-restaurant-card-wrapper"
                                  style={{
                                    width: "calc((100vw - 0px) - 12px)",
                                  }}
                                >
                                  <a
                                    data-testid="resturant-card-anchor-container"
                                    className="styles_container__fLC0R"
                                    aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                    tabIndex={0}
                                    role="button"
                                  >
                                    <div
                                      className="styles_imgContainer__1uHo5"
                                      aria-hidden="true"
                                    ></div>
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          "justify-content": "space-between",
                                          marginLeft: "22px",
                                          "margin-top": "17px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            "align-items": "center",
                                            "justify-content": "space-around",
                                          }}
                                        >
                                          <img
                                            style={{ width: "57px" }}
                                            src={BpIcon}
                                          />
                                          <div
                                            style={{ "margin-left": "11px" }}
                                          >
                                            <h2
                                              style={{
                                                fontSize: "18px",
                                                fontWeight: "700",
                                              }}
                                            >
                                              Daalchini Points
                                            </h2>
                                            {
                                              <p
                                                style={{ "margin-top": "7px" }}
                                              >
                                                {indianTime}
                                              </p>
                                            }
                                          </div>
                                        </div>
                                      </div>

                                      {/*
                                       */}
                                      <div
                                        style={{
                                          display: "flex",
                                          "justify-content": "space-around",
                                          "margin-top": "17px",
                                          "align-items": "flex-end",
                                        }}
                                      >
                                        <div>
                                          <div style={{}}>
                                            <p style={{ color: "#718198" }}>
                                              Balance amount
                                            </p>
                                            <h1
                                              style={{
                                                color: "#333F4E",
                                                "font-size": "20px",
                                              }}
                                            >
                                              ₹
                                              {props &&
                                                props.paymentOptionsList &&
                                                props.paymentOptionsList[3].balance.toFixed(
                                                  2
                                                )}{" "}
                                            </h1>
                                          </div>
                                        </div>
                                        <button
                                          style={{
                                            display: "flex",
                                            padding: "9px 32px",
                                            "justify-content": "center",
                                            "align-items": "center",
                                            gap: "8px",

                                            "border-radius": "20px",
                                            background: " #00B177",

                                            color: "#FFF",
                                            "font-family": "Roboto",
                                            "font-size": "15px",
                                            "font-style": "normal",
                                            "font-weight": "500",
                                            "line-height":
                                              "20px" /* 133.333% */,
                                            "text-transform": "capitalize",
                                            display: "flex",
                                            padding: "9px 24px",
                                            "justify-content": "center",
                                            "align-items": "center",
                                            gap: "8px",
                                          }}
                                          onClick={(e) => {
                                            props.RechargeFun();
                                          }}
                                        >
                                          Recharge
                                        </button>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div  className="h-screen"style={{ paddingTop: "50%", background: "aliceblue" }}>
                  {!(
                      props &&
                      props.paymentOptionsList &&
                      props.paymentOptionsList[2].reason == "BALANC_REDACTED"
                    ) && (
                    
                        <h4 style={{padding: "20px",
                          "margin-bottom": "10px"
                      }}>Transactions History</h4>
                     
                    )}
                 
                    {!(
                      props &&
                      props.paymentOptionsList &&
                      props.paymentOptionsList[2].reason == "BALANC_REDACTED"
                    ) &&
                      props &&
                      props.bpHistory &&
                      props.bpHistory.data &&
                      props.bpHistory.data.payload.reverse().map((el, index) => {
 
                           return(
                           <div className="_2NVhy _3hjyL">
                            
                           <div
                                       className="styles_slider__podEu"
                                       data-testid="grid-slider"
                                       style={{marginTop:"-8%"}}
                                     >
                                              <div
                                         className="styles_row__1ivP1 shadow-box"
                                         data-testid="grid-row"
                                         style={{
                                           marginTop: "4%",
                                           "margin": "4% 12px 22px",
                                           
                                           height:"81px",
                                           background: "white",
                                           "border-radius":"16px",background:"#FFF","box-shadow":"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"                      
                                         }}
                                       >
                                         <div style={{ display:"flex","align-items":"center","justify-content":"space-around",width:"100%"}}>
                                         <div style={{}}>
                                           {el.opsType=="debit"?<img style={{width:"44px",}} src={PaidIcon}/>:
                                           <img style={{width:"44px",}} src={Refundicon}/>
                                           }
                                           </div>
                                           {/* <div> */}
                                           <div style={{marginLeft:"-10%"}}>
                                             {el.opsType=="debit"?<h3 style={{color:"#333F4E",
             
             /* SM14 */
             "font-family": "Roboto",
             "font-size":"14px","font-style":"normal","font-weight":"600",
             "line-height": "150%", /* 21px */
             "letter-spacing": "0.2px"}}>Paid amount</h3>:<>{el.transactionType=="money_add"?<h3 style={{color:"background: #583C43",fontSize:"14px",fontWeight:"500"}}>Balance added</h3>:<h3 style={{color:"#333F4E",fontSize:"14px",fontWeight:"500"}}>Refunded amount</h3>}</>}
                                             <p style={{"margin-top":"5px",fontSize:"12px","color":"#64748B"}}>ID: <TruncatedOrderId text={el.orderId} limit={20}/></p>
                                             </div>
             
                                             <div style={{}}>
                                            {el.opsType=="debit"?<h3 style={{color: "#F43F5E",
             
             /* M14 */
             "font-family":"Roboto","font-size":"14px",
             display: "flex",
                 "justify-content":"flex-end",
             "font-style": "normal",
             "font-weight":"500","line-height":"150%","letter-spacing":"0.2px"}}>₹{""}{el.amount}</h3>:
             <>{el.transactionType=="money_add"?<h3 style={{display:"flex","justify-content":"flex-end",color: "#00B177",
             
             /* M14 */
             "font-family":"Roboto",
             "font-size":"14px",
             "font-style": "normal",
             "font-weight": "500",
             "line-height": "150%", /* 21px */
             "letter-spacing":"0.2px"}}>₹{""}{el.amount}</h3>:<h3 style={{display:"flex","justify-content":"flex-end",color: "#583C43",
             
             /* M14 */
             "font-family":"Roboto",
             "font-size":"14px",
             "font-style": "normal",
             "font-weight": "500",
             "line-height": "150%", /* 21px */
             "letter-spacing":"0.2px"}}>₹{""}{el.amount}</h3>}</>}
                                               <p style={{color: "#583C43",
             "text-align": "right",
             "font-family": "Roboto",
             "font-size": "11px",
             "font-style":"normal","font-weight":"400",
             "line-height": "150%", /* 16.5px */
             "letter-spacing":"0.2px"}}>{el.timestamp.slice(0, 10)}</p>
                                               </div>
                                               </div>
             
                                            {/*  </div> */}
             
             
             
                                       </div>
                                       </div>
             
                           </div>)
                           
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default DpHistory;
