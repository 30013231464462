import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { FETCH_CHECK_SODEXO_REQUEST, FETCH_LINK_SODEXO_REQUEST, FETCH_PAYMENT_OPTIONS_LIST_REQUEST, FETCH_PRE_CARD_REQUEST, FETCH_UNLINK_SODEXO_REQUEST, fetchCheckSodexoFailure, fetchCheckSodexoSuccess, fetchPreCardFailure, fetchPreCardSuccess, fetchSodexoLinkFailure, fetchSodexoLinkSuccess, fetchSodexoUnlinkFailure, fetchSodexoUnlinkSuccess} from "./action1";
import { fetchPaymentOptionMycartSuccess } from "../Mycart/action1";
import { fetchPaymentOptionFailure } from "./action1";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetchPaymentOPtionList(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchPaymentOptionMycartSuccess(response));
  
      action.data.handleResponsePaymentOption(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorPaymentOption(error)
      yield put(fetchPaymentOptionFailure(error.message));
    }
  }




  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchLinkSodexo(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchSodexoLinkSuccess(response));
  
      action.data.handleResponseLinkSodexo(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorSodexoLink(error)
      yield put(fetchSodexoLinkFailure(error.message));
    }
  }




  /////////////////////////////////// SODEXO UNLINK////////////////////////////////////////////////////

  function* fetchUnLinkSodexo(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchSodexoUnlinkSuccess(response));
  
      action.data.handleResponseUnLinkLinkSodexo(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorUnLinkLinkSodexo(error)
      yield put(fetchSodexoUnlinkFailure(error.message));
    }
  }

  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchCheckSodexo(action) {
    console.log("fetchCheckSodexo saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchCheckSodexoSuccess(response));
  
      action.data.handleResponseCheckSodexo(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorCheckSodexo(error)
      yield put(fetchCheckSodexoFailure(error.message));
    }
  }


  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchPreCard(action) {
    console.log("fetchPreCard paytm", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchPreCardSuccess(response));
  
      action.data.handleResponsePreCard(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorPreCard(error)
      yield put(fetchPreCardFailure(error.message));
    }
  }

  function* PaymentOptionSaga() {
    yield takeLatest(FETCH_PAYMENT_OPTIONS_LIST_REQUEST, fetchPaymentOPtionList);
    yield takeLatest(FETCH_LINK_SODEXO_REQUEST,fetchLinkSodexo)
    yield takeLatest (FETCH_CHECK_SODEXO_REQUEST,fetchCheckSodexo)
    yield takeLatest(FETCH_PRE_CARD_REQUEST,fetchPreCard)
    yield takeLatest(FETCH_UNLINK_SODEXO_REQUEST,fetchUnLinkSodexo)
    
  }
   
  export default PaymentOptionSaga;