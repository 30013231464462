
import { Routes, Route, Link,BrowserRouter as Router  } from 'react-router-dom';
import Vmlist_Container from '../src/components/Vmlist/container';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import GlobalSearch from './components/GlobalSearch/container';
import VMInventory_Container from './components/VmInventory/container';
import Orders from './components/Orders/container'
import Userprofile from './components/Userprofile/container';
import OrderDeatails from './components/OrderDetails/container';
import LoginPage from './components/Login/container';
import Mycart from './components/Mycart/container';
import PaymentOptions from './components/PaymentsOption/container';
import Orderstatus from './components/OrderStatus/container'
import ActiveOrder from './components/ActiveOrder/container';
import ContactLessVending from './components/ContactLessVending/container';
import BusinessPayment from './components/BusinessPayment/container';
import BpHistory from './components/BusinessPayment-history/container';
import Paytm from './components/Paytm/container';
import HelpAndSupport from './components/HelpandSupport/container';
import SodexoPaymentStatus from './components/SodexoPayStatus/container';
import TagListContainer from './components/TagpayList/container'
import TagAdd from './components/TagAdd/container';
import TagpayHistory from './components/TagpayHistory/container';
import PaymentOptionRecharge from './components/PaymentOptions_Recharge/component'
import TagDirRechargeContainer from './components/TagAdd/containerRecharge';
import DpHistory from './components/DpHistory/container';
import FaqHelpSupport from './components/Faq-Help-Support/component';
import FaqTopicsConatiner from './components/Faq-Help-Support/container'
import FaqDetailsContainer from './components/FaqDetails/container';
import Settings from './components/Settings/component';
import DeleteAccountContainer from './components/DeleteAccount/container';
import Coupons from './components/Coupons/container';
import OrderSearch from './components/OrderSearch/container';
import GiftCard from './components/GiftCard/component';

function App() {
  return (
   
  <div>
    <ToastContainer/>  
    <Router>
     <Routes>
        <Route path="/" element={<Vmlist_Container/>} />
        {/* <Route path="/" element={<VmList/>} /> */}
        <Route path="/GlobalSearch" element={<GlobalSearch/>} />
         <Route  exact path={"/items"} element={<VMInventory_Container />} />
         <Route  exact path="/orders" element={<Orders />} /> 
         <Route exact path="orders/Details/:orderId" element={<OrderDeatails />} />  
         <Route exact path="/profile" element={<Userprofile/>} />  
         <Route exact path="/login" element={<LoginPage/>} />  
         <Route exact path="/mycart" element={<Mycart/>} />  
         <Route exact path="/paymentOption" element={<PaymentOptions/>} /> 
         <Route exact path="/paymentOptionRecharge" element={<PaymentOptionRecharge/>} />   
         <Route exact path="order/status" element={<Orderstatus />} /> 
         <Route exact path="order/status/sodexo" element={<SodexoPaymentStatus />} /> 
         <Route exact path="/activeOrder" element={<ActiveOrder/>} /> 
         <Route exact path="/pickupOrder" element={<ContactLessVending/>}/>
         <Route exact path="/link/bp" element={<BusinessPayment/>} /> 
         <Route exact path="/bphistory" element={ <BpHistory/>} /> 
         <Route exact path="/dphistory" element={ <DpHistory/>} /> 
         <Route exact path="/paytm" element={ <Paytm/>} /> 
         <Route exact path="/help&support" element={< HelpAndSupport />} /> 
         <Route exact path="/taglist" element={< TagListContainer />} /> 
         <Route exact path="/addtag" element={< TagAdd />} /> 
         <Route exact path='tagdirrecharge' element={<TagDirRechargeContainer/>}/>
         <Route exact path="/taghistory/:uId" element={<TagpayHistory />} /> 
         <Route exact path="/faq-help-support" element={<FaqHelpSupport />} /> 
         <Route exact path="/settings" element={< Settings/>} /> 
         <Route exact path="/deleteaccount" element={< DeleteAccountContainer/>} /> 
         
    
         <Route exact path="/faq" element={<FaqTopicsConatiner />} />
         <Route exact path="/faqDetails/:topicId" element={<FaqDetailsContainer />} />
         <Route exact path='/coupon' element={<Coupons/>}/>
         <Route exact path='/ordersearch' element={<OrderSearch/>}/>
         <Route exact path='giftcard' element={<GiftCard/>}/>
         
       
      </Routes>
      </Router>
      </div>
  );
}

export default App;


// now last file to be set container.js and componet.jsx