import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {  FETCH_TAG_LIST_REQUEST,  fetchTagListFailure, fetchTagListSuccess,   } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetchTagList(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchTagListSuccess(response));
  
      action.data.handleResponseTagList(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorTagList(error)

      yield put(fetchTagListFailure(error.message));
    }
  }




 



  function* TagPaySaga() {
    yield takeLatest(FETCH_TAG_LIST_REQUEST,fetchTagList)
    
  }
  
  export default TagPaySaga;