import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { FETCH_ACTIVE_ORDERS_LIST_REQUEST, FETCH_CANCEL_ORDER_REQUEST, fetchActiveOrdersFailure, fetchActiveOrdersSuccess, fetchCancelOrderFailure, fetchCancelOrderSuccess } from "./action1";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetcheActiveOrders(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchActiveOrdersSuccess(response));
  
      action.data.handleResponseActiveOrdersList(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorActiveOrdersList(error)
      yield put(fetchActiveOrdersFailure(error.message));
    }
  }



  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetcheCancelOrders(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchCancelOrderSuccess(response));
  
      action.data.handleResponseCancleOrder(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorCancleOrder(error)
      yield put(fetchCancelOrderFailure(error.message));
    }
  }



  function* ActiveOrdersListSaga() {
    yield takeLatest(FETCH_ACTIVE_ORDERS_LIST_REQUEST, fetcheActiveOrders);
    yield takeLatest(FETCH_CANCEL_ORDER_REQUEST,fetcheCancelOrders)
    
  }
  
  export default ActiveOrdersListSaga;