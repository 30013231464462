export default function clearAllCookies() {
    var cookies = document.cookie.split(";");
  
    console.log(" clearAllCookies",cookies)
  
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";


    }
    console.log("all cookie",document.cookie)
  } 