import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {  FETCH_SEARCH_TOKEN_REQUEST, FETCH_SECRET_KEY_ACTIVE_REQUEST, FETCH_SECRET_KEY_LINK_REQUEST, FETCH_TAG_LIST_REQUEST,  fetchSearchTokenFailure,  fetchSearchTokenSuccess,  fetchSecretKeyActiveFailure,  fetchSecretKeyActiveSuccess,  fetchSecretKeyLinkFailure,  fetchSecretKeyLinkSuccess,  fetchTagListFailure, fetchTagListSuccess,   } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetchSearchToken(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchSearchTokenSuccess(response));
  
      action.data.handleResponseSearchToken(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorSearchToken(error)

      yield put(fetchSearchTokenFailure(error.message));
    }
  }



  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetchSecretKeyLink(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchSecretKeyLinkSuccess(response));
  
      action.data.handleResponseSecretKeyLink(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorSecretKeyLink(error)

      yield put(fetchSecretKeyLinkFailure(error.message));
    }
  }



  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetchSecretKeyActive(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchSecretKeyActiveSuccess(response));
  
      action.data.handleResponseSecretKeyActive(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorSecretKeyActive(error)

      yield put(fetchSecretKeyActiveFailure(error.message));
    }
  }


 



  function* AddTagSaga() {
    yield takeLatest(FETCH_SEARCH_TOKEN_REQUEST,fetchSearchToken)
    yield takeLatest(FETCH_SECRET_KEY_LINK_REQUEST,fetchSecretKeyLink)
    yield takeLatest(FETCH_SECRET_KEY_ACTIVE_REQUEST,fetchSecretKeyActive)
  }
  
  export default AddTagSaga;