import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { FETCH_BP_RESEND_OTP_REQUEST, FETCH_BP_SUBMIT_OTP_REQUEST, FETCH_LINK_BP_REQUEST, fetchBpResendOtpFailure, fetchBpResendOtpSuccess, fetchBpSubmitOtpFailure, fetchBpSubmitOtpSuccess, fetchLinkBpFailure, fetchLinkBpSuccess } from "./action1";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetchLinkBp(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchLinkBpSuccess(response));
  
      action.data.handleResponseLinkBp(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorLinkBp(error)
      yield put(fetchLinkBpFailure(error.message));
    }
  }


  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* fetcheBpSubmitOtp(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchBpSubmitOtpSuccess(response));
  
      action.data.handleResponseBpVerification(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorBpVerification(error)
      yield put(fetchBpSubmitOtpFailure(error.message));
    }
  }






    /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetcheBpResendOtp(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchBpResendOtpSuccess(response));
  
      action.data.handleResponseResendOtpBp(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorResendOtpBp(error)
      yield put(fetchBpResendOtpFailure(error.message));
    }
  }








  function* BpLinkSaga() {
    yield takeLatest(FETCH_LINK_BP_REQUEST, fetchLinkBp);
    yield takeLatest(FETCH_BP_SUBMIT_OTP_REQUEST, fetcheBpSubmitOtp);
    yield takeLatest (FETCH_BP_RESEND_OTP_REQUEST,fetcheBpResendOtp)
    
  }
  
  export default BpLinkSaga;