import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {    FETCH_CREATE_RECHARGE_ORDER_REQUEST, FETCH_DEACTIVE_TAG_REQUEST, FETCH_PAYTM_CAPTURE_REQUEST, FETCH_TAG_DETAILS_REQUEST, FETCH_TAG_HISTORY_REQUEST,     fetchCreateOrderFailure,     fetchCreateOrderSuccess,     fetchDeactiveTagFailure,     fetchDeactiveTagSuccess,     fetchPaytmCaptureFailure,     fetchPaytmCaptureSuccess,     fetchTagDetailsFailure,     fetchTagDetailsSuccess,     fetchTagHistoryFailure,  fetchTagHistorySuccess,     } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetchTagHistory(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchTagHistorySuccess(response));
  
      action.data.handleResponseTagHistory(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorTagHistory(error)

      yield put(fetchTagHistoryFailure(error.message));
    }
  }



  function*  fetchCreateOrderRecharge(action){

    console.log("fetchCreateOrderRecharge saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchCreateOrderSuccess(response));
  
      action.data.handleResponseCreateOrder(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorCreateOrder(error)

      yield put(fetchCreateOrderFailure(error.message));
    }

  }


  function*  fetchCreateOrder(action){

    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchPaytmCaptureSuccess(response));
  
      action.data.handleResponsePaytmCapture(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorPaytmCapture(error)

      yield put(fetchPaytmCaptureFailure(error.message));
    }

  }


  function*  fetchTagDetails(action){

    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchTagDetailsSuccess(response));
  
      action.data.handleResponseTagDeatils(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorTagDeatils(error)

      yield put(fetchTagDetailsFailure(error.message));
    }

  }



  function*  fetchTagDeactive(action){

    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchDeactiveTagSuccess(response));
  
      action.data.handleResponseDeactiveTag(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorDeactiveTag(error)

      yield put(fetchDeactiveTagFailure(error.message));
    }

  }




  function* TagHistorySaga() {
    yield takeLatest(FETCH_TAG_HISTORY_REQUEST,fetchTagHistory)
    yield takeLatest(FETCH_CREATE_RECHARGE_ORDER_REQUEST,fetchCreateOrderRecharge)
   yield takeLatest(FETCH_PAYTM_CAPTURE_REQUEST,fetchCreateOrder)
   yield takeLatest(FETCH_TAG_DETAILS_REQUEST,fetchTagDetails)
   yield takeLatest(FETCH_DEACTIVE_TAG_REQUEST,fetchTagDeactive)
  }
  
  export default TagHistorySaga;