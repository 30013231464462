import {
  FETCH_VMLIST_REQUEST,
  FETCH_VMLIST_SUCCESS,
  FETCH_VMLIST_FAILURE,
  FETCH_PRE_CARD_REQUEST_VMLIST,
  FETCH_PRE_CARD_SUCCESS_VMLIST,
  FETCH_PRE_CARD_FAILURE_VMLIST,
  FETCH_ACTIVE_ORDERS_REQUEST_VMLIST,
  FETCH_ACTIVE_ORDERS_SUCCESS_VMLIST,
  FETCH_ACTIVE_ORDERS_FAILURE_VMLIST
} from "./action";

const initialState = {
//////////////VM LIST VARIABLES///////////////
  VmList: [],
  NearByVMs: [],
  recentMachines:[],
  loading: false,
  error: null,

  ////////////// PREVIOUS CARD VARIABLES//////////////
  PreCardData:{
    
    PreCardVmID:null,
    PreCardItemsList:[],
    loading2: false,
    error2: null,
  },



  ////////////// PREVIOUS CARD VARIABLES//////////////
  ActiveOrdersData:{
    
   ActiveOrder:null,
   ActiveOrderItemsList:[],
    loading_active_orders: false,
    error_active_orders: null,
  },

};

const vmListReducer = (state = initialState, action) => {
  console.log("vmListReducer action---", action);

  switch (action.type) {

///////////////// SWITCH CASE FOR VM LIST ////////////////////////////////////////

    case FETCH_VMLIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_VMLIST_SUCCESS: 
      return {
        ...state,
        loading: false,
        error: null,
        VmList: action.VmList,
        NearByVMs: action.VmList.data.data.nearbyMachines,
        recentMachines: action.VmList.data.data.recentMachines,

      
      };

    case FETCH_VMLIST_FAILURE: 
      return {
        ...state,
        loading: true,
        error: action.error,
      };



///////////////////////////////////////////////////////////////////////////////////////////////






//////////////////////SWITCH CASES FOR PREVIOUS CARD DATA//////////////////////////////////////
case FETCH_PRE_CARD_REQUEST_VMLIST:
    return {
      ...state,
      loading2: true,
      error2: null,
    };

  case FETCH_PRE_CARD_SUCCESS_VMLIST: 
    return {
      ...state,
      loading2: false,
      error2: null,
      PreCardData: {
        ...state.PreCardData,
        PreCardVmID: action && action.PreCardData.headers && action.PreCardData.headers.machineid,
        PreCardItemsList:action&&action.PreCardData.data.data.items,
      },
     
    
    
    };

  case FETCH_PRE_CARD_FAILURE_VMLIST: 

    return {
      ...state,
      loading: true,
      error: action.error,
    };
 
  
  
  
  
//////////////////////SWITCH CASES FOR ACTIVE ORDERS //////////////////////////////////////
case FETCH_ACTIVE_ORDERS_REQUEST_VMLIST:
    return {
      ...state,
      loading2: true,
      error2: null,
    };

  case FETCH_ACTIVE_ORDERS_SUCCESS_VMLIST: 
    return {
      ...state,
      
      ActiveOrdersData: {
        ...state.ActiveOrdersData,
        ActiveOrderItemsList: action && action.ActiveOrders.data.data,
       
        loading_active_orders: false,
        error_active_orders: null,
      },
     
    
    
    };

  case FETCH_ACTIVE_ORDERS_FAILURE_VMLIST: 
  return {
    ...state,
    ActiveOrdersData: {
    
      loading_active_orders: true,
      error_active_orders: action.error,
    },
   
  }
  
  
  
  
  
  
    default:
      return state;

  }
};

export default vmListReducer;
