//now let create rootsaga

import { all } from "redux-saga/effects";
// let import our userSaga
import bannerListSaga from "../components/ImagesAds/saga";
import gobalSearchSaga from '../../src/components/GlobalSearch/saga'
import VmInventoryPageSaga from "../components/VmInventory/saga";
import OrderHistorySaga  from '../../src/components/Orders/saga'
import UserDetailsSaga from '../../src/components/Userprofile/saga'
import OrderDetailsSaga from '../../src/components/OrderDetails/saga'
import UserLoginSaga from "../components/Login/saga";
import MyCartSaga from "../components/Mycart/saga";
import PaymentOptionSaga from "../components/PaymentsOption/saga";
import ActiveOrdersListSaga from"../components/ActiveOrder/saga"
import PickupOrderSaga from "../components/ContactLessVending/saga";
import BpLinkSaga from "../components/BusinessPayment/saga"
import BpHistorySaga from "../components/BusinessPayment-history/saga";
import PaytmAuthSaga from "../components/Paytm/saga";
import OrderSearchImageSaga from "../components/HelpandSupport/Saga";
import TagPaySaga from "../components/TagpayList/saga";
import AddTagSaga from "../components/TagAdd/saga";
import TagHistorySaga from "../components/TagpayHistory/saga";
import DpHistorySaga from '../components/DpHistory/saga'
import FaqTopicsSaga from "../components/Faq-Help-Support/saga";
import FaqDetailsSaga from "../components/FaqDetails/saga";
import DeleteAccountSaga from "../components/DeleteAccount/saga";
import CouponsSaga from "../components/Coupons/saga";
//root saga also genrator function
///MUI -----------------
import vmListSaga from "../components/VmList1/saga";
import BannerSaga from '../components/Banners/saga'
import OrderSearchSaga from "../components/OrderSearch/Saga";
import GiftCard from "../components/GiftCard/saga";
 function* rootSaga(){
yield all(

    //here in a array we can add all saga 
    [

        vmListSaga(),
        BannerSaga(),
        GiftCard(),
        
        /////////////////MUI--------------------

        bannerListSaga(),
        gobalSearchSaga(),
        VmInventoryPageSaga(),
        OrderHistorySaga(),
        UserDetailsSaga(),
        OrderDetailsSaga(),
        UserLoginSaga(),
        MyCartSaga(),
        PaymentOptionSaga(),
        ActiveOrdersListSaga(),
        PickupOrderSaga(),
        BpLinkSaga(),
        BpHistorySaga(),
        PaytmAuthSaga(),
        OrderSearchImageSaga(),
        TagPaySaga(),
        AddTagSaga(),
        TagHistorySaga(),
        DpHistorySaga(),
        FaqTopicsSaga(),
        FaqDetailsSaga(),
        DeleteAccountSaga(),
        CouponsSaga(),
        OrderSearchSaga()


       
    ]
)

 }

 export default rootSaga