
import { connect } from "react-redux";
import { fetchBannersRequest } from "./action";
import { useEffect } from "react";
import Banner from "./ImagesAds";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
const Banner_Container=(props)=>{
   const navigate=useNavigate()
    console.log(" Banner_Container props",navigator.onLine)
    const BannerData=props&&props.bannerList&&props.bannerList.BannerList&&props.bannerList.BannerList.BannerList&&props.bannerList.BannerList.BannerList.data&&props.bannerList.BannerList.BannerList.data.response&&props.bannerList.BannerList.BannerList.data.response.banners
    const Banner_VmId=props.vmid
    console.log(" BannerData",BannerData)
    const handleResponse=(res)=>{
        console.log("handleResponse",res)
       }
       
       const handleError=(error)=>{
        console.log("khan handleErrorVmInventory", error.response);
        if(error.response.status==401|| error.response.status==403){

            console.log("khan handleErrorVmInventory", error.response.data.status.code);
            //Logout
            //localStorage.removeItem("UserJWTAuth")
              navigate("/login");
        }
        else{ toast.error(`${error.response.data.status.code}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        }
        
          }
       
       useEffect(()=>{
       
  
       
       const UserJWTAuth = localStorage.getItem('UserJWTAuth');
      if(navigator.onLine){ 
       props.fetchBannersRequest(
         
               {
              url:"/order/fetch/banners",
              method:"POST",
              "headers": {
               'Authorization':`Bearer ${UserJWTAuth}`,
               
               "x-app-platform": "web",
               "x-app-version": "v1.0.1",
               //HEADER ERROR
               "x-device-id":localStorage.getItem("uuid")
               
             },
             request:{"request": {
     
                "vendingMachineId": Banner_VmId
                }
            },
              handleError,
              handleResponse
       
           })
         }
       },[])


return(

<>
<Banner
BannerData={BannerData}
/>


</>)

}

const mapStateToProps=(props)=>{

    return( props)
 
 }
 
 
 const mapDispatchToProps={
 
    fetchBannersRequest
 
 }
 
 export default connect(mapStateToProps,mapDispatchToProps)(Banner_Container);