import React, { useEffect } from "react";
import mobileicon from '../../assets/mobileicon.png'
import PaytmAuthHeader from '../../components/Header/PaytmAuthHeader'
const Paytm=(props)=>{
  console.log("remainingTime",props.remainingTime)
  useEffect(()=>{
    window.scrollTo(0, 200); 
  },[])
    return(<>
    
    

          <PaytmAuthHeader title={"Paytm"} route={"/paymentOption"}/> 
    {/*       {props.mobileFlag&&<div>
          <div
            style={{
              padding: "5%",
              display: "flex",
              "justify-content": "space-around",
              marginTop:"20%"
            }}
          >
            <div>
                <div style={{ "display":"flex","justify-content":"space-evenly"}}>
              <img style={{ width: "66%", padding: "17%" }} src={mobileicon} />
              </div>
              <h3 style={{ "text-align":"center",color:"#333F4E",fontSize:"20px",fontWeight:"700"}}>Phone Verification</h3>
              <p style={{textAlign:"center","margin-top":"7%",color:"#64748B",fontSize:"14px",fontWeight:"400"}}>Enter your phone number</p>
            </div>
          </div>

          <div style={{ display: "flex", "justify-content": "space-evenly" }}>
            {" "}
            <input
              style={{
              
                padding: "3%",
                width: "81%",
                "margin-bottom": "11%",
                borderRadius: "11px",
                "font-size": "21px",
                border:" 1px solid rgb(148, 163, 184)"
                
              }}
             onChange={(e)=>{props.onchangeMobile(e)}} 
             placeholder="Mobile number"
            />
          </div>
         
          <div style={{ display: "flex", "justify-content": "space-evenly", "margin-top":"5%" }}>
          {console.log("mobileee",props&&props.mobile.length)}
            {props&&props.mobile&&props.mobile.length==10?
            
            <button
              className="btnEnable"
              style={{ width: "81%" }}
              onClick={props.SubmitMobile}
            >
              <p style={{ "font-size": "21px" }}>Continue</p>

            </button>:     <button
              className="btnEnable"
              style={{ width: "81%",backgroundColor:"#808780" }}
              onClick={props.SearchToken}
            >
              <p style={{ "font-size": "21px" }}>Continue</p>

            </button>}
          </div>
        </div>} */}
        { props.mobileFlag&&
             <div>
             <div
               style={{
                 padding: "5%",
                 display: "flex",
                 "justify-content": "space-around",
                
               }}
             >
               <div>
                   <div style={{ "display":"flex","justify-content":"space-evenly"}}>
                 <img style={{ width: "66%", padding: "17%" }} src={mobileicon} />
                 </div>
                 <h3 style={{ "text-align":"center",color:"#333F4E",fontSize:"20px",fontWeight:"700"}}>Phone Verification</h3>
                 <p style={{textAlign:"center","margin-top":"7%",color:"#64748B",fontSize:"14px",fontWeight:"400"}}>Your verification code send to {props.mobile}</p>
               </div>
             </div>
   
             <div style={{ display: "flex", "justify-content": "space-evenly" }}>
               {" "}
               <input
                 style={{
                  /*  "text-align": "center", */
                   padding: "3%",
                   width: "81%",
                   "margin-bottom": "11%",
                   borderRadius: "11px",
                   "font-size": "21px",
                   border:" 1px solid rgb(148, 163, 184)"
                   
                 }}
                 onChange={(e)=>{props.onchangeOtp(e)}} 
                placeholder="OTP"
               />
             </div>
            {/*  <p style={{"fontSize":"13px",fontWeight:"400",textAlign:"center"}}>Didn’t receive code? Resend in 30s</p> */}
            <div style={{    display: "flex","margin-top":"12%","justify-content":"center"}}>
      <p >Didn’t receive code?{props.remainingTime>0?<span>Resend</span>:<span style={{color:"rgb(0, 177, 119)"}} onClick={props.handleSendOTP}>Resend</span>}</p>
     
      </div>
      {props.isDisabled&&<p style={{    display:"flex","justify-content":"center"}}><span>{props.remainingTime>0&&`${props.remainingTime}s`}</span></p>}
     <div style={{display:"flex","justify-content":"center", "margin-top":"44px"}}>
     
        
  
            </div> 
             <div style={{ display: "flex", "justify-content": "space-evenly", "margin-top":"5%" }}>
               {props.Otp.length==6?<button
                 className="btnEnable"
                 style={{ width: "81%" }}
                 onClick={props.Verfy}
               >
                 <p style={{ "font-size": "21px" }}>Verify</p>
   
               </button>: <button
                 className="btnEnable"
                 style={{ width: "81%",backgroundColor:"#808780" }}
                
               >
                 <p style={{ "font-size": "21px" }}>Verify</p>
   
               </button>
               }


             </div>
           </div>
   
        }

    
    
    </>)
}
export default Paytm