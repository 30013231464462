


export const SEND_USER_LOGIN_MYCART_REQUEST="SEND_USER_LOGIN_MYCART_REQUEST"
export const SEND_USER_LOGIN_MYCART_SUCCESS="SEND_USER_LOGIN_MYCART_SUCCESS"
export const SEND_USER_LOGIN_MYCART_FAILURE="SEND_USER_LOGIN_MYCART_FAILURE"


export const sendUserLoginMycartRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:SEND_USER_LOGIN_MYCART_REQUEST,
        data:myurldata
    }

    
   
}

export const sendUserLoginMycartSuccess=(UserLoginMycart)=>{
return{
    type:SEND_USER_LOGIN_MYCART_SUCCESS,
    UserLoginMycart:UserLoginMycart
}
}

export const sendUserLoginMycartFailure=(error)=>{
    return{
    type:SEND_USER_LOGIN_MYCART_FAILURE,
    error:error
    }
}



export const SEND_SUBMIT_OTP_MYCART_REQUEST="SEND_SUBMIT_OTP_MYCART_REQUEST"
export const SEND_SUBMIT_OTP_MYCART_SUCCESS="SEND_SUBMIT_OTP_MYCART_SUCCESS"
export const SEND_SUBMIT_OTP_MYCART_FAILURE="SEND_SUBMIT_OTP_MYCART_FAILURE"


export const sendSubmitOtpMycartRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:SEND_SUBMIT_OTP_MYCART_REQUEST,
        data:myurldata
    }

    
   
}

export const sendSubmitOtpMycartSuccess=(SubmitOtpResMycart)=>{
return{
    type:SEND_SUBMIT_OTP_MYCART_SUCCESS,
    SubmitOtpResMycart:SubmitOtpResMycart
}
}

export const sendSubmitOtpMycartFailure=(error)=>{
    return{
    type:SEND_SUBMIT_OTP_MYCART_FAILURE,
    error:error
    }
}

export const FETCH_PAYMENT_OPTION_MYCART_REQUEST="FETCH_PAYMENT_OPTION_MYCART_REQUEST"
export const FETCH_PAYMENT_OPTION_MYCART_SUCCESS="FETCH_PAYMENT_OPTION_MYCART_SUCCESS"
export const FETCH_PAYMENT_OPTION_MYCART_FAILURE="FETCH_PAYMENT_OPTION_MYCART_FAILURE"


export const fetchPaymentOptionMycartRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_PAYMENT_OPTION_MYCART_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchPaymentOptionMycartSuccess=(PaymentOptionResMycart)=>{
return{
    type:FETCH_PAYMENT_OPTION_MYCART_SUCCESS,
    PaymentOptionResMycart:PaymentOptionResMycart
}
}

export const fetchPaymentOptionMycartFailure=(error)=>{
    return{
    type:FETCH_PAYMENT_OPTION_MYCART_FAILURE,
    error:error
    }
}






export const FETCH_PRE_CARD_MYCART_REQUEST="FETCH_PRE_CARD_MYCART_REQUEST"
export const FETCH_PRE_CARD_MYCART_SUCCESS="FETCH_PRE_CARD_MYCART_SUCCESS"
export const FETCH_PRE_CARD_MYCART_FAILURE="FETCH_PRE_CARD_MYCART_FAILURE"


export const fetchPreCardMycartRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_PRE_CARD_MYCART_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchPreCardMycartSuccess=(PreCardResMycart)=>{
return{
    type:FETCH_PRE_CARD_MYCART_SUCCESS,
    PreCardResMycart:PreCardResMycart
}
}

export const fetchPreCardMycartFailure=(error)=>{
    return{
    type:FETCH_PRE_CARD_MYCART_FAILURE,
    error:error
    }
}




export const FETCH_COPUON_REQUEST="FETCH_COPUON_REQUEST"
export const FETCH_COPUON_SUCCESS="FETCH_COPUON_SUCCESS"
export const FETCH_COPUON_FAILURE="FETCH_COPUON_FAILURE"


export const fetchCouponRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_COPUON_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchCouponSuccess=(CouponResMycart)=>{
return{
    type:FETCH_COPUON_SUCCESS,
    CouponResMycart:CouponResMycart
}
}

export const fetchCouponFailure=(error)=>{
    return{
    type:FETCH_COPUON_FAILURE,
    error:error
    }
}


export const FETCH_APPLY_COPUON_REQUEST="FETCH_APPLY_COPUON_REQUEST"
export const FETCH_APPLY_COPUON_SUCCESS="FETCH_APPLY_COPUON_SUCCESS"
export const FETCH_APPLY_COPUON_FAILURE="FETCH_APPLY_COPUON_FAILURE"


export const fetchApplyCouponRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_APPLY_COPUON_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchApplyCouponSuccess=(ApplyCopuonResMycart)=>{
return{
    type:FETCH_APPLY_COPUON_SUCCESS,
    ApplyCopuonResMycart:ApplyCopuonResMycart
}
}

export const fetchApplyCouponFailure=(error)=>{
    return{
    type:FETCH_APPLY_COPUON_FAILURE,
    error:error
    }
}


//////////////////////////////////////////////////////
export const FETCH_ADD_TO_CART_MY_CART_REQUEST="FETCH_ADD_TO_CART_MY_CART_REQUEST"
export const FETCH_ADD_TO_CART_MY_CART_SUCCESS="FETCH_ADD_TO_CART_MY_CART_SUCCESS"
export const FETCH_ADD_TO_CART_MY_CART_FAILURE="FETCH_ADD_TO_CART_MY_CART_FAILURE"


export const fetchAddToCartMycartRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_ADD_TO_CART_MY_CART_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchAddToCartMycartSuccess=(AddToCartResMycart)=>{
return{
    type:FETCH_ADD_TO_CART_MY_CART_SUCCESS,
    AddToCartResMycart:AddToCartResMycart
}
}

export const fetchAddToCartMycartFailure=(error)=>{
    return{
    type:FETCH_ADD_TO_CART_MY_CART_FAILURE,
    error:error
    }
}






//////////////////////////////////////////////////////
export const FETCH_REMOVE_FROM_CART_MY_CART_REQUEST="FETCH_REMOVE_FROM_CART_MY_CART_REQUEST"
export const FETCH_REMOVE_FROM_CART_MY_CART_SUCCESS="FETCH_REMOVE_FROM_CART_MY_CART_SUCCESS"
export const FETCH_REMOVE_FROM_CART_MY_CART_FAILURE="FETCH_REMOVE_FROM_CART_MY_CART_FAILURE"


export const fetchRemoveFormCartMycartRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_REMOVE_FROM_CART_MY_CART_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchRemoveFormCartMycartSuccess=(RemoveFormCartResMycart)=>{
return{
    type:FETCH_REMOVE_FROM_CART_MY_CART_SUCCESS,
    RemoveFormCartResMycart:RemoveFormCartResMycart
}
}

export const fetchRemoveFormCartMycartFailure=(error)=>{
    return{
    type:FETCH_REMOVE_FROM_CART_MY_CART_FAILURE,
    error:error
    }
}







//////////////////////////////////////////////////////
export const FETCH_CREATE_ORDER_REQUEST="FETCH_CREATE_ORDER_REQUEST"
export const FETCH_CREATE_ORDER_SUCCESS="FETCH_CREATE_ORDER_SUCCESS"
export const FETCH_CREATE_ORDER_FAILURE="FETCH_CREATE_ORDER_FAILURE"


export const fetchCreateOrderRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_CREATE_ORDER_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchCreateOrderSuccess=(CreateOrderRes)=>{
return{
    type:FETCH_CREATE_ORDER_SUCCESS,
    CreateOrderRes:CreateOrderRes
}
}

export const fetchCreateOrderFailure=(error)=>{
    return{
    type:FETCH_CREATE_ORDER_FAILURE,
    error:error
    }
}





//////////////////////////////////////////////////////
export const FETCH_INITIATED_PAYMENT_REQUEST="FETCH_INITIATED_PAYMENT_REQUEST"
export const FETCH_INITIATED_PAYMENT_SUCCESS="FETCH_INITIATED_PAYMENT_SUCCESS"
export const FETCH_INITIATED_PAYMENT_FAILURE="FETCH_INITIATED_PAYMENT_FAILURE"


export const fetchInitiatedPaymentRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_INITIATED_PAYMENT_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchInitiatedPaymentSuccess=(InitiatedPaymentRes)=>{
return{
    type:FETCH_INITIATED_PAYMENT_SUCCESS,
    InitiatedPaymentRes:InitiatedPaymentRes
}
}

export const fetchInitiatedPaymentFailure=(error)=>{
    return{
    type:FETCH_INITIATED_PAYMENT_FAILURE,
    error:error
    }
}





//////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_CHECK_SODEXO_ORDER_STATUS_REQUEST="FETCH_CHECK_SODEXO_ORDER_STATUS_REQUEST"
export const FETCH_CHECK_SODEXO_ORDER_STATUS_SUCCESS="FETCH_CHECK_SODEXO_ORDER_STATUS_SUCCESS"
export const FETCH_CHECK_SODEXO_ORDER_STATUS_FAILURE="FETCH_CHECK_SODEXO_ORDER_STATUS_FAILURE"


export const fetchCheckSodexoOrderStatusRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_CHECK_SODEXO_ORDER_STATUS_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchCheckSodexoOrderStatusSuccess=(SodexoOrderStatus)=>{
return{
    type:FETCH_CHECK_SODEXO_ORDER_STATUS_SUCCESS,
    SodexoOrderStatus:SodexoOrderStatus
}
}

export const fetchCheckSodexoOrderStatusFailure=(error)=>{
    return{
    type:FETCH_CHECK_SODEXO_ORDER_STATUS_FAILURE,
    error:error
    }
}





//////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_SODEXO_LINK_REQUEST="FETCH_SODEXO_LINK_REQUEST"
export const FETCH_SODEXO_LINK_SUCCESS="FETCH_SODEXO_LINK_SUCCESS"
export const FETCH_SODEXO_LINK_FAILURE="FETCH_SODEXO_LINK_FAILURE"


export const fetchSodexoLinkRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_SODEXO_LINK_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchSodexoLinkSuccess=(SodexoLink)=>{
return{
    type:FETCH_SODEXO_LINK_SUCCESS,
    SodexoLink:SodexoLink
}
}

export const fetchSodexoLinkFailure=(error)=>{
    return{
    type:FETCH_SODEXO_LINK_FAILURE,
    error:error
    }
}


//////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_CHECK_SODEXO_LINK_REQUEST="FETCH_CHECK_SODEXO_LINK_REQUEST"
export const FETCH_CHECK_SODEXO_LINK_SUCCESS="FETCH_CHECK_SODEXO_LINK_SUCCESS"
export const FETCH_CHECK_SODEXO_LINK_FAILURE="FETCH_CHECK_SODEXO_LINK_FAILURE"


export const fetchCheckSodexoLinkRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_CHECK_SODEXO_LINK_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchCheckSodexoLinkSuccess=(SodexoLink)=>{
return{
    type:FETCH_CHECK_SODEXO_LINK_SUCCESS,
    SodexoLink:SodexoLink
}
}

export const fetchCheckSodexoLinkFailure=(error)=>{
    return{
    type:FETCH_CHECK_SODEXO_LINK_FAILURE,
    error:error
    }
}


//////////////////////////////////////////////////////////////////////////////////////////////
export const FETCH_REMOVE_COUPON_REQUEST="FETCH_REMOVE_COUPON_REQUEST"
export const FETCH_REMOVE_COUPON_SUCCESS="FETCH_REMOVE_COUPON_SUCCESS"
export const FETCH_REMOVE_COUPON_FAILURE="FETCH_REMOVE_COUPON_FAILURE"


export const fetchRemoveCouponRequest=(myurldata)=>{
    console.log("sendSubmitOtpMycartRequest",myurldata)
    return{
        type:FETCH_REMOVE_COUPON_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchRemoveCouponSuccess=(remove)=>{
return{
    type:FETCH_REMOVE_COUPON_SUCCESS,
    remove:remove
}
}

export const fetchRemoveCouponFailure=(error)=>{
    return{
    type:FETCH_REMOVE_COUPON_FAILURE,
    error:error
    }
}





//fetchchecksodexoLinkRequest