import { FETCH_ORDER_DETAILS_FAILURE, FETCH_ORDER_DETAILS_REQUEST, FETCH_ORDER_DETAILS_SUCCESS } from "./action";


const initialState = {
    //////////////VM LIST VARIABLES///////////////
      OrderDetails: [],
      loading_Order_Details:false,
      error_Order_Details:null
     
}    



const OrderDetailsReducer = (state = initialState, action) => {
    console.log("OrderDetailsReducer",action&&action.OrderDetails&&action.OrderDetails.data&&action.OrderDetails.data.response)
    switch (action.type) {
//////////////////////SWITCH CASES FOR ACTIVE ORDERS //////////////////////////////////////
case FETCH_ORDER_DETAILS_REQUEST:
    return {
      ...state,
      
      loading_Order_Details:false,
      error_Order_Details:null
    };

  case FETCH_ORDER_DETAILS_SUCCESS: 
    return {
      ...state,
      
      OrderDetails: action&&action.OrderDetails&&action.OrderDetails.data&&action.OrderDetails.data.response,
      loading_Order_Details:false,
      error_Order_Details:null
    
    
    };

  case FETCH_ORDER_DETAILS_FAILURE: 
  return {
    ...state,
 
      loading_Order_Details:false,
      error_Order_Details:action.error
   
  }
  
  
  
  
  
  
    default:
      return state;

  }
};

export default OrderDetailsReducer;
