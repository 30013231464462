import React from "react";
import { Link, useNavigate } from "react-router-dom";
import profileicon from "../../../src/assets/profileicon.png"
import OrdersICon from "../../../src/assets/ordericon.png"
import home from "../../../src/assets/home.png"
import "../BottomNav/bottomNav.css" ;
import { mpClick } from "../../Mixpanel";
function BottomNarBar(props) {
  const navigate=useNavigate()
  const currentRoute = window.location.pathname;
console.log("currentRoute",currentRoute=="/profile");
  const UserJWTAuth = localStorage.getItem('UserJWTAuth');
 //const navigator=useNavigate()
 const gotoHome=()=>{
  mpClick({EventName:"Home_clicked"})
  navigate('/')
 }

 const gotoOrderHistory=()=>{
  mpClick({EventName:"Past_orders_clicked"})
  navigate('/orders')
 }

 const gotoProfile=()=>{
  mpClick({EventName:"Past_orders_clicked"})
  navigate('/profile')
 }
  return (
    <div className="MuiBox-root MuiBox-root-3 MuiBottomNavigation-root makeStyles-root-1" style={{"border-radius":"20px 20px 0px 0px"}}>
  <button className="MuiButtonBase-root MuiBottomNavigationAction-root Mui-selected" tabIndex={0} type="button">
  <div  style={{"text-decoration":"none",color:"black"}} onClick={(e)=>gotoHome()}> <span className="MuiBottomNavigationAction-wrapper">
  
  {(currentRoute=="/")?<><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
<path d="M26.4531 12C26.4531 12.8438 25.75 13.5 24.9531 13.5H23.4531L23.5 21C23.5 21.1406 23.5 21.2812 23.5 21.375V22.125C23.5 23.2031 22.6562 24 21.625 24H20.875C20.7812 24 20.7344 24 20.6875 24C20.6406 24 20.5469 24 20.5 24H17.875C16.7969 24 16 23.2031 16 22.125V18C16 17.2031 15.2969 16.5 14.5 16.5H11.5C10.6562 16.5 10 17.2031 10 18V22.125C10 23.2031 9.15625 24 8.125 24H5.5C5.40625 24 5.35938 24 5.26562 24C5.21875 24 5.17188 24 5.125 24H4.375C3.29688 24 2.5 23.2031 2.5 22.125V16.875C2.5 16.875 2.5 16.8281 2.5 16.7812V13.5H1C0.15625 13.5 -0.5 12.8438 -0.5 12C-0.5 11.5781 -0.359375 11.2031 -0.03125 10.875L11.9688 0.421875C12.2969 0.09375 12.6719 0 13 0C13.3281 0 13.7031 0.140625 13.9844 0.375L25.9375 10.875C26.3125 11.2031 26.5 11.5781 26.4531 12Z" fill="#583C43"/>
</svg></>:<><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
<path d="M26.2188 11.4375C26.5469 11.7188 26.5469 12.1875 26.2656 12.5156C26.0312 12.8438 25.5625 12.8438 25.2344 12.5625L23.5 11.0156V20.25C23.5 22.3594 21.8125 24 19.75 24H6.25C4.14062 24 2.5 22.3594 2.5 20.25V11.0156L0.71875 12.5625C0.390625 12.8438 -0.078125 12.8438 -0.3125 12.5156C-0.59375 12.1875 -0.59375 11.7188 -0.265625 11.4375L12.4844 0.234375C12.7656 -0.046875 13.1875 -0.046875 13.4688 0.234375L26.2188 11.4375ZM6.25 22.5H9.25V15C9.25 14.2031 9.90625 13.5 10.75 13.5H15.25C16.0469 13.5 16.75 14.2031 16.75 15V22.5H19.75C20.9688 22.5 22 21.5156 22 20.25V9.70312L13 1.78125L4 9.70312V20.25C4 21.5156 4.98438 22.5 6.25 22.5ZM10.75 22.5H15.25V15H10.75V22.5Z" fill="#718198"/>
</svg></>}





      <span className="MuiBottomNavigationAction-label Mui-selected" style={{color:" #718198",paddingTop:"5px",
"text-align":"center",
"font-family": "Roboto",
"font-size": "12px",
"font-style":"normal",
"font-weight": "400",
"line-height":"normal"}}> Home</span>
    </span>
    <span className="MuiTouchRipple-root" />
    </div>
  </button>
  <button  className="MuiButtonBase-root MuiBottomNavigationAction-root" tabIndex={0} type="button">
    <div  style={{"text-decoration":"none",color:"black"}} onClick={(e)=>{gotoOrderHistory()}}><span className="MuiBottomNavigationAction-wrapper">
   
   {(currentRoute=="/orders")?<><svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24" fill="none">
<path d="M5.25 5.25C5.25 2.39062 7.59375 0 10.5 0C13.3594 0 15.75 2.39062 15.75 5.25V7.5H18.75C19.9688 7.5 21 8.53125 21 9.75V19.5C21 21.9844 18.9844 24 16.5 24H4.5C1.96875 24 0 21.9844 0 19.5V9.75C0 8.53125 0.984375 7.5 2.25 7.5H5.25V5.25ZM7.5 7.5H13.5V5.25C13.5 3.60938 12.1406 2.25 10.5 2.25C8.8125 2.25 7.5 3.60938 7.5 5.25V7.5ZM6.375 12C6.98438 12 7.5 11.5312 7.5 10.875C7.5 10.2656 6.98438 9.75 6.375 9.75C5.71875 9.75 5.25 10.2656 5.25 10.875C5.25 11.5312 5.71875 12 6.375 12ZM14.625 9.75C13.9688 9.75 13.5 10.2656 13.5 10.875C13.5 11.5312 13.9688 12 14.625 12C15.2344 12 15.75 11.5312 15.75 10.875C15.75 10.2656 15.2344 9.75 14.625 9.75Z" fill="#583C43"/>
</svg></>:<><svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24" fill="none">
<path d="M6 6V4.5C6 2.01562 7.96875 0 10.5 0C12.9844 0 15 2.01562 15 4.5V6H18.75C19.9688 6 21 7.03125 21 8.25V19.5C21 21.9844 18.9844 24 16.5 24H4.5C1.96875 24 0 21.9844 0 19.5V8.25C0 7.03125 0.984375 6 2.25 6H6ZM7.5 6H13.5V4.5C13.5 2.85938 12.1406 1.5 10.5 1.5C8.8125 1.5 7.5 2.85938 7.5 4.5V6ZM2.25 7.5C1.82812 7.5 1.5 7.875 1.5 8.25V19.5C1.5 21.1875 2.8125 22.5 4.5 22.5H16.5C18.1406 22.5 19.5 21.1875 19.5 19.5V8.25C19.5 7.875 19.125 7.5 18.75 7.5H15V11.25C15 11.6719 14.625 12 14.25 12C13.8281 12 13.5 11.6719 13.5 11.25V7.5H7.5V11.25C7.5 11.6719 7.125 12 6.75 12C6.32812 12 6 11.6719 6 11.25V7.5H2.25Z" fill="#718198"/>
</svg></>}

      <span className="MuiBottomNavigationAction-label" style={{color:" #718198",paddingTop:"5px",
"text-align":"center",
"font-family": "Roboto",
"font-size": "12px",
"font-style":"normal",
"font-weight": "400",
"line-height":"normal"}}>Orders</span>
    </span>
    <span className="MuiTouchRipple-root" />
  
    </div>
  </button>
  <button className="MuiButtonBase-root MuiBottomNavigationAction-root" tabIndex={0} type="button">
 <div  style={{"text-decoration":"none",color:"black"}} onClick={(e)=>{gotoProfile()}}>
  <div  style={{"text-decoration":"none"}}>
  <span className="MuiBottomNavigationAction-wrapper">
  {!(currentRoute=="/profile")?<><svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
<path d="M11 12C14.2812 12 17 9.32812 17 6C17 2.71875 14.2812 0 11 0C7.67188 0 5 2.71875 5 6C5 9.32812 7.67188 12 11 12ZM11 1.5C13.4375 1.5 15.5 3.5625 15.5 6C15.5 8.48438 13.4375 10.5 11 10.5C8.51562 10.5 6.5 8.48438 6.5 6C6.5 3.5625 8.51562 1.5 11 1.5ZM13.3438 14.25H8.60938C4.10938 14.25 0.5 17.9062 0.5 22.4062C0.5 23.2969 1.20312 24 2.09375 24H19.8594C20.75 24 21.5 23.2969 21.5 22.4062C21.5 17.9062 17.8438 14.25 13.3438 14.25ZM19.8594 22.5H2.09375C2.04688 22.5 2 22.4531 2 22.4062C2 18.75 4.95312 15.75 8.60938 15.75H13.3438C17 15.75 20 18.75 20 22.4062C20 22.4531 19.9062 22.5 19.8594 22.5Z" fill="#718198"/>
</svg></>:<><svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
<path d="M11 12C14.2812 12 17 9.32812 17 6C17 2.71875 14.2812 0 11 0C7.67188 0 5 2.71875 5 6C5 9.32812 7.67188 12 11 12ZM13.3438 14.25H8.60938C4.10938 14.25 0.5 17.9062 0.5 22.4062C0.5 23.2969 1.20312 24 2.09375 24H19.8594C20.75 24 21.5 23.2969 21.5 22.4062C21.5 17.9062 17.8438 14.25 13.3438 14.25Z" fill="#583C43"/>
</svg></>}

      <span className="MuiBottomNavigationAction-label" style={{color:" #718198",paddingTop:"5px",
"text-align":"center",
"font-family": "Roboto",
"font-size": "12px",
"font-style":"normal",
"font-weight": "400",
"line-height":"normal"}}>Profile</span>
    </span></div>
    </div>
    <span className="MuiTouchRipple-root" />
  </button>
</div>

  );
}

export default BottomNarBar;
