import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { FETCH_USER_ALL_LOGOUT_REQUEST, FETCH_USER_DETAILS_REQUEST, FETCH_USER_LOGIN_COUNT_REQUEST, FETCH_USER_LOGOUT_REQUEST, fetchLogoutAllDeviceFailure, fetchLogoutAllDeviceSuccess, fetchLogoutCurrentDeviceFailure, fetchLogoutCurrentDeviceSuccess, fetchUserDetailsFailure, fetchUserDetailsSuccess } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetcheUserDetails(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(fetchUserDetailsSuccess(response));
  
      action.data.handleResponseUserDetails(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorUserDetails(error)
      yield put(fetchUserDetailsFailure(error.message));
    }
  }

    /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

function* fetcheLogout(action) {
  console.log("fetchPreCard saga", action);
  try {
    const response = yield call(callApi, action);

    yield put(fetchLogoutCurrentDeviceSuccess(response));

    action.data.handleResponseLogoutCurrentDevice(response);
  } catch (error) {
    console.log("fetchPreCard error", error.message);
    
    yield put(fetchLogoutCurrentDeviceFailure(error.message));
    action.data.handleErrorLogoutCurrentDevice(error)
  }
}


function* fetcheLogoutAll(action) {
  console.log("fetchPreCard saga", action);
  try {
    const response = yield call(callApi, action);

    yield put(fetchLogoutAllDeviceSuccess(response));

    action.data.handleResponseLogoutAllDevice(response);
  } catch (error) {
    console.log("fetchPreCard error", error.message);
    
    yield put(fetchLogoutAllDeviceFailure(error.message));
    action.data.handleErrorLogoutAllDevice(error)
  }
}


  function* UserDetailsSaga() {
    yield takeLatest(FETCH_USER_DETAILS_REQUEST, fetcheUserDetails);
    yield takeLatest (FETCH_USER_LOGOUT_REQUEST,fetcheLogout)
    yield takeLatest(FETCH_USER_ALL_LOGOUT_REQUEST,fetcheLogoutAll)
    
    
  }
  
  export default UserDetailsSaga;