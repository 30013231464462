export const FETCH_PICKUP_ORDER_REQUEST="FETCH_PICKUP_ORDER_REQUEST"
export const FETCH_PICKUP_ORDER_SUCCESS="FETCH_PICKUP_ORDER_SUCCESS"
export const FETCH_PICKUP_ORDER_FAILURE="FETCH_PICKUP_ORDER_FAILURE"


export const fetchPickupOrderRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_PICKUP_ORDER_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchPickupOrderSuccess=(PickupOrder)=>{
return{
    type:FETCH_PICKUP_ORDER_SUCCESS,
    OrderPickupOrderHistory:PickupOrder
}
}

export const fetchPickupOrderFailure=(error)=>{
    return{
    type:FETCH_PICKUP_ORDER_FAILURE,
    error:error
    }
}