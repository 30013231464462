import React,{useState,useEffect} from "react";
import mixpanel from 'mixpanel-browser'
mixpanel.init("791abdc0adcf4a969113fc6a44a806c8",{debug:true})
if(localStorage.getItem("mobileNo")){

    if(localStorage.getItem("temp_user_id")){
        console.log("mpclick",localStorage.getItem("UserId"))
        mixpanel.identify(localStorage.getItem("UserId"))
    }
console.log("mpclick with userid",mixpanel.get_distinct_id())
}
else{

    mixpanel.identify()
    
    console.log("mpclick guest",mixpanel.get_distinct_id())
    localStorage.setItem("temp_user_id",mixpanel.get_distinct_id())
}
// Set user properties or perform other actions
//mixpanel.people.set({ $name: "guest", email: "guest@example.com" });



// MixPnl.js file
export const mpClick = (data) => {
    console.log("mpClick",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        source: data.data.source,
        vmid: data.data.vmid,
       
    });}
  else {mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        name: "Event",
        company: "Pwa Event",
       
    });}
};

export const mpSearch = (data) => {
    console.log("mpClick",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        search_query: data.data.search_query,

       
    });}

};

export const mpSearchResult = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        search_query: data.data.search_query,
        count:data.data.count

       
    });}

};

export const mpSearchResultClicked = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        ResultType: data.data.ResultType,
        ResultId:data.data.ResultId

       
    });}

};




export const mpGlobalAddToCart = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        itemID:data.data.itemID,
        itemName:data.data.itemName,
        itemPrice:data.data.itemPrice,
        vmId:data.data.vmId,
        sourcePage:data.data.sourcePage

       
    });}

};




export const mpGlobalVmClicked = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        itemID:data.data.itemID,
        itemName:data.data.itemName,
        itemPrice:data.data.itemPrice,
        vmId:data.data.vmId,
        sourcePage:data.data.sourcePage

       
    });}

};

export const mpGlobalAddToCartError = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        Error:data.data.Error
       
    });}

};


export const mpCart = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        ItemsInCart:data.data.ItemsInCart,
        SourcePage:data.data.SourcePage

       
    });}

};


export const mpCartClicked = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        ItemsInCart:data.data.ItemsInCart,
        TotalCartValue:data.data.TotalCartValue

       
    });}

};



export const mpActiveOrdersVmInventory = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        order_id:data.data.order_id,
        items_count:data.data.items_count,
        SourcePage:data.data.SourcePage

       
    });}

};

export const mpAddItemintoCart = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        CartValue:data.data.CartValue,
        Number_of_Items:data.data.Number_of_Items,
       

       
    });}

};

export const mpChagePg = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        Current_default_gateway:data.data.Current_default_gateway,
      

       
    });}

};

export const mpPgOptions = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        Current_default_gateway:data.data.Current_default_gateway,
      

       
    });}

};

export const mpActiveOrder = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        Current_default_gateway:data.data.Current_default_gateway,
      

       
    });}

};


export const mpHelp = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
        order_id:data.data.order_id,
      

       
    });}

};

export const mpCoupon = (data) => {
    console.log("mpClick ",data)
    if(data.data){mixpanel.track(data.EventName?data.EventName:"tahzeeb not Event",{
       
      

       
    });}

};



const MixPnl=(props)=>{
   
   

/*  let mpClick=(d)=>{
        console.log("mpClick",d)
            mixpanel.track("Vm List Page",{
              name:"tahzeeb khan",
              company:"Daalchini"
            })
          }
 */
    useEffect(()=>{

        console.log("mpClick---useEffect",props.page)
        if(props.page){
        mixpanel.track(props.page?props.page:"tahzeeb", {
            Properties: props&&props.pro,
            company: "Daalchini PWA",
           
        });
    }
    
        
    },[])

    return(
    
    
    <>
    
    
    </>
    
    
    )
}

export default MixPnl;