import {  SEND_SKIP_LOGIN_REQUEST, SEND_SKIP_LOGIN_SUCCESS, SEND_SKIP_LOGIN_FAILURE } from "./action";


const initialState = {
    //////////////VM LIST VARIABLES///////////////
      SkipLogin_Guest_ClientId: null,
      SkipLogin_Guest_Jwt:null,
      SkipLogin_Guest_OauthToken:null,
      loading_Skip_Login:false,
      error_Skip_Login:null
     
}    



const LoginReducer = (state = initialState, action) => {
    console.log("SkipLoginDataReducer",action)
    switch (action.type) {
//////////////////////SWITCH CASES FOR ACTIVE ORDERS //////////////////////////////////////
case SEND_SKIP_LOGIN_REQUEST:
    return {
      ...state,
      
      loading_Skip_Login:false,
      error_Skip_Login:null
    };

  case SEND_SKIP_LOGIN_SUCCESS: 
    return {
      ...state,
      
      SkipLogin_Guest_ClientId: action&&action.SkipLoginRes&&action.SkipLoginRes&&action.SkipLoginRes.data&&action.SkipLoginRes&&action.SkipLoginRes.data.response&&action.SkipLoginRes.data.response.clientId,
      SkipLogin_Guest_OauthToken:action&&action.SkipLoginRes&&action.SkipLoginRes&&action.SkipLoginRes.data&&action.SkipLoginRes&&action.SkipLoginRes.data.response&&action.SkipLoginRes.data.response.oauthToken,
      SkipLogin_Guest_Jwt:action&&action.SkipLoginRes&&action.SkipLoginRes&&action.SkipLoginRes.data&&action.SkipLoginRes&&action.SkipLoginRes.data.response&&action.SkipLoginRes.data.response.jwt,
      loading_Skip_Login:false,
      error_Skip_Login:null
    
    
    };

  case  SEND_SKIP_LOGIN_FAILURE: 
  return {
    ...state,
 
      loading_Skip_Login:false,
      error_Skip_Login:action.error
   
  }
  
  
  
  
  
  
    default:
      return state;

  }
};

export default LoginReducer;
