import OrderStatusSuccesslogo from '../../assets/orderStatus.png'
import Cross from '../../assets/cross.png'
import RightTick from '../../assets/rightTick.png'
import Contacticon from '../../assets/contact.png'
import Failed from '../../assets/orderStatusFailedlogo.png'
const SodexoPaymentStatus=()=>{
  const finalAmount=parseInt(localStorage.getItem('finalAmount'));
    const sodexoPaymentStatus = JSON.parse(localStorage.getItem('sodexoPaymentStatus'));
    console.log("sodexoPay",sodexoPaymentStatus.data)
    return(<>
        {sodexoPaymentStatus.data&&sodexoPaymentStatus.data.success?
 
 <div
 style={{
   background:
     "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
 }}
 className=" pb-16 pt-20 overflow-y-auto h-screen"

>
<div style={{paddingTop:"20%"}}>
<div style={{}}>


<div style={{ }}>
<div style={{  }}>
<div style={{display:"flex",justifyContent:"space-around"}}>
<img

style={{ }}
src={OrderStatusSuccesslogo} 
/>
</div>


<div style={{display:"flex",justifyContent:"space-around"}}>
<h4
style={{ margin: "3px",color: "#64748B",


"font-family":"Roboto",
"font-size":"14px",
"font-style": "normal",
"font-weight": "400",
"line-height": "150%","letter-spacing":"0.2px",marginTop:"3%" }}
>
Paid Successfuly to 
</h4>

</div>
</div>

<div
style={{  }}
>
<div style={{display:"flex",justifyContent:"space-around"}}>
<h1
style={{
"margin-bottom": "8px",

color: "#722323",

color: "#718198",
"text-align":"center",


"font-family":"Roboto",
"font-size":"20px",
"font-style":"normal","font-weight":"700","line-height":"normal"
}}
>
Daalchini
</h1>
</div>


<div style={{display:"flex",justifyContent:"space-around"}}><div style={{  display:"flex"  }}><h3 style={{color:"#384F6F","text-align":"center","font-family":"Roboto","font-size":"30px","font-style":"normal","font-weight":"700","line-height":"normal"}}>₹{finalAmount&&finalAmount.toFixed(2)}</h3>  <img
 style={{     height: "33px",
 width: "33px",
 "margin-left": "10px"}}
  src={RightTick} 
/></div> 
</div>
<div style={{display: "flex","justify-content":"space-around"}}>
<div style={{display:"flex","align-items":"center",marginTop:"10%"  }}>
<h2 style={{ margin: "3px", "margin-left": "0px",color: "#718198",


"font-family":"Roboto",
"font-size": "14px",
"font-style": "normal",
"font-weight":"600",
"line-height": "150%",
"letter-spacing":"0.2px",}}>
 Order Id :
</h2>
<h2 style={{ color: "#384F6F",


"font-family":"Roboto","font-size":"14px","font-style":"normal","font-weight":"600","line-height":"150%","letter-spacing":"0.2px" }}>
   {sodexoPaymentStatus.data.orderId}

</h2>
</div>
</div>
<div style={{display:"flex",justifyContent:"space-around"}}>
<div style={{display:"flex" }}>
<h2 style={{ margin: "3px", "margin-left": "0px",color: "#718198",


"font-family":"Roboto",
"font-size":"13px",
"font-style": "normal",
"font-weight": "400",
"line-height": "150%","letter-spacing":"0.2px" }}>
 Payment Method:
</h2>
<h2 style={{ margin: "3px",color: "#384F6F",

"font-family":"Roboto",
"font-size": "13px",
"font-style": "normal",
"font-weight": "500",
"line-height":"150%",
"letter-spacing":"0.2px" }}>{sodexoPaymentStatus.data.pgName}</h2>
</div>
</div>
<div style={{ }}>

</div>
</div>
</div>
</div>
<div>
</div>

</div>

<div style={{display:"flex",bottom:"21%",position:"absolute",left:"35%",padding:"5px","border-color":"#c4cbd8"}}><img src={Contacticon}/>
<div style={{"margin-left":"11px",color:" #9aa0a6"}}>Contact Us</div>
</div>
</div> 
            
            : 
////////////////////////////////////////////////////////////////////////
<>
 <div
          style={{
            background:
              "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
          }}
          className=" pb-16 pt-20 overflow-y-auto h-screen"

        >
  <div style={{paddingTop:"20%"}}>
<div style={{}}>


  <div style={{ }}>
    <div style={{  }}>
      <div style={{display:"flex",justifyContent:"space-around"}}>
      <img
      
        style={{ }}
         src={Failed} 
      />
      </div>
      

      <div style={{display:"flex",justifyContent:"space-around"}}>
        <h4
      style={{ margin: "3px",color: "#F43F5E",


      "font-family":"Roboto",
      "font-size":"14px",
      "font-style": "normal",
      "font-weight": "400",
      "line-height": "150%","letter-spacing":"0.2px",marginTop:"3%" }}
        >
        Payment Failed!
        </h4>
    
      </div>
    </div>

    <div
      style={{  }}
    >
      <div style={{display:"flex",justifyContent:"space-around"}}>
      <h1
     style={{
      "margin-bottom": "8px",
     
      color: "#722323",
     
      color: "#718198",
"text-align":"center",


"font-family":"Roboto",
"font-size":"20px",
"font-style":"normal","font-weight":"700","line-height":"normal"
    }}
      >
        Daalchini
      </h1>
      </div>
  

 <div style={{display:"flex",justifyContent:"space-around"}}><div style={{  display:"flex"  }}><h3 style={{color:"#384F6F","text-align":"center","font-family":"Roboto","font-size":"30px","font-style":"normal","font-weight":"700","line-height":"normal"}}>₹{finalAmount&&finalAmount.toFixed(2)}</h3>  <img
          style={{     height: "33px",
          width: "33px",
          "margin-left": "10px"}}
           src={Cross} 
        /></div> 
        </div>
        <div style={{display: "flex","justify-content":"space-around"}}>
      <div style={{display:"flex","align-items":"center",marginTop:"10%"  }}>
        <h2 style={{ margin: "3px", "margin-left": "0px",color: "#718198",


"font-family":"Roboto",
"font-size": "14px",
"font-style": "normal",
"font-weight":"600",
"line-height": "150%",
"letter-spacing":"0.2px",}}>
          Order Id :
        </h2>
        <h2 style={{ color: "#384F6F",


"font-family":"Roboto","font-size":"14px","font-style":"normal","font-weight":"600","line-height":"150%","letter-spacing":"0.2px" }}>
            {sodexoPaymentStatus.data.orderId}
         
        </h2>
      </div>
      </div>
      <div style={{display:"flex",justifyContent:"space-around"}}>
      <div style={{display:"flex" }}>
        <h2 style={{ margin: "3px", "margin-left": "0px",color: "#718198",


"font-family":"Roboto",
"font-size":"13px",
"font-style": "normal",
"font-weight": "400",
"line-height": "150%","letter-spacing":"0.2px" }}>
          Payment Method:
        </h2>
        <h2 style={{ margin: "3px",color: "#384F6F",

"font-family":"Roboto",
"font-size": "13px",
"font-style": "normal",
"font-weight": "500",
"line-height":"150%",
"letter-spacing":"0.2px" }}>{sodexoPaymentStatus.data.pgName}</h2>
      </div>
      </div>
      <div style={{ }}>
        
      </div>
    </div>
  </div>
</div>
<div>
</div>
<div style={{display:"flex"}}><p style={{padding:"7%",textAlign:"center", color:"#384F6F",


fontFamily: "Roboto",
fontSize:"13px",
fontStyle:"normal",
fontWeight:"400",
lineHeight:"150%",
"letterSpacing":"0.2px"}}>If your money is deducted, it will be auto refunded withn 24 hrs.</p></div>
</div>

  <div style={{display:"flex",bottom:"21%",position:"absolute",left:"35%",padding:"5px","border-color":"#c4cbd8"}}><img src={Contacticon}/>
  <div style={{"margin-left":"11px",color:" #9aa0a6"}}>Contact Us</div>
  </div>
</div> 





//////////////////////////////////////////////////////////////////////////


       

  
        
         
                  
  
  
              
   

  

</>
            }
            </>
    )
}
export default SodexoPaymentStatus;





  